import React, { useEffect, useState, useContext } from "react"
import Tile from "../Tile/Tile"
import Button from "../Button/Button"
import cx from "classnames"
import LoadingIcon from "../Loading/LoadingIcon"
import UserContext from "../../store/UserContext"
import { getToken } from "../../utils/common"
import BookingFormView from "../Booking/General/BookingFormView"
import SelectionDetailsView from "../Booking/Selector/SelectionDetailsView"
import SelectorOverlay from "../Booking/Selector/SelectorOverlay"
import styles from "../Booking/roomSelectionView.module.css"

var selectedRoomValue
const UpgradeRoomSelectionView = ({  nextStep }) => {
    const [loading, setLoading] = useState()
    const [error, setError] = useState(false)
    const [selection, setSelection] = useState(null)
    const [roomIndex, setroomIndex] = useState(null)
    const [open, setOpen] = useState(null)
    const [preview, setPreview] = useState(null)
    const [user, setUser] = useContext(UserContext)
    const bookingData = user.upgradeBooking;
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [selectedRoomId, setSelectedRoomId] = useState(null)
    const [disableButton, setDisableButton] = useState(true)
    
    useEffect(() => {
        var upgradeBooking = JSON.parse(sessionStorage.getItem('upgradeBooking'))
        fetch(
          process.env.REACT_APP_API_ENDPOINT +
            "/agent_portal/itineraries/" +
            bookingData.itinaryID.id+
            "/check_availability",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({
              visitorID: 123456,
              itineraryID: bookingData.itinaryID.id,
              promoCode: "", // TODO: connect landing page promo codes here
              rooms: bookingData.rooms
            //   rooms: rooms.map((room) => {
            //     return {
            //       adults,
            //       infants,
            //       children,
            //     }
            //   }),
            }),
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json()
            } else {
              throw new Error("Something went wrong")
            }
          })
          .then((response) => {
            setSelection(response.data.checkAvailability)
            setLoading(false)
          })
          .catch((error) => {
            setError(true)
            setLoading(false)
          })
      }, [loading])

      if (loading || error || !selection) {
        return (
          <h1 className="text-4xl">
            <LoadingIcon className="py-20 text-j-magenta" />
          </h1>
        )
      }

      const onSelect = (index, selected, fromSelector, selected_room) => {
        setDisableButton(false)
        const rooms = bookingData.rooms;

        if (selected_room) {

          rooms[index] = Object.assign(
            {},
            rooms[index],
            { selected },
            {
              room_id: selected_room.itinerary_room_id,
              total_price: selected_room.total_price,
              room_number: selected_room.number,
            }
          )
        } else {
          rooms[index] = Object.assign(
            {},
            rooms[index],
            { selected },
            { room_id: null, total_price: null, room_number: null }
          )
        }
        selectedRoomValue =
          index == rooms.length - 1 &&
          !rooms.filter((room) => !room.selected).length
            if (selectedRoomValue) {
            setUser(
                Object.assign({}, user, {
                upgradeBooking: Object.assign({}, bookingData, {
                    rooms,
                }),
                })
            )
            } else {
            setUser(
                Object.assign({}, user, {
                    upgradeBooking: Object.assign({}, bookingData, {
                    rooms,
                    roomsSelected: selectedRoomValue,
                }),
                })
            )
            }
      }

      const buttonFunction = () => {
        setDisableButton(true)
      }

      const conformingPaymentDetails = () => {
        // let bookingInput = {}
        // let visitorsIDNew = 123456
        // let itineraryID = bookingData.itinaryID.id
        // bookingInput.rooms = bookingData.rooms.map((room) => ({
        //   priceKey: room.selected.priceKey,
        //   roomId: room.room_id,
        // }))
        // fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings`, {
        //     method: "POST",
        //     headers: {
        //       Accept: "application/json",
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${getToken()}`,
        //     },
        //     body: JSON.stringify({
        //       variables: {
        //         input: bookingInput,
        //         visitorID: visitorsIDNew,
        //         itineraryID: itineraryID,
        //       },
        //     }),
        //   })
        //     .then((response) => response.json())
        //     .then((response) => {
        //       if (response.booking) {
        //       }
        //     })
        nextStep()
      }

      return (
        <>
            <BookingFormView
                title="Upgrade Your room"
                buttonText="Proceed to passenger information"
                lessSpace={true}
                onClick={() => {
                conformingPaymentDetails()
                window.scrollTo({ top: 0, behavior: "smooth" })
                setDisableButton(true)
                }}
                id="otp-form"
                disabled={disableButton}
            >
                {open && (
                    <div style={{ zIndex: 999999 }}>
                        <div
                        style={{
                            position: "fixed",
                            backgroundColor: "rgba(0,0,0, 0.2)",
                            zIndex: 999,
                            top: "0px",
                            left: "0px",
                            right: "0px",
                            bottom: "0px",
                        }}
                        // onClick={() => setOpen(false)}
                        ></div>
                        <SelectorOverlay
                        className="disable-scrollbars itinerarymodal"
                        onClose={() => {
                            setOpen(null);
                            setSelectedCategoryId(null)
                            setSelectedRoomId(null)
                        }}
                        rooms={bookingData.rooms}
                        selections={selection}
                        itinerary={bookingData.itinaryID}
                        onSelect={onSelect}
                        selectedCategory={open}
                        roomIndex={roomIndex}
                        />
                    </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {bookingData.rooms.map((room, i) => {
                         const newSelections = selection[i].categories
                         const index = newSelections.findIndex(data => data.name == room.roomCatogory);
                         newSelections.splice(0, index); 
                        return (<RoomView
                            room={room}
                            changePop={(selectedCategory, index) => {
                                setroomIndex(index)
                                setOpen(selectedCategory)
                            }}
                            selections={newSelections}
                            roomIndex={i}
                            onSelect={(selection) => onSelect(i, selection)}
                            onDetails={(room, selection, selectionIndex) => {
                                setPreview(Object.assign([room.id, selectionIndex]))
                            }}
                            selectedCategoryId={selectedCategoryId}
                            setSelectedCategoryId={setSelectedCategoryId}
                            selectedRoomId={selectedRoomId}
                            setSelectedRoomId={setSelectedRoomId}
                            bookingData={bookingData}
                            old_room_number={room}
                            buttonFunction= {buttonFunction}
                        />)}
                    )}
                    {preview && (
                    <div style={{ zIndex: 999999 }}>
                      <div
                        style={{
                          position: "fixed",
                          backgroundColor: "rgba(0,0,0, 0.2)",
                          zIndex: 999,
                          top: "0px",
                          left: "0px",
                          right: "0px",
                          bottom: "0px",
                        }}
                        onClick={() => setPreview(null)}
                      ></div>
                      {/* {selection[preview[0]]} */}
                      <SelectionDetailsView
                        rooms={bookingData.rooms}
                        selections={selection[preview[0]].categories}
                        selectionIndex={preview[1]}
                        roomIndex={preview[0]}
                        onClose={() => setPreview(null)}
                        onSelect={() => {
                          setroomIndex(preview[0])
                          setOpen(selection[preview[0]].categories[preview[1]])
                          setPreview(null)
                        }}
                      />
                      <div className="bg-white">{preview} </div>
                    </div>
                  )}
                </div>
            </BookingFormView>
        </>
      )
}

export default UpgradeRoomSelectionView;

const RoomView = ({
    room,
    onSelect,
    selections,
    onDetails,
    changePop,
    roomIndex,
    selectedCategoryId,
    setSelectedCategoryId,
    selectedRoomId,
    setSelectedRoomId,
    bookingData,
    old_room_number,
    buttonFunction
  }) => {

    const [updateRoom, setupdateRoom] = useState(null)
    useEffect(() => {
        const index = selections.findIndex(data => data.name == room.roomCatogory);
        selections.splice(0, index); 
        setupdateRoom(selections)
    },[updateRoom])

    if (!updateRoom) {
        return (
          <h1 className="text-4xl">
            <LoadingIcon className="py-20 text-j-magenta" />
          </h1>
        )
    }

    // const roomCardFunction = (selections) => {
    //     const index = selections.findIndex(data => data.name == "Balcony");
    //     selections.splice(0, index+1); 
    //     return( 
    //         selections.map((r) => {
    //             <RoomCard  />
    //         })
            
        //     selections.map((r, i) => {<RoomCard
        //     room={r}
        //     key={i}
        //     roomId={room.id}
        //     changePop={changePop}
        //     onChange={(selection) => onSelect(selection)}
        //     onDetails={(selection) => onDetails(room, selection, i)}
        //     roomIndex={roomIndex}
        //     selectedCategoryId={selectedCategoryId}
        //     setSelectedCategoryId={setSelectedCategoryId}
        //     selectedRoomId={selectedRoomId}
        //     setSelectedRoomId={setSelectedRoomId}
        // />}))
        // )
    //   ))
    // }

    const selection = room.selected ? (
      <RoomCard
        room={room.selected}
        roomId={room.id}
        selected
        changePop={changePop}
        onChange={() => onSelect(null)}
        onDetails={(selection) =>
          onDetails(
            room,
            selection,
            selections.map((s) => s.name).indexOf(room.selected.name)
          )
        }
        roomIndex={roomIndex}
        roomNumber={room.room_number}
        selectedCategoryId={selectedCategoryId}
        setSelectedCategoryId={setSelectedCategoryId}
        selectedRoomId={selectedRoomId}
        setSelectedRoomId={setSelectedRoomId}
        buttonFunction={buttonFunction}
      />
    ) : (
      updateRoom.map((r, i) => (
          
        <RoomCard
          room={r}
          indexValue={i}
          roomId={room.id}
          changePop={changePop}
          onChange={(selection) => onSelect(selection)}
          onDetails={(selection) => onDetails(room, selection, i)}
          roomIndex={roomIndex}
          roomNumber
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
          selectedRoomId={selectedRoomId}
          setSelectedRoomId={setSelectedRoomId}
          old_room_number={old_room_number}
          buttonFunction={buttonFunction}
        />
    //   ))
    ))
    )
  
    return (
      <Tile
        theme="magenta"
        shadow
        className="my-2 bg-bgcolor rounded-lg"
        id={`room-${room.id}`}
      >
        <Tile.Inner>
          <h5 className="uppercase">
            Cabin {room.id + 1} - {room.adults + room.children + room.infants}{" "}
            Guests
          </h5>
          <br />
          {selection}
        </Tile.Inner>
      </Tile>
    )
  }

  const RoomCard = ({
    room,
    indexValue,
    roomId,
    onChange,
    selected,
    onDetails,
    changePop,
    roomIndex,
    roomNumber,
    selectedCategoryId,
    setSelectedCategoryId,
    selectedRoomId,
    setSelectedRoomId,
    old_room_number,
    buttonFunction
  }) => {
    const [roomSelected, setRoomSelected] = useState(false)
    useEffect(() => {
      if (room.category_id === selectedCategoryId && roomId === selectedRoomId) {
        setRoomSelected(true)
      } else {
        setRoomSelected(false)
      }
    }, [selectedCategoryId, selectedRoomId])
    
    function onSelectIfSelected() {
      buttonFunction()
      onChange(room)
    }
  
    function onSelectNotSelected(roomId) {
      changePop(room, roomIndex)
      setSelectedCategoryId(room.category_id)
      setSelectedRoomId(roomId)
    }
  
    return (
      <Tile className="mb-8 relative rounded-lg overflow-hidden">
        <Tile.Inner
          tiny
          className={cx(
            "rounded-big",
            room.popular ? "bg-j-orange" : "bg-j-white",
            roomSelected ? styles.highlighter : ""
          )}
          theme={room.popular ? "orange" : "white"}
        >
          <div className="flex justify-between">
            <h4
              className={cx(
                "font-bold pb-0 self-center",
                room.popular ? "text-white" : "text-j-magenta"
              )}
            >
              {room.name}
            </h4>
            {indexValue != 0 && <Button
              className={cx(
                "border self-center pt-1",
                room.popular
                  ? "border-white bg-j-orange text-j-white"
                  : "border-j-gray bg-j-white text-j-gray"
              )}
              onClick={() => {
                selected ? onSelectIfSelected() : onSelectNotSelected(roomId)
              }}
            >
              {selected ? "Change" : "Upgrade"}
            </Button>
            }
          </div>
          {selected && (
            <div className="pt-3">
              <div className="text-xs text-j-green" style={{fontSize: "0.95rem", fontWeight: "bold"}}>Room No: {roomNumber}</div>
            </div>
          )}
          {indexValue == 0 && <div className="flex justify-between pt-5 text-xs text-j-green" style={{fontSize: "0.95rem", fontWeight: "bold"}}>
              Selected Room: {old_room_number.roomNumber}
          </div>}
          <div className="flex justify-between pt-5">
            <a
              className={cx(
                "text-xs uppercase cursor-pointer self-center",
                room.popular ? "text-j-white" : "text-j-orange"
              )}
              onClick={() => {
                onDetails(room)
              }}
            >
              Details
            </a>
            <h2
              className={cx(
                "leading-none self-center pb-0 pr-6 text-2xl font-normal",
                room.popular ? "text-j-white" : "text-j-black"
              )}
            >
              &#x20B9; {(room.price.total || 0).toLocaleString("hi-IN")}
            </h2>
          </div>
        </Tile.Inner>
      </Tile>
    )
  }