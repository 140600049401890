import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import BookingFormView from "./General/BookingFormView";
import Tile from "../Tile/Tile";
import Button from "../Button/Button";
import cx from "classnames";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../store/UserContext";
import { getToken } from "../../utils/common";
import SummaryView from "./General/SummaryView";
import LoadingIcon from "../Loading/LoadingIcon";
import InputField from "../Form/InputField";
import PhoneField from "../Form/PhoneFieldAlt";
import PhoneFieldP2A from "../Form/PhoneFieldAltP2A";
import TextField from "../Form/TextField";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { name, phone, email, date, phone_pattern, first_name } from "../../utils/validations";
import CheckBox from "../Form/CheckBox";
import RadioField from "../Form/RadioField";
import CardPaymentForm from "./payment-forms/CardPaymentForm";
import BankPaymentForm from "./payment-forms/BankPaymentForm";
import UPIPaymentForm from "./payment-forms/UPIPaymentForm";
import WalletPayment from "./payment-forms/WalletPayment";
import scrollTo from "../../utils/scrollTo";
import moment from "moment";
import NimbblPaymentForm from './payment-forms/NimbblPaymentForm';
// import NimbblCheckout from './nimbleCheckout.js'

class ConformBookingView extends Component {
    documentData;
    constructor(props) {
        super(props);
        this.state = {
            booking: [],
            selection: []
        }
    }

    componentWillMount() {
        this.documentData = JSON.parse(localStorage.getItem('store'));
        if (localStorage.getItem('store')) {
            this.setState({ booking: this.documentData })
            this.setState({ selection: JSON.parse(sessionStorage.getItem('checkAvailability')) })
        }
    }

    backbuttonpressed = (e) => {
        e.preventDefault()
        this.props.prevStep()
    }

    handleSubmit = () => {

    }

    render() {
        return (
            <>
                <SummaryViewUI backButtonFunction={this.props.backButtonFunction} />
            </>
        )
    }
}

export default ConformBookingView;

const SummaryViewUI = (backButtonFunction) => {
    let history = useHistory();
    const [user, setUser] = React.useContext(UserContext);
    console.log('roh user', user);
    const booking = user.booking || {};
    const due_Date = user.due_date;
    const paymentOptionID = user.payment_option_id;
    const [isFailed, setFailed] = useState(false);
    const [panToggle, setPanToggle] = useState(true);
    let departure = booking.departure;
    let departureTime = booking.departure_time;
    let bookigStore = JSON.parse(window.sessionStorage.getItem('booking'));
    const [amountStatus, setAmount] = useState(0);
    const totalPrice = bookigStore.total;
    let twentyPercentage = window.sessionStorage.getItem('partialPayment');
    let partialPayment = window.sessionStorage.getItem('partialPayment');
    const paymentRule = window.sessionStorage.getItem('partialRule');
    let tcsTaxes = JSON.parse(sessionStorage.getItem('tcs_taxes'));
    // let twentyPercentage = totalPrice *(parseFloat(paymentRule)/100);
    twentyPercentage = twentyPercentage;
    const [renderView, setRenderView] = useState(false)
    let itiId = JSON.parse(localStorage.getItem('store'));
    itiId = itiId.booking.itinerary.id;
    console.log(itiId)

    const [travelExpense, setTravelExpense] = useState(false);
    const [isAbove7L, setIsAbove7L] = useState(false);
    const [taxRegime, setTaxRegime] = useState('new_regime');
    const [taxType, setTaxType] = useState('tcs');
    const [tcsObject, setTcsObject] = useState();
    // const twentyPercentage = totalPrice*0.25;
    const remaingAmount = totalPrice - twentyPercentage;

    useEffect(() => {
        if (totalPrice > 700000) {
            setIsAbove7L(true)
            setTravelExpense(false)
        } else {
            setIsAbove7L(false)
            setIsAbove7L(false)
        }
    }, [])

    useEffect(() => {
        let is_international = JSON.parse(window.sessionStorage.getItem('is_international'))
        if (is_international) {
            if (taxType == 'tcs') {
                if (travelExpense) {
                    setTcsObject(tcsTaxes.tcs_with_pan)
                } else {
                    setTcsObject(tcsTaxes.tcs)
                }
            } else {
                const data = {
                    "pct": 0,
                    "amount": 0,
                }
                setTcsObject(data)
            }
        }
    }, [travelExpense, taxType])

    useEffect(() => {

        setRenderView(true)

        const URL_status = new URLSearchParams(window.location.search).get("fail");
        const flow = window.sessionStorage.getItem('flowDirection');
        if (flow == 'true') {
            if (URL_status == 1) {
                setFailed(true);
            }
        }
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/balance`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                    `Bearer ${getToken()}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setAmount(response.amount)
            })
    }, [])
    // x = x.split(" - ");

    let bookingArr = booking.rooms;

    return (
        <>
            {renderView && <> <h1 className="text-xl font-bold mt-4" id="payment">{isFailed ? "Request failed!" : "Payment"}</h1>
                {isFailed ? (
                    <div className="bg-j-yellow text-j-black rounded-big p-3 my-4">
                        <h1 className="text-base font-medium pb-0">
                            <i className="fas fa-exclamation-triangle text-2xl pr-1" />
                            Payment failed. Please try again!
                        </h1>
                        <p className="text-xs leading-relaxed">
                            Somehow, we couldn't receive your payment. Any amount deducted from
                            your account will be refunded in 7 working days.
                        </p>
                    </div>
                ) : null}
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2 pt-0 mt-4">
                    <SummaryView
                        booking={booking}
                        paymentPage={true}
                        itineraryID={itiId}
                        panToggle={panToggle}
                        totalPrice={totalPrice}
                        taxType={taxType}
                        setTaxType={setTaxType}
                        taxRegime={taxRegime}
                        setTaxRegime={setTaxRegime}
                        travelExpense={travelExpense}
                        setTravelExpense={setTravelExpense}
                        isAbove7L={isAbove7L}
                        setIsAbove7L={setIsAbove7L}
                        tcsObject={tcsObject}
                    />
                    <div>
                        <ConformBooking
                            amountStatus={amountStatus}
                            booking={booking}
                            totalPrice={totalPrice}
                            twentyPercentage={twentyPercentage}
                            partialPayment={partialPayment}
                            remaingAmount={remaingAmount}
                            paymentRule={paymentRule}
                            due_Date={due_Date}
                            paymentOptionID={paymentOptionID}
                            backButtonFunction={backButtonFunction}
                            setPanToggle={setPanToggle}
                            taxType={taxType}
                            taxRegime={taxRegime}
                            travelExpense={travelExpense}
                            isAbove7L={isAbove7L}
                            tcsObject={tcsObject}
                        />
                    </div>
                </div>
            </>
            }
        </>
    )
}

const ConformBooking = ({ amountStatus, booking, totalPrice, twentyPercentage, partialPayment, remaingAmount, paymentRule, due_Date, paymentOptionID, backButtonFunction, setPanToggle, taxType, taxRegime, travelExpense, isAbove7L, tcsObject }) => {
    let history = useHistory();
    const [submitted, setSubmitted] = useState(false);
    const [user, setUser] = React.useContext(UserContext);
    let bookingID = window.sessionStorage.getItem('booking');
    var storageProfile = JSON.parse(localStorage.getItem('UserProfile'));
    const [defaultname, setDefaultName] = useState(storageProfile.name)
    const [defaultFirstname, setDefaultFirstName] = useState(storageProfile.first_name)
    const [defaultLastname, setDefaultLastName] = useState(storageProfile.last_name)
    const [defaultenumber, setDefaultNumber] = useState(storageProfile.phone_number)
    const [defaultemail, setDefaultEmail] = useState(storageProfile.email)
    const [defaultaddress, setDefaultAddress] = useState(storageProfile.address)
    const [defaultGSTN, setDefaultGSTN] = useState(storageProfile.gstin)
    const [defaultGSTName, setDefaultGSTName] = useState(storageProfile.gstName)
    const [defaultPAN, setDefaultPAN] = useState(storageProfile.pan)
    const [defaultState, setDefaultState] = useState(storageProfile.state)

    var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))
    let is_international = JSON.parse(window.sessionStorage.getItem('is_international'))
    let payment_option_tcs_response = JSON.parse(window.sessionStorage.getItem('payment_option_tcs_response'))
    const [billingData, setBillingData] = useState(null);
    bookingID = JSON.parse(bookingID);
    const accountType = bookingID.agent_commission_type;
    bookingID = bookingID.id;
    // const formRef = useRef(null)
    const isGiftCard = booking.giftCard;
    const [showTax, setShowTax] = useState(!isGiftCard && user.gstin);
    const [loading, setLoading] = useState(false);
    const [cardOpen, cardDetails] = useState(false);
    const [partialStatus, setPartialStatus] = useState(false);
    const [fullPay, setFullPay] = useState(true);
    const [displayAmount, setDisplayAmount] = useState("Full")
    const submitRef = useRef(null);
    const [gstValidation, setGSTValidation] = useState(false);
    const [partialTcsObject, setPartialTcsObject] = useState();
    const [isFailed, setFailed] = useState(
        new URLSearchParams(window.location.search).get("fail")
    );
    const [backButtonstatus, setBackButtonstatus] = useState(true)

    const bookingInput = {
        contact: {
            first_name: "",
            last_name: "",
            email: "",
            phoneNumber: "",
        },
        paymentInfo: {
            plan: "",
            voucherNumber: "",
            promoCode: "",
            first_name: "",
            last_name: "",
            email: "",
        },
    };

    bookingInput.paymentInfo.type = "wallet"
    bookingInput.paymentInfo.amount = totalPrice

    // const handleSubmit = (requestData) => {
    //     setLoading(true);
    //     fetch(`https://cordelia.fortelogic.in/agent_portal/payments`, {
    //         method: 'POST',
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization:
    //             `Bearer ${getToken()}`,
    //         },
    //         body: JSON.stringify(requestData)
    //     }).then(response => response.json())
    //     .then(response => {
    //         if(response.status == 'Success') {
    //             setLoading(false);
    //             history.push("/admin/success");
    //         }
    //     }).catch(err => err);
    //     // history.push("/admin/success");
    // }

    const schema = Joi.object({
        first_name,
        phone,
        email,
        last_name: accountType == 'p2a' ? Joi.string().min(2).required() : Joi.string().empty(""),
        address: Joi.string().empty(""),
        gst: Joi.string().empty(""),
        pan: Joi.string().empty(""),
        state: Joi.string().empty(""),
        haveGstin: Joi.string().empty(""),
        gstin: Joi.string().empty(""),
        gstin_name: Joi.string().min(2).empty(""),
        gstin_phone: Joi.string().pattern(phone_pattern).empty(""),
    })
        .with("haveGstin", "gstin")
        .with("haveGstin", "gstin_name")
        .with("haveGstin", "gstin_phone");

    const { register, errors, handleSubmit, setError } = useForm({
        resolver: joiResolver(schema),
    });

    const submitHandler = handleSubmit((data) => {
        const { address, gst, pan, state, haveGstin, gstin, gstin_name, gstin_phone, ...rest } = data;
        console.log(gst)
        let billing = {}
        const cleanData = Object.assign(
            {},
            rest,
            { address },
            { gst },
            { gstin_name },
            { pan },
            { is_international },
            { state },
            haveGstin && gstin && gstin_name && gstin_phone
                ? { gstin, gstin_name, gstin_phone }
                : {}
        );
        if (is_international) {
            if (taxType == 'tcs') {
                billing = { 'expense_above_7l': !travelExpense, tax_regime: taxRegime, tds_opted: false, ...cleanData }
            } else {
                billing = { 'expense_above_7l': null, tax_regime: taxRegime, tds_opted: true, ...cleanData }
            }
        } else {
            billing = { ...cleanData }
        }
        if (gst == "" || gst == undefined) {
            setGSTValidation(false)
            setBillingData(billing);
            cardDetails(true)

        } else {
            var gstRegix = gst.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)
            if (gstRegix == null) {
                setGSTValidation(true)
            } else {
                setGSTValidation(false)
                setBillingData(billing);
                cardDetails(true)
            }
        }
        setUser(
            Object.assign(
                {},
                user,
                { email: data.email },
                showTax
                    ? {
                        gstin: data.gstin,
                        name: data.gstin_name,
                        phone: data.gstin_phone,
                    }
                    : {}
            )
        );
    });

    const setFailedError = (error) => {
        setFailed(error);
        if (error) scrollTo("payment");
    };

    useEffect(() => {
        if (taxType == 'tcs') {
            if (travelExpense) {
                setPartialTcsObject(payment_option_tcs_response.tcs_with_pan)
            } else {
                setPartialTcsObject(payment_option_tcs_response.tcs)
            }
        } else {
            setPartialTcsObject('')
        }
    }, [partialStatus, travelExpense, taxType])

    const onChangeValue = (status, e) => {
        setFullPay(!fullPay);
        if (status == false) {
            setPartialStatus(true);
        } else {
            setPartialStatus(false);
        }
        // setPartialStatus(status);
        if (displayAmount == 'Full') {
            setDisplayAmount('Partial')
        } else {
            setDisplayAmount('Full');
        }
    }

    const onChangeName = (e) => {
        setDefaultName(e.target.value)
    }

    const onChangeFirstName = (e) => {
        setDefaultFirstName(e.target.value)
    }

    const onChangeLastName = (e) => {
        setDefaultLastName(e.target.value)
    }

    const onChangeNumber = (e) => {
        console.log(e.target.value)
        setDefaultNumber(e.target.value)
    }

    const onChangeEmail = (e) => {
        setDefaultEmail(e.target.value)
    }

    const onChangeGSTN = (e) => {
        setDefaultGSTN(e.target.value)
        setGSTValidation(false)
    }
    const onChangeGSTName = (e) => {
        setDefaultGSTName(e.target.value)
    }

    const onChangeAddress = (e) => {
        setDefaultAddress(e.target.value)
    }

    const onChangePAN = (e) => {
        setDefaultPAN(e.target.value)
        if (e.target.value !== "" && e.target.value !== null) {
            setPanToggle(true);
        } else {
            setPanToggle(false);
        }
    }

    const onChangeState = (e) => {
        setDefaultState(e.target.value)
    }

    const restTOdefault = () => {
        setDefaultName(storageProfile.name)
        setDefaultFirstName(storageProfile.first_name)
        setDefaultLastName(storageProfile.last_name == null ? '' : storageProfile.last_name)
        setDefaultNumber(storageProfile.phone_number)
        setDefaultEmail(storageProfile.email)
        setDefaultAddress(storageProfile.address)
        setDefaultGSTN(storageProfile.gstin)
        setDefaultPAN(storageProfile.pan)
        if (storageProfile.pan !== "" && storageProfile.pan !== null) {
            setPanToggle(true);
        } else {
            setPanToggle(false);
        }
        setGSTValidation(false)
        console.log(storageProfile.state)
        if (storageProfile.state == undefined) {
            setDefaultState("")
        } else {
            setDefaultState(storageProfile.state)
        }
    }

    const handleWalletPayNow = () => {
        setBackButtonstatus(false)
    }

    const handleWalletPayNowTrue = () => {
        setBackButtonstatus(true)
    }

    return (
        <>
            {/* <h1 className="alt">Billing details</h1> */}
            <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta" >
                <Tile.Inner className="pb-0">
                    {!cardOpen && <>
                        <div className="flex">
                            <h1 className="text-grey-600 text-sm w-9/12">Billing details</h1>
                            {accountType == 'p2a' && <p className="w-3/12 text-j-orange cursor-pointer border text-center rounded-lg shadow pt-1 pb-1" onClick={restTOdefault}><span><i className="fas fa-redo pr-2"></i></span>Reset</p>}
                        </div>
                        <div className="pt-10">
                            <form onSubmit={submitHandler}>
                                {accountType == 'p2p' ? (
                                    <div className="grid grid-cols-2 gap-2" >
                                        <InputField
                                            readonly="true"
                                            placeholder="First name"
                                            name="first_name"
                                            lessSpace
                                            defaultValue={defaultFirstname}
                                            value={defaultFirstname}
                                            ref={register({ required: true })}
                                            error={errors && errors.first_name && "Please enter your first name"}
                                        />
                                        <InputField
                                            readonly="true"
                                            placeholder="Last name"
                                            name="last_name"
                                            lessSpace
                                            defaultValue={defaultLastname}
                                            value={defaultLastname}
                                            ref={register({ required: true })}
                                            error={errors && errors.last_name && "Please enter your last name"}
                                        />
                                    </div>) : <>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div>
                                            <InputField
                                                placeholder="First name"
                                                name="first_name"
                                                lessSpace
                                                defaultValue={defaultFirstname}
                                                value={defaultFirstname}
                                                onChangeValue={onChangeFirstName}
                                                pathName="payment"
                                                ref={register({ required: true })}
                                                error={errors && errors.first_name && "Please enter your first name"}
                                            />
                                        </div>
                                        <div>
                                            <InputField
                                                placeholder="Last name"
                                                name="last_name"
                                                lessSpace
                                                defaultValue={defaultLastname}
                                                value={defaultLastname}
                                                onChangeValue={onChangeLastName}
                                                pathName="payment"
                                                ref={register({ required: true })}
                                                error={errors && errors.last_name && "Please enter your last name"}
                                            />
                                        </div>

                                    </div>
                                </>
                                }
                                {accountType == 'p2p' ? <PhoneField
                                    readonly="true"
                                    name="phone"
                                    placeholder="Phone number"
                                    ref={register({ required: true })}
                                    lessSpace
                                    payementpage={true}
                                    accountType={accountType}
                                    defaultValue={defaultenumber}
                                    defaultenumber={defaultenumber}
                                    noIcon
                                /> : <PhoneField
                                    name="phone"
                                    placeholder="Phone number"
                                    ref={register({ required: true })}
                                    lessSpace
                                    payementpage={true}
                                    accountType={accountType}
                                    defaultValue={defaultenumber}
                                    defaultenumber={defaultenumber}
                                    value={defaultenumber}
                                    onChangeValue={onChangeNumber}
                                    pathName="payment"
                                    noIcon
                                    error={
                                        errors && errors.phone && "Please enter a correct phone number"
                                    }
                                />
                                }
                                {accountType == 'p2p' ? <InputField
                                    readonly="true"
                                    placeholder="Email address"
                                    type="email"
                                    name="email"
                                    lessSpace
                                    defaultValue={defaultemail}
                                    ref={register({ required: true })}
                                    error={errors && errors.email && "Please enter a valid email address"}
                                /> : <InputField
                                    placeholder="Email address"
                                    type="email"
                                    name="email"
                                    lessSpace
                                    defaultValue={defaultemail}
                                    value={defaultemail}
                                    onChangeValue={onChangeEmail}
                                    pathName="payment"
                                    ref={register({ required: true })}
                                    error={errors && errors.email && "Please enter a valid email address"}
                                />
                                }
                                {accountType == 'p2a' ? <TextField
                                    placeholder="Address"
                                    name="address"
                                    lessSpace
                                    className="border rounded shadow"
                                    defaultValue={defaultaddress}
                                    value={defaultaddress}
                                    onChangeValue={onChangeAddress}
                                    pathName="payment"
                                    ref={register({ required: false })}
                                // error={errors && errors.name && "Please enter your full name"}
                                /> : null
                                }
                                {accountType == 'p2a' || is_international ? <div className='flex'>
                                    <InputField
                                        className="w-full"
                                        placeholder="GSTIN NO"
                                        name="gst"
                                        lessSpace
                                        defaultValue={defaultGSTN}
                                        value={defaultGSTN}
                                        onChangeValue={onChangeGSTN}
                                        pathName="payment"
                                        gstValidation={gstValidation}
                                        ref={register({ required: false })}
                                        error={errors && errors.gst && "Provide valid GSTIN NO"}
                                    />
                                    <InputField
                                        className="w-full"
                                        placeholder="GST Name"
                                        name="gstin_name"
                                        lessSpace
                                        defaultValue={defaultGSTName}
                                        value={defaultGSTName}
                                        onChangeValue={onChangeGSTName}
                                        pathName="payment"
                                        // gstValidation={gstValidation}
                                        ref={register({ required: false })}
                                    // error={errors && errors.gst && "Provide valid GSTIN NO"}
                                    />
                                </div> : null
                                }
                                {accountType == 'p2a' ? <InputField
                                    placeholder="PAN number"
                                    name="pan"
                                    lessSpace
                                    defaultValue={defaultPAN}
                                    value={defaultPAN}
                                    onChangeValue={onChangePAN}
                                    pathName="payment"
                                    ref={register({ required: false })}
                                    error={errors && errors.pan && "Provide valid PAN NO"}
                                /> : null
                                }
                                {accountType == 'p2a' ? <InputField
                                    placeholder="State"
                                    name="state"
                                    lessSpace
                                    defaultValue={defaultState}
                                    value={defaultState}
                                    onChangeValue={onChangeState}
                                    pathName="payment"
                                    ref={register({ required: false })}
                                    error={errors && errors.state && "Please enter your state"}
                                /> : null
                                }
                                <input
                                    name="haveGstin"
                                    type="hidden"
                                    value={showTax ? "1" : ""}
                                    ref={register()}

                                />
                                {/* {!isGiftCard && (
                                <CheckBox onChange={() => setShowTax(!showTax)} value={showTax}>
                                    I have GSTIN
                                </CheckBox>
                            )} */}
                                {/* <div className={showTax ? "" : "hidden"}>
                                <InputField
                                    placeholder="GSTIN"
                                    name="gstin"
                                    lessSpace
                                    defaultValue={(user.gstin && user.gstin.gstin) || ""}
                                    ref={register({ required: true })}
                                    error={errors && errors.gstin && "Please enter GSTIN"}
                                />
                                <InputField
                                    placeholder="Name"
                                    name="gstin_name"
                                    lessSpace
                                    defaultValue={
                                    (user.gstin && user.gstin.name) || user.name || booking.name
                                    }
                                    ref={register({ required: true })}
                                    error={errors && errors.gstin_name && "Please enter a name"}
                                />
                                <PhoneField
                                    name="gstin_phone"
                                    placeholder="Mobile number"
                                    ref={register({ required: true })}
                                    lessSpace
                                    defaultValue={
                                    (user.gstin && user.gstin.phone) || user.phone || booking.phone
                                    }
                                    noIcon
                                    error={
                                    errors &&
                                    errors.gstin_phone &&
                                    "Please enter a correct phone number"
                                    }
                                />
                            </div> */}
                                {/* uncomment below line once api done */}
                                <div>
                                    {/* <label>
                                    <span className="text-j-magenta text-sm">Got an offer/coupon code?</span><br/>
                                    <div className="flex">
                                        <input type="text" style={{flex:1}} className="border border-grey-200 rounded-l pl-1" placeholder="Enter coupen code"/>
                                        <button type="submit" className="bg-j-orange text-white pl-2 pr-2">Apply</button>
                                    </div>
                                </label> */}
                                    <h3 className="text-sm text-j-magenta font-semibold mt-4">GRAND TOTAL</h3>
                                    <h1 className="text-2xl text-j-magenta font-bold">{currancyStatus.symbol} {Math.round(totalPrice) + (tcsObject?.amount || 0)}</h1>
                                    <div className="flex mt-2">
                                        <div className="w-9/12">
                                            {partialPayment > 0 && <div className="radio">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="Partial"
                                                        checked={displayAmount === "Partial"}
                                                        onChange={(e) => onChangeValue(partialStatus, e)}
                                                    // className="pt-4"
                                                    />
                                                    <span className="text-xs pl-2 font-bold">Reserve now by paying {paymentRule}</span><br />
                                                    {!fullPay && <span className="pl-5" style={{ fontSize: "11px" }}>DUE: {currancyStatus.symbol} {Math.round(Math.round(totalPrice) + (tcsObject?.amount || 0)) - (Math.round(twentyPercentage) + (partialTcsObject ? Math.round(partialTcsObject.amount) : 0))} before {moment(due_Date).format("D MMMM YYYY")}</span>}

                                                    {user.partial_onboard_credit_offer ?
                                                        <span className="pl-5 pt-2" style={{ fontSize: "11px", display: "block" }}>{user.partial_onboard_credit_offer}</span> : null}

                                                </label>
                                            </div>
                                            }
                                            <div className="radio w-full mt-2 mb-4">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="Male"
                                                        checked={displayAmount === "Full"}
                                                        onChange={(e) => onChangeValue(partialStatus, e)}
                                                    />
                                                    <span className="text-xs pl-2 font-bold">Book Now by paying 100%</span>
                                                    {user.onboard_credit_offer ?
                                                        <span className="pl-5 pt-2" style={{ fontSize: "11px", display: "block" }}>{user.onboard_credit_offer}</span> : null}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-3/12">
                                            <p className="text-xs" style={{ fontSize: "8px" }}>AMOUNT PAYABLE</p>
                                            <h1 className="text-xl text-j-magenta font-bold">{currancyStatus.symbol} {fullPay ? Math.round(totalPrice) + (tcsObject?.amount || 0) : Math.round(twentyPercentage) + (partialTcsObject ? Math.round(partialTcsObject.amount) : 0)}</h1>
                                            {!fullPay && <p className="text-xs text-j-magenta" style={{ fontSize: "9px" }}>DUE AMOUNT : {currancyStatus.symbol} {Math.round(Math.round(totalPrice) + (tcsObject?.amount || 0)) - (Math.round(twentyPercentage) + (partialTcsObject ? Math.round(partialTcsObject.amount) : 0))}</p>}
                                        </div>
                                    </div>
                                </div>
                                <input type="submit" ref={submitRef} className="bg-j-red-light text-white w-full mb-12 p-3 rounded-small" />
                            </form>
                        </div>
                    </>
                    }
                    {cardOpen &&
                        <div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2">
                                <h1 className="alt">Payment options</h1>
                                {backButtonstatus && <button className="bg-grey-light focus:outline-none text-j-magenta font-bold py-2 px-4 rounded inline-flex items-center justify-end" onClick={() => cardDetails(false)}>
                                    <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521,7.521.7.52z" /></svg>
                                    <span>Back</span>
                                </button>}
                            </div>
                            <span className="text-j-gray">
                                <RadioField
                                    name="paymentOption"
                                    defaultValue="bank"
                                    paymentStatus={true}
                                    handleWalletPayNowTrue={handleWalletPayNowTrue}
                                    options={[
                                        {
                                            value: "nimbbl",
                                            label: "Pay Using Payment Gateway",
                                            view: (
                                                <NimbblPaymentForm
                                                    bookingID={booking.id}
                                                    billingData={billingData}
                                                    partialStatus={partialStatus}
                                                    paymentOptionID={paymentOptionID}
                                                />
                                            ),
                                        },
                                        {
                                            value: "wallet",
                                            label: "Cordelia Wallet",
                                            view: (
                                                <WalletPayment
                                                    booking={booking}
                                                    paymentPath="booking"
                                                    billingData={billingData}
                                                    partialStatus={partialStatus}
                                                    submitRef={submitRef}
                                                    setFailed={setFailedError}
                                                    amountStatus={amountStatus}
                                                    totalPrice={totalPrice}
                                                    twentyPercentage={twentyPercentage}
                                                    walletStatus={true}
                                                    paymentOptionID={paymentOptionID}
                                                    backButtonFunction={backButtonFunction}
                                                    bookingType="normal"
                                                    handleWalletPayNow={handleWalletPayNow}
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            </span>
                        </div>
                    }

                </Tile.Inner>
            </Tile>

            {/* {loading &&  <h1 className="text-4xl">
            <LoadingIcon className="py-20 text-j-magenta" />
            </h1>}
            {!loading && <Button
                className="w-full border text-white border-j-magenta bg-j-red-light pt-1"
                onClick={()=>handleSubmit({
                    variables: { input: bookingInput },booking_id: bookingID
                })}
            >
            Confirm Booking
            </Button>
            } */}
        </>
        // <button
        //     className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        //     type="button"
        //     onClick={()=>handleSubmit({
        //         variables: { input: bookingInput },booking_id: bookingID
        //       })}
        //     disabled={amountStatus.amount}
        // >
        //     Confirm Booking
        // </button>
    )
}

const OrderDetail = ({ title, children, big }) => (
    <>
        <h4
            className={
                big ? "text-white text-3xl font-bold pt-4" : "text-white pt-2"
            }
        >
            {children}
        </h4>
    </>
);