/*eslint-disable*/
import React from "react";
import { Link, useHistory  } from "react-router-dom";
import { getUser, removeUserSession } from '../../utils/common';
import LogoImage from "../../assets/images/logo.png";
import cx from "classnames";
import styles from "./Slider.Module.css";

// import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
// import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [userProf, setUserProf] = React.useState(JSON.parse(localStorage.getItem("UserProfile")));
  let history = useHistory();

  // React.useEffect(() => {
  //   setUserProf(JSON.parse(sessionStorage.getItem("UserProfile")));
  // }, [userProf])

  const handleLogout= () => {
      removeUserSession();
      history.push('/login');
  }
  return (
    <>
      <nav className={cx(styles.scrollbars,"md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6")}>
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0  self-center"
            to="/"
          >
            <img src={LogoImage } className="h-24 text-center"/>
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              {/* <NotificationDropdown /> */}
            </li>
            <li className="inline-block relative">
              {/* <UserDropdown /> */}
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Cordelia cruises
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            <hr className="my-4 md:min-w-full" />
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 no-underline">
            {/* <a
                  className={
                    "text-xs uppercase font-bold block text-orange pl-6" 
                  }
                  href="https://bit.ly/2YF4NPT"
                  target="_blank"
                >
                  Webinar Signup
                </a> */}
              <a
                  className={
                    "text-xs uppercase font-bold block text-orange" 
                  }
                  href="https://drive.google.com/drive/folders/1JMnl2OvtSbXbfyjtpeTaHalqSWsptpKM"
                  target="_blank"
                >
                  <i
                    className={
                      "fas fa-business-time mr-1 text-sm text-orange"
                    }
                  ></i>{" "}
                  Business kit<span className="text-orange text-lg"> *</span>
                </a>
                {/* <a
                  className={
                    "text-xs uppercase font-bold block pt-2 pl-6 text-orange" 
                  }
                  href="/pdfs/Offers_Feb_2023_B2B.pdf"
                  target="_blank"
                >
                  {" "}
                  OUR CURRENT OFFER
                </a> */}
                <a
                  className={
                    "text-xs uppercase font-bold block pt-2 pl-6 text-orange" 
                  }
                  href="https://images.cordeliacruises.com/static/main_brochure.pdf"
                  target="_blank"
                >
                  {" "}
                  Download Brochure
                </a>
                <a
                  className={
                    "text-xs uppercase font-bold block pt-2 pl-6 text-orange" 
                  }
                  href="https://images.cordeliacruises.com/static/valet_sevice.pdf"
                  target="_blank"
                >
                  {" "}
                  Valet Services Mumbai
                </a>
                <a
                  className={
                    "text-xs uppercase font-bold block pt-2 pl-6 text-orange"
                  }
                  href="https://images.cordeliacruises.com/static/5_night_infographic_b2b.pdf"
                  target="_blank"
                >
                  {" "}
                  Day wise activity details
                </a>
            </h6>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Agent Booking Pages
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center  pl-2">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-j-magenta hover:text-j-magenta"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>

              {userProf.access_types.includes('portal') && <li className="items-center  pl-2">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/routes") !== -1
                      ? "text-j-magenta hover:text-j-magenta"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/routes"
                >
                  <i
                    className={
                      "fas fa-pencil-alt mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/routes") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Book a Cabin
                </Link>
              </li>}

              {userProf.access_types.includes('portal') && <li className="items-center  pl-2">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/routes") !== -1
                      ? "text-j-magenta hover:text-j-magenta"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/reports"
                >
                  <i
                    className={
                      "fas fa-calendar mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/routes") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Reports
                </Link>
              </li>}

              {/* later enable */}

              <li className="items-center">
                <a
                  className={
                    "text-xs uppercase font-bold block text-gray-800 hover:text-gray-600 px-2 pb-3" 
                  }
                  href= {process.env.REACT_APP_API_WEB_CHECK_IN_URL+'/web-check-in/booking-details'}
                  target="_blank"
                >
                  <i
                    className={
                      
                      "far fa-calendar-check mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                   Web Checkin
                </a>
              </li>

              {userProf.access_types.includes('portal') && <li className="items-center pl-2">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/wallet") !== -1
                      ? "text-j-magenta hover:text-j-magenta"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/wallet"
                >
                  <i
                    className={
                      "fas fa-wallet mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/wallet") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Cordelia Wallet
                </Link>
              </li>}

              {userProf.access_types.includes('portal') && <li className="items-center  pl-2">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/contact") !== -1
                      ? "text-j-magenta hover:text-j-magenta"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/contact"
                >
                  <i
                    className={
                      "fas fa-address-book mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/contact") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Contact Us
                </Link>
              </li>}

            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            <div
                  className="grid grid-cols-2 grid-cols-2"
                  onClick={() => {
                    setAdminOpen(!adminOpen);
                  }}
                >
                  <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">Admin Pages</h6>
                  <i
                    style={{ color: "grey", fontSize: 28, textAlign: "right" }}
                    className={cx(
                      "fas cursor-pointer text-j-gray-light text-2xl",
                      {
                        "fa-angle-down": !adminOpen,
                        "fa-angle-up": adminOpen,
                      }
                    )}
                  ></i>
                </div>
                {adminOpen && (
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                  <li className="items-center  pl-2">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/change-password") !== -1
                          ? "text-j-magenta hover:text-j-magenta"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/change-password"
                    >
                      <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{" "}
                      Change Password
                    </Link>
                  </li>
                  <li className="items-center  pl-2">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/change-number") !== -1
                          ? "text-j-magenta hover:text-j-magenta"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/change-number"
                    >
                      <i className="fas fa-phone text-gray-500 mr-2 text-sm"></i>{" "}
                      Change Number
                    </Link>
                  </li>
                  <li className="items-center  pl-2">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/agent-profile") !== -1
                          ? "text-j-magenta hover:text-j-magenta"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/agent-profile"
                    >
                      <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{" "}
                      Agent Profile
                    </Link>
                  </li>
                  <li className="items-center  pl-2">
                    <Link
                      className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                      onClick={handleLogout}
                    >
                      <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{" "}
                      Logout
                    </Link>
                  </li>
                </ul>
                )}
          </div>
        </div>
      </nav>
    </>
  );
}
