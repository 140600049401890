import React, { Component, useContext } from "react";
import {
    Redirect,
  } from "react-router";

class WalletSuccess extends Component {
    state = {
        redirect: false
    }
    componentDidMount() {
        this.id = setTimeout(() => this.setState({ redirect: true }), 3000)
    }

    componentWillUnmount() {
        clearTimeout(this.id)
    }

    redirectNow() {
        this.state.redirect = true;
    }

    render() {
        return this.state.redirect
        ? <Redirect to="/admin/wallet" />
        : (<div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-magenta m-10 h-full">
                <span className="inline-block align-middle mr-8">
                    <b className="capitalize">Recharge</b> Success
                    <h4 className="pb-5">
                    <i className="fas fa-check-circle text-j-green text-3xl pr-2 pt-2" />
                        We have received your payment.
                    </h4>
                    <p className="text-black">Redirect to wallet after 10 sec</p>
                    <a onClick={this.redirectNow}>Redirect Now</a>
                </span>
            </div>
        )
    }
}

export default WalletSuccess;