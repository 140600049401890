import React, { Component, useContext, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Tile from "../../components/Tile/Tile";
import { getToken } from "../../utils/common";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import ModifyCard from "../../components/Booking/Cards/ModifyCards";
import moment from "moment";
import cx from "classnames";
import {
    useHistory,
    withRouter,
    useLocation
  } from "react-router-dom";
  import styles from "./modify.module.css";
  import DeckSelection from "./deckSelection";
  import Modal from "../../components/Modal/Modal";

class ModifyBooking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            samplesData: null,
            loading: true,
            resetFlag: true,
            backupData: null,
            backupWholeData: null,
            filteredItineraries: [],
            flagDestination: false,
            flagDeparture: false,
            flagMonth: false,
            destVal: null,
            origVal: null,
            origMonth: null,
            dest: null,
            orig: null,
            filteredItineraries: [],
            booking_reference:'',
            step:0,
            itinerary: null,
            open: false,
            rescheduling_price: null,
            individual_price: null,
            total_price: null,
            date: false,
            pricing: null,
            modifyData:null,
            shipName:null,
            guestCount:null,
            startDate:null,
            endDate:null,
            displayAmount: "Full",
            partialStatus: false,
            fullPay:true,
            submitted:false,
            initialLoad: true,
            showModalStatus: false,
            checkAvailability: [],
            bookingDataValue: null,
            itineraryID: null,
            itineray: null,
            room: [],
            newRoomParams: null,
            proceedLoading:false,
            pricingStatus: false,
            loadingContinueStatus: false
        }
    }

    componentDidMount() {
        let modifyData = JSON.parse(sessionStorage.getItem("modifyData"))
        sessionStorage.removeItem("newRoomSelected");
        console.log(modifyData)
        this.setState({shipName:modifyData.shipName,guestCount:modifyData.number_of_guest,startDate:modifyData.start_date})
        this.setState({booking_reference: modifyData.booking_reference})
        this.setState({modifyData:modifyData})
    }

    updateRoom=(room)=>{
        this.setState({room:room})
    }

    uniques = (value, index, self) => self.indexOf(value) === index;
    

    onChangeByDate = (value) => {
        this.setState({initialLoad:false})
        var token = JSON.parse(localStorage.getItem('store'));
        this.setState({step:1})
        this.setState({loading:true});
        let modifyData = JSON.parse(sessionStorage.getItem("modifyData"))
        console.log(modifyData)
        this.setState({booking_reference: modifyData.booking_reference})
        let URL;
        if(value == 'byDate') {
            URL = process.env.REACT_APP_API_ENDPOINT + '/agent_portal/reschedule/itineraries?booking_reference=' + this.state.booking_reference +'&same_route=yes';
            this.setState({date: true})
        } else {
            URL = process.env.REACT_APP_API_ENDPOINT + '/agent_portal/reschedule/itineraries?booking_reference=' + this.state.booking_reference;
            this.setState({date: false})
        }
        fetch(URL, {
              method: 'GET',
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                  `Bearer ${getToken()}`,
              },
            }).then((response) => response.json())
              .then((response) => {
                console.log(response);
                let dataArr = response.data.routes;
                this.setState({ backupWholeData: dataArr });
                let tempArr = [];
                let destinations = [];
                let origins = [];
                let durations = [];
                destinations = Object.values(dataArr)
                .map(
                    (route) =>
                    route.ports &&
                    route.ports.length &&
                    route.ports.slice(1, route.ports.length).map((p) => p.port.name)
                )
                .flat(1)
                .filter(this.uniques);
                destinations.sort();
                origins = Object.values(dataArr)
                .map(
                    (route) =>
                    route.ports && route.ports.length && route.ports[0].port.name
                )
                .filter(this.uniques);
                origins.sort();
                durations = [2, 3, 4, 5, 6, 7];
                durations.sort();
                this.setState({ dest: destinations });
                this.setState({ orig: origins });
                dataArr.forEach((ele) => {
                for (let i = 0; i < ele.itineraries.length; i++) {
                    let obj = {};
                    obj.itiId = ele.id;
                    obj.id = ele.itineraries[i].id;
                    obj.cruice_name = ele.itineraries[i].name;
                    obj.Departure = ele.itineraries[i].ports[0].port.name;
                    obj.destination =
                    ele.itineraries[i].ports[
                        ele.itineraries[i].ports.length - 1
                    ].port.name;
                    obj.departure_date = `${new Date(ele.itineraries[i].startTime)}`;
                    obj.no_days = ele.itineraries[i].nightCount;
                    obj.rem_obj = ele.itineraries[i];
                    tempArr.push(obj);
                    obj = {};
                }
                });
                sessionStorage.setItem("itinerary", JSON.stringify(dataArr));
                this.setState({ samplesData: tempArr });
                this.setState({ backupData: tempArr });
                this.setState({ loading: false });
                this.setState({ resetFlag: false });
            })
    }

    getPriceSummary = (itineraryID) => {
        this.setState({loadingContinueStatus: true})
        this.setState({pricingStatus: true})
        console.log(itineraryID,"nik-log")
        if(itineraryID.startTime) this.setState({startDate:itineraryID.startTime})
        if(itineraryID.endTime) this.setState({endDate:itineraryID.endTime})
        var token = JSON.parse(localStorage.getItem('store'));
        var modifyData = JSON.parse(sessionStorage.getItem('modifyData'));
        var newRoomNumber = JSON.parse(sessionStorage.getItem("newRoomSelected"))
        var itineryIDVal
        var refIDVal
        var reshudilingInput
        var category
        var oldRoomNumber
        if(newRoomNumber != undefined) {
            itineryIDVal = this.state.itinerary.id;
            refIDVal = modifyData.booking_reference;
            // this.setState({itinerary: modifyData.booking.itinerary})
            reshudilingInput = {
                itinerary: itineryIDVal,
                booking_reference: refIDVal,
                rooms: modifyData.booking.rooms.map((room,i)=>{
                    return{
                    "room_type": room.code,
                    "room_no":newRoomNumber[i], 
                    "old_room_no": room.number
                    }
                })
            }
        } else {
            this.setState({itinerary: itineraryID})
            reshudilingInput = {
                itinerary: itineraryID.id,
                booking_reference: this.state.booking_reference
            }
        }
        
          fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/reschedule/pricing', {
            method: 'POST',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                `Bearer ${getToken()}`,
            },
            body: JSON.stringify(reshudilingInput)
          }).then((response) => response.json())
            .then((response) => {
                if(response.message==='room not available'){
                    this.setState({showModalStatus: true});
                }
                this.setState({ rescheduling_price: response.rescheduling_fee })
                this.setState({individual_price: response.rooms})
                this.setState({total_price: response.payable})
                this.setState({pricing: response})
            //   this.setState({ recheduleStep: step + 1 })
                console.log(response) 
                this.setState({step:2})
                this.setState({pricingStatus: false})
                this.setState({loadingContinueStatus: false})
            })
    }

    confirmApi = () => {
        const { match, location, history } = this.props;
        this.setState({submitted: true})
        sessionStorage.setItem("bookingPath", "normal")
        sessionStorage.setItem('post-booking', false)
        var token = JSON.parse(localStorage.getItem('store'));
        var modifyData = JSON.parse(sessionStorage.getItem('modifyData'));
        var newRoomNumber = JSON.parse(sessionStorage.getItem("newRoomSelected"))
        console.log(this.state.newRoomParams,"nik-log")
        let reshudilingInput;
        if(newRoomNumber){  
            reshudilingInput = {
                itinerary: this.state.itinerary.id,
                booking_reference: this.state.booking_reference,
                payment_option_id: this.state.partialStatus?this.state.pricing.payment_option_id: null,
                rooms: modifyData.booking.rooms.map((room,i)=>{
                    return{
                    "room_type": room.code,
                    "room_no":newRoomNumber[i], 
                    "old_room_no": room.number
                    }
                })
            }
        }else{
            reshudilingInput= {
                itinerary: this.state.itinerary.id,
                booking_reference: this.state.booking_reference,
                payment_option_id: this.state.partialStatus?this.state.pricing.payment_option_id: null,
              }
        }
          fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/reschedule/confirm', {
            method: 'POST',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                `Bearer ${getToken()}`,
            },
            body: JSON.stringify(reshudilingInput)
          }).then((response) => response.json())
            .then((response) => {
                if(response.status !=500) {
                    if(response.message == "room not available") {
                        // alert("Room is not available for this date.Please reschedule your booking with another Date/Itinerary")
                        // this.setState({step:1})
                        this.setState({showModalStatus: true});
                    } else {
                        if (response.payable > 0) {
                            var roomCategory;
                            var rooms_data = [];
                            var roomNumber;
                            var roomCode;
                            this.state.individual_price.map((guest, i) => {
                                var adultCount = 0;
                                var childCount = 0;
                                var infantCount = 0;
                                guest.price_details.map((guestData) => {
                                    if (guestData.type == "ADULT") {
                                        adultCount = adultCount + 1
                                        // this.setState({adult:this.state.adult+1})
                                    } else if (guestData.type == "CHILD") {
                                        childCount = childCount + 1
                                    } else {
                                        infantCount = infantCount + 1
                                    }
                                })
                                roomCategory = guest.category;
                                roomNumber = guest.number;
                                roomCode = guest.code;
                                rooms_data.push({
                                    "adults": adultCount,
                                    "infants": infantCount,
                                    "children": childCount,
                                    "roomCatogory": roomCategory,
                                    "roomNumber": roomNumber,
                                    "roomCode": roomCode,
                                    "id": i
                                })
                            })
                            const itineraryData = {
                                itineraries: this.state.itinerary,
                                amount: response.payable,
                                rechedule: true,
                                rooms: rooms_data,
                                number: this.state.booking_reference,
                                itinerary: this.state.itinerary,
                                bookingID: response.booking_id,
                                total_amount_paid: this.state.pricing.total_amount_paid,
                                rescheduling_fee: this.state.pricing.reschedule_fee,
                                gst: this.state.pricing.reschedule_gst,
                                total_amount_payable: this.state.pricing.payable,
                                due_amount: this.state.pricing.due_amount,
                                due_Date:this.state.pricing.due_date,
                                revised_itinerary_amount: this.state.pricing.revised_itinerary_amount,
                                fare_difference:this.state.pricing.fare_difference,
                                payment_rule:this.state.pricing.partial_amount_rule,
                                payment_option_id: this.state.pricing.payment_option_id,
                                partialStatus:this.state.partialStatus,
                                partial_payable_amount:this.state.pricing.partial_payable_amount
                            }
                            sessionStorage.setItem("itinerary", JSON.stringify(itineraryData))
                            history.push({
                            pathname: '/admin/paymentGateway',
                            })
                        } else {
                            const itineraryData = {
                                itineraries: this.state.itinerary
                            }
                            sessionStorage.setItem("itinerary", JSON.stringify(itineraryData))
                            sessionStorage.setItem("bookingID",response.booking.id)
                            sessionStorage.setItem("bookingPath", "modify")
                            history.push({
                                pathname: '/admin/booking/success',
                            })
                        }
                    }
                }
                this.setState({submitted: false})
            })
    }
    
    onChangeValue = (status) => {
        this.setState({fullPay:!this.state.fullPay});
        if(status==false) {
            this.setState({partialStatus:true});
        } else {
            this.setState({partialStatus:false});
        }
        // setPartialStatus(status);
        if(this.state.displayAmount == 'Full') {
            this.setState({displayAmount:'Partial'})
        } else {
            this.setState({displayAmount:'Full'})
        }
    }

    showModal = e => {
        this.setState({
          showModalStatus: true
        });
    };

    changeRoom = () => {
        this.setState({
            showModalStatus: false
        });
        this.setState({step: 3})
    }

    proceedCabinChange = () => {
        this.setState({proceedLoading:true});
        var token = JSON.parse(localStorage.getItem('store'));
        let modifyData = JSON.parse(sessionStorage.getItem("modifyData"))
        const bookingData = modifyData.booking
        fetch(process.env.REACT_APP_API_ENDPOINT +"/agent_portal/reschedule/check_availability?booking_reference="+modifyData.booking_reference+"&itinerary_id="+this.state.itinerary.id+"&step=reschedule",
        {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:  `Bearer ${getToken()}`,
            },
            body: JSON.stringify({

            })
        }).then((res) => {
            if (res.status === 401) {
            }
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Something went wrong");
            }
        }).then((response) => {
            console.log(response,bookingData,"nik-log response")
            this.setState({checkAvailability:response.data.checkAvailability})
            this.setState({bookingDataValue: bookingData})
            this.setState({itineraryID: this.state.itinerary})
            this.setState({room:bookingData.rooms})
            this.setState({step: 4})
        }).catch((error) => {

        })
        this.setState({proceedLoading:false});
    }

    stepHandleNex = (params) => {
        this.setState({newRoomParams:params})
        this.getPriceSummary(params)
    }

    render() {
        return(
            <>
                <div 
                    className="uppercase cursor text-right text-j-magenta p-4"
                >
                    {this.state.step != 3 &&<Tile shadow className="mb-2 rounded-lg">
                        <Tile.Inner
                            className="rounded-lg flex justify-between bg-magenta text-white"
                            theme="magenta"
                            tiny
                        >
                            <p className="text-sm leading-none uppercase self-center w-6/12 text-left" style={{lineHeight:'18px'}}>{this.state.shipName}<br /><span className="pt-2">{this.state.guestCount} Guest, {moment(this.state.startDate).format("ddd, D MMM YYYY")}</span></p>
                            <Button
                            className="border border-j-white bg-j-orange text-j-white w-3/12 focus:outline-none focus:ring-4 focus:ring-white"
                            onClick={()=>this.onChangeByDate('byDate')}
                            >
                            CHANGE DATE
                            </Button>
                            <Button
                            className="border border-j-white bg-j-orange text-j-white w-3/12 ml-2 focus:outline-none focus:ring-4 focus:ring-white"
                            onClick={()=>this.onChangeByDate('byItinerary')}
                            >
                            CHANGE ITINERARY
                            </Button>
                        </Tile.Inner>
                    </Tile>}
                    {this.state.step == 0 && 
                        <p className="text-center text-4xl text-gray-400 pt-10 pb-10">No Result Found</p>
                    }
                    {this.state.step == 1 && <div>
                        {this.state.loading && (
                        <>
                            <h1 className="text-4xl">
                                <LoadingIcon className="py-20 text-j-magenta" />
                            </h1>
                        </>
                        )}
                        {!this.state.loading && (
                            <>
                                {this.state.samplesData.length === 0 ? (
                                    <p className="text-center text-4xl text-gray-400 pt-10 pb-10">No Result Found</p>
                                ) : (
                                    (this.state.resetFlag) ? (
                                    <div style={{ textAlign: "center", fontSize: "4rem" }}>
                                        <p className="loader-text mt-4">Click on Apply</p>
                                        <LoadingIcon className="py-20 text-j-magenta" />
                                    </div>
                                    ) : (<ModifyCard cardData={this.state.samplesData} monthHere={this.state.origMonth} byDate={this.state.date} getPriceSummary={this.getPriceSummary}/>)
                                )}
                            </>
                        )}
                    </div>
                    }
                    {this.state.step == 2 && <>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2">
                        <span>
                            <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg" style={{ marginRight: '5px' }}>
                                <Tile.Inner className="pb-0">
                                    <OrderDetail title="SHIP">
                                        {this.state.itinerary.ship.name}
                                    </OrderDetail>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="mr-2">
                                        <OrderDetail title="Departure">
                                            {this.state.itinerary.ports[0].port.name}
                                            <br></br>
                                            {moment(this.state.startDate).format("llll")}
                                        </OrderDetail>
                                        </div>
                                        <div className="ml-2">
                                        <OrderDetail title="Arrival">
                                            {this.state.itinerary.ports[this.state.itinerary.ports.length-1].port.name}
                                            <br></br>
                                            {moment(this.state.endDate).format("llll")} 
                                        </OrderDetail> 
                                        </div>
                                    </div>
                                </Tile.Inner>
                            </Tile>
                        </span>
                        <span>
                            <Tile tiny theme="white" className="mb-5 col-span-2 bg-transparent" style={{ marginLeft: '5px', backgroundColor: 'transparent' }}>
                                <div className="border border-j-gray-lighter" style={{ backgroundColor: "white", borderRadius: "10px" }}>
                                    <div
                                        style={{ margin: '25px', color: '#500E4B', fontWeight: 'bold' }}
                                        className="grid grid-cols-1 grid-cols-2"
                                        onClick={() => {
                                        this.setState({open:!this.state.open});
                                        }}
                                    >
                                        <span className="pt-1 text-left"> PRICE DETAILS</span>
                                        {/* <i
                                        style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                                        className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                                            "fa-angle-down": !this.state.open,
                                            "fa-angle-up": this.state.open,
                                        })}
                                        ></i> */}
                                    </div>
                                    {/* {this.state.open && this.state.individual_price.map((booking, index) =>
                                        <div key={index}>
                                            <div style={{ color: "black" }}>
                                                <div style={{ margin: "25px" }}>
                                                    <div style={{ width: '100%' }}>
                                                        {booking.price_details.map((price, index) =>
                                                        <div key={index} style={{ margin: "25px" }}>
                                                            <div style={{ width: '100%' }}>
                                                            <PaymentDetails content={price.type} details={Math.round((price.total) * 100 / 100)} big={true} />
                                                            <PaymentDetails content="Cabin Fare:" details={Math.round((price.fare) * 100 / 100)} big={false} />
                                                            {price.discount != '0' && <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
                                                                <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">Individual Discount</div>
                                                                <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
                                                                - &#x20B9;{price.discount}
                                                                </div>
                                                            </div>}
                                                            <PaymentDetails content="Port Charges" details={price.portCharges} big={false} />
                                                            <PaymentDetails content="Gratuity:" details={price.gratuity} big={false} />
                                                            </div>
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    } */}
                                    <div style={{ margin: "25px" }} className="border-b-2 border-dashed pb-4">
                                        <PaymentDetails content="Total Amount Paid" details={Math.round((this.state.pricing.total_amount_paid) * 100 / 100)} big={false} currancyStatus={this.state.currancyStatus}/>
                                        <PaymentDetails content="New Itinerary Amount" details={Math.round((this.state.pricing.revised_itinerary_amount) * 100 / 100)} big={false} currancyStatus={this.state.currancyStatus}/>
                                        <PaymentDetails content="Fare Difference" details={Math.round((this.state.pricing.fare_difference) * 100 / 100)} big={false} currancyStatus={this.state.currancyStatus}/>
                                        <PaymentDetails content="Rescheduling Fee" details={Math.round((this.state.pricing.reschedule_fee) * 100 / 100)} big={false} currancyStatus={this.state.currancyStatus}/>
                                        <PaymentDetails content="GST" details={Math.round((this.state.pricing.reschedule_gst) * 100 / 100)} big={false} currancyStatus={this.state.currancyStatus}/>
                                    </div>
                                    <div style={{ margin: "25px" }}>
                                        <PaymentDetails content="Total Amount Payable" details={Math.round((this.state.partialStatus?this.state.pricing.partial_payable_amount:this.state.pricing.payable) * 100 / 100)} big={false} currancyStatus={this.state.currancyStatus}/>
                                        {this.state.pricing.refundable_amount>0&&<PaymentDetails content="Refund Amount" details={Math.round((this.state.pricing.refundable_amount) * 100 / 100)} big={false} />}
                                        {this.state.pricing.due_amount>0 && <>
                                        {!this.state.fullPay && <PaymentDetails content="Due Amount" details={Math.round((this.state.pricing.due_amount) * 100 / 100)} big={false} currancyStatus={this.state.currancyStatus}/>}
                                        {!this.state.fullPay && <div>
                                            <p className="text-left" style={{fontSize:"11px",color: "grey"}}>Payable before {moment(this.state.pricing.due_date).format("D MMMM YYYY")}</p>
                                        </div>
                                        }
                                        </>
                                        }
                                    </div>
                                </div>
                            </Tile>
                        </span>
                    </div>
                    <div className="grid grid-cols-2">
                        <div></div>
                        <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta">
                                <Tile.Inner className="pb-4 pt-6">
                                <div className="grid grid-cols-1">
                                    {this.state.pricing.partial_amount_rule == "25%" && <div className="radio flex">
                                        <div className="pl-6 ">
                                            <input
                                                    type="radio"
                                                    value="Partial"
                                                    checked={this.state.displayAmount === "Partial"}
                                                    onChange={(e)=>this.onChangeValue(this.state.partialStatus, e)}
                                                                            // className="pt-4"
                                            />
                                            <span className="text-sm pl-2 font-bold text-black w-full text-left" >Reserve now by paying {this.state.pricing.partial_amount_rule}</span><br />
                                            {!this.state.fullPay && <span className="pl-5 text-black text-left flex" style={{fontSize:"11px"}}>DUE: &#x20B9;{Math.round(this.state.pricing.due_amount)} before {moment(this.state.pricing.due_date).format("D MMMM YYYY")}</span>}
                                    </div>
                                    </div>
                                    }
                                    <div className="radio w-full mt-2 mb-4 w-full">
                                        <label className="flex pl-6">
                                            <input
                                                type="radio"
                                                value="Male"
                                                checked={this.state.displayAmount === "Full"}
                                                onChange={()=>this.onChangeValue(this.state.partialStatus)}
                                            />
                                            <span className="text-sm pl-2 font-bold text-black  w-full text-left">Book Now by paying 100%</span>
                                        </label>
                                    </div>                                    
                                </div>
                                </Tile.Inner>
                            </Tile>

                    </div>
                    <div className="grid grid-cols-2 gap-4" >
                        <div></div>
                        <div>
                        <Button
                            className={cx(
                                "w-full mt-9 text-white mb-7 pt-1 bg-j-red-light",
                            )}
                            onClick={() => this.confirmApi()}
                            // style={{textAlign:'center', color: 'white' , paddingTop: '5x' , paddingBottom: '5px'}}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                    </>
                }
                {this.state.step == 3 && <> 
                        <Tile shadow className="mb-2 rounded-lg">
                            <Tile.Inner
                                className={cx(styles.tile,"rounded-lg flex justify-between bg-magenta text-white shadow-xl")}
                                theme="magenta"
                                tiny
                            >
                            <p className={cx(styles.tilep," uppercase text-sm leading-none uppercase self-center md:w-6/12 lg:w-6/12 text-left")} style={{lineHeight:'18px'}}>Change Cabin/room</p>
                                <Button
                                    className={cx(styles.tilebutton,"uppercase border border-j-white bg-j-orange text-j-white w-3/12 md:ml-2 lg:ml-2 focus:outline-none focus:ring-4 focus:ring-white")}
                                    onClick={()=>this.proceedCabinChange()}
                                        // loading={this.proceedLoading}
                                >
                                    {this.state.proceedLoading ? <LoadingIcon /> : "Proceed" }
                                </Button>
                            </Tile.Inner>
                        </Tile>
                    </>
                }
                {this.state.step == 4 && <> 
                    <>
                        <DeckSelection 
                            checkAvailability={this.state.checkAvailability}
                            booking={[]}
                            itineraryID={this.state.itineraryID}
                            rooms={this.state.room}
                            updateRoom={this.updateRoom}
                            selections={this.state.checkAvailability}
                            stepHandleNex={this.stepHandleNex}
                            loadingContinueStatus={this.state.loadingContinueStatus}
                        />
                    </>
                    </>
                }
                </div>
                <Modal show={this.state.showModalStatus} onClose={this.showModal}>
                    <div className="p-4 text-center">
                        <p>Room is not available. Please reschedule your booking with another deck/room number</p>
                         <div>
                            <Button
                                className={cx(
                                    "w-full mt-9 text-white mb-7 pt-1 bg-j-red-light",
                                )}
                                        onClick={() => this.changeRoom()}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(ModifyBooking);

const OrderDetail = ({ title, children, big }) => (
    <div className="mb-6">
      <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none text-left">
        {title}
      </h1>
      <h4
        className={
          big ? "text-j-magenta text-3xl font-bold pt-4 text-left" : "text-j-magenta pt-2 text-lg font-semibold text-left"
        }
      >
        {children}
      </h4>
    </div>
  );

  const PaymentDetails = ({ content, details, big, currancyStatus }) => (
    <>
      <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
        {big && <>
          <div style={{ color: "black", fontSize: 16, fontWeight: 'bold' }} className="float-left bold text-left">
            {content} {content=="GST" && <span><div className={cx(styles.tooltip)}><i className="fas fa-info-circle"></i>
            <span className={cx(styles.tooltiptext)}>GST applied on rescheduling fees</span>
            </div></span>}
            {content=="Revised Itinerary Amount" && <span><div className={cx(styles.tooltip)}><i className="fas fa-info-circle"></i>
            <span className={cx(styles.tooltiptext)}>This fare includes cabin fare,port, gratuity and GST.</span>
            </div></span>}
          </div>
          <div style={{ color: "black", fontSize: 16, textAlign: 'right', fontWeight: 'bold' }} className="float-right font-weight-bold text-capitalize text-left">
                &#x20B9;{details}
          </div>
        </>
        }
        {!big && <>
          <div style={{ color: "grey", fontSize: 14 }} className="float-left bold text-left">{content} {content=="GST" && <span><div className={cx(styles.tooltip)}><i className="fas fa-info-circle"></i>
            <span className={cx(styles.tooltiptext)}>GST applied on rescheduling fees</span>
            </div></span>}
            {content=="New Itinerary Amount" && <span><div className={cx(styles.tooltip)}><i className="fas fa-info-circle"></i>
            <span className={cx(styles.tooltiptext)}>This fare includes cabin fare,port, gratuity and GST.</span>
            </div></span>}
            </div>
          <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize text-left">
            &#x20B9;{details}
          </div>
        </>
        }
      </div>
    </>
  );