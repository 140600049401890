import React, { Component, useContext, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { getToken } from "../../utils/common";
import {
    useHistory,
    withRouter,
    useLocation
  } from "react-router-dom";
import CompleteHoldGuestDetailsView from "../../components/Booking/CompleteHoldBookingGuestDetails_copy";

var bookingID = null;

class CompleteHoldBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postStepHold: sessionStorage.getItem('post_hold-step') == undefined ? 1 : parseInt(window.sessionStorage.getItem('post_hold-step')),
            bookingDetails: null,
            guestDetails: null,
            loading: true
        };
    }

    componentDidMount() {
        const { match, location, history } = this.props;
        bookingID = location.state.bookingID;
        this.fetchHoldBookingApi(bookingID)
    }

    fetchHoldBookingApi = (bookingID) => {
        console.log(bookingID)
        fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/hold_bookings/'+bookingID.id, {
            method: 'GET',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                `Bearer ${getToken()}`,
            },
        }).then((response) => response.json())
        .then((response) => {
            console.log(response)
            this.setState({bookingDetails: response.booking, guestDetails: response.guest_details})
            this.setState({loading: false})
        })
    }

    nextStep = () => {
        const { postStepHold } = this.state;
        this.setState({
            postStepHold: postStepHold + 1,
        });
    };
    
    prevStep = () => {
        const { postStepHold } = this.state;
        this.setState({ 
            postStepHold: postStepHold - 1,
        });
    };

    switchState = () => {
        const { postStepHold } = this.state;
        sessionStorage.setItem('post-hold-step', postStepHold);
        switch (postStepHold) {
            case 1:
                return (
                    <>
                        <CompleteHoldGuestDetailsView 
                            bookingDetails={this.state.bookingDetails}
                            guestDetails={this.state.guestDetails}
                        />
                    </>
                )
        }
    }

    render() {
        return(
            <Layout>
                <div className="flex flex-col h-screen">
                    <main class="flex-grow overflow-y-auto md:pr-14 lg:pr-14 pl-4 pr-4 md:pl-14 lg:14">
                        {!this.state.loading ? this.switchState(): ''}
                    </main>
                </div>
            </Layout>
        )
    }
}

export default withRouter(CompleteHoldBooking);