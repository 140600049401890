import React, { useContext, useState } from "react";
import UserContext from "../../store/UserContext";
import cx from "classnames";
import moment from "moment";
import Button from "../Button/Button";
import DayWiseOverlay from "../Booking/Selector/DayWiseOverlay";
import styles from "./RouteCard.module.css";
import { useHistory } from "react-router-dom";
import SoldOut from "../../assets/images/sold_out.png";
import { getToken, removeUserSession } from "../../utils/common";
import Modal from "../Modal/ModalError"
import images from "../../assets/images/error.jpg";
// NOTE: The route should already have a list of itineraries included!
export const RouteCard = ({ route, light, nightCount }) => {
  const [user, setUser] = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openItiModal, setOpenItiModal] = useState(false);
  const [checkItineraryID, setItiniCheckID] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [errorModal , setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState('')
  let history = useHistory();

  const clickShow = () => {
    setShowMore((oldValue) => !oldValue);
  };

  const goToBooking = (route, itinerary, nightCount) => {
    setUser(
      Object.assign({}, user, {
        name: null,
        phone: null,
        success_status: false,
        booking: Object.assign({}, user.booking, {
          itinerary,
          route: null,
          nightCount: null,
          roomsSelected: false,
          guestsFilled: false,
          giftCard: null,
          jurisdiction: null,
          packages: null,
          hotels: null,
          returnHotels: null,
          returnFlight: null,
          flight: null,
          visitorID: 123456,
          rooms: null,
          departure: itinerary.ports[0].port.name,
          destination: itinerary.ports[itinerary.ports.length - 1].port.name,
          departure_time: `${new Date(itinerary.startTime)}`,
          destination_time: `${new Date(itinerary.startTime)}`,
        }),
      })
    );
    sessionStorage.removeItem('tempData')
    console.log('itinerary---', itinerary)
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/itineraries/${itinerary.id}/hold_availability`, {
        method: 'GET',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
            `Bearer ${getToken()}`,
        },
    }).then(response => {
        console.log('res', response)
        setErrorStatus(response.status)
        if(response.status == 200) {
          return response.json()
        } else {
          setErrorMessage(response.message)
          setErrorModal(true)
        }
      })

    .then(response => {
        console.log('res', errorStatus)
  
            console.log('response ---', response)
            sessionStorage.setItem('maxHoldCount', response.available_hold_booking_count)
            if(response.available_hold_booking_count > 0) {
                history.push("/admin/pre-booking");
            } else {
                history.push("/admin/booking");
            }
          
    })
        // history.push("/admin/pre-booking");
    };

  route.itineraries.sort(
    (a, b) => new Date(a.startTime) - new Date(b.startTime)
  );

  let groupMonthly = route.itineraries.reduce((result, ite) => {
    let dateObj = new Date(ite.startTime);
    let monthyear = dateObj.toLocaleString("en-us", {
      month: "long",
      year: "numeric",
    });
    if (!result[monthyear]) result[monthyear] = [ite];
    else result[monthyear].push(ite);
    return result;
  }, {});

  const handleDateClick = (month) => {
    setSelectedMonth(month);
  };

  const handleOk = () => {
        setErrorModal(false)
        removeUserSession();
        history.push('/login');
  }

  return (
    <div
      className={cx(
        open
          ? "bg-magenta text-j-white mb-12 rounded-lg p-5"
          : "bg-white mb-12 rounded-lg p-5"
      )}
    >
      <div className="px-5 pb-0 pt-8">
        {route.ports.map((p) => (
          <h4
            className={cx(
              "flex uppercase leading-tiny",
              open ? "text-white" : "text-j-magenta"
            )}
            key={p.id}
          >
            <div className={cx("text-center w-12", !open && "text-j-magenta")}>
              <i className="fas fa-anchor"></i>
            </div>
            <span>{p.port.name}</span>
          </h4>
        ))}
        <br></br>
      </div>
      {open ? (
        <>
          <h4 className="text-lg text-white" id={route.id}>
            AVAILABLE CRUISE DATES
          </h4>
          <br></br>
          {Object.keys(groupMonthly).map((month, index) => (
            <div style={{ textAlign: "center" }}>
              {(!selectedMonth && index === 0) ||
                (selectedMonth !== month && (
                  <h5 className="font-medium mb-3">
                    <button
                      className="h-10 pt-1 items-center rounded transition duration-300 ease-in-out px-8 justify-center font-bold uppercase text-sm bg-j-orange text-white"
                      style={{ width: "64%" }}
                      onClick={() => handleDateClick(month)}
                    >
                      {month}
                    </button>
                  </h5>
                ))}
              {selectedMonth === month || (index === 0 && !selectedMonth)
                ? groupMonthly[
                    selectedMonth || Object.keys(groupMonthly)[0]
                  ].map((itinerary) => (
                    <div
                      className="border rounded-lg border-j-gray-lighter p-5 bg-white mb-10"
                      key={itinerary.id}
                    >
                      <div className="relative">
                        <div className="">
                        {itinerary.is_sold_out?
                        <div className={styles.soldOut}>
                          <img src={SoldOut}></img>
                        </div>:<div/>}
                          <small className="uppercase text-sm text-j-gray-lighter">
                            Cruise starts
                          </small>
                          <span className="text-lg uppercase block text-lg text-j-black">
                            {moment(itinerary.startTime)
                              .locale("en")
                              .format("ddd, D MMM YYYY")}
                          </span>
                        </div>
                        <br />
                        <div className="">
                          <small className="uppercase text-sm text-j-gray-lighter">
                            Cruise ends
                          </small>
                          <span className="text-lg uppercase block text-lg text-j-black">
                            {moment(itinerary.endTime)
                              .locale("en")
                              .format("ddd, D MMM YYYY")}
                          </span>
                        </div>
                      </div>
                      {openItiModal && (
                        <div style={{ zIndex: 999999 }}>
                          <div
                            style={{
                              position: "fixed",
                              backgroundColor: "rgba(0,0,0, 0.2)",
                              zIndex: 999,
                              top: "0px",
                              left: "0px",
                              right: "0px",
                              bottom: "0px",
                            }}
                            onClick={() => setOpenItiModal(false)}
                          ></div>
                          <DayWiseOverlay
                            itineraryId={checkItineraryID}
                            style="close"
                            onCancel={() => setOpenItiModal(false)}
                          ></DayWiseOverlay>
                        </div>
                      )}
                      <br />
                      <Button
                        className="bg-j-orange w-full text-white"
                        onClick={() =>
                          goToBooking(route, itinerary, nightCount)
                        }
                        disabled={itinerary.is_sold_out}
                      >
                        Get price
                      </Button>
                      <br />
                      <Button
                        className="bg-j-orange w-full text-white"
                        onClick={() => {
                          setOpenItiModal(true);
                          setItiniCheckID(itinerary.id);
                        }}
                        disabled={itinerary.is_sold_out}
                      >
                        More Info
                      </Button>
                      <br />
                      <Button
                        className="bg-white text-j-orange w-full mb-4 border border-j-orange cr-bttn"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Hide cruise dates
                      </Button>
                    </div>
                  ))
                : null}
            </div>
          ))}
        </>
      ) : (
        <Button
          className="bg-white text-j-orange w-full mb-4 border border-j-orange"
          onClick={() => {
            setOpen(true);
          }}
        >
          See cruise dates
        </Button>
      )}

      <Modal show={errorModal}>
          <div id="menu" className="w-full h-full">
              <div className="2xl:container  2xl:mx-auto py-3 px-4 md:px-28 flex justify-center items-center">
                <div className="w-96 md:w-auto dark:bg-gray-800 relative flex flex-col justify-center items-center bg-white py-6 px-4 md:px-24 xl:py-6 xl:px-36">
                  <div role="banner">
                        <img src={images} className="w-full rounded-lg h-full"/>
                  </div>
                        <div class="mt-3">
                            <h1 role="main" class="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">{errorStatus}</h1>
                        </div>

                        <div class="mt">
                              <p class="mt-2 sm:w-80 text-base dark:text-white leading-7 text-center text-gray-800">Some thing went wrong!</p>
                              <p class="mt-2 sm:w-80 text-base dark:text-white leading-7 text-center text-white">{errorMessage}</p>
                        </div>
                        <button className="w-full dark:text-gray-800 dark:hover:bg-gray-100 dark:bg-white sm:w-auto mt-6 text-base leading-4 text-center text-white py-3 px-16 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 bg-j-orange hover:bg-j-purple" onClick={() => handleOk()}>OK</button>
                  </div>
              </div>
          </div>
      </Modal>
    </div>
  );
};

export default RouteCard;
