import React, { Component, useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  withRouter
} from "react-router-dom";
import PrivateRoute from "../utils/PrivateRoute"
import PublicRoute from "../utils/PublicRoute";

import Login from '../pages/Login/index';
import Dashboard from '../pages/Dashboard/index';
import BookingList from '../pages/Booking-list/index';
import ChangePassword from '../pages/Change-password/index';
import ChangeNumber from '../pages/Change-number/index';
import AgentProfile from '../pages/Agent-profile/index';
import PostShorex from '../pages/Post-booking-addon/index';
import Routes from '../pages/Routes/index';
import Booking from '../pages/Booking/index';
import Wallet from '../pages/Wallet/index';
import Sidebar from '../components/Sidebar/Sidebar';
import AdminNavbar from '../components/Header/AdminNavbar';
import WalletSuccess from '../pages/wallet-success/index';
import Success from "../pages/Success/index";
import Fail from "../pages/Fail/index";
import Contact from "../pages/Contact/index";
import PaymentDue from "../pages/pay-due-amount/index";
import ContactThankYou from "../pages/ContactThankYou/index";
import BookingListDetails from "../pages/Booking-list-details/index";
import { UserProvider } from "../store/UserContext";
import { getUser, removeUserSession } from '../utils/common';
import UpgradeBooking from "../pages/Upgrade-booking/index";
import PaymentGateway from "../pages/PaymentGateway/index";
import Webcheckin from "../pages/web-check-in/index";
import ModifyBooking from "../pages/Modify-booking/index";
import PostShorexSuccess from "../pages/Post-booking-addon/success";
import PreBooking from "../pages/Pre-Booking";
import HoldBooking from '../pages/Hold-booking';
import CompleteHoldBooking from "../pages/Hold-booking/completeBooking";
import CompletePayment from "../pages/Hold-booking/completePayment"
import PaymentLoader from "../pages/PaymentLoader/paymentLoader";
import WalletPaymentLoader from "../pages/PaymentLoader/walletPaymentLoader";
import ManageBooking from "../pages/manage-booking/index";
import AddGuest from "../pages/manage-booking/add-guest/index";
import ChangeCabin from "../pages/manage-booking/change-cabin/index";
import RemoveCabin from "../pages/manage-booking/remove-cabin/index";
import ManageBookingConfirm from "../pages/manage-booking/confirm/index";
import ManageBookingSuccess from "../pages/manage-booking/success/index";
import Reports from "../pages/Reports";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = { logginStatus: true };
        this.events = [
          "load",
          "mousemove",
          "mousedown",
          "click",
          "scroll",
          "keypress"
        ];

        // this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        for (var i in this.events) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
      }

      clearTimeout() {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
      }

      setTimeout() {
        // this.warnTimeout = setTimeout(this.warn, 5 * 1000);

        this.logoutTimeout = setTimeout(this.logout, 600 * 1000);
      }

      resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
      }


      logout() {
        const { match, location, history } = this.props;
        // Send a logout request to the API
        removeUserSession();
        history.push('/login')
        this.destroy();
      }

      destroy() {
        this.clearTimeout();

        for (var i in this.events) {
          window.removeEventListener(this.events[i], this.resetTimeout);
        }
      }
      render() {
        return (
            <RouterFunction />
        )
      }
}

export default withRouter(App);

const RouterFunction = () => {

    // Navigation

    const STORE_NAME = "store";
    const hasLocal = typeof window !== "undefined" && window.localStorage;
    const DEFAULT_STATE = {
        placeLoaded: false,
        place: {
          // TODO: Set default place here, might be flexible depending on the theme.
          address: "Bangalore",
          coordinates: [40.741895, -73.989308],
        },
      };
    const storedState = hasLocal
    ? JSON.parse(window.localStorage.getItem(STORE_NAME))
    : {};
    const [user, setUser] = useState(
    Object.assign({}, DEFAULT_STATE, storedState)
    );

    const setStore = (value) => {
        if (hasLocal)
        window.localStorage.setItem(STORE_NAME, JSON.stringify(value));
        setUser(value);
    };

    return (
        <>
            <Sidebar />
            <div className="md:ml-64 bg-gray-200">
                {/* <AdminNavbar /> */}
                <div className=" mx-auto w-full">
                    <UserProvider value={[user, setStore]}>
                        <Switch>
                            <PrivateRoute path="/admin/modify-booking">
                              <ModifyBooking />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/webcheckin">
                              <Webcheckin />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/paymentGateway">
                              <PaymentGateway />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/upgrade-booking-details">
                              <UpgradeBooking />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/pay-due-amount">
                                <PaymentDue />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/wallet-success">
                                <WalletSuccess />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/booking-details">
                                <BookingListDetails />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/contact/thank-you">
                                <ContactThankYou />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/contact">
                                <Contact />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/fail">
                                <Fail />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/booking/success">
                                <Success />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/wallet">
                                <Wallet />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/booking">
                                <Booking />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/routes">
                                <Routes />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/bookingList">
                                <BookingList />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/dashboard">
                                <Dashboard />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/change-password">
                                <ChangePassword />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/change-number">
                                <ChangeNumber />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/agent-profile">
                                <AgentProfile />
                            </PrivateRoute>
                            <PrivateRoute exact path="/admin/post-booking-addon">
                                <PostShorex />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/post-booking-addon/success">
                                <PostShorexSuccess />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/pre-booking">
                                <PreBooking />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/hold-booking">
                                <HoldBooking />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/complete-hold-booking">
                                <CompleteHoldBooking />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/complete-hold-booking-payment">
                                <CompletePayment />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/paymentLoader" exact>
                              <PaymentLoader />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/walletPaymentLoader">
                              <WalletPaymentLoader />
                            </PrivateRoute>
                            <PrivateRoute exact path="/admin/manage-booking">
                              <ManageBooking />
                            </PrivateRoute>
                            <PrivateRoute exact path="/admin/manage-booking/add-guest">
                              <AddGuest />
                            </PrivateRoute>
                            <PrivateRoute exact path="/admin/manage-booking/change-cabin">
                              <ChangeCabin />
                            </PrivateRoute>
                            <PrivateRoute exact path="/admin/manage-booking/remove-cabin">
                              <RemoveCabin />
                            </PrivateRoute>
                            <PrivateRoute exact path="/admin/manage-booking/confirm">
                              <ManageBookingConfirm />
                            </PrivateRoute>
                            <PrivateRoute exact path="/admin/manage-booking/success">
                              <ManageBookingSuccess />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/reports">
                                <Reports />
                            </PrivateRoute>
                            {/* <PublicRoute path="/" >
                                <Login />
                            </PublicRoute> */}
                            <Redirect from="/admin" to="/admin/dashboard" />
                        </Switch>
                    </UserProvider>
                </div>
            </div>
        </>
    );
}
