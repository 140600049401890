import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../store/UserContext";
import Layout from "../../../components/Layout/Layout";
// import Header from "../../../components/Header/Header";
// import FooterView from "../../../components/FooterAlt/Footer";
import cx from "classnames";
import View from "../../../components/View/View";
import { useForm } from "react-hook-form";
import Modal from "../../../components/Modal/Modal";
import RadioField from "../../../components/Form/RadioField";
import SelectorOverlay from "../../../components/MangeBooking/SelectorOverlay";
import { convertNodeToElement } from "react-html-parser";
import { RenderGuestForm } from "../add-guest";

const ChangeCabin = () => {
  const [user, setUser] = useContext(UserContext);
  const [bookingId, setBookingId] = useState();
  const [cabins, setCabins] = useState();
  const [activeCabin, setActiveCabin] = useState(null);
  const [itinerary, setItinerary] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [openDeck, setOpenDeck] = useState(false);
  const [beds, setBeds] = useState();
  const [roomNo, setRoomNo] = useState();
  const [guestCount, setGuestCount] = useState(1);
  const [bedOptions, setBedOptions] = useState();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.id) {
      const _data = JSON.parse(localStorage.getItem("manageBooking"));
      setBookingId(params.id);
      // fetchBookingDetails(params.id);
      setCabins([_data.changeCabin.cabin]);
      setItinerary(_data.changeCabin.itinerary)
      const bedOptions = _data.changeCabin.cabin.rooms_available.map(bedCount=>{
        return { value: bedCount, label: `I want ${bedCount} Bed Cabin` }
      })
      setBedOptions(bedOptions)
    } else window.location = "/my-bookings";
  }, [setBookingId]);

  // const fetchBookingDetails = (id) => {
  //   fetch(
  //     process.env.REACT_APP_API_ENDPOINT + "/bookings/" + id + "/details",
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${user.verificationToken.token}`,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       setCabins(response.booking.rooms);
  //       setItinerary(response.booking.itinerary);
  //     });
  // };

  const RenderCabin = ({
    cabin,
    setShowAlert,
    setActiveCabin,
    activeCabin,
    selectedRoom,
  }) => {
    return (
      <div className={`rounded-lg shadow-card grid grid-cols-3 p-5 bg-j-white ${cabin === activeCabin && selectedRoom? 'border border-j-orange':''}`}>
        <div className="grid grid-rows-5 col-span-2">
          <p className="text-lg uppercase font-medium">{cabin.category}</p>
          <p></p>
          <p>Guests: {cabin.guests}</p>
          <p>Deck No.: {cabin.deck_no} </p>
          <p>Room No.: {cabin.room_no}</p>
          {cabin === activeCabin && selectedRoom && (
            <p className="text-j-green">Selected Room No:{selectedRoom} </p>
          )}
        </div>
        <div className="flex flex-col justify-between">
          <p className="pt-1">
            {/* Available:<span className="ml-2 text-j-available">01</span> */}
          </p>
          {cabin !== activeCabin && (
            <button
              className="bg-j-orange text-white p-2 px-3 rounded mb-2"
              onClick={() => {
                setActiveCabin(cabin);
                setShowAlert(true);
              }}
            >
              Modify Cabin
            </button>
          )}
        </div>
      </div>
    );
  };

  const onBedSelection = (data) => {
    setBeds(data.beds);
    setShowAlert(false);
    setOpenDeck(true);
  };

  const onSubmit= (data)=>{
    const _data = {
      changeCabin: {
        cabin: activeCabin,
        itinerary: itinerary,
        guestData: data[activeCabin.bkroom_id],
      },
    };
    localStorage.setItem("manageBooking", JSON.stringify(_data));
    window.location = `/admin/manage-booking/confirm?action=change-cabin&id=${bookingId}&cabinId=${activeCabin.bkroom_id}&roomNo=${roomNo}`
  }

  const resetActiveCabin = () => {
    setActiveCabin(null);
  };

  return (
    <Layout title="My Bookings">
      {/* <Header
        className="bg-j-magenta text-j-white"
        bgImages={[
          "/images/my-booking-web.webp",
          "/images/my-booking-mobile.webp",
        ]}
        isManangeBooking
      ></Header> */}
      <main className="bg-auto">
        <div className={cx("bg-j-magenta text-j-white p-6")} id="filters">
          <div className="flex justify-between cursor-pointer">
            {/* <span className="self-center">My Bookings</span> */}
          </div>
        </div>
        <View>
          <div className="md:mx-10 py-8 h-full">
            <h3 className="font-medium mb-9">Cabins</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              {cabins &&
                cabins.map((cabin) => (
                  <RenderCabin
                    cabin={cabin}
                    setShowAlert={setShowAlert}
                    activeCabin={activeCabin}
                    setActiveCabin={setActiveCabin}
                    selectedRoom={roomNo}
                    key={cabin.id}
                  />
                ))}
            </div>
            {roomNo && (
              <div className="my-4">
                <h3 className="font-medium mb-9">Guest Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <form>
                    <RenderGuestForm
                      count={0}
                      guestCount={guestCount}
                      setGuestCount={setGuestCount}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                      activeCabin={activeCabin}
                    />
                  </form>
                </div>
              </div>
            )}
            <Modal show={showAlert} onClose={() => {setShowAlert(false);resetActiveCabin();}}>
              <div className="bg-j-white text-center rounded p-5 px-10">
                <form onSubmit={handleSubmit(onBedSelection)}>
                  <span
                    className="float-right text-j-orange"
                    onClick={() => setShowAlert(false)}
                  >
                    <i className="fas fa-times cursor-pointer" onClick={resetActiveCabin}></i>
                  </span>
                  <h4 className="pt-5 font-bold text-xl mb-4">
                    Modifying this Cabin because
                  </h4>
                  <div className="w-1/2 mx-auto mb-2">
                    <RadioField
                      radioStyle={{
                        border: "2px solid #EA725B",
                        width: "1em",
                        height: "1em",
                      }}
                      options={bedOptions}
                      className="-mb-1"
                      lessSpace
                      inverseError
                      name="beds"
                      ref={register({ required: true })}
                    />
                    {errors && errors.beds && (
                      <small class="text-xs pt-1 text-j-orange">
                        Please select an option
                      </small>
                    )}
                  </div>
                  <p className="text-j-orange italic mb-3">
                    *Additional Cost will be applicable
                  </p>
                  <button className="bg-j-orange text-white p-2 px-8 rounded mb-2">
                    CONTINUE
                  </button>
                </form>
              </div>
            </Modal>
            {openDeck && (
              <div style={{ zIndex: 999999 }}>
                <div
                  style={{
                    position: "fixed",
                    backgroundColor: "rgba(0,0,0, 0.2)",
                    zIndex: 999,
                    top: "0px",
                    left: "0px",
                    right: "0px",
                    bottom: "0px",
                  }}
                  onClick={() => {
                    setOpenDeck(false);
                    setActiveCabin(null);
                  }}
                ></div>
                <SelectorOverlay
                  className="disable-scrollbars itineraryModal"
                  onClose={() => {
                    setOpenDeck(false);
                  }}
                  onCancel={() => {
                    setOpenDeck(false);
                    setActiveCabin(null);
                  }}
                  rooms={[]}
                  // selections={selection}
                  itinerary={itinerary}
                  onSelect={(roomNo) => setRoomNo(roomNo)}
                  selectedCategory={{ category_id: activeCabin.category_id }}
                  beds={beds}
                  // roomIndex={roomIndex}
                />
              </div>
            )}
            <div className="text-center my-5">
              {roomNo && (
                <button
                  className="bg-j-orange text-white p-2 px-8 rounded mb-2"
                  onClick={handleSubmit(onSubmit)}
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </View>
      </main>
      {/* <footer>
        <FooterView />
      </footer> */}
    </Layout>
  );
};

export default ChangeCabin;
