import React from "react";

// components

export default function CardProfile() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
        <div className="px-6">
          <div className="mt-12">
            <h3 className="text-base font-semibold leading-normal mb-2 text-gray-800 mb-2">
              Payment Details
            </h3>
            <div className="mb-2 text-gray-700 mt-6">
              <i className="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
              PAN :- AACCW7972J
            </div>
            <div className="mb-2 text-gray-700">
              <i className="fas fa-university mr-2 text-lg text-gray-500"></i>
              GSTIN :- 27AACCW7972J1ZO
            </div>
            <h3 className="text-base font-semibold leading-normal mt-6 text-gray-800 mb-6 text-center underline">
              Payments to be made to
            </h3>
            <div className="mb-2 text-gray-700 text-sm">
              <div className="grid grid-cols-2">
                <div>
                    Beneficiary: <br/><br/><br/>
                    Bank Name: <br/><br/>
                    Bank Account Number: <br/><br/>
                    IFSC: <br/><br/>
                    Branch:
                </div>
                <div className="mb-10">
                  <span className="font-bold">WATERWAYS LEISURE TOURISM PRIVATE LIMITED</span> <br/><br/>
                  <span className="font-bold">IDBI Bank Limited</span><br/><br/>
                  <span className="font-bold">0014102000037411</span><br/><br/>
                  <span className="font-bold">IBKL0000014</span><br/><br/>
                  KHAR BRANCH MUMBAI <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
