import React, { Component, useState, useEffect, useContext } from "react";
import PaymentForm from "./PaymentForm";
import CheckoutForm from "./PostBookingCheckoutForm";
import cx from "classnames";
import Button from "../../Button/Button";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../../store/UserContext"
import { getToken } from "../../../utils/common";


const WalletPayment = ({ booking, billingData, partialStatus ,submitRef, setFailed, amountStatus, paymentPath, totalPrice,  twentyPercentage, walletStatus, bookingID, paymentOptionID, backButtonFunction, normalBooking, bookingType, handleWalletPayNow, customCheckout,walletLoader}) => {
    const [user, setUser] = useContext(UserContext)
    const [submitted, setSubmitted] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [allowPayment, setAllowPayment] = useState(false);
    const [amount,setAmount]= useState();
    let history = useHistory();
    const onSubmit = (e) => {
        e.preventDefault();
        // handleWalletPayNow("wallet")
        setClicked(true);
        if (submitRef && submitRef.current) submitRef.current.click();
    };
    
    useEffect(()=> {
        // console.log(partialStatus)
        // console.log(totalPrice)
        // console.log(amountStatus)
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/balance`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                `Bearer ${getToken()}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                    setAmount(response.amount) 
            })
            if(totalPrice>amount) {
                setAllowPayment(false)
            } else {
                setAllowPayment(true)
            }
    })

    useEffect(() => {
          if (paymentPath == "booking") {
            if (clicked && billingData) {
              setSubmitted(true)
              setClicked(false)
              setShowCheckout(true)
            }
          } else if (paymentPath == "wallet") {
            if (clicked) {
              setSubmitted(true)
              setClicked(false)
              setShowCheckout(true)
            }
          }
        // if (clicked && billingData) {
        //   setSubmitted(true);
        //   setClicked(false);
        //   setShowCheckout(true);
        // }
    }, [billingData, clicked]);

    const handleRecharge = () => {
        setUser(
            Object.assign({}, user, {
              rechargeStatus: true
            })
          )
        history.push("/admin/wallet");
    }

    return(
        <>
            {allowPayment && <PaymentForm
                buttonText="Pay Amount"
                onSubmit={onSubmit} 
                submitted={walletLoader}
            ></PaymentForm>}
            {!allowPayment && !walletLoader && <>
                <p className="text-center text-j-orange text-sm">You dont have enough balance on your wallet please recharge</p>
                <Button
                    className="bg-j-red-light text-white w-full mt-4 pt-1"
                    onClick={()=>handleRecharge()}
                >
                        Recharge Now
                </Button>
            </>}
            {showCheckout ? (
                <CheckoutForm
                    booking={booking}
                    paymentPath={paymentPath}
                    bookingID={bookingID}
                    billingData={billingData}
                    walletStatus={walletStatus}
                    partialStatus={partialStatus}
                    paymentOptionID={paymentOptionID}
                    wallet = {true}
                    totalPrice= {totalPrice}
                    normalBooking = {normalBooking}
                    backButtonFunction={backButtonFunction}
                    bookingType={bookingType}
                    amount={amount}
                    customCheckout={customCheckout}
                    cancel={(error) => {
                        setShowCheckout(false);
                        setSubmitted(false);
                        if (error) setFailed(error);
                    }}
                />
            ) : null}
        </>
      )
  }


export default WalletPayment;