import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../store/UserContext";
import Layout from "../../../components/Layout/Layout";
// import Header from "../../../components/Header/Header";
// import FooterView from "../../../components/FooterAlt/Footer";
import cx from "classnames";
import View from "../../../components/View/View";
import RadioField from "../../../components/Form/RadioField";
import Modal from "../../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import CountryStates from "../../../public/country-states.json";
import StateCities from "../../../public/state-cities.json";
import CheckBox from "../../../components/Form/CheckBox";
import { getToken } from "../../../utils/common";
import moment from "moment";

export const RenderGuestForm = ({
  count,
  guestCount,
  setGuestCount,
  register,
  setValue,
  errors,
  activeCabin,
  watch,
}) => {
  const [countryStatesArray, setCountryState] = useState([
    "Maharashtra",
    "Tamil Nadu",
    "Karnataka",
    "Kerala",
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Telangana",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Lakshadweep",
    "Madhya Pradesh",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Pondicherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tripura",
    "Uttaranchal",
    "Uttar Pradesh",
    "West Bengal",
  ]);
  const [country, setCountry] = useState("India");
  const [state, setState] = useState();
  const prefix = `${activeCabin.bkroom_id}.${count}`;
  const errorPrefix =
    errors &&
    errors[`${activeCabin.bkroom_id}`] &&
    errors[`${activeCabin.bkroom_id}`][count];

  const validateDob = (value) => {
    const age = new Date().getFullYear() - new Date(value).getFullYear();
    const guestType = watch(`${prefix}.type`);
    if (age < 0) {
      return false;
    } else if (guestType === "ADULT") {
      return age >= 12;
    } else if (guestType === "CHILD") {
      return ((age <= 12)&&(age>2));
    } else if (guestType === "INFANT") {
      return (age <= 2);
    } else {
      return false;
    }
  };
  useEffect(() => {
    register(`${prefix}.citizenship`, "India");
    setValue(`${prefix}.citizenship`, "India");
  }, [register, setValue, prefix]);
  return (
    <div
      className="rounded shadow-card p-5 bg-j-white relative"
      key={`${count}_guest`}
    >
      <span
        className="float-right"
        onClick={() => {
          if (guestCount > 1) setGuestCount(guestCount - 1);
        }}
      >
        {/* <i className="fas fa-times cursor-pointer"></i> */}
      </span>
      <p className="mb-4">Guest {count + 1}:</p>
      <RadioField
        // name={`${prefix}_gender`}
        radioStyle={{
          border: "2px solid #EA725B",
          width: "1em",
          height: "1em",
        }}
        options={[
          { value: "ADULT", label: "Adult" },
          { value: "CHILD", label: "Child" },
          { value: "INFANT", label: "Infant" },
        ]}
        horizontal
        lessSpace
        inverseError
        // {...register("example")}
        name={`${prefix}.type`}
        // {...register(`${activeCabin.id}.${count}.type`)}
        ref={register({ required: true })}
        error={errorPrefix && errorPrefix.type && "Please select a type"}
      />
      <div
        className={`grid grid-cols-2 gap-2 ${
          errorPrefix && (errorPrefix.first_name || errorPrefix.last_name)
            ? "mb-6"
            : "mb-2"
        }`}
      >
        <div>
          <input
            className="border rounded p-2 w-full"
            name={`${prefix}.first_name`}
            placeholder="First Name"
            ref={register({ required: true })}
            // {...register(`${activeCabin.id}.${count}.first_name`)}
          />
          {errorPrefix && errorPrefix.first_name && (
            <p class="absolute text-xs pt-1 text-j-orange">
              Please enter first name
            </p>
          )}
        </div>
        <div>
          <input
            className="border rounded p-2 w-full"
            name={`${prefix}.last_name`}
            placeholder="Last Name"
            ref={register({ required: true })}
            // {...register(`${activeCabin.id}.${count}.last_name`)}
          />
          {errorPrefix && errorPrefix.last_name && (
            <p class="absolute text-xs pt-1 text-j-orange">
              Please enter last name
            </p>
          )}
        </div>
      </div>
      <div className={`${errorPrefix && errorPrefix.gender ? "mb-6" : "mb-2"}`}>
        <select
          className="w-full border rounded p-2 bg-j-white"
          name={`${prefix}.gender`}
          ref={register({ required: true })}
        >
          <option selected value="" disabled>
            Select Your Gender
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        {errorPrefix && errorPrefix.gender && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please select the gender
          </p>
        )}
      </div>
      <div
        className={`${
          errorPrefix && errorPrefix.date_of_birth ? "mb-6" : "mb-2"
        }`}
      >
        <input
          type="date"
          className="border rounded p-2 w-full"
          placeholder="Date of Birth"
          name={`${prefix}.date_of_birth`}
          max={moment().format('YYYY-MM-DD')}
          ref={register({ required: true, validate: validateDob })}
        />
        {errorPrefix && errorPrefix.date_of_birth && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please enter a valid date of birth
          </p>
        )}
      </div>
      <div className={`${errorPrefix && errorPrefix.meal ? "mb-6" : "mb-2"}`}>
        <select
          className="w-full border rounded p-2 bg-j-white"
          name={`${prefix}.meal`}
          ref={register({ required: true })}
        >
          <option selected value="" disabled>
            Choose a Meal
          </option>
          <option value="Veg">Vegetarian</option>
          <option value="Jain">Jain</option>
          <option value="Non. Veg">Non - Vegetarian</option>
          <option value="Gluten Free">Gluten Free</option>
        </select>
        {errorPrefix && errorPrefix.meal && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please select a meal
          </p>
        )}
      </div>
      <div
        className={`${errorPrefix && errorPrefix.country ? "mb-6" : "mb-2"}`}
      >
        <select
          className="w-full border rounded p-2 bg-j-white"
          name={`${prefix}.country`}
          ref={register({ required: true })}
          value={country}
          onChange={(event) => {
            let x = event.target.value;
            setCountry(x);
            setValue(`${prefix}.citizenship`, "India");
            setCountryState(
              CountryStates.countries.filter(
                (country) => country.country === x
              )[0].states
            );
          }}
        >
          <option selected value="" disabled>
            Choose a Country
          </option>
          {CountryStates.countries.map((country) => {
            return (
              <option value={country.country} selected>
                {country.country}
              </option>
            );
          })}
        </select>
        {errorPrefix && errorPrefix.country && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please select a country
          </p>
        )}
      </div>
      <div className={`${errorPrefix && errorPrefix.state ? "mb-6" : "mb-2"}`}>
        <select
          className="w-full border rounded p-2 bg-j-white"
          name={`${prefix}.state`}
          ref={register({ required: true })}
          onChange={(event) => {
            let x = event.target.value;
            setState(x);
          }}
        >
          <option selected value="" disabled>
            Choose a State
          </option>
          {countryStatesArray.map((states) => {
            return <option value={states}>{states}</option>;
          })}
        </select>
        {errorPrefix && errorPrefix.state && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please select a state
          </p>
        )}
      </div>
      <div className={`${errorPrefix && errorPrefix.city ? "mb-6" : "mb-2"}`}>
        <select
          className="w-full border rounded p-2 bg-j-white"
          name={`${prefix}.city`}
          ref={register({ required: true })}
        >
          <option selected value="" disabled>
            Choose a City
          </option>
          {state && StateCities[state]
            ? StateCities[state].map((city) => {
                return <option value={city}>{city}</option>;
              })
            : null}
        </select>
        {errorPrefix && errorPrefix.city && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please select a city
          </p>
        )}
      </div>
      <div className="-mb-3 inline-block align-middle">
        <CheckBox
          name={`${prefix}.is_nri`}
          id={`${prefix}.is_nri`}
          ref={register({ required: false })}
          defaultValue={"false"}
          value={watch(`${prefix}.is_nri`) === "true" ? true : false}
          onChange={() => {
            if (watch(`${prefix}.is_nri`) === "false") {
              setValue(`${prefix}.is_nri`, "true");
            } else setValue(`${prefix}.is_nri`, "false");
          }}
          classes="mt-2 -mb-1"
          labelPaddingLarge
        >
          I am an NRI
        </CheckBox>
        {/* <input
          type="checkbox"
          className="border rounded p-2 mt-2"
          placeholder="I am an NRI"
          name={`${prefix}.is_nri`}
          value="true"
          onChange={()=>console.log(watch(`${prefix}.is_nri`))}
          ref={register({ required: false })}
        /><label for={`${prefix}.is_nri`}> I am an NRI</label> */}
      </div>
      <div className={`${errorPrefix && errorPrefix.phone ? "mb-6" : "mb-2"}`}>
        <input
          type="tel"
          className="border rounded p-2 w-full"
          placeholder="10 Digit Mobile Number"
          name={`${prefix}.phone`}
          ref={register({ required: true ,pattern: { value: /^\d{10}$/ }})}
        />
        {errorPrefix && errorPrefix.phone && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please enter your phone number
          </p>
        )}
      </div>
      <div className={`${errorPrefix && errorPrefix.email ? "mb-6" : "mb-2"}`}>
        <input
          type="email"
          className="border rounded p-2 w-full"
          placeholder="Email"
          name={`${prefix}.email`}
          ref={register({ required: true, pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}})}
        />
        {errorPrefix && errorPrefix.email && (
          <p class="absolute text-xs pt-1 text-j-orange">
            Please enter email address
          </p>
        )}
      </div>
    </div>
  );
};

const AddGuest = () => {
  const [bookingId, setBookingId] = useState();
  const [cabins, setCabins] = useState();
  const [itinerary, setItinerary] = useState();
  const [showGuest, setShowGuest] = useState(false);
  const [cabinInTransition, setCabinInTransition] = useState(null);
  const [activeCabin, setActiveCabin] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [guestCount, setGuestCount] = useState(1);
  // const [availableCabin, setAvailableCabin] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const _data = {
      addGuest: {
        cabin: activeCabin,
        itinerary: itinerary,
        guestData: data[activeCabin.bkroom_id],
      },
    };
    localStorage.setItem("manageBooking", JSON.stringify(_data));
    window.location = `/admin/manage-booking/confirm?action=add-guest&id=${bookingId}&cabinId=${activeCabin.bkroom_id}`;
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.id) {
      setBookingId(params.id);
      fetchBookingDetails(params.id);
    } else window.location = "/my-bookings";
  }, [setBookingId]);

  const fetchBookingDetails = (id) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/bookings/get_cabin_details?id=" +
        id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          setItinerary(response.data.itinerary);
          setCabins(response.data.rooms);
          // setActiveCabin(response.data[0]);
          // let availability = {};
          // response.data.rooms.forEach((cabin) => {
          //   availability[cabin.bkroom_id] = {
          //     available_beds: cabin.available_beds,
          //   };
          // });
          // setAvailableCabin(availability);
          // setShowGuest(true);
        }
      });
  };

  const RenderCabin = ({
    cabin,
    activeCabin,
    selectCabin,
    setCabinInTransition,
  }) => {
    return (
      <div
        className={`rounded-lg shadow-card grid grid-cols-3 p-5 bg-j-white ${
          cabin === activeCabin ? "border border-j-orange" : ""
        }`}
      >
        <div className="grid grid-rows-5 col-span-2">
          <p className="text-lg uppercase font-medium">{cabin.category}</p>
          <p></p>
          <p>Guests: {cabin.guests}</p>
          <p>Deck No.: {cabin.deck_no} </p>
          <p>Room No.: {cabin.room_no}</p>
        </div>
        <div className="flex flex-col justify-between">
          <p className="pt-1">
            Available:
            <span className="ml-2 text-j-available">
              {cabin.available_beds}
            </span>
          </p>
          {cabin !== activeCabin && cabin.available_beds > 0 && (
            <button
              className="bg-j-orange text-white p-2 px-3 rounded mb-2"
              onClick={() => {
                setCabinInTransition(cabin);
                selectCabin(cabin);
              }}
            >
              Add Guest
            </button>
          )}
          {/* {(cabin.available_beds == 0 && cabin.guests < 4 && cabin.rooms_availablelength>0)} */}
          {cabin.available_beds === 0 &&
            cabin.guests < 4 &&
            cabin.rooms_available.length > 0 && (
              <button
                className="bg-j-orange text-white p-2 px-3 rounded mb-2"
                onClick={() => {
                  const _data = {
                    changeCabin: {
                      cabin: cabin,
                      itinerary: itinerary,
                    },
                  };
                  localStorage.setItem("manageBooking", JSON.stringify(_data));
                  window.location = `/admin/manage-booking/change-cabin?id=${bookingId}`;
                }}
              >
                Change Cabin
              </button>
            )}
        </div>
      </div>
    );
  };

  const selectCabin = (cabin) => {
    if (activeCabin) {
      setShowAlert(true);
    } else {
      setActiveCabin(cabin);
      setShowGuest(true);
      setGuestCount(1);
    }
  };
  return (
    <Layout title="My Bookings">
      {/* <Header
        className="bg-j-magenta text-j-white"
        bgImages={[
          "/images/my-booking-web.webp",
          "/images/my-booking-mobile.webp",
        ]}
        isManangeBooking
      ></Header> */}
      <main className="bg-auto">
        <div className={cx("bg-j-magenta text-j-white p-6")} id="filters">
          <div className="flex justify-between cursor-pointer">
            {/* <span className="self-center">My Bookings</span> */}
          </div>
        </div>
        <View>
          <div className="md:mx-10 py-8 h-full">
            <h3 className="font-medium mb-9">Cabins</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              {cabins &&
                cabins.map((cabin) => (
                  <RenderCabin
                    cabin={cabin}
                    activeCabin={activeCabin}
                    selectCabin={selectCabin}
                    setCabinInTransition={setCabinInTransition}
                  />
                ))}
            </div>
            <Modal show={showAlert} onClose={() => setShowAlert(false)}>
              <div className="bg-j-white text-center rounded p-5 px-10">
                <span
                  className="float-right text-j-orange"
                  onClick={() => setShowAlert(false)}
                >
                   <i className="fas fa-times cursor-pointer" ></i>
                </span>
                <h4 className="mt-5 font-bold text-xl">Add Guest Alert!</h4>
                <p className="w-4/5 mx-auto mb-4">
                  You can add guests in one cabin at a time. Do you want to
                  discard the selection and add guests in another cabin?
                </p>
                <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
                  <button
                    className="bg-j-orange text-white p-2 px-3 rounded mb-2"
                    onClick={() => {
                      reset();
                      setShowGuest(true);
                      setActiveCabin(cabinInTransition);
                      setGuestCount(1);
                      setShowAlert(false);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-j-white border border-j-orange text-j-orange p-2 px-3 rounded mb-2"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </Modal>
            {showGuest && (
              <>
                {/* <div className="my-5 bg-j-orange-light p-5 text-md rounded-lg">
                  <span className="float-right">
                    <i className="fal fa-times"></i>
                  </span>
                  <p>
                    <i className="fal fa-exclamation-circle mr-2"></i>
                    <strong>Important Note:</strong> You can add maximum{" "}
                    {availableCabin[activeCabin.bkroom_id].available_beds}{" "}
                    guests to this cabin{" "}
                    <span className="text-j-orange italic cursor-pointer" onClick={()=>window.location=`/manage-booking/change-cabin?id=${bookingId}`}>
                      I want to modify my cabin
                    </span>
                  </p>
                </div> */}
                <div className="mt-5">
                  {/* {availableCabin[activeCabin.bkroom_id].available_beds > 1 ? (
                    <button
                      className="bg-j-white border border-j-orange text-j-orange p-2 px-3 rounded mb-2 float-right"
                      onClick={() => {
                        if (
                          guestCount <
                          availableCabin[activeCabin.bkroom_id].available_beds
                        )
                          setGuestCount(guestCount + 1);
                      }}
                    >
                      Add Guest
                    </button>
                  ) : (
                    <button
                      className="bg-j-white border border-j-orange text-j-orange p-2 px-3 rounded mb-2 float-right"
                      onClick={() => {}}
                    >
                      Change Cabin
                    </button>
                  )} */}

                  <h3 className="font-medium mb-9">Guest Details</h3>
                </div>
                <form>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* {[0, 1].map((count) => {
                      return (
                        <RenderGuestForm
                          count={count}
                          guestCount={guestCount}
                          setGuestCount={setGuestCount}
                          register={register}
                          errors={errors}
                        />
                      );
                    })} */}
                    <RenderGuestForm
                      count={0}
                      guestCount={guestCount}
                      setGuestCount={setGuestCount}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                      activeCabin={activeCabin}
                    />

                    {/* {guestCount.map((guest) => (
                    <RenderGuestForm />
                  ))} */}
                  </div>
                </form>
              </>
            )}
            <div className="text-center my-5">
              {activeCabin && (
                <button
                  className="bg-j-orange text-white p-2 px-8 rounded mb-2"
                  // onClick={() => {
                  //   window.location = `/manage-booking/confirm`;
                  // }}
                  onClick={handleSubmit(onSubmit)}
                  // type="submit" form="hook-form"
                  // onSubmit={handleSubmit(onSubmit)}
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </View>
      </main>
      {/* <footer>
        <FooterView />
      </footer> */}
    </Layout>
  );
};

export default AddGuest;
