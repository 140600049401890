import React, { useState, useEffect } from "react";
import cx from 'classnames';
import Button from '../../Button/Button';
import BookingOverlay from "../General/BookingOverlay";
import DeckOverlay from './DeckOverlay';
import { getToken } from "../../../utils/common";

const SelectorOverlay = ({ onClose, rooms, selections, itinerary, onSelect, selectedCategory, roomIndex,itineraryID }) => {
    const [index, setIndex] = useState(roomIndex);
    const [deck, setDeck] = useState(null);
    const [decks, setDecks] = useState([]);
    const onDeckSelected = (deck) => {
        setDeck(deck);
    }
    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT+"/agent_portal/itineraries/"+itinerary.id+"/decks/?category_id="+selectedCategory.category_id,{
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
            `Bearer ${getToken()}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setDecks(data);
          });
    }, [setDecks]);


    const onSkipClicked = () => {
        onClose()
    }

    if (index == rooms.length) <div />;

    if (deck) {
        return (
            <DeckOverlay
                className={cx("deck-overlay-override")}
                rooms={rooms}
                roomIndex={index}
                selections={selections}
                selectedCategory = {selectedCategory}
                deck={deck}
                itinerary={itinerary}
                onClose={onClose}
                onDeckClose={onSkipClicked}
                onSelect={(index, selected, selected_room) => {
                    onSelect(index, selected, true, selected_room) ;
                    onSkipClicked();
                }}
                itineraryID={itineraryID} />
        )
    }

    return (
        <BookingOverlay
            style="close"
            onCancel={onClose}
        >
            <h2 className="uppercase pb-4 text-2xl font-medium">Cabin {roomIndex + 1}</h2>
            <h2 className="text-2xl pb-4 ">Choose a deck</h2>
            <p className="pb-4 text-sm">Please select a deck from below to see the available room options</p>
            {decks.map((deck) => <DeckCard key={deck.id} deck={deck} onSelect={onDeckSelected} />)}
        </BookingOverlay>
    );
};

export default SelectorOverlay;


const DeckCard = ({ deck, onSelect }) => {
    const [galleryOpen,setGalleryOpen]= useState(false);
    return(
    <div className={cx("rounded-lg border border-white p-4 mb-4", deck.selectable ? "bg-white text-black" : "bg-magenta text-white")}>
        <p className="text-xs uppercase pb-2">Deck {deck.id}</p>
        <p className="text-sm font-bold">{deck.description}</p>
        {deck.obstructed&&<div className="grid grid-cols-2"><p className="text-j-green-lighter mt-2 italic">Obstructed View Cabins</p><p className="text-j-orange text-right underline mt-2 italic cursor-pointer" onClick={()=>setGalleryOpen(!galleryOpen)}>View Gallery</p></div>}
        {galleryOpen&&<div className="grid grid-cols-2 gap-2 mt-4"><img src={process.env.REACT_APP_API_PUBLIC_IMAGE_HOSTNAME+deck.images[0].src} className="w-full h-full rounded-lg" alt="Obstructed Cabin"/><img src={process.env.REACT_APP_API_PUBLIC_IMAGE_HOSTNAME+deck.images[1].src} className="w-full h-full rounded-lg" alt="Obstructed Cabin" /></div>}
        {deck.selectable && (
            <>
                <h4 className="text-j-orange py-4">
                </h4>
                <Button className="w-full bg-j-orange text-white pt-1" onClick={() => {
                    onSelect(deck);
                    }}>Select this deck</Button>
            </>
        )}
    </div>
)}

// const getDecks = (selection) => (
//     decks.map(deck => Object.assign(
//         {}, deck, {
//         rooms: Array(getRandomInt(2, 10)).fill({}).map(
//             () => ({ number: getRandomInt(1, 44), category: getRandomItem(selection.categories) }))
//     }
//     ))
// )



function getRandomInt(min, max) {
    return min + Math.floor(Math.random() * Math.floor(max));
}

function getRandomItem(items) {
    return items[Math.floor(Math.random() * items.length)]
}
