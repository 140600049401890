import cx from "classnames";
import React from "react";

const CheckBox = React.forwardRef(({ children, value, onChange, labelPaddingLarge=false, ...rest },ref) => (
  <div
    className="flex mb-6 cursor-pointer"
    onClick={() => onChange && onChange({ target: { value: !value } })}
  >
    <i
      className={cx(
        "far pr-2 pt-2 text-lg self-center leading-none",
        value ? "fa-check-square" : "fa-square"
      )}
    />
    <input  ref={ref} type="hidden" value={value} {...rest} />
    <p className={`leading-0 ${labelPaddingLarge? 'pt-3': 'pt-1'}`}>{children}</p>
  </div>
));

export default CheckBox;
