import React, { Component, useContext, useState } from "react";
import GuestSelectionView from "../../components/Booking/GuestSelectionView";
import RoomSelectionView from "../../components/Booking/RoomSelectionView";
import GuestDetailsView from "../../components/Booking/GuestDetailsView";
import ConformBookingView from "../../components/Booking/ConformBookingView";
import PaymentSummaryView from "../../components/Booking/PaymentSummaryView";
import UserContext from "../../store/UserContext";
import RoomsCard from "../../components/Booking/Cards/RoomsCard";
import SelectionCard from "../../components/Booking/Cards/SelectionCard";
import GuestsCard from "../../components/Booking/Cards/GuestsCard";
import ShorexCard from "../../components/Booking/Cards/ShorexCard";
import SafetyCard from "../../components/Booking/Cards/SafetyCard";
import ProductCard from "../../components/Booking/Cards/ProductCard";
import ShoreExcursion from "../../components/Booking/ShoreExcursion";
import SafetyProtection from "../../components/Booking/SafetyProtection"
import Layout from "../../components/Layout/Layout";

class Booking extends Component {

  state = {
    step: parseInt(window.sessionStorage.getItem('step')),
    backButtonStatus: true,
  };

  backButtonFunction = () => {
    this.setState({backButtonStatus: false})
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ 
      step: step - 1,
    });
  };
  
  handleModify = (newStep) => {
    const { step } = this.state;
    this.setState({
      step: newStep
    })
  }

  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  switchState = () => {
    const { step } = this.state;
    sessionStorage.setItem('step', step);
    switch (step) {
      case 1:
        return (
          <>
            <HeaderView 
               prevStep={this.prevStep}
               backStatus={false}
               step={step}
               handleModify={this.handleModify}
            />
            <GuestSelectionView
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              maxRoom={15}
              // values={values}
            />
          </>
        );
      case 2:
        return (
          <>
            <HeaderView 
               prevStep={this.prevStep}
               backStatus={true}
               step={step}
               handleModify={this.handleModify}
            />
            <RoomSelectionView
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              // values={values}
            />
          </>
        );
      case 3:
          return (
            <>
              <HeaderView 
                prevStep={this.prevStep}
                backStatus={true}
                step={step}
                handleModify={this.handleModify}
              />
              <PaymentSummaryView
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                // values={values}
              />
            </>
        );
      case 4:
        return (
          <>
            <HeaderView 
                prevStep={this.prevStep}
                backStatus={true}
                step={step}
                handleModify={this.handleModify}
            />
            <GuestDetailsView
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              // values={values}
            />
          </>
        );
        case 5:
          return (
            <>
              <HeaderView 
                  prevStep={this.prevStep}
                  backStatus={this.state.backButtonStatus}
                  step={step}
                  handleModify={this.handleModify}
              />
              <ShoreExcursion
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                backButtonFunction = {this.backButtonFunction}
                // values={values}
              />
            </>
          );
      // case 6:
      //       return (
      //         <>
      //           <HeaderView 
      //               prevStep={this.prevStep}
      //               backStatus={this.state.backButtonStatus}
      //               step={step}
      //               handleModify={this.handleModify}
      //           />
      //           <SafetyProtection
      //             nextStep={this.nextStep}
      //             prevStep={this.prevStep}
      //             handleChange={this.handleChange}
      //             backButtonFunction = {this.backButtonFunction}
      //             // values={values}
      //           />
      //         </>
      //       );
      case 6:
        return (
          <>
            <HeaderView 
                prevStep={this.prevStep}
                backStatus={this.state.backButtonStatus}
                step={step}
                handleModify={this.handleModify}
            />
            <ConformBookingView
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              backButtonFunction = {this.backButtonFunction}
              // values={values}
            />
          </>
        );
    }
  };

  render() {
    return (
      <Layout>
        <div className="flex flex-col h-screen">
          {/* <Header /> */}
          <main class="flex-grow overflow-y-auto md:pr-14 lg:pr-14 pl-4 pr-4 md:pl-14 lg:14">
            {this.switchState()}
          </main>
          {/* <Footer /> */}
        </div>
      </Layout>
    );
  }
}

export default Booking;

const HeaderView = ({prevStep, backStatus, step, handleModify}) => {
  const [user, setUser] = useContext(UserContext);
  const booking = user.booking || {};
  const [modifyBooking, setModifyBooking] = useState(false)
  const backbuttonpressed = () => {
    prevStep()
  }

  const toggleModify = () => {
    setModifyBooking(!modifyBooking);
  }


  return(
    <>
      <div className="grid grid-cols-3 border-b p-6">
        <div>
            {backStatus && <button className="font-semibold text-j-magenta" onClick={backbuttonpressed}>
                        <i className="fas fa-arrow-circle-left" style={{fontSize: "28px"}}></i>
            </button>
            }
        </div>
        <div className="text-center text-j-orange font-bold">{booking.itinerary.ship.name}</div>
        <div 
          className="uppercase cursor text-right text-j-magenta"
        >
          <button className="bg-transparent  font-semibold py-2 px-4 border border-j-magenta rounded" onClick={() => toggleModify()}>
            Modify Booking
          </button>
        </div>
      </div>
      <ModifyToggle step={step} modifyBooking={modifyBooking} handleModify={handleModify}/>
    </>
  )
}

const ModifyToggle = ({step, modifyBooking, handleModify}) => {
  const [user, setUser] = useContext(UserContext);
  const booking = user.booking || {};
  const gridClass = step>2 ? "grid grid-cols-1 md:grid-cols-1 gap-4" : "grid grid-cols-1 md:grid-cols-1 gap-4"
  return(
    <div className={gridClass}>
      <div>
        {step > 0 && modifyBooking && (
          <ProductCard booking={booking} handleModify={handleModify} step={step}/>
        )}
        {step > 1 && modifyBooking && (
          <RoomsCard booking={booking} handleModify={handleModify} step={step}/>)
        }
        {step > 2 && modifyBooking && (
          <SelectionCard booking={booking} handleModify={handleModify} step={step}/>)
        }
        {step > 4 && modifyBooking && (
          <GuestsCard booking={booking} handleModify={handleModify} step={step}/>)
        }
        {step > 5 && modifyBooking && (
          <ShorexCard booking={booking} handleModify={handleModify} step={step}/>)
        }
         {step > 6 && modifyBooking && (
          <SafetyCard booking={booking} handleModify={handleModify} step={step}/>)
        }
      </div>
    </div>
  )
}
