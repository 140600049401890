import React from "react";
import Input from "./Input";
import Field from "./Field";
import Select from "./Select";
import cx from "classnames";


const countryCodes = [
  "+91",
  "+1",
  "+20",
  "+211",
  "+212",
  "+213",
  "+216",
  "+218",
  "+220",
  "+221",
  "+222",
  "+223",
  "+224",
  "+225",
  "+226",
  "+227",
  "+228",
  "+229",
  "+230",
  "+231",
  "+232",
  "+233",
  "+234",
  "+235",
  "+236",
  "+237",
  "+238",
  "+239",
  "+240",
  "+241",
  "+242",
  "+243",
  "+244",
  "+245",
  "+246",
  "+247",
  "+248",
  "+249",
  "+250",
  "+251",
  "+252",
  "+253",
  "+254",
  "+255",
  "+256",
  "+257",
  "+258",
  "+260",
  "+261",
  "+262",
  "+263",
  "+264",
  "+265",
  "+266",
  "+267",
  "+268",
  "+269",
  "+27",
  "+290",
  "+291",
  "+297",
  "+298",
  "+299",
  "+30",
  "+31",
  "+32",
  "+33",
  "+34",
  "+350",
  "+351",
  "+352",
  "+353",
  "+354",
  "+355",
  "+356",
  "+357",
  "+358",
  "+359",
  "+36",
  "+370",
  "+371",
  "+372",
  "+373",
  "+374",
  "+375",
  "+376",
  "+377",
  "+378",
  "+379",
  "+380",
  "+381",
  "+382",
  "+385",
  "+386",
  "+387",
  "+966",
  "+967",
  "+968",
  "+970",
  "+971",
  "+972",
  "+973",
  "+974",
  "+975",
  "+976",
  "+977",
  "+979",
  "+98",
  "+992",
  "+993",
  "+994",
  "+995",
  "+996",
  "+998",
];

const PhoneFieldAlt = React.forwardRef(
  (
    {
      className,
      error,
      name,
      onChange,
      lessSpace,
      inverseError,
      defaultValue,
      noIcon,
      countryPhoneCode,
      payementpage,
      accountType,
      onChangeValue,
      pathName,
      defaultenumber,
      ...rest
    },
    ref
  ) => {
    console.log(defaultValue)
    const [prefix, number] = (defaultValue || "").split(" ");
    const [value, setValue] = React.useState(defaultValue);
    const codeInput = React.useRef(null);
    const phoneInput = React.useRef(null);
    const updateValue = (e) => {
      console.log('ahi', phoneInput.current.value)
      if(onChangeValue) onChangeValue(e)
      const val = `${codeInput.current.value.length ? `${codeInput.current.value} ` : ""
        }${phoneInput.current.value}`;
      setValue(val);
      onChange && onChange({ target: { value: val } });
    };

    return (
      <Field
        icon={!noIcon && "fas fa-mobile flex-grow-0"}
        error={error}
        inverseError={inverseError}
        className={cx(className)}
        lessSpace={lessSpace}
  
      >
       <Select
          withIcon={!noIcon}
          ref={codeInput}
          tiny
          className={"w-16"}
          onChange={updateValue}
          defaultValue={"+91"}
          maxLength="5"
        >
          {countryCodes.map((c) => (
            <option value={c} key={c}>
              {c}
            </option>
          ))}
        </Select>
        <Input
          ref={phoneInput}
          {...rest}
          type="number"
          onChange={updateValue}
          grow
          withIcon
          defaultValue={number || prefix}
          maxLength="10"
        />
        <input ref={ref} name={name} value={value} type="hidden" />
      </Field>
    );
  }
);

export default PhoneFieldAlt;
