import React, { useEffect, useState, useContext } from "react"
import BookingFormView from "./General/BookingFormView"
import Tile from "../Tile/Tile"
import Button from "../Button/Button"
import cx from "classnames"
import LoadingIcon from "../Loading/LoadingIcon"
import SelectorOverlay from "./Selector/SelectorOverlay"
import SelectionDetailsView from "./Selector/SelectionDetailsView"
import UserContext from "../../store/UserContext"
import { getToken } from "../../utils/common"
import styles from "./roomSelectionView.module.css"
import { Link, useHistory } from "react-router-dom";
import offer from "../../assets/images/discount.png";
import Modal from "../Modal/Modal";
import { Document, Page,pdfjs} from 'react-pdf';
import {deviceType} from 'react-device-detect';

var selectedRoomValue
const RoomSelectionView = ({ nextStep, prevStep }) => {
  let history = useHistory();
  const [user, setUser] = useContext(UserContext)
  const booking = user.booking || {}
  const [selection, setSelection] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [roomIndex, setroomIndex] = useState(null)
  const [open, setOpen] = useState(null)
  const [preview, setPreview] = useState(null)
  const [itinerary, setItinerary] = useState(booking.itinerary)
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const [selectedRoomId, setSelectedRoomId] = useState(null)
  const [renderView, setRenderView] = useState(false)
  const [loadingPriceSummary, setLoadingPriceSummary] = useState(false)
  const [specialFare, setSpecialFare] = useState(false)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  // const { itinerary_data, itinerary_error, itinerary_loading } = useQuery(GET_ITINERARIES, {
  //     variables: {},
  //     skip: !!selection || itinerary,
  //   });
  //   useEffect(() => {
  //     if (itinerary_data && itinerary_data.itineraries) {
  //       setItinerary(itinerary_data.itineraries[0]);
  //     }
  //   }, [itinerary_data, itinerary_error, itinerary_loading]);

  useEffect(() => {
    window.localStorage.removeItem('partialPayment');
    window.localStorage.removeItem('partialRule');
    setRenderView(true)
    var itinerary = booking.itinerary
    var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))
    var fare = JSON.parse(localStorage.getItem('specialFare'));
    setSpecialFare(fare)
    window.sessionStorage.setItem(
      "shore_excursion_new",
      JSON.stringify(null)
    )
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/itineraries/" +
        itinerary.id +
        "/check_availability",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          currency: currancyStatus.code,
          visitorID: booking.visitorID,
          itineraryID: itinerary && itinerary.id,
          promoCode: "", // TODO: connect landing page promo codes here
          hold_booking: true,
          rooms: booking.rooms.map((room) => {
            const { adults, infants, children } = room
            return {
              adults,
              infants,
              children,
            }
          }),
          price_type: fare ? 'special_pricing' : null
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error("Something went wrong")
        }
      })
      .then((response) => {
        setSelection(response.data.checkAvailability)
        setLoading(false)
      })
      .catch((error) => {
        setError(true)
        setLoading(false)
      })
  }, [loading])

  if (loading || error || !selection) {
    return (
      <h1 className="text-4xl">
        <LoadingIcon className="py-20 text-j-magenta" />
      </h1>
    )
  }

  const onSelect = (index, selected, fromSelector, selected_room) => {
    console.log('roh-iii', index, selected, selected_room);
    const rooms = [...booking.rooms]
    if (selected_room) {
      rooms[index] = Object.assign(
        {},
        rooms[index],
        { selected },
        {
          room_id: selected_room.itinerary_room_id,
          total_price: selected_room.total_price,
          room_number: selected_room.number,
        }
      )
    } else {
      rooms[index] = Object.assign(
        {},
        rooms[index],
        { selected },
        { room_id: null, total_price: null, room_number: null }
      )
    }
    selectedRoomValue =
      index == rooms.length - 1 &&
      !rooms.filter((room) => !room.selected).length
    if (selectedRoomValue) {
      setUser(
        Object.assign({}, user, {
          booking: Object.assign({}, booking, {
            rooms,
          }),
        })
      )
    } else {
      setUser(
        Object.assign({}, user, {
          booking: Object.assign({}, booking, {
            rooms,
            roomsSelected: selectedRoomValue,
          }),
        })
      )
    }
  }

  const conformingPaymentDetails = (requestData) => {
    setLoadingPriceSummary(true)
    let bookingInput = {}
    let visitorsID = window.localStorage.getItem("store")
    let bookingID = JSON.parse(visitorsID)
    let visitorsIDNew = bookingID.visitorID
    let itineraryID = bookingID.booking.itinerary.id
    var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))
    var fare = JSON.parse(localStorage.getItem('specialFare'));
    let deviceTypes;

    if (deviceType === 'mobile') {
      deviceTypes = 'mobile';
    } else if (deviceType === 'tablet') {
      deviceTypes = 'tablet';
    } else if (deviceType === 'browser') {
      deviceTypes = 'desktop';
    } else {
      deviceTypes = 'desktop';
    }
    bookingInput.rooms = requestData.rooms.map((room) => ({
      priceKey: room.selected.priceKey,
      roomId: room.room_id,
    }))

    console.log('roh-bookingInput', bookingInput);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/hold_bookings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        variables: {
          input: bookingInput,
          visitorID: visitorsIDNew,
          itineraryID: itineraryID,
        },
        price_type: fare ? "special_pricing" : null,
        device:deviceTypes
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.booking) {
          window.sessionStorage.setItem("is_international", JSON.stringify(response.is_international))
          window.sessionStorage.setItem("booking_id", JSON.stringify(response.booking.id))
          window.sessionStorage.setItem(
            "booking",
            JSON.stringify(response.booking)
          )
          window.sessionStorage.setItem(
            "tcs_taxes",
            JSON.stringify({
              tcs:response.tcs&&response.tcs!==null?response.tcs:0,
              tcs_with_pan:response.tcs_with_pan && response.tcs_with_pan!==null ?response.tcs_with_pan:0
            })
          );
          if (booking.invoice_url) {
            delete booking.invoice_url
          }
          setUser(
            Object.assign({}, user, {
              booking: Object.assign({}, booking, {
                number: response.booking.number,
              }),
            })
          )
          setLoadingPriceSummary(false)
          nextStep()
          //   setPriceDeclaration(true)
        } else {
          //   setPriceDeclaration(false)
          //   setErrorMsg(response.data)
        }
      })
      .catch((err) => err)
  }
  const handleConfirm = (requestData) => {
    onSelect(0, null, null, null)
    // setLoading(true)
    localStorage.setItem('specialFare', specialFare);
    setRenderView(true)
    var itinerary = booking.itinerary
    var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))
    var fare = JSON.parse(localStorage.getItem('specialFare'));
    setSpecialFare(fare)
    console.log('rohspecialFare', specialFare);
    window.sessionStorage.setItem(
      "shore_excursion_new",
      JSON.stringify(null)
    )
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/itineraries/" +
        itinerary.id +
        "/check_availability",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          currency: currancyStatus.code,
          visitorID: booking.visitorID,
          itineraryID: itinerary && itinerary.id,
          promoCode: "", // TODO: connect landing page promo codes here
          hold_booking: true,
          rooms: booking.rooms.map((room) => {
            const { adults, infants, children } = room
            return {
              adults,
              infants,
              children,
            }
          }),
          price_type: specialFare ? 'special_pricing' : null
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error("Something went wrong")
        }
      })
      .then((response) => {
        setSelection(response.data.checkAvailability)
        setLoading(false)
      })
      .catch((error) => {
        setError(true)
        setLoading(false)
      })
  }

  const backbuttonpressed = () => {
    prevStep()
  }

  const isComplete = !booking.rooms.filter((room) => !room.selected).length

  return (
    <>
      {renderView && <><BookingFormView
        title="Find your perfect room"
        buttonText="Proceed to passenger information"
        lessSpace={true}
        onClick={() => {
          conformingPaymentDetails(booking)
          window.scrollTo({ top: 0, behavior: "smooth" })
        }}
        id="otp-form"
        disabled={!isComplete}
        loading={loadingPriceSummary}
        specialFare={specialFare}
        setSpecialFare={(e) => setSpecialFare(e)}
        confirm={handleConfirm}
      >
        {open && (
          <div style={{ zIndex: 999999 }}>
            <div
              style={{
                position: "fixed",
                backgroundColor: "rgba(0,0,0, 0.2)",
                zIndex: 999,
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
              }}
              onClick={() => setOpen(false)}
            ></div>
            <SelectorOverlay
              className="disable-scrollbars itinerarymodal"
              onClose={() => {
                setOpen(null);
                setSelectedCategoryId(null)
                setSelectedRoomId(null)
              }}
              rooms={booking.rooms}
              selections={selection}
              itinerary={booking.itinerary}
              onSelect={onSelect}
              selectedCategory={open}
              roomIndex={roomIndex}
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {booking.rooms.map((room, i) => (
            <RoomView
              room={room}
              changePop={(selectedCategory, index) => {
                setroomIndex(index)
                setOpen(selectedCategory)
              }}
              selections={selection[i].categories}
              roomIndex={i}
              onSelect={(selection) => onSelect(i, selection)}
              onDetails={(room, selection, selectionIndex) => {
                setPreview(Object.assign([room.id, selectionIndex]))
              }}
              selectedCategoryId={selectedCategoryId}
              setSelectedCategoryId={setSelectedCategoryId}
              selectedRoomId={selectedRoomId}
              setSelectedRoomId={setSelectedRoomId}
              itinerary={itinerary}
            />
          ))}

          {preview && (
            <div style={{ zIndex: 999999 }}>
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "rgba(0,0,0, 0.2)",
                  zIndex: 999,
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                }}
                onClick={() => setPreview(null)}
              ></div>
              <SelectionDetailsView
                rooms={booking.rooms}
                selections={selection[preview[0]].categories}
                selectionIndex={preview[1]}
                roomIndex={preview[0]}
                onClose={() => setPreview(null)}
                onSelect={() => {
                  setroomIndex(preview[0])
                  setOpen(selection[preview[0]].categories[preview[1]])
                  setPreview(null)
                }}
              />
            </div>
          )}
        </div>
      </BookingFormView>
      </>}
    </>
  )
}

export default RoomSelectionView

const RoomView = ({
  room,
  onSelect,
  selections,
  onDetails,
  changePop,
  roomIndex,
  selectedCategoryId,
  setSelectedCategoryId,
  selectedRoomId,
  setSelectedRoomId,
  itinerary
}) => {
  const selection = room.selected ? (
    <RoomCard
      room={room.selected}
      roomId={room.id}
      selected
      changePop={changePop}
      onChange={() => onSelect(null)}
      onDetails={(selection) =>
        onDetails(
          room,
          selection,
          selections.map((s) => s.name).indexOf(room.selected.name)
        )
      }
      roomIndex={roomIndex}
      roomNumber={room.room_number}
      selectedCategoryId={selectedCategoryId}
      setSelectedCategoryId={setSelectedCategoryId}
      selectedRoomId={selectedRoomId}
      setSelectedRoomId={setSelectedRoomId}
      itinerary={itinerary}
    />
  ) : (
    selections.map((r, i) => (
      <RoomCard
        room={r}
        key={i}
        roomId={room.id}
        changePop={changePop}
        onChange={(selection) => onSelect(selection)}
        onDetails={(selection) => onDetails(room, selection, i)}
        roomIndex={roomIndex}
        selectedCategoryId={selectedCategoryId}
        setSelectedCategoryId={setSelectedCategoryId}
        selectedRoomId={selectedRoomId}
        setSelectedRoomId={setSelectedRoomId}
        itinerary={itinerary}
      />
    ))
  )

  return (
    <Tile
      theme="magenta"
      shadow
      className="my-2 bg-bgcolor rounded-lg"
      id={`room-${room.id}`}
    >
      <Tile.Inner>
        <h5 className="uppercase">
          Cabin {room.id + 1} - {room.adults + room.children + room.infants}{" "}
          Guests
        </h5>
        <br />
        {selection}
      </Tile.Inner>
    </Tile>
  )
}

const RoomCard = ({
  room,
  roomId,
  onChange,
  selected,
  onDetails,
  changePop,
  roomIndex,
  roomNumber,
  selectedCategoryId,
  setSelectedCategoryId,
  selectedRoomId,
  setSelectedRoomId,
  itinerary
}) => {
  const [roomSelected, setRoomSelected] = useState(false)
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))

  useEffect(() => {
    if (room.category_id === selectedCategoryId && roomId === selectedRoomId) {
      setRoomSelected(true)
    } else {
      setRoomSelected(false)
    }
  }, [selectedCategoryId, selectedRoomId])
  
  function onSelectIfSelected() {
    onChange(room)
  }

  function onSelectNotSelected(room,roomId) {
    changePop(room, roomIndex)
    setSelectedCategoryId(room.category_id)
    setSelectedRoomId(roomId)
  }

  return (
    <div className="mb-8 relative rounded-lg overflow-hidden text-white bg-white shadow-xl flex">
      {room.onboard_credit_offer ? <div className={cx("w-1/12 bg-j-orange", styles.wrapper)}>
        <h3>Offer</h3>
      </div>: null }
      {room.offer_description ? <p class="ribbon">
          <span class="ribbon-text"><strong class="ribbon-bold">{room.offer_description}</strong></span>
        </p> : null}
      <Tile.Inner
        tiny
        noPadding
        className={cx(
          "rounded-big",
          room.offer_description ? "mt-16" : "",
          room.onboard_credit_offer ? " w-11/12" : "w-full",
          room.popular ? "bg-j-orange" : "bg-j-white",
          roomSelected ? styles.highlighter : ""
        )}
        theme={room.popular ? "orange" : "white"}
      >
    {/* {room.onboard_credit_offer ?
         <p class="ribbon">
          <span class="ribbon-text" style={{background:"#EA725B"}}><strong class="ribbon-bold">{room.onboard_credit_offer} </strong></span>
        </p> : null}  */}
        <div className="px-5 py-5">
        <div className="flex justify-between">
          <h4
            className={cx(
              "font-bold pb-0 self-center",
              room.popular ? "text-white" : "text-j-magenta"
            )}
          >
            {room.name}
          </h4>
          <p className="self-center px-2 hidden md:block text-j-magenta">Deck No: <span className="font-bold">{room.deck_no.join(', ')}</span></p>
          <Button
            className={cx(
              "border self-center pt-1 h-10",
              room.popular
                ? "border-white bg-j-orange text-j-white"
                : "border-j-gray bg-j-white text-j-gray"
            )}
            onClick={() => {
              selected ? onSelectIfSelected() : onSelectNotSelected(room,roomId)
            }}
          >
            {selected ? "Change" : "Select"}
          </Button>
        <p className="self-center md:hidden text-j-magenta">Deck No: <span className="font-bold">{room.deck_no.join(', ')}</span></p>
        </div>
        {selected && (
          <div className="pt-3">
            <div className="text-xs text-j-green" style={{fontSize: "0.95rem", fontWeight: "bold"}}>Room No: {roomNumber}</div>
          </div>
        )}
        {room.onboard_credit_offer ?<div className="flex w-8/12 pt-2">
          <span className="pt-2"><img src={offer} alt="discount" /></span><p className="text-black text-xs pl-2 pr-2">{room.onboard_credit_offer}</p>
        </div>: null
        }
        <div className="flex justify-between pt-5">
          <a
            className={cx(
              "text-xs uppercase cursor-pointer self-center",
              room.popular ? "text-j-white" : "text-j-orange"
            )}
          >
             <span onClick={() => {
              onDetails(room);
            }}>
            Details</span>
            {(room.code==="INTERIORSTANDARD"||room.code==="OCEANVIEWSTANDARD")&&<span className="ml-4 font-bold" onClick={()=>setShowModal(true)}>
            Compare Now
            </span>}
          </a>
          <h2
            className={cx(
              "leading-none self-center pb-0 pr-6 text-md font-normal font-bold",
              room.popular ? "text-black" : "text-black"
            )}
          >
            {/* <p className="text-j-gray text-sm font-normal text-right pb-2">From</p>
            {currancyStatus.symbol} {(room.price.total || 0).toLocaleString("hi-IN")} */}
            <p className="text-j-gray text-sm font-normal text-right pb-2">From</p>
              {itinerary?.discount_pct > 0 && <p className="text-sm font-medium text-right line-through">{currancyStatus.symbol} {(Math.floor(room.price.actual_total) || 0).toLocaleString("hi-IN")}</p>}
              <p>{currancyStatus.symbol} {(room.price.total || 0).toLocaleString("hi-IN")}</p>
          </h2>
        </div>
        </div>
        {room.sub_category&& !selected &&<hr className="mt-4"/>}
        {room.sub_category&& !selected &&<div className="grid grid-cols-3 pl-5 pr-10 pt-5 pb-4 cursor-pointer bg-j-orange-lighter border-l-8 border-j-orange-brand" onClick={()=>setOpen(!open)}>
          <p className="font-medium flex items-center col-span-2 text-j-magenta"><span><img src="https://images.cordeliacruises.com/static/premium_badge.png" /></span>Select Premium Deck</p>
          <i
            style={{ color: "grey", fontSize: 28, textAlign: "right", marginTop:"-5px"}}
            className={cx(
              "fas cursor-pointer text-j-gray-light text-4xl",
              {
                "fa-angle-down": !open,
                "fa-angle-up": open,
              }
            )}
          ></i>
        </div>}
        {open&&room.sub_category&&
        room.sub_category.map(room=>{return(<div key={room.category_id}>
          <hr className="pt-2"/>
          <Tile.Inner
          tiny
          noPadx
          className={cx(
          "rounded-big",
          room.onboard_credit_offer ? " w-11/12" : "w-full",
          room.popular ? "bg-j-orange" : "bg-j-white",
          roomSelected ? styles.highlighter : ""
        )}
        theme={room.popular ? "orange" : "white"}
      >
        <div className="px-5 py-5">
        <div className="flex justify-between">
          <h4
            className={cx(
              "font-bold pb-0 self-center",
              room.popular ? "text-j-white" : "text-j-magenta"
            )}
          >
            {room.name}
          </h4>
          <p className="self-center px-2 hidden md:block text-j-magenta">Deck No: <span className="font-bold">{room.deck_no.join(', ')}</span></p>
          <Button
            className={cx(
              "border self-center",
              room.popular
                ? "border-j-white bg-j-orange text-j-white"
                : "border-j-gray bg-j-white text-j-gray"
            )}
            style={{ outline: 0 }}
            onClick={() => {
              selected ? onSelectIfSelected() : onSelectNotSelected(room,roomId);
            }}
          >
            {selected ? "Change" : "Select"}
          </Button>
        </div>
        <p className="self-center md:hidden text-j-magenta">Deck No: <span className="font-bold">{room.deck_no.join(', ')}</span></p>
        {selected && (
          <div className="pt-3">
            <div className="text-xs text-j-green" style={{fontSize: "0.95rem", fontWeight: "bold"}}>Room No: {roomNumber}</div>
          </div>
        )}
        {room.onboard_credit_offer ?<div className="flex w-8/12 pt-2">
          <span className="pt-2"><img src={offer} alt="discount" /></span><p className="text-black text-xs pl-2 pr-2">{room.onboard_credit_offer}</p>
        </div>: null
        }
        <div className="flex justify-between pt-5">
          <a
            className={cx(
              "text-xs uppercase cursor-pointer self-center",
              room.popular ? "text-j-white" : "text-j-orange"
            )}
          >
            <span onClick={() => {
              onDetails(room);
            }}>
            Details</span>
            {(room.code==="INTERIORSTANDARD"||room.code==="OCEANVIEWSTANDARD")&&<span className="ml-4 font-bold" onClick={()=>setShowModal(true)}>
            Compare Now
            </span>}
          </a>
          <h2
            className={cx(
              "leading-none self-center pb-0 pr-6 text-md font-normal font-bold",
              room.popular ? "text-black" : "text-black"
            )}
          >
            <p className="text-j-gray text-sm font-normal text-right pb-2">From</p>
            {itinerary?.discount_pct > 0 && <p className="text-sm font-medium line-through text-right">{currancyStatus.symbol} {(Math.floor(room.price.actual_total) || 0).toLocaleString("hi-IN")}</p>}
            <p>{currancyStatus.symbol} {(room.price.total || 0).toLocaleString("hi-IN")}</p>
          </h2>
        </div>
        </div>
        </Tile.Inner>
        </div>);})
        }
      </Tile.Inner>
      <div className="pdf-modal">
        <Modal show={showModal} onClose={()=>setShowModal(false)}>
        <Document file='/pdfs/Subcat_Grid.pdf'><h2 className="text-j-orange p-3 text-center cursor-pointer" onClick={()=>setShowModal(false)}>Close</h2><Page pageNumber={1}/></Document>
      </Modal>
      </div>
    </div>
  )
}
