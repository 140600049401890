import React from "react";

export default function ThankYouCardSettings() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Write to Us</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-4">
          <div className="text-center text-ls text-j-white">
            <div
              className="flex flex-col justify-between pb-48"
              style={{ maxHeight: "100%", height: 500 }}
            >
              <div className="pt-16">We have received your message</div>
              <i
                className="fas fa-check-circle text-j-green py-14"
                style={{ fontSize: 180 }}
              />
              <p>We will get back to you shortly.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
