import React from "react";

// components

export default function ContactDetails() {
  return (
    <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
            <ContactView label="For Group enquiries:" link="groups@cordeliacruises.com"/>
            <ContactView label="For Wedding enquiries:" link="Weddings@cordeliacruises.com"/>
            <ContactView label="For API Support:" link="apisupport@cordeliacruises.com"/>
            <ContactView label="Email id of finance with account details:" link="accounts@waterways-leisure.com"/>
            <div className="px-6">
                <div className="mt-4 mb-6">
                    {/* <h3 className="text-base font-semibold leading-normal mb-2 text-gray-800 mb-2">
                        Address link:
                    </h3>
                    <a href="https://bit.ly/WLTPL_LOC" target="_blank" className="underline">https://bit.ly/WLTPL_LOC</a> */}
                </div>
            </div>
        </div>
    </>
  )
}

const ContactView = ({label, link}) => {
    return( 
        
            <div className="px-6">
                <div className="mt-4">
                    <h3 className="text-base font-semibold leading-normal mb-2 text-gray-800 mb-2">
                        {label}
                    </h3>
                    <a href={`mailto:${link}`} className="underline">{link}</a>
                </div>
            </div>
    )
}