import React, { useState, Component, setUser, useEffect, useRef } from 'react';
import BookingFormView from "./General/BookingFormView";
import Tile from "../Tile/Tile";
import Button from "../Button/Button";
import cx from "classnames";
import scrollTo from "../../utils/scrollTo";
import UserContext from "../../store/UserContext";
import { CancelView } from "../View/FAQView";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { getToken } from "../../utils/common";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { name, phone, email, date, phone_pattern } from "../../utils/validations";
import Modal from "../Modal/Modal";
import LoadingIcon from "../Loading/LoadingIcon";

class PaymentSummaryView extends Component {

  render() {
    const { values } = this.props;
    return (
      <>
        <PaymentSummaryViewAPI nextStep={this.props} />
      </>
    )
  }
}
export default PaymentSummaryView;



const PaymentSummaryViewAPI = (nextStep) => {
  let history = useHistory();
  const [user, setUser] = React.useContext(UserContext);
  const bookingMain = user.booking;
  const itinerary = user.booking.itinerary;
  const booking = user.booking.rooms || {};
  const [renderView, setRenderView] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setRenderView(true)
  }, [])

  const backbuttonpressed = (e) => {
    e.preventDefault()
    nextStep.nextStep.prevStep()
  }

  const conformingPaymentDetails = (e) => {
    e.preventDefault()
    setDisabled(true);
    nextStep.nextStep.nextStep()
  }

  return (
    <>
      <BookingFormView
        buttonText="Continue"
        onClick={conformingPaymentDetails}
        lessSpace={true}
        id="otp-form"
        disabled={disabled}
      >
        {PriceDetails(booking || [], bookingMain, renderView, itinerary)}
      </BookingFormView>
    </>
  )
}

const PriceDetails = (bookingArr, bookingMainArr, renderView, itinerary) => {
  const [open, setOpen] = useState(null);
  const [modelEmail, setModelEmail] = useState(false);
  const [emailValue, setEmailValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false)
  const submitRef = useRef(null);
  const [commissionOpen, setCommissionOpen] = useState(false);
  const [fareOpen, setFareOpen] = useState(false);
  const [cancellationPolicy, setCancellationPolicy] = useState(null);
  const [reschedulePolicy, setReschedulePolicy] = useState(null);
  const [shareStatus, setShareStatus] = useState(null)

  // const [modelEmail, setModelEmail] = useState(false);
  // const [emailValue, setEmailValue] = useState(null);
  // const [submitted, setSubmitted] = useState(false);
  // const submitRef = useRef(null);

  let itiId = JSON.parse(localStorage.getItem('store'));;
  itiId = itiId.booking.itinerary.id;
  let bookingidEmailShare = JSON.parse(sessionStorage.getItem('booking'));
  let sessionBookingID = JSON.parse(sessionStorage.getItem('booking_id'))
  bookingidEmailShare = bookingidEmailShare.id;
  const cancellationDiv = cancellationPolicy ? (
    <div>
      {cancellationPolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{ display: "revert" }}>{term}</li>
                );
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)
  const rescheduleDiv = reschedulePolicy ? (
    <div>
      {reschedulePolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {ele.fees[0] && Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{ display: "revert" }}>{term}</li>
                )
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)

  const modalClose = () => {
    // setShareStatus(shareValue)
    setModelEmail(false)
  }

  const modalOpen = (shareValue) => {
    setShareStatus(shareValue)
    setModelEmail(true);
  }

  const schema = Joi.object({
    email,
  })


  const { register, errors, handleSubmit, setError } = useForm({
    resolver: joiResolver(schema),
  });

  const shareEmailQuatation = () => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(emailValue)) {
      setEmailValidation(true)
    } else {
      setEmailValidation(false)
      setSubmitted(true);
      let dataObj = {
        "booking_id": sessionBookingID,
        "step": "payment",
        "email": emailValue,
        "auth": getToken(),
        "quotation_type": shareStatus
      };
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/quotations`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(dataObj),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw Error("Verification failed");
        }
        )
        .then((response) => {
          alert("Quotation Sent successfully");
          setSubmitted(false);
          modalClose()

        }).catch((err) => console.log(err));
    }
  }

  const onChangeValue = (e) => {
    const email = e.target.value;
    setEmailValue(email);
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/itineraries/${itiId}/policies`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.json())
      .then((res) => {
        setCancellationPolicy(res.cancellation);
        setReschedulePolicy(res.reschedule);
      }).catch((err) => {
        alert("Error Fetching Cancellation and Reschedule Policies");
      });
  }, [])
  let bookingStore = window.sessionStorage.getItem('booking');
  let tcsTaxes = JSON.parse(sessionStorage.getItem('tcs_taxes'));
  let is_international = JSON.parse(sessionStorage.getItem('is_international'));
  bookingStore = JSON.parse(bookingStore);
  const container = {
    margin: "15px",
    backgroundColor: "white",
    borderRadius: "10px"
  }
  const { rooms } = bookingMainArr;
  let agentPrice = 0;
  let grandTotalValue = 0;
  let grandTotalValueIncTax = 0;

  const guests = rooms
    .map((r) => r.adults + r.children + r.infants)
    .reduce((t, i) => t + i, 0);


  const selected = {};
  rooms.forEach((r) => {
    if (!selected[r.selected.name]) selected[r.selected.name] = 0;
    selected[r.selected.name]++;
  });

  bookingArr.forEach((booking) => {
    booking.selected.price.individual.forEach((r) => {
      agentPrice = r.fare + agentPrice;
    })
  })

  bookingArr.reduce((grandTotal, booking) => {
    grandTotalValue = grandTotalValue + booking.selected.price.total;
  }, 0)

  grandTotalValueIncTax = grandTotalValue + (bookingStore.gst);

  const returnFormattedCurrency = (x) => {
    x = Number(x);
    x = x.toString();
    var afterPoint = '';
    if (x.indexOf('.') > 0)
      afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    return res;
  }

  // const schema = Joi.object({
  //   email,
  // })


  // const { register, errors, handleSubmit, setError } = useForm({
  //   resolver: joiResolver(schema),
  // });






  return (
    <>
      <Modal show={modelEmail} onClose={modalClose}>
        <div className="p-6">
          <p className="mb-4">Please enter your Email-ID:</p>
          {/* <InputField
                        placeholder="Email address"
                        type="email"
                        name="email"
                        lessSpace
                        // defaultValue={storageProfile.email}
                        // ref={register({ required: true })}
                        error={errors && errors.email && "Please enter a valid email address"}
                    /> */}
          <input
            required
            name={email}
            placeholder="Email address"
            type="email"
            className="w-full h-10 px-2 w-full text-black rounded mt-2 focus:outline-non flex-grow max-w-full placeholder-j-gray-lighter text-black text-sm shadow placeholder-gray-500 placeholder-opacity-75 self-center pt-1 leading-none mb-2 border-grey-600"
            // value={this.props.value}
            // defaultValue={this.props.defaultValue}
            onChange={(e) => onChangeValue(e)} />
          {emailValidation && <p className="text-sm text-j-orange pb-2 pl-1">Please Enter a valid E-mail ID</p>}
          <p className="pb-0 pl-1 text-black">**Please check your spam folder if email is not received in Inbox.</p>
          {!submitted && <Button
            className="bg-j-red-light text-white w-full mt-4 pt-1"
            disabled={submitted}
            onClick={() => shareEmailQuatation()}
          >
            Submit
          </Button>}
          {submitted && <Button
            className="bg-j-red-light text-white w-full mt-6 pt-1"
            disabled={submitted}
          >
            <LoadingIcon />
          </Button>}
        </div>
      </Modal>
      {renderView && <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2">
        <span>
          <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg" style={{ marginRight: '5px' }}>
            <Tile.Inner className="pb-0">
              <OrderDetail title="SHIP">
                {bookingMainArr.itinerary.ship.name}
              </OrderDetail>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="mr-2">
                  <OrderDetail title="Departure">
                    {bookingMainArr.departure}
                    <br></br>
                    {moment(bookingMainArr.itinerary.startTime).format("llll")}
                  </OrderDetail>
                </div>
                <div className="ml-2">
                  <OrderDetail title="Arrival">
                    {bookingMainArr.destination}
                    <br></br>
                    {moment(bookingMainArr.itinerary.endTime).format("llll")}
                  </OrderDetail>
                </div>
              </div>
              {Object.keys(bookingArr)
                .map((k, index) =>

                  <OrderDetail title={`Cabin ${index + 1}`}>
                    {bookingArr[index].selected.name}<br />
                    Deck No: {((bookingArr[index].room_number).toString()).charAt(0)}<br />
                    Room No: {bookingArr[index].room_number}<br />
                    {bookingArr[index].adults + bookingArr[index].children + bookingArr[index].infants}  Guest{(bookingArr[index].adults + bookingArr[index].children + bookingArr[index].infants) > 1 ? "s" : null}
                  </OrderDetail>
                )}
              {/* <OrderDetail title="ROOM AND GUEST">
                    {Object.keys(selected)
                    .map((k) => `${selected[k]} x ${k}`)
                    .join(", ")}
                  , {guests} Guest{guests > 1 ? "s" : null} ,

                  </OrderDetail> */}

              {/* <div className="grid justify-end mb-4">
                <a href="#"><i className="fas fa-share"></i> Share</a>
              </div> */}
              <div className="flex justify-end mb-4">
                <a onClick={e => modalOpen('gross_qoute')} className="cursor-pointer border-2 p-2 rounded-lg"><i className="fas fa-share"></i> Share Gross Quote</a>
                <a onClick={e => modalOpen('net_quote')} className="cursor-pointer border-2 p-2 rounded-lg ml-2"><i className="fas fa-share"></i> Share Net Quote</a>
              </div>
            </Tile.Inner>
          </Tile>
        </span>
        <span>
          <Tile tiny theme="white" className="mb-5 col-span-2 bg-transparent" style={{ marginLeft: '5px', backgroundColor: 'transparent' }}>
            <div className="border border-j-gray-lighter" style={{ backgroundColor: "white", borderRadius: "10px" }}>
              <div
                style={{ margin: '25px', color: '#500E4B', fontWeight: 'bold' }}
                className="grid grid-cols-1 grid-cols-2"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <span className="pt-1"> PRICE DETAILS</span>
                <i
                  style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                  className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                    "fa-angle-down": !open,
                    "fa-angle-up": open,
                  })}
                ></i>
              </div>
              {open && (
                <>
                  {bookingArr.map((booking, index) =>
                    <div key={index}>
                      <div style={{ color: "black" }}>
                        {booking.selected.price.individual.map((price, index) =>
                          <div key={index} style={{ margin: "25px" }}>
                            <div style={{ width: '100%' }}>
                              <PaymentDetails content={price.type} details={returnFormattedCurrency(Math.round((price.total) * 100 / 100))} big={true} />
                              <PaymentDetails content="Cabin Fare:" details={returnFormattedCurrency(Math.round((price.fare) * 100 / 100))} big={false} />
                              {price.discount != '0' && <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
                                <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">Individual Discount {price.discount_text && price.discount_text }</div> 
                                <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
                                  - {returnFormattedCurrency(price.discount)}
                                </div>
                              </div>}
                              <PaymentDetails content="Service Charges & Levies" details={returnFormattedCurrency(price.portCharges + price.gratuity)} big={false} />
                              <PaymentDetails content="Fuel Surcharge" details={returnFormattedCurrency(price.fuelSurcharge)} big={false} />
                              {/* <PaymentDetails content="Port Charges" details={returnFormattedCurrency(price.portCharges)} big={false} />
                          <PaymentDetails content="Gratuity:" details={returnFormattedCurrency(price.gratuity)} big={false} /> */}
                              {parseFloat(price.insurance) > 0 && <PaymentDetails content="Insurance:" details={returnFormattedCurrency(price.insurance)} big={true} />}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {bookingStore.agent_commission_type == "p2p" && <div style={{ margin: '25px' }}>
                    <PaymentDetails content="Discount:" details={returnFormattedCurrency(bookingStore.agent_commission)} big={true} />
                  </div>}
                </>
              )}
              {/* {open && bookingArr.map((booking, index) =>
                <div key={index}>
                  <div style={{ color: "black" }}>
                    {booking.selected.price.individual.map((price, index) =>
                      <div key={index} style={{ margin: "25px" }}>
                        <div style={{ width: '100%' }}>
                          <PaymentDetails content={price.type} details={returnFormattedCurrency(Math.round((price.total) * 100 / 100))} big={true}/>
                          <PaymentDetails content="Cabin Fare:" details={returnFormattedCurrency(Math.round((price.fare) * 100 / 100))} big={false} />
                          {price.discount != '0' && <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
                            <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">Individual Discount</div>
                            <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
                              - {returnFormattedCurrency(price.discount)}
                            </div>
                          </div>}
                          <PaymentDetails content="Service Charges & Levies" details={returnFormattedCurrency(price.portCharges+price.gratuity)} big={false} />
                          <PaymentDetails content="Fuel Surcharge" details={returnFormattedCurrency(price.fuelSurcharge)} big={false} />
                          { parseFloat(price.insurance) > 0 && <PaymentDetails content="Insurance:" details={returnFormattedCurrency(price.insurance)} big={true} />}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )} */}

              {/* {open && <div style={{ margin: '25px' }}>
                <PaymentDetails content="Promo Code:" details="0" big={true} />
                <PaymentDetails content="New Year Promo:" details="0" big={false} />
              </div>
              } */}

              {open && <div style={{ margin: '25px' }}>
                  <PaymentDetails content="Sub-Total:" details={returnFormattedCurrency(Math.round(bookingStore.total - bookingStore.gst))} big={true} />
                  <PaymentDetails content="Taxable Sub Total:" details={returnFormattedCurrency(Math.round(bookingStore.total - bookingStore.gst))} big={false} />
                </div>
              }
              {open && <div style={{ margin: '25px' }}>
                <PaymentDetails content="Taxes:" details={returnFormattedCurrency(Math.round(bookingStore.gst))} big={true} />
                <PaymentDetails content="GST:" details={returnFormattedCurrency(Math.round(bookingStore.gst))} big={false} />
              </div>
              }

              <div style={{ margin: '25px' }}>
                {itinerary?.discount_pct != 0 && <p className='text-j-black text-sm text-right line-through'>&#x20B9;{returnFormattedCurrency(Math.round(bookingStore.actual_gross_fare))}</p>}
                <PaymentDetails content="Gross Fare:" details={returnFormattedCurrency(Math.round(bookingStore.gross_fare))} big={true} />
              </div>

              {bookingStore.coupon_code && <div style={{ margin: '25px' }}>
                <PaymentDetails content={`Applied Coupon (${bookingStore.coupon_code}):`} details={returnFormattedCurrency(bookingStore.coupon_discount)} big={true} />
              </div>}

              {/* {bookingStore.agent_commission_type == "p2p" && <div style={{ margin: '25px' }}>
                <PaymentDetails content="Discount:" details={returnFormattedCurrency(bookingStore.agent_commission)} big={true} />
              </div>} */}

              <div style={{ margin: '25px' }}>
                {itinerary?.discount_pct != 0 && <p className='text-j-black text-sm text-right line-through'>&#x20B9;{returnFormattedCurrency(Math.round(bookingStore.actual_total))}</p>}
                <PaymentDetails content="Net Payable to Cordelia:" details={returnFormattedCurrency(Math.round(bookingStore.total))} big={true} />
              </div>

              {bookingStore.agent_commission_type == "p2a" && <>
                <div
                  style={{ margin: '25px', color: '#500E4B', fontWeight: 'bold' }}
                  className="grid grid-cols-1 grid-cols-2"
                  onClick={() => {
                    setCommissionOpen(!commissionOpen);
                  }}
                >
                  <span className="pt-1"> COMMISSION DETAILS</span>
                  <i
                    style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                    className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                      "fa-angle-down": !commissionOpen,
                      "fa-angle-up": commissionOpen,
                    })}
                  ></i>
                </div>
                {commissionOpen && <div style={{ margin: '25px' }}>
                  <PaymentDetails content="Commission:" details={returnFormattedCurrency(Math.round(bookingStore.agent_commission))} big={true} />
                </div>
                }
              </>
              }
              <div
                style={{
                  margin: "25px",
                  marginBottom: "25px",
                  color: "#500E4B",
                  fontWeight: "bold",
                }}
                className="grid grid-cols-1 grid-cols-2"
                onClick={() => {
                  setFareOpen(!fareOpen);
                }}
              >
                <span className="pt-1">Cancellation and Reschedule policy</span>
                <i
                  style={{ color: "grey", fontSize: 28, textAlign: "right" }}
                  className={cx(
                    "fas cursor-pointer text-j-gray-light text-4xl",
                    {
                      "fa-angle-down": !fareOpen,
                      "fa-angle-up": fareOpen,
                    }
                  )}
                ></i>
              </div>
              <div
                style={{
                  margin: "25px",
                  marginBottom: "18px",
                  color: "#500E4B",
                  fontWeight: "bold",
                }}
                className="grid grid-cols-1"
              >
                <a href="https://images.cordeliacruises.com/static/insurance_web_document.pdf" target="_blank" className="text-j-magenta font-bold">
                  Cordelia Cruises Insurance Benefit Document
                </a>
              </div>
              <div
                style={{
                  margin: "25px",
                  marginBottom: "18px",
                  color: "#500E4B",
                  fontWeight: "bold",
                }}
                className="grid grid-cols-1"
              >
                <a href="https://cordelia-images-prod.s3.ap-south-1.amazonaws.com/static/Other_Policy.pdf" target="_blank" className="text-j-magenta font-bold">
                  Other Additional Charges
                </a>
              </div>
              {fareOpen && (
                <div style={{ padding: "1rem" }}>
                  {cancellationDiv}
                  {rescheduleDiv}
                </div>
              )}
            </div>
          </Tile>
          {is_international ? <Tile
            tiny
            theme="white"
            className="mb-5 col-span-2 bg-transparent"
            style={{ marginLeft: "5px", backgroundColor: "transparent" }}
          >
            <div
              style={{
                color: "#500E4B",
                fontWeight: "500",
              }}
              className="mt-3"
            >
              <span className="pt-1">
                <span class="rounded-full ml-1 mr-4" style={{ backgroundColor: "#500E4B", height: "8px", width: "8px", display: "inline-block" }}></span>
                I agree to pay TCS charges applicable as per below:
              </span>
            </div>
            <div
              style={{
                color: "#500E4B",
                fontWeight: "500",
              }}
              className='mt-4'
            >
              <div className="flex">
                <span className="fa fa-database mt-1 mr-2"></span>
                <div className="">
                  <span
                    style={{
                      color: "#000",
                    }}
                    className="text-sm font-normal max-w-xs"
                  >
                    In the financial year, if the Tour expenses are less than 7 lacs then 5% TCS will be levied
                  </span>
                  
                </div>
              </div>
              <div className="flex">
                <span className="fa fa-database mt-1 mr-2"></span>
                <div className="">
                  <span
                    style={{
                      color: "#000",
                    }}
                    className="text-sm font-normal max-w-xs"
                  >
                    In the financial year, if the Tour expenses are more than 7 lacs then 20% TCS will be levied
                  </span>
                  
                </div>
              </div>
              <div className="flex">
                <span className="fa fa-database mt-1 mr-2"></span>
                <div className="">
                  <span
                    style={{
                      color: "#000",
                    }}
                    className="text-sm font-normal max-w-xs"
                  >
                    TCS will apply to the total cost (i.e.((Cabin fare + Service Charge & Levies + Fuel charge) *GST)*TCS)
                  </span>
                  
                </div>
              </div>
            </div>
          </Tile> : null}
        </span>
      </div>
      }
    </>
  )
}

const OrderDetail = ({ title, children, big }) => (
  <div className="mb-6">
    <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none ">
      {title}
    </h1>
    <h4
      className={
        big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2 text-lg font-semibold"
      }
    >
      {children}
    </h4>
  </div>
);

const PaymentDetails = ({ content, details, big }) => (
  <>
    <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
      {big && <>
        <div style={{ color: "black", fontSize: 16, fontWeight: 'bold' }} className="float-left bold">
          {content}
        </div>
        <div style={{ color: "black", fontSize: 16, textAlign: 'right', fontWeight: 'bold' }} className="float-right font-weight-bold text-capitalize">
          &#x20B9;{details}
        </div>
      </>
      }
      {!big && <>
        <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">{content}</div>
        <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
          &#x20B9;{details}
        </div>
      </>
      }
    </div>
  </>
);
