import React, { Component } from "react";
import ChangePasswordCard from "../../components/Cards/ChangePasswordCard";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (<>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 pt-4 px-4" style={{margin: "0 auto"}}>
          <ChangePasswordCard />
        </div>
      </div>
    </>);
  }
}

export default Contact;
