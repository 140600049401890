import React from "react";
import cx from "classnames";

const Field = ({
  children,
  icon,
  className,
  right,
  left,
  error,
  inverseError,
  lessSpace,
  gstValidation,
  pathName
}) => (
  <div className={cx("relative", lessSpace ? "mb-6" : "mb-10", className)}>
    <div
      className={cx(
        "bg-transparent  p-2 pt-0 transition ease-in-out duration-300 flex",
        // {
        //   "rounded-tiny border": !right && !left,
        //   "rounded-l-tiny border": left,
        //   "rounded-r-tiny border-t border-b border-r": right,
        //   "border-j-red shadow-error": error && !inverseError,
        //   "border-j-orange shadow-error": error && inverseError,
        //   "border-j-gray-light": !error,
        // }
      )}
    >
      {children}
    </div>
    {error && (
      <small
        className={cx(
          "absolute text-xs pl-2",
          lessSpace ? "pt-1" : "pt-2",
          inverseError ? "text-red-500" : "text-red-500"
        )}
      >
        {error}
      </small>
    )}
    {pathName == "payment" && gstValidation && <small
        className={cx(
          "absolute text-xs pl-2",
          lessSpace ? "pt-1" : "pt-2",
          inverseError ? "text-red-500" : "text-red-500"
        )}
      >
        please Enter valid GST number
      </small>}
  </div>
);

export default Field;
