import React, { Component } from "react";
import CardStats from "../../components/Cards/CardStats";
import CardSettings from "../../components/Cards/ThankYouCardSettings";
import CardProfile from "../../components/Cards/CardProfile";
import ContactDetails from "../../components/Cards/ContactDetails";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let gtm = process.env.NEXT_PUBLIC_MEASUREMENT_ID;
    let url = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_MEASUREMENT_ID}`;
    return (
      <>
        <Helmet>
          <script async src={url}></script>
          <script
            async
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag("js", new Date());

                gtag("config", "${gtm}");`,
            }}
          />

          <script
            async
            dangerouslySetInnerHTML={{
              __html: `gtag('event', 'conversion';, {'send_to': 'AW-413740424/VcGSCMaGnPcBEIjbpMUB'});`,
            }}
          />
        </Helmet>
        <div className="relative bg-magenta pt-16 pb-16 ">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div className="text-center text-white">
              <div className="flex flex-wrap">
                <div className="w-1/2 lg:w-1/2 xl:w-1/2 px-4">
                  <CardStats
                    statSubtitle="Email"
                    statTitle="travelpartners@cordeliacruises.com"
                    statArrow="up"
                    statDescripiron="Contact Now"
                    statIconName="far fa-chart-bar"
                    statIconColor="bg-red-500"
                  />
                </div>
                <div className="w-1/2 lg:w-1/2 xl:w-1/2 px-4">
                  <CardStats
                    statSubtitle="Phone Number"
                    statTitle="02268811110"
                    statArrow="up"
                    // statPercent="3.48"
                    // statPercentColor="text-green-500"
                    statDescripiron="Contact Now"
                    statIconName="far fa-chart-bar"
                    statIconColor="bg-red-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <BottomView />
        </div>
      </>
    );
  }
}

export default Contact;

const BottomView = () => {
  const [openTab, setOpenTab] = React.useState(2);
  let mapFrame = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.3083763541827!2d72.81632481490009!3d19.00612838712808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce972240b6af%3A0xa3400548f629f168!2sContinental%20Building%2C%20B%20Wing%2C%20BDD%20Chawls%20Worli%2C%20Worli%2C%20Mumbai%2C%20Maharashtra%20400018!5e0!3m2!1sen!2sin!4v1612385624781!5m2!1sen!2sin"  height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;
  return (
    <>
      <div className="w-full lg:w-6/12 px-4 mt-4">
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
          role="tablist"
        >
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 1
                  ? "text-white bg-j-red-light"
                  : "text-j-magenta bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              Payment Details
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 2
                  ? "text-white bg-j-red-light"
                  : "text-j-magenta bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              Write to Us
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 3
                  ? "text-white bg-j-red-light"
                  : "text-j-magenta bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              Contact Details
            </a>
          </li>
        </ul>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                <CardProfile />
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <CardSettings />
              </div>
              <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                <ContactDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-6/12 px-4 mt-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
          {ReactHtmlParser(mapFrame)}
          <div className="m-4">
            <h3 className="text-base font-semibold leading-normal mb-2 text-gray-800 mb-2 underline">
              Contact details to have address
            </h3>
            <p className="text-sm">M/s. Waterways Leisure Tourism Pvt. Ltd.</p>
            <p className="text-sm">1st Floor, Continental Building,</p>
            <p className="text-sm">135, Dr. Annie Besant Road,</p>
            <p className="text-sm">Next to Doordarshan Building,</p>
            <p className="text-sm">Worli, Mumbai 400018</p>
          </div>
          <div className="bg-magenta">
            <h5 className="leading-4 mb-4 divide-y divide-yellow-500 text-center mt-4 text-white">
              Connect with us and stay in the loop
            </h5>
            <div className="flex grid grid-cols-5 text-center gap-4 text-white justify-center mb-4">
              <a className="cursor-pointer text-3xl" href="https://www.linkedin.com/company/cordelia-cruises/mycompany/?viewAsMember=true">
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://m.facebook.com/cordeliacruises/"
                target="_blank"
                className="cursor-pointer text-3xl"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://www.instagram.com/cordeliacruises/?hl=enn"
                target="_blank"
                className="cursor-pointer text-3xl"
              >
                <i className="fab fa-instagram-square"></i>
              </a>
              <a
                href="https://twitter.com/CordeliaCruises"
                target="_blank"
                className="cursor-pointer text-3xl"
              >
                <i className="fab fa-twitter-square"></i>
              </a>
              <a
                href="https://blog.cordeliacruises.com/"
                target="_blank"
                className="cursor-pointer text-3xl"
              >
                <i className="fab fa-blogger"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
