import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import Tile from "../../components/Tile/Tile";
import CardPaymentForm from "../../components/Booking/payment-forms/CardPaymentForm";
import BankPaymentForm from "../../components/Booking/payment-forms/BankPaymentForm";
import UPIPaymentForm from "../../components/Booking/payment-forms/UPIPaymentForm";
import WalletPayment from "../../components/Booking/payment-forms/WalletPayment";
import RadioField from "../../components/Form/RadioField";
import scrollTo from "../../utils/scrollTo";
import UserContext from "../../store/UserContext";
import moment from "moment";
import cx from "classnames";
import {
    useHistory,
    withRouter,
    useLocation
  } from "react-router-dom";
  import { getToken } from "../../utils/common";
import NimbblPaymentForm from '../../components/Booking/payment-forms/NimbblPaymentForm';

class PaymentGateway extends Component {
    documentData;
    constructor(props) {
        super(props);
        this.state = {
            booking:[],
            selection: []
        }
    }

    handleSubmit = () => {

    }

    render() {
        return(
            <>
                <SummaryViewUI />
            </>
        )
    }
}

export default withRouter(PaymentGateway);

const SummaryViewUI = ({ }) => {
    let history = useHistory();
    const [user, setUser] = React.useContext(UserContext);
    const bookingMain = user.upgradeBooking;
    // const bookingArr = bookingMain.rooms;
    // const bookingData = user.bookingID || {};
    const [itinerary, setItinerary] = useState(JSON.parse(sessionStorage.getItem('itinerary')))
    const [isFailed, setFailed] = useState(
        new URLSearchParams(window.location.search).get("fail")
    );
    const [renderView, setRenderView] = useState(false)
    const [amountStatus, setAmount] = useState(0);
    const [roomData, setRoomData] = useState([]);
    const [bookingidValue, setBookingIdValue] = useState(null);
    const [cancellationPolicy, setCancellationPolicy] = useState(null);
    const [reschedulePolicy, setReschedulePolicy] = useState(null);
    const [fareOpen, setFareOpen] = useState(false);
    const [open, setOpen] = useState(false)
    const [displayAmount, setDisplayAmount] = useState("Full")
    const [partialStatus, setPartialStatus] = useState(false);
    const [fullPay, setFullPay ] = useState(true);
    var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))
    const userProfile= JSON.parse(localStorage.getItem("UserProfile"));

    const cancellationDiv = cancellationPolicy ? (
        <div>
          {cancellationPolicy.map((ele) => {
            return (
              <>
                <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
                  <u>
                    {ele.title}
                  </u>
                </h3>
                <br></br>
                <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    {Object.keys(ele.fees[0]).map((heading) => {
                      heading = heading.replaceAll("_", " ")
                      return (
                        <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                      )
                    })}
                  </tr>
                  {ele.fees.map((rows) => {
                    return (
                      <tr>
                        <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                        <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                      </tr>
                    );
                  })}
                </table>
                <br></br>
                <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
                  {ele.terms.map((term) => {
                    return (
                      <li style={{display: "revert"}}>{term}</li>
                    );
                  })}
                </ul>
                <br></br>
              </>
            );
          })}
        </div>
      ) : (<></>)
      const rescheduleDiv = reschedulePolicy ? (
        <div>
          {reschedulePolicy.map((ele) => {
            return (
              <>
                <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
                  <u>
                    {ele.title}
                  </u>
                </h3>
                <br></br>
                <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    {Object.keys(ele.fees[0]).map((heading) => {
                      heading = heading.replaceAll("_", " ")
                      return (
                        <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                      )
                    })}
                  </tr>
                  {ele.fees.map((rows) => {
                    return (
                      <tr>
                        <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                        <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                      </tr>
                    );
                  })}
                </table>
                <br></br>
                <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
                  {ele.terms.map((term) => {
                    return(
                      <li style={{display: "revert"}}>{term}</li>
                    )
                  })}
                </ul>
                <br></br>
              </>
            );
          })}
        </div>
      ) : (<></>)

    useEffect(() => {
        setRenderView(true)
        if(!itinerary.rechedule) {
            setRoomData(bookingMain.rooms)
        } else {
            setRoomData(itinerary.rooms)
            setBookingIdValue(itinerary.bookingID)
        }

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/itineraries/${itinerary.itineraries.id}/policies`, {
            method: 'GET',
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            },
        }).then((res) => res.json())
        .then((res) => {
            setCancellationPolicy(res.cancellation);
            setReschedulePolicy(res.reschedule);
        }).catch((err) => {
            alert("Error Fetching Cancellation and Reschedule Policies");
        });

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/balance`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
            `Bearer ${getToken()}`,
        },
        })
        .then((response) => response.json())
        .then((response) => {
          console.log(response)
                setAmount(response.amount) 
        })
    },[])

    const onChangeValue = (status ,e) => {
        setFullPay(!fullPay);
        if(status==false) {
            setPartialStatus(true);
        } else {
            setPartialStatus(false);
        }
        // setPartialStatus(status);
        if(displayAmount == 'Full') {
            setDisplayAmount('Partial')
        } else {
            setDisplayAmount('Full');
        }
    }

    return (
        <>
             <h1 className="text-xl font-bold pt-4 mr-10 ml-10"  id="payment">{isFailed ? "Request failed!" : "Payment"}</h1>
                {isFailed ? (
                    <div className="bg-j-yellow text-j-black rounded-big p-3 my-4 mr-20 ml-20">
                        <h1 className="text-base font-medium pb-0">
                            <i className="fas fa-exclamation-triangle text-2xl pr-1" />
                            Payment failed. Please try again!
                        </h1>
                        <p className="text-xs leading-relaxed">
                            Somehow, we couldn't receive your payment. Any amount deducted from
                            your account will be refunded in 7 working days.
                        </p>
                    </div>
                ) : null}
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-6 pt-0 mt-4 mr-10 ml-10">
                    <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta">
                        <Tile.Inner className="pb-0">
                            {/* <OrderDetail title="Booking Reference no">
                                {bookingData.number}
                            </OrderDetail> */}
                            <OrderDetail title="Ship">
                                {itinerary.itineraries.name}
                            </OrderDetail>
                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <OrderDetail title="Departure">
                                        {moment(itinerary.itineraries.startTime).format("ddd, D MMM YYYY, h:mm a")}
                                    </OrderDetail>
                                    </div>
                                    <div>
                                    <OrderDetail title="Arrival">
                                        {moment(itinerary.itineraries.endTime).format("ddd, D MMM YYYY, h:mm a")}
                                    </OrderDetail>
                                </div>
                            </div>
                            <div
                                style={{ color: '#500E4B', fontWeight: 'bold' }}
                                className="grid grid-cols-1 grid-cols-2"
                                 onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                <span className="pt-1 text-left"> PRICE DETAILS</span>
                                <i
                                    style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                                    className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                                            "fa-angle-down": !open,
                                            "fa-angle-up": open,
                                    })}
                                ></i> 
                                 </div>
                                {open && <div style={{ width: '100%' }} className="mt-4">
                                    <div className="border-b-2 border-dashed pb-4">
                                        <PaymentDetails content="Total Amount Paid" details={Math.round((itinerary.total_amount_paid) * 100 / 100)} big={false} currancyStatus={currancyStatus}/>
                                        <PaymentDetails content="Revised Itinerary Amount" details={Math.round((itinerary.revised_itinerary_amount) * 100 / 100)} big={false} currancyStatus={currancyStatus}/>
                                        <PaymentDetails content="Fare Difference" details={Math.round((itinerary.fare_difference) * 100 / 100)} big={false} currancyStatus={currancyStatus}/>
                                        <PaymentDetails content="Rescheduling Fee" details={Math.round((itinerary.rescheduling_fee) * 100 / 100)} big={false} currancyStatus={currancyStatus}/>
                                        <PaymentDetails content="GST" details={Math.round((itinerary.gst) * 100 / 100)} big={false} currancyStatus={currancyStatus}/>
                                    </div>
                                    <div className="mt-4">
                                        <PaymentDetails content="Total Amount Payable" details={Math.round((itinerary.partialStatus?itinerary.partial_payable_amount:itinerary.total_amount_payable) * 100 / 100)} big={false} currancyStatus={currancyStatus}/>
                                        {itinerary.due_amount>0 && <>
                                        {itinerary.partialStatus && <PaymentDetails content="Due Amount" details={Math.round((itinerary.due_amount) * 100 / 100)} big={false} currancyStatus={currancyStatus}/>}
                                        {itinerary.partialStatus&&<div>
                                            <p className="text-left" style={{fontSize:"11px",color: "grey"}}>Payable before {moment(itinerary.due_Date).format("D MMMM YYYY")}</p>
                                        </div>}
                                        </>
                                        }
                                    </div>
                                </div>}

                                <div
                                    style={{
                                    color: "#500E4B",
                                    fontWeight: "bold",
                                    marginTop:'15px'
                                    }}
                                    className="grid grid-cols-1 grid-cols-2 pb-4"
                                    onClick={() => {
                                    setFareOpen(!fareOpen);
                                    }}
                                >
                                    <span className="pt-1">Cancellation and Reschedule policy</span>
                                    <i
                                    style={{ color: "grey", fontSize: 28, textAlign: "right" }}
                                    className={cx(
                                        "fas cursor-pointer text-j-gray-light text-4xl",
                                        {
                                        "fa-angle-down": !fareOpen,
                                        "fa-angle-up": fareOpen,
                                        }
                                    )}
                                    ></i>
                                </div>
                                {fareOpen && (
                                    <div style={{ padding: "1rem" }}>
                                    {cancellationDiv}
                                    {rescheduleDiv}
                                    </div>
                                )}

                                <div className="flex mt-2">
                                    <div className="w-9/12">
                                       <div className="radio"></div>
                                    </div>
                                </div>
                            {/* {Object.keys(roomData)
                                .map((k, index) => 
                                
                                    <OrderDetail title={`Cabin ${index+1}`}>
                                        {roomData[index].selected == null ? roomData[index].roomCatogory:roomData[index].selected.name}<br/>
                                        Deck No: {((roomData[index].selected == null ? roomData[index].roomNumber:roomData[index].room_number).toString()).charAt(0)}<br/>
                                        Room No: {roomData[index].selected == null ? roomData[index].roomNumber:roomData[index].room_number}<br/>
                                        {roomData[index].adults+roomData[index].children+roomData[index].infants}  Guest{(roomData[index].adults+roomData[index].children+roomData[index].infants) > 1 ? "s" : null}
                                    </OrderDetail>
                                )} */}

                            {/* <OrderDetail title="Status">
                                {bookingData.status}
                            </OrderDetail>
                            <OrderDetail title="Total Amount">
                                {bookingData.total_amount}
                            </OrderDetail>
                            <OrderDetail title="Amount Paid">
                                {bookingData.amount_paid}
                            </OrderDetail>
                            <OrderDetail title="Balance amount">
                                {bookingData.pending_amount}
                            </OrderDetail> */}
                        </Tile.Inner>
                    </Tile>
                    <div>
                            {/* <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta">
                                <Tile.Inner className="pb-4 pt-6">
                                    {itinerary.payment_rule !=null && <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="Partial"
                                                checked={displayAmount === "Partial"}
                                                onChange={(e)=>onChangeValue(partialStatus, e)}
                                                // className="pt-4"
                                            />
                                            <span className="text-sm pl-2 font-bold">Reserve now by paying {itinerary.payment_rule}</span><br />
                                            {!fullPay && <span className="pl-5" style={{fontSize:"11px"}}>DUE: &#x20B9;{Math.round(itinerary.due_amount)} before {moment(itinerary.due_Date).format("D MMMM YYYY")}</span>}
                                        </label>
                                    </div>
                                    }
                                    <div className="radio w-full mt-2 mb-4">
                                        <label>
                                            <input
                                                type="radio"
                                                value="Male"
                                                checked={displayAmount === "Full"}
                                                onChange={(e)=>onChangeValue(partialStatus, e)}
                                            />
                                            <span className="text-sm pl-2 font-bold">Book Now by paying 100%</span>
                                        </label>
                                    </div>
                                </Tile.Inner>
                            </Tile> */}
                            <ConformBooking amount={itinerary.amount} billingID={bookingidValue} amountPay={itinerary.amount} partialStatus={itinerary.partialStatus} partialAmount={itinerary.partial_payable_amount} paymentOptionID={itinerary.payment_option_id} balance={amountStatus} userProfile={userProfile}/>
                    </div>
                </div>
                </>
    )
}

const ConformBooking = ({amountValue,billingID,amountPay, partialStatus, partialAmount, paymentOptionID, balance, userProfile}) => {
    console.log(balance)
    const submitRef = useRef(null);
    const [amount, setAmount] = useState(null)
    const [billingData, setBillingData] = useState(null);
    const [isFailed, setFailed] = useState(
        new URLSearchParams(window.location.search).get("fail")
    );
    const setFailedError = (error) => {
        setFailed(error);
        if (error) scrollTo("payment");
    };

    const [backButtonstatus, setBackButtonstatus] = useState(true)

    const handleWalletPayNow = () => {
      setBackButtonstatus(false)
    }

    const handleWalletPayNowTrue = () => {
        setBackButtonstatus(true)
    }


    return(
        <>
            <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta" >
                <Tile.Inner className="pb-0">
                <span className="text-j-gray" style={{width: "500px"}}>
                            <RadioField
                                name="paymentOption"
                                defaultValue="bank"
                                paymentStatus= {true}
                                handleWalletPayNowTrue={handleWalletPayNowTrue}
                                options={[
                                    {
                                        value: "mimbbl",
                                        label: "Pay Using Payment Gateway",
                                        view: (
                                            <NimbblPaymentForm
                                            bookingID={billingID}
                                            billingData={userProfile} 
                                            paymentOptionID={paymentOptionID}
                                            />
                                        ),
                                    },
                                    {
                                        value: "wallet",
                                        label: "Cordelia Wallet",
                                        view: (
                                          <WalletPayment
                                            amount={partialStatus?partialAmount:amountPay}
                                            booking=""
                                            amountStatus={balance}
                                            bookingID={billingID}
                                            paymentPath="wallet"
                                            billingData=""
                                            partialStatus= {partialStatus}
                                            submitRef={submitRef}
                                            setFailed={setFailedError}
                                            walletStatus={false}
                                            normalBooking= {true}
                                            totalPrice= {amountPay}
                                            paymentOptionID={paymentOptionID}
                                            bookingType="modify_booking"
                                            handleWalletPayNow={handleWalletPayNow}
                                          />
                                        ),
                                      },
                                ]}
                            />
                        </span>
                </Tile.Inner>
            </Tile>
        </>
    )
}

const OrderDetail = ({ title, children, big }) => (
    <div className="mb-6">
      <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none">
        {title}
      </h1>
      <h4
        className={
          big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2 text-lg font-semibold"
        }
      >
        {children}
      </h4>
    </div>
  );

  const PaymentDetails = ({ content, details, big, currancyStatus }) => (
    <>
      <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
        {big && <>
          <div style={{ color: "black", fontSize: 16, fontWeight: 'bold' }} className="float-left bold text-left">
            {content}
          </div>
          <div style={{ color: "black", fontSize: 16, textAlign: 'right', fontWeight: 'bold' }} className="float-right font-weight-bold text-capitalize text-left">
            {currancyStatus.symbol}{details}
          </div>
        </>
        }
        {!big && <>
          <div style={{ color: "grey", fontSize: 14 }} className="float-left bold text-left">{content}</div>
          <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize text-left">
          {currancyStatus.symbol}{details}
          </div>
        </>
        }
      </div>
    </>
  );