import React, { Component, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./login.module.css";
import Input from "../../components/Form/LoginInput";
import SelectField from "../../components/Form/SelectField";
import DisabledInput from "../../components/Form/DisabledInput";
import DisabledCopy from "../../components/Form/DisabledCopy";
import { setUserSession } from "../../utils/common";
import LogoImage from "../../assets/images/logo.png";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import CountryStates from "../../public/country-states.json";
import States from "../../public/state-city.json";
import Countdown from 'react-countdown';
import moment from "moment";
import cx from "classnames"

import Telegram from "../../assets/images/telegram-back.png";
import Layout from "../../components/Layout/Layout";
import { useForm } from "react-hook-form";
import Select from "../../components/Form/Select";
import Slider from "react-slick";


const Weeding = 'https://images.cordeliacruises.com/cordelia_v2/public/images/Wedding-agent.webp'
const SL = 'https://images.cordeliacruises.com/cordelia_v2/public/images/SL_Agent.webp'
const Goa = 'https://images.cordeliacruises.com/cordelia_v2/public/images/Goa_agent_16.webp'
const payday = 'https://images.cordeliacruises.com/cordelia_v2/public/images/cordelia-payday-agent-portal.webp'
const Christmas = 'https://images.cordeliacruises.com/cordelia_v2/public/images/christmas.webp'
const Newyear = 'https://images.cordeliacruises.com/cordelia_v2/public/images/New_year.webp'
const Diwali = 'https://images.cordeliacruises.com/cordelia_v2/public/images/Diwali_agent_16.webp'
const Navratri = 'https://images.cordeliacruises.com/cordelia_v2/public/images/Navratri_agent_16.webp'
const Festive = 'https://images.cordeliacruises.com/cordelia_v2/public/images/festive_agent.webp'
const Lakshadweep = 'https://images.cordeliacruises.com/cordelia_v2/public/images/lakshadweep_agent_16.webp'
const BannerCarousel = () => {
  const banners = [ Festive, Navratri, Diwali, Lakshadweep, Goa, Christmas, Newyear, Weeding];
  return (
    <Slider arrows={true} dots={true} autoplay={true} autoplaySpeed={4000} className={cx(styles.signinsignupCarousel, "packagesCardSlider")}>
      {banners.map(data => { return <img src={data} className={styles.signinsignup} /> })}
    </Slider>
  )
}

class Login extends Component {
  state = {
    step: 1,
    isChecked: false,
    email: "",
    editPanFlag: true,
    bankDetailsFlag: false,
    panCardNumber: null,
    wherePan: true,
    loaderFlag: false,
    state: null,
    city: null,
    internationalLogin: false
  };

  componentDidMount() {
    const username = localStorage.getItem("username");
    const checkbox = localStorage.getItem("checkbox");
    if (checkbox && username !== "") {
      this.setState({
        isChecked: true,
        email: localStorage.username,
      });
    }
  }

  onChangeCheckbox = (event) => {
    this.setState({
      isChecked: event.target.checked,
    });
  };

  onChangeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onClick = (val) => {
    this.setState({
      step: val,
    });
  };
  setPanRecord = (value) => {
    this.setState({
      panCardNumber: value,
    });
  };
  setLoaderFlag = (value) => {
    this.setState({
      loaderFlag: value,
    });
  };
  onClickFlag = (val, flagType) => {
    if (flagType === "pan") {
      this.setState({
        editPanFlag: val,
      });
    } else if (flagType === "wherePan") {
      this.setState({
        wherePan: val,
      });
    } else {
      this.setState({
        bankDetailsFlag: val,
      });
    }
  };

  handleInternationalLogin = (status) => {
    this.setState({ internationalLogin: status })
  }

  switchState = () => {
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <LoginForm
            onClick={this.onClick}
            onChangeCheckbox={this.onChangeCheckbox}
            onState={this.state}
            onChangeValue={this.onChangeValue}
            setLoaderFlag={this.setLoaderFlag}
            handleInternationalLogin={this.handleInternationalLogin}
          />
        );
      case 2:
        return (
          <CheckGSTINComp
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setPanRecord={this.setPanRecord}
            onChangeValue={this.onChangeValue}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
      case 3:
        return (
          <CheckPANComp
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setPanRecord={this.setPanRecord}
            onChangeValue={this.onChangeValue}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
      case 4:
        return (
          <VerifyGSTDetails
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setPanRecord={this.setPanRecord}
            onChangeValue={this.onChangeValue}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
      case 5:
        return (
          <StartSignUpComp
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setPanRecord={this.setPanRecord}
            onChangeValue={this.onChangeValue}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
      case 6:
        return (
          <AddressComp
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setPanRecord={this.setPanRecord}
            onChangeValue={this.onChangeValue}
            setLoaderFlag={this.setLoaderFlag}
            internationalStatus={this.state.internationalLogin}
          />
        );
      case 7:
        return (
          <VerifySignUpComp
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setPanRecord={this.setPanRecord}
            onChangeValue={this.onChangeValue}
            setLoaderFlag={this.setLoaderFlag}
            internationalStatus={this.state.internationalLogin}
          />
        );
      case 8:
        return (
          <CheckForgetPasswordComp
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
      case 9:
        return (
          <VerifyForgetPasswordComp
            onClick={this.onClick}
            onClickFlag={this.onClickFlag}
            onState={this.state}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
    }
  };

  render() {
    return <Layout>{this.switchState()}</Layout>;
  }
}
const agencyName = (inputtxt) => {
  let agencyName = /^.{3,}$/;
  return agencyName.test(inputtxt);
}

const phonenumber = (inputtxt) => {
  let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneno.test(inputtxt);
}
const pincodeCheck = (inputtxt) => {
  let pinReg = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
  return pinReg.test(inputtxt);
}

const checkPassword = (str) => {
  // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
  // at least six characters
  let re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
  return re.test(str);
}

const nameLengthCheck = (str) => {
  console.log(str)
  // Name length Check
  if (str.length >= 2) {
    return true
  } else {
    return false
  }
}

var verifyGSTINDataVar = {};

const LoginApi = (payload, history, setLoaderFlag) => {
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      if (response.status === 200) {
        let data = await response.json();
        console.log(data.profile)
        localStorage.setItem("UserProfile", JSON.stringify(data.profile));
        setUserSession(data.token, payload.email, data.exp);
        var redirectURl = localStorage.getItem('redirectURL')
        history.push(redirectURl);
      } else {
        let data = await response.json();
        alert(`${data.message}`);
      }
      setLoaderFlag(false);
    })
    .catch((err) => err);

};

const gstinAPI = (payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
  } = onState;
  verifyGSTINDataVar = {};
  let obj1 = {};
  obj1.gstin = payload.gstin;
  setPanRecord(payload.gstin.substring(2, 13));
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/verify_gstin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj1),
  })
    .then(async (response) => {
      if (response.status === 200) {
        let data = await response.json();
        verifyGSTINDataVar = data;
        if (data.pan) {
          setPanRecord(data.pan);
          verifyGSTINDataVar["pan_status"] = "SUCCESS";
          verifyGSTINDataVar["pan_flag"] = false;
          onClick(4);
        }
      } else {
        let data = await response.json();
        alert(data.message);
      }
      setLoaderFlag(false);
    })
    .catch((err) => err);
};

const panAPI = (payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag) => {
  let backUpverifyGSTINDataVar = verifyGSTINDataVar;
  verifyGSTINDataVar["pan"] = payload.pan;
  setPanRecord(payload.pan)
  verifyGSTINDataVar["gstin"] = verifyGSTINDataVar["gstin"];
  verifyGSTINDataVar["communicationAddress"] =
    verifyGSTINDataVar["complete_address"];
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
  } = onState;
  if (panCardNumber) {
    payload.pan = panCardNumber;
    verifyGSTINDataVar["pan"] = payload.pan;
  }
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/verify_pan`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      if (response.status === 200) {
        let data = await response.json();
        setPanRecord(payload.pan);
        verifyGSTINDataVar["pan_status"] = data.status;
        verifyGSTINDataVar["pan_flag"] = true;
        onClick(4);
        onClickFlag(true, "wherePan");
      } else {
        let data = await response.json();
        alert(data.message);
      }
      setLoaderFlag(false);
    })
    .catch((err) => err);
};

const startSignUpAPI = (payload, onClick, onState, setLoaderFlag) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
  } = onState;
  payload.gstin = verifyGSTINDataVar.gstin || null;
  payload.pan = panCardNumber;
  verifyGSTINDataVar.email = payload.email;
  verifyGSTINDataVar.agency_name = payload.agency_name;
  verifyGSTINDataVar.phone_number = payload.phone_number;
  verifyGSTINDataVar.first_name = payload.first_name;
  verifyGSTINDataVar.last_name = payload.last_name;
  verifyGSTINDataVar.password = payload.password;
  verifyGSTINDataVar.region = payload.region;
  verifyGSTINDataVar.sales_manager = payload.sales_manager
  setLoaderFlag(false);
  onClick(6);
};
const AddressCompAPI = (payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag, internationalStatus) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
  } = onState;
  verifyGSTINDataVar["pan"] = panCardNumber;
  verifyGSTINDataVar["commission_type"] = payload["commission_type"];
  if (bankDetailsFlag) {
    verifyGSTINDataVar["travel_agency_attributes"] = {
      address_line_1: payload["address_line_1"],
      address_line_2: payload["address_line_2"],
      area: payload["area"],
      city: payload["city"],
      district: payload["district"],
      state: payload["state"],
      country: payload['country'],
      pincode: payload["pincode"],
      owner: verifyGSTINDataVar["name"],
      phone_number: verifyGSTINDataVar["phone_number"],
      email: verifyGSTINDataVar["email"],
      portal_agent_type: payload["portal_agent_type"],
      communicationAddress: verifyGSTINDataVar["complete_address"],
      referralCode: payload["referral_code"],
      bank_accounts_attributes: [
        {
          bank_name: payload["bank_name"],
          account_number: payload["account_number"],
          beneficiary_name: payload["beneficiary_name"],
          ifsc_code: payload["ifsc_code"],
          branch_name: payload["branch_name"],
        },
      ],
    };
  } else {
    verifyGSTINDataVar["travel_agency_attributes"] = {
      name: verifyGSTINDataVar.agency_name,
      address_line_1: payload["address_line_1"],
      address_line_2: payload["address_line_2"],
      area: payload["area"],
      city: payload["city"],
      district: payload["district"],
      state: payload["state"],
      country: payload["country"],
      pincode: payload["pincode"],
      owner: verifyGSTINDataVar["name"],
      phone_number: verifyGSTINDataVar["phone_number"],
      email: verifyGSTINDataVar["email"],
      portal_agent_type: payload["portal_agent_type"],
      communicationAddress: verifyGSTINDataVar["complete_address"],
      referralCode: payload["referral_code"],
    };
  }

  if (internationalStatus) {
    var URL = 'international_register'
  } else {
    var URL = 'register'
  }


  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/${URL}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(verifyGSTINDataVar),
  })
    .then(async (response) => {
      if (response.status === 200) {
        let data = await response.json();
        if (data) {
          onClick(7);
        }
      } else {
        let data = await response.json();
        alert(data.message);
      }
      setLoaderFlag(false);
    })
    .catch((err) => err);
};

const verifySignUpAPI = (payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag, internationalStatus) => {
  payload.email = verifyGSTINDataVar.email;
  payload.phone_number = verifyGSTINDataVar.phone_number;
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/verify`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      if (response.status === 200) {
        onClickFlag(true, "wherePan");
        onClickFlag(true, "pan");
        onClickFlag(false, "bank");
        setPanRecord(null);
        verifyGSTINDataVar = {};
        setLoaderFlag(false);
        onClick(1);
        alert("Sign - Up Successful");
      } else {
        let data = await response.json();
        alert(data.message);
        setLoaderFlag(false);
      }
    })
    .catch((err) => err);
};

const startForgetPasswordAPI = (payload, onClick, setLoaderFlag) => {
  verifyGSTINDataVar = {};
  verifyGSTINDataVar.email = payload.email;
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/reset_password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      if (response.status === 200) {
        onClick(9);
        setLoaderFlag(false);
      } else {
        let data = await response.json();
        alert(data.message);
        setLoaderFlag(false);
      }
    })
    .catch((err) => err);
};

const verifyForgetPasswordAPI = (payload, onClick, setLoaderFlag) => {
  payload.email = verifyGSTINDataVar.email;
  fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/agent_portal/verify_reset_password`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  )
    .then(async (response) => {
      if (response.status === 200) {
        verifyGSTINDataVar = {};
        onClick(1);
        setLoaderFlag(false);
        alert("Password Changed");
      } else {
        let data = await response.json();
        alert(data.message);
        setLoaderFlag(false);
      }
    })
    .catch((err) => err);
};

const LoginForm = ({ onClick, onChangeCheckbox, onState, onChangeValue, setLoaderFlag, handleInternationalLogin }) => {
  let form = useRef(null);
  var todayDateMom = moment(new Date()).format('MM/DD/yyyy HH:mm:ss');

  console.log(todayDateMom)
  var todayDate = new Date(todayDateMom);


  console.log(todayDateMom)
  var date = new Date("09/15/2021 24:00:00");

  console.log(date.getTime())
  console.log(todayDate.getTime())
  var milliseconds = date.getTime() - todayDate.getTime()
  console.log(milliseconds)

  let history = useHistory();
  const [typeOfPassword, changePasswordType] = useState("password");
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag,
  } = onState;
  const handleLoginSubmit = (event) => {
    if (isChecked && email !== "") {
      localStorage.setItem("username", email);
      localStorage.setItem("checkbox", isChecked);
    }
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    LoginApi(payload, history, setLoaderFlag);
  };

  const rendererHours = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null
    } else {
      // Render a countdown
      return (
        <>
          <span className="p-4 text-2xl text-j-magenta font-bold flex justify-center">Our offers end in </span>
          <div className="flex justify-center">
            <div>
              <p className={cx(styles.count, "bg-j-orange p-4 pl-8 pr-10 text-white text-3xl text-bold rounded text-center")}>{days}</p>
              <p className="p-2 text-sm text-j-magenta font-bold text-center">Days</p>
            </div>
            <span className="text-bold text-3xl p-2">:</span>
            <div>
              <p className={cx(styles.count, "bg-j-orange p-4 pl-6 pr-6 text-white text-3xl text-bold rounded text-center")}>{hours}</p>
              <p className="p-2 text-sm text-j-magenta font-bold text-center">Hours</p>
            </div>
            <span className="text-bold text-3xl p-2">:</span>
            <div>
              <p className={cx(styles.count, "bg-j-orange p-4 text-white text-3xl text-bold rounded text-center")}>{minutes}</p>
              <p className="p-2 text-sm text-j-magenta font-bold">Minutes</p>
            </div>
            <span className="text-bold text-3xl p-2">:</span>
            <div>
              <p className={cx(styles.count, "bg-j-orange p-4 text-white text-3xl text-bold rounded text-center")}>{seconds}</p>
              <p className="p-2 text-sm text-j-magenta font-bold">Seconds</p>
            </div>
          </div>
        </>
      )

    }
  };

  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        {/* <img src={LoginImage} className={styles.signinsignup} /> */}
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={handleLoginSubmit}
          className="w-full text-j-magenta lg:20 p-6 md:p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-36 text-center" />
          </div>
          <div className="pt-4 px-2 flex flex-col items-center justify-center">

            {/* <h3 className="text-xl sm:text-2xl xl:text-2xl leading-tight underline mb-2 cursor-pointer"
              onClick={() => {
                handleInternationalLogin(false)
                onClick(2);
              }}>
              <strong>SIGN UP</strong>
            </h3> */}
            {/* <h3 className="text-black text-xl mb-2">or</h3> */}
            <h3 className="text-xl sm:text-2xl xl:text-2xl font-bold leading-tight">
              Login To Your Account
            </h3>
            <div className="mt-4 mb-6">
              <Countdown
                date={Date.now() + milliseconds}
                renderer={rendererHours}
              />
            </div>
          </div>
          <div className="mt-8 w-full px-2 sm:px-6">
            <div>
              <label className="text-magenta">Email&nbsp;<sup className="text-j-orange">*</sup></label>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                onChangeValue={onChangeValue}
              />
            </div>
            <div className="mt-6">
              <label className="text-magenta">Password&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="password"
                name="password"
                placeholder="Password"
                type={typeOfPassword}
              />
              {
                (typeOfPassword === "password") ? <i style={{ position: "relative", top: "-30px", right: "-91%", }} class="fa fa-eye" onClick={() => {
                  changePasswordType("text");
                }}></i> : <i style={{ position: "relative", top: "-30px", right: "-91%", }} class="fa fa-eye-slash" onClick={() => {
                  changePasswordType("password");
                }}></i>
              }
            </div>
          </div>
          <div className="pt-6 w-full flex justify-between px-2 sm:px-6 cursor-pointer">
            <div className="flex items-center">
              <input
                id="rememberme"
                className="w-3 h-3 mr-2"
                type="checkbox"
                checked={isChecked}
                onChange={onChangeCheckbox}
              />
              <label htmlFor="rememberme" className="text-xs">
                Remember Me
              </label>
            </div>
            <a
              className="text-xs"
              onClick={() => {
                onClick(8);
              }}
            >
              Forgot Password?
            </a>
          </div>
          <div className="px-2 sm:px-6">
            {
              (!loaderFlag) ? (<button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                Login
              </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
            {/* <p className="mt-8 text-xs text-center">
              Don’t Have An Account?{" "}
              <a
                className="underline cursor-pointer pr-2"
                onClick={() => {
                  handleInternationalLogin(false)
                  onClick(2);
                }}
              >
                Sign Up
              </a>
              (<sup> *</sup> To Sign - Up you will need a GSTIN/PAN)
            </p>
            <p className="mt-4 text-xs text-center">
              If you are an internation Agent?{" "}
              <a
                className="underline cursor-pointer"
                onClick={() => {
                  handleInternationalLogin(true)
                  onClick(5);
                }}
              >
                Sign Up as an International Agent
              </a>
            </p> */}
            <p className="mt-4 text-xs text-center">
              For any issues, while registering please contact us at{" "}
              <a
                className="underline cursor-pointer"
                href="tel:02268811110"
              >
                02268811110
              </a>
              {" "}/{" "}
              <a
                className="underline cursor-pointer"
                href="mailto:travelpartners@cordeliacruises.com"
              >
                travelpartners@cordeliacruises.com
              </a>
            </p>
            {/* <p className="mt-6 text-xs text-center">
              <a>
                <sup>*</sup> To Sign - Up you will need a GSTIN/PAN
              </a>
            </p> */}
          </div>
          <a href="https://www.linkedin.com/company/cordelia-cruises/mycompany/?viewAsMember=true">
            <div className="flex flex-row justify-center items-center mt-5">
              <p className="text-xs text-center pr-2 text-xl">For latest updates and offers follow us on : </p>
              <i className="fab fa-linkedin text-3xl"></i>
            </div>
          </a>
          <div className="flex grid grid-cols-6 text-center gap-4 justify-center mt-4">
            <a
              href="https://www.facebook.com/cordeliacruises/"
              target="_blank"
              className="cursor-pointer text-3xl"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://www.instagram.com/cordeliacruises/?hl=en"
              target="_blank"
              className="cursor-pointer text-3xl"
            >
              <i className="fab fa-instagram-square"></i>
            </a>
            <a
              href="https://twitter.com/CordeliaCruises"
              target="_blank"
              className="cursor-pointer text-3xl"
            >
              <i className="fab fa-twitter-square"></i>
            </a>
            <a
              href="https://blog.cordeliacruises.com/"
              target="_blank"
              className="cursor-pointer text-3xl"
            >
              <i className="fab fa-blogger"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCIGZzyqWsbCH1-VNFsXrY9g"
              target="_blank"
              className="cursor-pointer text-3xl"
            >
              <i className="fab fa-youtube-square"></i>
            </a>
            <a
              href="https://t.me/cordeliacruises"
              target="_blank"
              className="cursor-pointer text-3xl"
            >
              <i className="fab fa-telegram rounded" style={{ backgroundImage: `url(${Telegram})`, backgroundSize: 'contain', transform: `scale(0.9)` }}></i>
            </a>
          </div>
        </form>
      </div>
    </section>
  );
};

const CheckGSTINComp = ({ onClick, onClickFlag, onState, setPanRecord, onChangeValue, setLoaderFlag }) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;
  let form = useRef(null);
  const checkGSTIN = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    onClickFlag(false, "wherePan");
    gstinAPI(payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag);
  };
  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={checkGSTIN}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Sign - Up - Step - 1
            </h3>
          </div>
          <div className="mt-12 w-full px-2 sm:px-6">
            <label className="text-magenta">GSTIN&nbsp;<sup className="text-j-orange">*</sup></label>
            <Input id="gstin" name="gstin" placeholder="GSTIN" type="text" />
          </div>
          <div className="mt-4 px-2 sm:px-6 cursor-pointer cursor-pointer">
            <p className="text-xs text-center">
              <a
                className="underline"
                onClick={() => {
                  onClick(3);
                }}
              >
                Continue to Sign - Up with just PAN
              </a>
            </p>
          </div>
          <div className="px-2 sm:px-6">
            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Check GSTIN
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
            <p className="mt-4 text-xs text-center cursor-pointer">
              <a
                className="underline"
                onClick={() => {
                  onClickFlag(true, "wherePan");
                  onClickFlag(true, "pan");
                  onClickFlag(false, "bank");
                  setPanRecord(null);
                  verifyGSTINDataVar = {};
                  onClick(1);
                }}
              >
                Login
              </a>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

const CheckPANComp = ({ onClick, onClickFlag, onState, setPanRecord, onChangeValue, setLoaderFlag }) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;
  let form = useRef(null);
  const checkPAN = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    verifyGSTINDataVar = {};
    panAPI(payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag);
  };
  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={checkPAN}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Sign - Up - Step - 1
            </h3>
          </div>
          <div className="mt-12 w-full px-2 sm:px-6">
            <label className="text-magenta">PAN&nbsp;<sup className="text-j-orange">*</sup></label>
            <Input id="pan" name="pan" placeholder="PAN" type="text" />
          </div>
          <div className="mt-4 px-2 sm:px-6 cursor-pointer">
            <p className="text-xs text-center">
              <a
                className="underline"
                onClick={() => {
                  onClick(2);
                }}
              >
                Continue to Sign - Up with GSTIN
              </a>
            </p>
          </div>
          <div className="px-2 sm:px-6">

            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Check PAN
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
            <p className="mt-4 text-xs text-center cursor-pointer">
              <a
                className="underline"
                onClick={() => {
                  onClickFlag(true, "wherePan");
                  onClickFlag(true, "pan");
                  onClickFlag(false, "bank");
                  setPanRecord(null);
                  verifyGSTINDataVar = {};
                  onClick(1);
                }}
              >
                Login
              </a>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

const VerifyGSTDetails = ({
  onClick,
  onClickFlag,
  onState,
  setPanRecord,
  onChangeValue,
  setLoaderFlag
}) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;
  let verificationData = verifyGSTINDataVar;
  let form = useRef(null);
  const moveToSignUp = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    if (wherePan) {
      onClick(5);
      setLoaderFlag(false);
    } else {
      panAPI(payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag);
    }
  };
  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={moveToSignUp}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Sign - Up - Step - 2
            </h3>
          </div>
          {verificationData && (
            <div>
              <div className="mt-12 w-full px-2 sm:px-6">
                {verificationData["id"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>ID</span>
                      <span>:</span>
                    </div>
                    <span>{verificationData["id"].toUpperCase()}</span>
                  </div>
                )}
                {verificationData["gstin"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>GSTIN</span>
                      <span>:</span>
                    </div>
                    <span>{verificationData["gstin"].toUpperCase()}</span>
                  </div>
                )}
                {wherePan && panCardNumber ? (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>REGD. PAN</span>
                      <span>:</span>
                    </div>
                    <span>{panCardNumber}</span>
                  </div>
                ) : (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>REGD. PAN</span>
                      <span>:</span>
                    </div>
                    <div style={{ display: "flex", width: "50%" }}>
                      {editPanFlag ? (
                        <div>
                          <DisabledInput
                            id="pan"
                            name="pan"
                            placeholder="PAN"
                            type="text"
                            value={panCardNumber}
                          />
                          <a
                            onClick={() => {
                              onClickFlag(false, "pan");
                            }}
                            style={{
                              position: "relative",
                              left: "125%",
                              bottom: "42%",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fas fa-pencil-alt text-2xl pr-1" />
                          </a>
                        </div>
                      ) : (
                        <div>
                          <Input
                            id="panCardNumber"
                            name="panCardNumber"
                            placeholder="PAN"
                            type="text"
                            onChangeValue={onChangeValue}
                          />
                          <a
                            onClick={() => {
                              onClickFlag(true, "pan");
                            }}
                            style={{
                              position: "relative",
                              left: "125%",
                              bottom: "42%",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fas fa-check-square text-2xl pr-1" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className="grid grid-cols-2"
                  style={{
                    paddingBottom: "2rem",
                    borderBottom: "1px dashed",
                  }}
                >
                  <div className="grid grid-cols-2">
                    <span>PAN STATUS</span>
                    <span>:</span>
                  </div>
                  {verificationData["pan_status"] && (
                    <span>
                      {verificationData["pan_status"].toUpperCase() ||
                        "SUCCESS"}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-12 w-full px-2 sm:px-6">
                {verificationData["name"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>NAME</span>
                      <span>:</span>
                    </div>
                    <span>{verificationData["name"].toUpperCase()}</span>
                  </div>
                )}
                {verificationData["address_line_1"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>ADDRESS:</span>
                      <span>:</span>
                    </div>
                    <span>
                      {verificationData["address_line_1"].toUpperCase()}
                    </span>
                  </div>
                )}
                {verificationData["address_line_2"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>ADDRESS (2):</span>
                      <span>:</span>
                    </div>
                    <span>
                      {verificationData["address_line_2"].toUpperCase()}
                    </span>
                  </div>
                )}
                {verificationData["area"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>AREA</span>
                      <span>:</span>
                    </div>
                    {verificationData["area"] && (
                      <span>{verificationData["area"].toUpperCase()}</span>
                    )}
                  </div>
                )}
                {verificationData["city"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>CITY</span>
                      <span>:</span>
                    </div>
                    <span>{verificationData["city"].toUpperCase()}</span>
                  </div>
                )}
                {verificationData["district"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>DISTRICT</span>
                      <span>:</span>
                    </div>
                    <span>{verificationData["district"].toUpperCase()}</span>
                  </div>
                )}
                {verificationData["state"] && (
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2">
                      <span>STATE</span>
                      <span>:</span>
                    </div>
                    <span>{verificationData["state"].toUpperCase()}</span>
                  </div>
                )}
                {verificationData["pincode"] && (
                  <div
                    className="grid grid-cols-2"
                    style={{
                      paddingBottom: "2rem",
                      borderBottom: "1px dashed",
                    }}
                  >
                    <div className="grid grid-cols-2">
                      <span>PINCODE</span>
                      <span>:</span>
                    </div>
                    <span>{verificationData["pincode"].toUpperCase()}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="px-2 sm:px-6">

            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Verify GSTIN/PAN Status
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
            <p className="mt-4 text-xs text-center cursor-pointer">
              <a
                className="underline"
                onClick={() => {
                  onClickFlag(true, "wherePan");
                  onClickFlag(true, "pan");
                  onClickFlag(false, "bank");
                  setPanRecord(null);
                  setLoaderFlag(false);
                  verifyGSTINDataVar = {};
                  onClick(2);
                }}
              >
                Go Back
              </a>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

const StartSignUpComp = ({ onClick, onClickFlag, onState, setPanRecord, onChangeValue, setLoaderFlag }) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;
  let form = useRef(null);
  const [typeOfPassword, changePasswordType] = useState("password");
  const [selectedRegion, setSelectedRegion] = useState();
  const RegionData = {
    West: [
      "Karan Kapoor",
      "Sulekha Singh",
      "Vasant Sharma",
      "Abhishek Athavale",
      "Ninad Sahasrabudhe",
      "Dileshwar Kumar Sahu",
      "Aditya Patankar",
    ],
    Central: [
      "Jaya Rawat",
      "Gunjan Khanna"
    ],
    South: [
      "Dinesh Chettithodi",
      "Raghu Pokala",
      "Sunil Kumar",
      "Margaret Roopa",
      "Maddu Santosh Kumar",
      "Rohit Kumar Shetty",
      "Umesh Devadiga",
      "Anistaberry Peter",
    ],
    North: [
      "Aasifa Ahmed",
      "Neha Sharma",
      "Rahul Jaravta",
      "Shalini Singh",
      "Siddharth Saxena",
      "Prashant Upadhyay",
      "Shweta Parmar",
      "Harpreet Singh",
    ],
    East: [
      "Manish Anand"
    ],
  };
  const handleSignUpSubmit = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    console.log(payload)
    if (payload.password === payload.confirm_password && checkPassword(payload.password) && agencyName(payload.agency_name) && phonenumber(payload.phone_number) && nameLengthCheck(payload.first_name) && nameLengthCheck(payload.last_name)) {
      startSignUpAPI(payload, onClick, onState, setLoaderFlag);
    } else {
      if (payload.password !== payload.confirm_password && !checkPassword(payload.password) && !phonenumber(payload.phone_number) && !nameLengthCheck(payload.first_name) && !nameLengthCheck(payload.last_name)) {
        alert("Please Enter a Valid Password - Min. 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character & Please Enter a Valid 10 Digit Phone Number & Minimum length for Name is 4");
        setLoaderFlag(false);
      } else if (payload.password !== payload.confirm_password) {
        alert("Password Don't Match");
        setLoaderFlag(false);
      } else if (!checkPassword(payload.password)) {
        alert("Please Enter a Valid Password - Min. 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character ");
        setLoaderFlag(false);
      } else if (!agencyName(payload.agency_name)) {
        alert("Please Enter a Valid Agency Name");
        setLoaderFlag(false);
      } else if (!phonenumber(payload.phone_number)) {
        alert("Please Enter a Valid 10 Digit Phone Number");
        setLoaderFlag(false);
      } else if (!nameLengthCheck(payload.first_name)) {
        alert("Minimum length for Name is 4");
        setLoaderFlag(false);
      } else if (!nameLengthCheck(payload.last_name)) {
        alert("Minimum length for Name is 4");
        setLoaderFlag(false);
      }
    }
  };
  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={handleSignUpSubmit}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Sign - Up - Step - 3
            </h3>
          </div>
          <div className="mt-12 w-full px-2 sm:px-6">
            <div>
              <label className="text-magenta">Email&nbsp;<sup className="text-j-orange">*</sup></label>
              <Input id="email" name="email" type="email" />
            </div>
            <div className="mt-6">
              <label className="text-magenta">Travel Agency Name&nbsp;<sup className="text-j-orange">*</sup></label>
              <Input id="agency_name" name="agency_name" type="text" />
            </div>
            <div className="grid grid-cols-2 gap-2 mt-6">
              <div>
                <label className="text-magenta">First Name&nbsp;<sup className="text-j-orange">*</sup></label>
                <Input id="first_name" name="first_name" type="text" />
              </div>
              <div>
                <label className="text-magenta">Last Name&nbsp;<sup className="text-j-orange">*</sup></label>
                <Input id="last_name" name="last_name" type="text" />
              </div>
            </div>
            <div className="mt-6">
              <label className="text-magenta">Phone Number&nbsp;<sup className="text-j-orange">*</sup></label>
              <Input id="phone_number" name="phone_number" type="number" />
            </div>
            <div className="mt-6 grid grid-cols-2 gap-8">
              <div>
                <label className="text-magenta">
                  Region&nbsp;<sup className="text-j-orange">*</sup>
                </label>
                <Select
                  // icon="fal fa-utensils"
                  id="region"
                  name={`region`}
                  lessSpace
                  // defaultValue={guest.gender != null ?guest.gender : null}
                  placeholder="Select Region"
                  inverseError
                  // error={errors && errors[`${prefix}_gender`] && "Please select a gender"}
                  onChange={(event) => { setSelectedRegion(event.target.value) }}
                  required
                >
                  <option selected value="" disabled>
                    Select Region
                  </option>
                  {Object.keys(RegionData).map((region) => {
                    return <option value={region}>{region === 'Central' ? 'Co-Operate' : region}</option>;
                  })}
                </Select>
              </div>
              <div>
                <label className="text-magenta">
                  Sales Manager&nbsp;<sup className="text-j-orange">*</sup>
                </label>
                <Select
                  // icon="fal fa-utensils"
                  id="sales_manager"
                  name={`sales_manager`}
                  lessSpace
                  // defaultValue={guest.gender != null ?guest.gender : null}
                  placeholder="Select Sales Manager"
                  inverseError
                  required
                // error={errors && errors[`${prefix}_gender`] && "Please select a gender"}
                >
                  <option selected value="" disabled>
                    Select Sales Manager
                  </option>
                  {selectedRegion && RegionData[selectedRegion].map((region) => {
                    return <option value={region}>{region}</option>;
                  })}
                </Select>
              </div>
            </div>
            <div className="mt-6">
              <label className="text-magenta">Password&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="password"
                name="password"
                placeholder="Password"
                type={typeOfPassword}
              />
              {
                (typeOfPassword === "password") ? <i style={{ position: "relative", top: "-24px", right: "-96%", }} class="fa fa-eye" onClick={() => {
                  changePasswordType("text");
                }}></i> : <i style={{ position: "relative", top: "-24px", right: "-96%", }} class="fa fa-eye-slash" onClick={() => {
                  changePasswordType("password");
                }}></i>
              }
            </div>
            <div className="mt-6">
              <label className="text-magenta">Confirm Password&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="confirm_password"
                name="confirm_password"
                placeholder="Confirm Password"
                type="password"
              />
            </div>
          </div>
          <div className="px-2 sm:px-6 mt-6">

            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Sign - Up
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
            <p className="mt-4 text-xs text-center">
              <a
                className="underline cursor-pointer"
                onClick={() => {
                  onClickFlag(true, "wherePan");
                  onClickFlag(true, "pan");
                  onClickFlag(false, "bank");
                  setPanRecord(null);
                  verifyGSTINDataVar = {};
                  setLoaderFlag(false);
                  onClick(1);
                }}
              >
                Cancel
              </a>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

const AddressComp = ({ onClick, onClickFlag, onState, setPanRecord, onChangeValue, setLoaderFlag, internationalStatus }) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;
  let form = useRef(null);
  let verificationData = verifyGSTINDataVar;

  const [state, setState] = useState();
  const [country, setCountry] = useState(!internationalStatus ? "India" : null);
  const [stateCityArray, setstateCityArray] = useState([])
  const [countryStatesArray, setCountryState] = useState([])

  const detailsSignUpSubmit = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    if (payload.country && payload.country != 'india') {
      AddressCompAPI(payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag, internationalStatus);
    } else {
      if (pincodeCheck(payload.pincode)) {
        AddressCompAPI(payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag, internationalStatus);
      } else {
        alert("Invalid Pincode");
        setLoaderFlag(false);
      }
    }
  };
  // useEffect=()=>{
  //   if(!internationalStatus){
  //     setCountryState(CountryStates.countries.filter((countries) => countries.country === "India")[0].states);}
  // }
  React.useEffect = () => {
    console.log("rendered")
    if (!internationalStatus) {
      setCountryState(CountryStates.countries.filter((countries) => countries.country === "India")[0].states);
    }
  }
  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={detailsSignUpSubmit}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Input Your Details
            </h3>
          </div>
          <div className="mt-12 w-full px-2 sm:px-6">
            <div
              className="grid grid-cols-2 gap-4"
              style={{ marginBottom: "2rem" }}
            >
              <div>
                <label className="text-magenta">Address Line 1&nbsp;<sup className="text-j-orange">*</sup></label>
                <Input
                  id="address_line_1"
                  name="address_line_1"
                  placeholder="Address Line - 1"
                  type="text"
                />
              </div>
              <div>
                <label className="text-magenta">Address Line 2&nbsp;<sup className="text-j-orange">*</sup></label>
                <Input
                  id="address_line_2"
                  name="address_line_2"
                  placeholder="Address Line - 2"
                  type="text"
                />
              </div>
            </div>
            {!internationalStatus && <div style={{ marginBottom: "2rem" }}>
              <label className="text-magenta">Area&nbsp;<sup className="text-j-orange">*</sup></label>
              <Input id="area" name="area" placeholder="Area" type="text" />
            </div>}
            {internationalStatus && <div
              className="grid grid-cols-2 gap-4"
              style={{ marginBottom: "2rem" }}
            >
              <div>
                <label className="text-magenta">Country&nbsp;<sup className="text-j-orange">*</sup></label>
                <select
                  name={`country`}
                  withIcon
                  lessSpace
                  id="country"
                  placeholder="Country"
                  className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                  onChange={(event) => {
                    let x = event.target.value;
                    setCountry(x);
                    setCountryState(CountryStates.countries.filter((countries) => countries.country === x)[0].states);
                  }}
                  required="required"
                >
                  <option selected value="" disabled>Select country</option>
                  {CountryStates.countries.map((countries) => {
                    return <option value={countries.country}>{countries.country}</option>
                  })}
                </select>
              </div>
              <div>
                <label className="text-magenta">State&nbsp;<sup className="text-j-orange">*</sup></label>
                <select
                  name={`state`}
                  withIcon
                  lessSpace
                  id="state"
                  placeholder="State"
                  className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                  required="required"
                >
                  <option selected value="" disabled>Choose a State</option>
                  {countryStatesArray.map((states) => {
                    return <option value={states}>{states}</option>
                  })}
                </select>
              </div>
            </div>}
            {!internationalStatus && <div
              className="grid grid-cols-2 gap-4"
              style={{ marginBottom: "2rem" }}
            >
              <div>
                <label className="text-magenta">State&nbsp;<sup className="text-j-orange">*</sup></label>
                <select
                  name={`state`}
                  withIcon
                  lessSpace
                  id="state"
                  placeholder="State"
                  className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                  onChange={(event) => {
                    let x = event.target.value;
                    setState(x);
                    setstateCityArray(States.states.filter((states) => states.state === x)[0].cities);
                  }}
                >
                  <option selected value={null} disabled>Choose a State</option>
                  {States.states.map((states) => {
                    return <option value={states.state}>{states.state}</option>
                  })}
                </select>
              </div>
              <div><label className="text-magenta">District&nbsp;<sup className="text-j-orange">*</sup></label>
                <Input
                  id="district"
                  name="district"
                  placeholder="District"
                  type="text"
                /></div>
            </div>}
            <div
              className="grid grid-cols-2 gap-4"
              style={{ marginBottom: "2rem" }}
            >
              {/* <div>
                  {!internationalStatus&&<><label className="text-magenta">District&nbsp;<sup className="text-j-orange">*</sup></label>
                      <Input
                        id="district"
                        name="district"
                        placeholder="District"
                        type="text"
                      /></>}
              </div> */}
              {/* {!internationalStatus&&<><label className="text-magenta">City&nbsp;<sup className="text-j-orange">*</sup></label>
                <Input id="city" name="city" placeholder="City" type="text" /></>} */}
              {!internationalStatus && <div><label className="text-magenta">City&nbsp;<sup className="text-j-orange">*</sup></label><select
                name={`city`}
                id="city"
                className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
              >
                <option selected value={null} disabled>Choose a City</option>
                {
                  stateCityArray.map((cities) => {
                    return <option value={cities}>{cities}</option>
                  })
                }
              </select></div>}
              {/* <select 
                    name={`city`}
                    id="city"
                    className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                >
                      <option selected value={null} disabled>Choose a City</option>
                      {
                          stateCityArray.map((cities) => {
                              return  <option value={cities}>{cities}</option>
                          })
                      }
                </select> */}
              <div>
                <label className="text-magenta">Pincode&nbsp;<sup className="text-j-orange">*</sup></label>
                <Input
                  id="pincode"
                  name="pincode"
                  placeholder="PINCODE"
                  type="number"
                />
              </div>
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <label className="text-magenta">Portal Agent Type&nbsp;<sup className="text-j-orange">*</sup></label>
              <select
                name="portal_agent_type"
                id="portal_agent_type"
                style={{
                  width: "100%",
                  padding: "0.625rem",
                  borderRadius: "0.625rem",
                  boxShadow:
                    "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                }}
              >
                <option selected value="mice">
                  Mice
                </option>
                <option value="b2b">B2B</option>
                <option value="leisure">Leisure</option>
                <option value="consolidator">Consolidator</option>
                <option value="weddings">Weddings</option>
              </select>
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <label className="text-magenta">Transaction Module&nbsp;<sup className="text-j-orange">*</sup></label>
              <select
                name="commission_type"
                id="commission_type"
                style={{
                  width: "100%",
                  padding: "0.625rem",
                  borderRadius: "0.625rem",
                  boxShadow:
                    "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                }}
              >
                <option value="p2p">P2P(Cut and Pay Model)</option>
                <option value="p2a">P2A(Gross Pay Model)</option>
              </select>
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <label className="text-magenta">Referral Code</label>
              <div className="flex flex-col mt-1">
                <input
                  id="referral_code"
                  name="referral_code"
                  placeholder="Referral Code (Optional)"
                  type="text"
                  className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                />
              </div>
            </div>
            {bankDetailsFlag ? (
              <div className="bank-details-div">
                <p className="mt-8 text-xs text-right cursor-pointer">
                  <a
                    className="underline"
                    onClick={() => {
                      onClickFlag(false, "bank");
                    }}
                  >
                    Remove Bank Details
                  </a>
                </p>

                <div style={{ marginBottom: "2rem" }}>
                  <label className="text-magenta">Bank Name&nbsp;<sup className="text-j-orange">*</sup></label>
                  <Input
                    id="bank_name"
                    name="bank_name"
                    placeholder="Bank Name"
                    type="text"
                  />
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <label className="text-magenta">Account Number&nbsp;<sup className="text-j-orange">*</sup></label>
                  <Input
                    id="account_number"
                    name="account_number"
                    placeholder="Account Number"
                    type="number"
                  />
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <label className="text-magenta">Beneficiary Name&nbsp;<sup className="text-j-orange">*</sup></label>
                  <Input
                    id="beneficiary_name"
                    name="beneficiary_name"
                    placeholder="Beneficiary Name"
                    type="text"
                  />
                </div>
                <div
                  className="grid grid-cols-2 gap-4"
                  style={{ marginBottom: "2rem" }}
                >
                  <div>
                    <label className="text-magenta">IFSC&nbsp;<sup className="text-j-orange">*</sup></label>
                    <Input
                      id="ifsc_code"
                      name="ifsc_code"
                      placeholder="IFSC"
                      type="text"
                    />
                  </div>
                  <div>
                    <label className="text-magenta">Branch Name&nbsp;<sup className="text-j-orange">*</sup></label>
                    <Input
                      id="branch_name"
                      name="branch_name"
                      placeholder="Branch Name"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {!internationalStatus && <p className="mt-8 text-xs text-right cursor-pointer">
                  <a
                    className="underline"
                    onClick={() => {
                      onClickFlag(true, "bank");
                    }}
                  >
                    Add Bank Details
                  </a>
                </p>}
              </div>
            )}
          </div>
          <div className="px-2 sm:px-6">

            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Continue
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
            <p className="mt-4 text-xs text-center">
              <a
                className="underline cursor-pointer"
                onClick={() => {
                  onClickFlag(true, "wherePan");
                  onClickFlag(true, "pan");
                  onClickFlag(false, "bank");
                  setPanRecord(null);
                  verifyGSTINDataVar = {};
                  setLoaderFlag(false);
                  onClick(1);
                }}
              >
                Cancel
              </a>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

const VerifySignUpComp = ({ onClick, onClickFlag, onState, setPanRecord, onChangeValue, setLoaderFlag, internationalStatus }) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;
  let form = useRef(null);
  const verifySignUpSubmit = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    verifySignUpAPI(payload, onClick, onState, setPanRecord, onClickFlag, setLoaderFlag, internationalStatus);
  };
  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={verifySignUpSubmit}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Verify Sign - Up
            </h3>
          </div>
          <div className="mt-12 w-full px-2 sm:px-6">
            <div>
              <label className="text-magenta">Email OTP&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="email_otp"
                name="email_otp"
                placeholder="Email OTP"
                type="number"
              />
            </div>
            {!internationalStatus && <div>
              <label className="text-magenta">Mobile OTP&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="phone_otp"
                name="phone_otp"
                placeholder="Mobile OTP"
                type="number"
              />
            </div>}
          </div>
          <div className="px-2 sm:px-6">
            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Complete Sign - Up
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
            <p className="mt-4 text-xs text-center">
              <a
                className="underline"
                onClick={() => {
                  onClickFlag(true, "wherePan");
                  onClickFlag(true, "pan");
                  onClickFlag(false, "bank");
                  setPanRecord(null);
                  verifyGSTINDataVar = {};
                  setLoaderFlag(false);
                  onClick(1);
                }}
              >
                Cancel
              </a>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

const CheckForgetPasswordComp = ({ onClick, onClickFlag, onState, setLoaderFlag }) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;

  let form = useRef(null);
  const checkForgetPassword = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    startForgetPasswordAPI(payload, onClick, setLoaderFlag);
  };

  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={checkForgetPassword}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Enter Your Email
            </h3>
          </div>
          <div className="mt-12 w-full px-2 sm:px-6">
            <label className="text-magenta">Email&nbsp;<sup className="text-j-orange">*</sup></label>
            <Input id="email" name="email" placeholder="Email" type="email" />
          </div>
          <div className="mt-4 w-full flex justify-between px-2 sm:px-6">
            <a
              className="text-xs"
              onClick={() => {
                onClick(1);
                setLoaderFlag(false);
              }}
            >
              Cancel
            </a>
          </div>
          <div className="px-2 sm:px-6">

            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Submit
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
          </div>
        </form>
      </div>
    </section>
  );
};

const VerifyForgetPasswordComp = ({ onClick, onClickFlag, onState, setLoaderFlag }) => {
  const {
    email,
    password,
    isChecked,
    editPanFlag,
    bankDetailsFlag,
    panCardNumber,
    wherePan,
    loaderFlag
  } = onState;
  let form = useRef(null);
  const [typeOfPassword, changePasswordType] = useState("password");
  const verifyForgetPassword = (event) => {
    setLoaderFlag(true);
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    if (payload.password === payload.confirm_new_password && checkPassword(payload.password)) {
      verifyForgetPasswordAPI(payload, onClick, setLoaderFlag);
    } else {
      if (payload.password !== payload.confirm_new_password && !checkPassword(payload.password)) {
        alert("Please Enter a Valid Password - Min. 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character or Passwords Don't Match");
        setLoaderFlag(false);
      } else if (payload.password !== payload.confirm_new_password) {
        alert("Password Don't Match");
        setLoaderFlag(false);
      } else if (!checkPassword(payload.password)) {
        alert("Please Enter a Valid Password - Min. 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character ");
        setLoaderFlag(false);
      }
    }
  };
  return (
    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 overflow-auto h-full">
      <div className="h-full hidden sm:block overflow-auto">
        <BannerCarousel />
      </div>
      <div className="bg-gray-100 {-- h-screen --} flex justify-center lg:items-baseline h-full overflow-auto">
        <form
          ref={form}
          onSubmit={verifyForgetPassword}
          className="w-full text-j-magenta p-20"
        >
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img src={LogoImage} className="h-48 text-center" />
          </div>
          <div className="pt-8 px-2 flex flex-col items-center justify-center">
            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
              Verify Yourself
            </h3>
          </div>
          <div className="mt-12 w-full px-2 sm:px-6">
            <div style={{ marginBottom: "2rem" }}>
              <label className="text-magenta">New Password&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="password"
                name="password"
                placeholder="New Password"
                type={typeOfPassword}
              />
              {
                (typeOfPassword === "password") ? <i style={{ position: "relative", top: "-24px", right: "-96%", }} class="fa fa-eye" onClick={() => {
                  changePasswordType("text");
                }}></i> : <i style={{ position: "relative", top: "-24px", right: "-96%", }} class="fa fa-eye-slash" onClick={() => {
                  changePasswordType("password");
                }}></i>
              }
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <label className="text-magenta">Confirm New Password&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="confirm_new_password"
                name="confirm_new_password"
                placeholder="Confirm New Password"
                type="password"
              />
            </div>
            <div>
              <label className="text-magenta">OTP&nbsp;<sup className="text-j-orange">*</sup></label>
              <DisabledCopy
                id="reset_otp"
                name="reset_otp"
                placeholder="OTP"
                type="number"
              />
            </div>
          </div>
          <div className="mt-4 w-full flex justify-between px-2 sm:px-6">
            <a
              className="text-xs"
              onClick={() => {
                onClick(1);
                setLoaderFlag(false);
              }}
            >
              Cancel
            </a>
          </div>
          <div className="px-2 sm:px-6">

            {
              (!loaderFlag) ? (
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                  Submit
                </button>) : (
                <div>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </div>)
            }
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
