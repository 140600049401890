import React, { useState, useEffect, useContext } from "react";
import cx from "classnames";
import Button from "../Button/Button";
import BookingOverlay from "../Booking/General/BookingOverlay";
import DeckOverlay from "./DeckOverlay";
import UserContext from "../../store/UserContext";
import { useHistory } from 'react-router-dom'
import { getToken } from "../../utils/common";

const SelectorOverlay = ({
  onClose,
  onCancel,
  rooms,
  selections,
  itinerary,
  onSelect,
  selectedCategory,
  roomIndex,
  itineraryID,
  beds,
}) => {
  console.log(rooms);
  const [index, setIndex] = useState(roomIndex);
  const [deck, setDeck] = useState(null);
  const [user, setUser] = useContext(UserContext);
  // const [loading, setAppStatus] = useState(false);
  // const [decks, setDecks] = useState(getDecks(selections[index]));
  const [decks, setDecks] = useState([]);
  const [bedCount, setBeds] = useState(beds);
  const onDeckSelected = (deck) => {
    setDeck(deck);
  };
  const router = useHistory();
  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/itineraries/" +
        itinerary.id +
        "/decks?category_id=" +
        selectedCategory.category_id +
        "&beds=" +
        bedCount,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
    .then((res) => res.json())
      .then((data) => {
        setDecks(data);
      });
    //   fetch(
    //     process.env.REACT_APP_API_ENDPOINT +
    //       "/itineraries/" +
    //       itinerary.id +
    //       "/check_availability?category_id=" +
    //       selectedCategory.category_id,
    //     {
    //       method: "GET",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${user.verificationToken.token}`,
    //       },
    //     }
    //   )
    //     .then((res) => {
    //       if (res.ok) {
    //         return res.json();
    //       } else {
    //         throw new Error("Something went wrong");
    //       }
    //     }).then(data=>{
    //         console.log(data)
    //     })
  }, [setDecks]);

  const onSkipClicked = () => {
    // if (index < rooms.length - 1) {
    //     setIndex(index + 1)
    //     setDeck(null)
    //     fetch("https://cordelia.fortelogic.in/api/itineraries/"+itinerary.id+"/decks/?category_id="+selectedCategory.category_id, {
    //         method: 'GET',
    //           headers: {
    //               'Accept': 'application/json',
    //               'Content-Type': 'application/json',
    //               Authorization: `Bearer ${user.verificationToken.token}`,
    //           },
    //       })
    //       .then((res) => res.json())
    //       .then((data) => {
    //         setDecks(data);
    //     });
    // } else {
    onClose();
    // }
  };

  if (index == rooms.length) <div />;

  if (deck) {
    return (
      <DeckOverlay
        className={cx("deck-overlay-override")}
        rooms={rooms}
        roomIndex={index}
        selections={selections}
        selectedCategory={selectedCategory}
        deck={deck}
        beds={bedCount}
        itinerary={itinerary}
        onClose={onClose}
        onCancel={onCancel}
        onDeckClose={onSkipClicked}
        onSelect={(index, selected, selected_room) => {
          onSelect(index, selected, true, selected_room);
          onSkipClicked();
        }}
        itineraryID={itineraryID}
      />
    );
  }

  return (
    <BookingOverlay style="close" onCancel={onCancel}>
      {/* <SkipButton className="mb-12" onClick={onSkipClicked} /> */}
      <h2 className="uppercase pb-4">Cabin</h2>
      <h2>Choose a deck</h2>
      <p className="pb-4">
        Please select a deck from below to see the available cabin options
      </p>
      {decks.map((deck) => (
        <DeckCard key={deck.id} deck={deck} onSelect={onDeckSelected} />
      ))}
      {/* <SkipButton className="mb-12" onClick={onSkipClicked} /> */}
    </BookingOverlay>
  );
};

export default SelectorOverlay;

const DeckCard = ({ deck, onSelect }) => {
  const [galleryOpen, setGalleryOpen] = useState(false);
  return (
    <div
      className={cx(
        "rounded-big border border-j-white p-4 mb-4",
        deck.selectable
          ? "bg-j-white text-j-black"
          : "bg-j-magenta text-j-white"
      )}
    >
      <p className="text-xs uppercase pb-2">Deck {deck.id}</p>
      <p className="font-bold">{deck.description}</p>
      {deck.obstructed && (
        <div className="grid grid-cols-2">
          <p className="text-j-green-lighter mt-2 italic">
            Obstructed View Cabins
          </p>
          <p
            className="text-j-orange text-right underline mt-2 italic cursor-pointer"
            onClick={() => setGalleryOpen(!galleryOpen)}
          >
            View Gallery
          </p>
        </div>
      )}
      {galleryOpen && (
        <div className="grid grid-cols-2 gap-2 mt-4">
          <img
            src={process.env.NEXT_PUBLIC_IMAGE_HOSTNAME + deck.images[0].src}
            className="w-full h-full rounded-lg"
          />
          <img
            src={process.env.NEXT_PUBLIC_IMAGE_HOSTNAME + deck.images[1].src}
            className="w-full h-full rounded-lg"
          />
        </div>
      )}
      {deck.selectable && (
        <>
          <h4 className="text-j-orange pb-4"></h4>
          <Button
            className="w-full bg-j-red-light text-j-white"
            onClick={() => {
              onSelect(deck);
            }}
          >
            Select this deck
          </Button>
        </>
      )}
    </div>
  );
};

// const getDecks = (selection) =>
//   decks.map((deck) =>
//     Object.assign({}, deck, {
//       rooms: Array(getRandomInt(2, 10))
//         .fill({})
//         .map(() => ({
//           number: getRandomInt(1, 44),
//           category: getRandomItem(selection.categories),
//         })),
//     })
//   );

function getRandomInt(min, max) {
  return min + Math.floor(Math.random() * Math.floor(max));
}

function getRandomItem(items) {
  return items[Math.floor(Math.random() * items.length)];
}
