import React, { useState, Component, setUser, useEffect, useRef } from 'react';
import BookingFormView from "../Booking/General/BookingFormView";
import Tile from "../Tile/Tile";
import Button from "../Button/Button";
import cx from "classnames";
import scrollTo from "../../utils/scrollTo";
import UserContext from "../../store/UserContext";
import { CancelView } from "../View/FAQView";
import moment from "moment";
import LoadingIcon from "../Loading/LoadingIcon"
import { useHistory } from "react-router-dom";
import { getToken } from "../../utils/common";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { name, phone, email, date, phone_pattern } from "../../utils/validations";
import Modal from "../Modal/Modal";


class UpgradePaymentSummary extends Component {

  render() {
    const { values } = this.props;
    return (
      <>
        <PaymentSummaryViewAPI nextStep={this.props} />
      </>
    )
  }
}
export default UpgradePaymentSummary;

const PaymentSummaryViewAPI = (nextStep) => {
    let history = useHistory();
    const [user, setUser] = React.useContext(UserContext);
    const [error, setErrorMessage] = useState(false)
    const bookingMain = user.upgradeBooking;
    const booking = user.upgradeBooking.rooms || {};
    const [individualPrice, setIndividualPrice] = useState(null);
    const [fulldetails, setFullDetails] = useState(null);
    const [loading, setLoading] = useState()
    const [open, setOpen] = useState(null);
    const [fareOpen, setFareOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [cancellationPolicy, setCancellationPolicy] = useState(null);
    const [modelEmail, setModelEmail] = useState(false);
  const [emailValue, setEmailValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const submitRef = useRef(null);
  const [reschedulePolicy, setReschedulePolicy] = useState(null);
  let itiId = JSON.parse(localStorage.getItem('store'));;
  itiId = itiId.booking.itinerary.id;
  let bookingidEmailShare = JSON.parse(sessionStorage.getItem('booking'));
  bookingidEmailShare = bookingidEmailShare.id;
  const cancellationDiv = cancellationPolicy ? (
    <div>
      {cancellationPolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{display: "revert"}}>{term}</li>
                );
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)
  const rescheduleDiv = reschedulePolicy ? (
    <div>
      {reschedulePolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return(
                  <li style={{display: "revert"}}>{term}</li>
                )
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)

  const modalClose = () => {
    setModelEmail(false)
  }

  const modalOpen = (e) => {
    setModelEmail(true);
  }

  const schema = Joi.object({
    email,
  })


  const { register, errors, handleSubmit, setError } = useForm({
    resolver: joiResolver(schema),
  });

  const shareEmailQuatation = () => {
    setSubmitted(true);
    let dataObj = {
      "booking_id": bookingidEmailShare,
      "step": "payment",
      "email": emailValue,
      "auth": getToken()
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/quotations`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(dataObj),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error("Verification failed");
      }
      )
      .then((response) => {
        alert("Quotation Send successfully");
        setSubmitted(false);
        modalClose()

      }).catch((err) => console.log(err));
  }

  const onChangeValue = (e) => {
    const email = e.target.value;
    setEmailValue(email);
  }

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/itineraries/${itiId}/policies`, {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) => res.json())
      .then((res) => {
        setCancellationPolicy(res.cancellation);
        setReschedulePolicy(res.reschedule);
      }).catch((err) => {
        alert("Error Fetching Cancellation and Reschedule Policies");
      });
      var roomsData=[];
      bookingMain.rooms.map((data,i) => {
        if(data.selected == undefined)
        {
          roomsData.push({
            "room_type": data.roomCode,
            "old_room_no": data.roomNumber,
            "room_no": data.roomNumber
          })
        } else {
          roomsData.push({
            "room_type":data.selected.code,
            "old_room_no": data.roomNumber,
            "room_no": data.room_number
          })
        }
      })

      const bookingInput = {
        booking_reference:  bookingMain.roomNumber,
        rooms: roomsData
      }
 
      fetch(process.env.REACT_APP_API_ENDPOINT+'/agent_portal/upgrade/pricing', {
            method: 'POST',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
              `Bearer ${getToken()}`,
            },
            body: JSON.stringify(bookingInput)
        }).then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error("Something went wrong")
          }
        })
        .then((response) => {
          // setFullDetails(response);
          setIndividualPrice(response)
          setLoading(false)
        }).catch((error) => {
          setErrorMessage(true)
          setLoading(false)
        })
    },[loading])

    if (loading || error || !individualPrice) {
      return (
        <h1 className="text-4xl">
          <LoadingIcon className="py-20 text-j-magenta" />
        </h1>
      )
    }
  
    const backbuttonpressed = (e) => {
      e.preventDefault()
      nextStep.nextStep.prevStep()
    }
  
    const conformingPaymentDetails = (e) => {
      const itineraryData = {
        itineraries: bookingMain.itinerary,
        amount: individualPrice.payable
      }
      sessionStorage.setItem("itinerary",JSON.stringify(itineraryData))
      e.preventDefault()
      setDisabled(true);
      const reshudilingInput = {
        itinerary:  bookingMain.itinaryID.id,
        booking_reference: bookingMain.roomNumber
      }
      fetch(process.env.REACT_APP_API_ENDPOINT+'/agent_portal/upgrade/confirm', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
          `Bearer ${getToken()}`,
        },
        body: JSON.stringify(reshudilingInput)
      }).then((response) => response.json())
      .then((response) => {
        history.push({
          pathname: '/admin/paymentGateway',
        })
      })
      // nextStep.nextStep.nextStep()
    }

    const returnFormattedCurrency = (x) => {
      x = Number(x);
      x=x.toString();
      var afterPoint = '';
      if(x.indexOf('.') > 0)
        afterPoint = x.substring(x.indexOf('.'),x.length);
      x = Math.floor(x);
      x=x.toString();
      var lastThree = x.substring(x.length-3);
      var otherNumbers = x.substring(0,x.length-3);
      if(otherNumbers != '')
          lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
      return res;
    }
  
    return (
      <>
      <Modal show={modelEmail} onClose={modalClose}>
        <div className="p-6">
          <p className="mb-4">Please enter your Email-ID:</p>
          {/* <InputField
                        placeholder="Email address"
                        type="email"
                        name="email"
                        lessSpace
                        // defaultValue={storageProfile.email}
                        // ref={register({ required: true })}
                        error={errors && errors.email && "Please enter a valid email address"}
                    /> */}
          <input
            required
            name={email}
            placeholder="Email address"
            type="email"
            className="w-full h-10 px-2 w-full text-black rounded mt-2 focus:outline-non flex-grow max-w-full placeholder-j-gray-lighter text-black text-sm shadow placeholder-gray-500 placeholder-opacity-75 self-center pt-1 leading-none mb-4 border-grey-600"
            // value={this.props.value} 
            // defaultValue={this.props.defaultValue} 
            onChange={(e) => onChangeValue(e)} />
          {!submitted && <Button
            className="bg-j-red-light text-white w-full mt-6 pt-1"
            disabled={submitted}
            onClick={() => shareEmailQuatation()}
          >
            Submit
          </Button>}
          {submitted && <Button
            className="bg-j-red-light text-white w-full mt-6 pt-1"
            disabled={submitted}
          >
            <LoadingIcon />
          </Button>}
        </div>
      </Modal>
        <BookingFormView
          buttonText="Continue"
          onClick={conformingPaymentDetails}
          disabled={disabled}
          lessSpace={true}
          id="otp-form"
        >
          <>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2">
          <span>
            <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg" style={{ marginRight: '5px' }}>
              <Tile.Inner className="pb-0">
                <OrderDetail title="SHIP">
                  {bookingMain.itinerary.name}
                </OrderDetail>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="mr-2">
                  <OrderDetail title="Departure">
                    {/* {bookingMain.itinerary.departure}
                    <br></br> */}
                    {moment(bookingMain.itinerary.start_time).format("ddd, D MMM YYYY, h:mm a")}
                  </OrderDetail>
                </div>
                <div className="ml-2">
                  <OrderDetail title="Arrival">
                    {/* {bookingMain.itinerary.destination}
                    <br></br> */}
                    {moment(bookingMain.itinerary.end_time).format("ddd, D MMM YYYY, h:mm a")}
                  </OrderDetail>
                </div>
              </div>
              {Object.keys(booking)
                .map((k, index) =>

                  <OrderDetail title={`Cabin ${index + 1}`}>
                    {booking[index].selected == undefined ? booking[index].roomCatogory :booking[index].selected.name}<br />
                        Room No: {booking[index].selected == undefined ? booking[index].roomNumber:booking[index].room_number}<br />
                    {booking[index].adults + booking[index].children + booking[index].infants}  Guest{(booking[index].adults + booking[index].children + booking[index].infants) > 1 ? "s" : null}
                  </OrderDetail>
                )}
              </Tile.Inner>
            </Tile>
          </span>
          <span>
            <Tile tiny theme="white" className="mb-5 col-span-2 bg-transparent" style={{ marginLeft: '5px', backgroundColor: 'transparent' }}>
              <div className="border border-j-gray-lighter" style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  style={{ margin: '25px', color: '#500E4B', fontWeight: 'bold' }}
                  className="grid grid-cols-1 grid-cols-2"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <span className="pt-1"> PRICE DETAILS</span>
                  <i
                    style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                    className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                      "fa-angle-down": !open,
                      "fa-angle-up": open,
                    })}
                  ></i>
                </div>
                {individualPrice.rooms.map((details, index) =>
                  <div key={index}>
                    <div style={{ color: "black" }}>
                      {open && details.price_details.map((price, index) =>
                        <div key={index} style={{ margin: "25px" }}>
                          <div style={{ width: '100%' }}>
                            <PaymentDetails content={price.type} details={returnFormattedCurrency(Math.round((price.total) * 100 / 100))} big={true} />
                            <PaymentDetails content="Cabin Fare:" details={returnFormattedCurrency(Math.round((price.fare) * 100 / 100))} big={false} />
                            {price.discount != '0' && <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
                              <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">Individual Discount</div>
                              <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
                                - &#x20B9;{returnFormattedCurrency(price.discount)}
                              </div>
                            </div>}
                            <PaymentDetails content="Service Charges & Levies" details={returnFormattedCurrency(price.portCharges+price.gratuity)} big={false} />
                            <PaymentDetails content="Fuel Surcharge" details={returnFormattedCurrency(price.fuelSurcharge)} big={false} />
                            {/* <PaymentDetails content="Port Charges" details={returnFormattedCurrency(price.portCharges)} big={false} />
                            <PaymentDetails content="Gratuity:" details={returnFormattedCurrency(price.gratuity)} big={false} /> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>  
                )}
                {open && <div style={{ margin: '25px' }}>
                        <PaymentDetails content="Promo Code:" details="0" big={true} />
                        <PaymentDetails content="New Year Promo:" details="0" big={false} />
                      </div>
                }
                {open && <div style={{ margin: '25px' }}>
                        <PaymentDetails content="Sub-Total:" details={returnFormattedCurrency(individualPrice.base_price)} big={true} />
                        <PaymentDetails content="Taxable Sub Total:" details={returnFormattedCurrency(Math.round(individualPrice.base_price), 0)} big={false} />
                      </div>
                }
                {open && <div style={{ margin: '25px' }}>
                  <PaymentDetails content="Taxes:" details={returnFormattedCurrency(Math.round(individualPrice.tax))} big={true} />
                  <PaymentDetails content="GST:" details={returnFormattedCurrency(Math.round(individualPrice.tax))} big={false} />
                </div>
                }

                <div style={{ margin: '25px' }}>
                  <PaymentDetails content="Discount:" details={returnFormattedCurrency(individualPrice.agent_commission)} big={true} />
                </div>
                <div style={{ margin: '25px' }}>
                  <PaymentDetails content="Net Payable to Cordelia:" details={returnFormattedCurrency(Math.round(individualPrice.payable))} big={true} />
                </div> 
                <div
                style={{
                  margin: "25px",
                  marginBottom: "25px",
                  color: "#500E4B",
                  fontWeight: "bold",
                }}
                className="grid grid-cols-1 grid-cols-2"
                onClick={() => {
                  setFareOpen(!fareOpen);
                }}
              >
                <span className="pt-1">Cancellation and Reschedule policy</span>
                <i
                  style={{ color: "grey", fontSize: 28, textAlign: "right" }}
                  className={cx(
                    "fas cursor-pointer text-j-gray-light text-4xl",
                    {
                      "fa-angle-down": !fareOpen,
                      "fa-angle-up": fareOpen,
                    }
                  )}
                ></i>
              </div>
              {fareOpen && (
                                    <div style={{ padding: "1rem" }}>
                                    {cancellationDiv}
                                    {rescheduleDiv}
                                    </div>
                                )}
              {/* <div className="grid justify-end mb-4">
                <a onClick={e => modalOpen(e)} className="cursor-pointer"><i className="fas fa-share"></i> Share</a>
              </div> */}
              </div>
            </Tile>
            </span>
            </div>
            </>
        </BookingFormView>
      </>
    )
  }

  const OrderDetail = ({ title, children, big }) => (
    <div className="mb-6">
      <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none ">
        {title}
      </h1>
      <h4
        className={
          big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2 text-lg font-semibold"
        }
      >
        {children}
      </h4>
    </div>
  );

  const PaymentDetails = ({ content, details, big }) => (
    <>
      <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
        {big && <>
          <div style={{ color: "black", fontSize: 16, fontWeight: 'bold' }} className="float-left bold">
            {content}
          </div>
          <div style={{ color: "black", fontSize: 16, textAlign: 'right', fontWeight: 'bold' }} className="float-right font-weight-bold text-capitalize">
            &#x20B9;{details}
          </div>
        </>
        }
        {!big && <>
          <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">{content}</div>
          <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
            &#x20B9;{details}
          </div>
        </>
        }
      </div>
    </>
  );