import { addDays } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { getToken } from "../../utils/common";

const Reports = (props) => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [rangeBooking, setRangeBooking] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const getWalletReport = () => {
    const formData = new FormData();
    formData.append('from_date', `${moment(range[0].startDate).format("DD/MM/YYYY")}`);
    formData.append('to_date', `${moment(range[0].endDate).format("DD/MM/YYYY")}`);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/wallet_report`,
      {
        method: "POST",
        headers: {
          // "Content-disposition" : "attachment; filename=[Wallet Report]", 
          // "Content-Type" : "text/csv;charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: formData,
      }
    )
      .then((response) => response.text())
      .then((response) => {
        const data = response;
        const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = 'Wallet Report.csv';
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(
          ":"
        );
        anchor.click();
      });
  };

  const getBookingReport = () => {
    const formData = new FormData();
    formData.append('from_date', `${moment(rangeBooking[0].startDate).format("DD/MM/YYYY")}`);
    formData.append('to_date', `${moment(rangeBooking[0].endDate).format("DD/MM/YYYY")}`);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/booking_report`,
      {
        method: "POST",
        headers: {
          // "Content-disposition" : "attachment; filename=[Booking Report]", 
          // "Content-Type" : "text/csv;charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: formData,
      }
    )
      .then((response) => response.text())
      .then((response) => {
        const data = response;
        const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = 'Booking Report.csv';
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(
          ":"
        );
        anchor.click();
      });
  };
  return (
    <div className="p-10 h-screen">
      <h2 className="mb-10 text-j-magenta text-2xl">Reports</h2>
      
      {/* <div className="pb-5 flex align-middle	">
        <p className="mt-2">Click here to download Booking Report:</p>
      <button
          className="bg-j-orange px-5 py-2 rounded-xl ml-4 text-white font-medium"
          onClick={() => getBookingReport()}
        >
          <i class="fa fa-download mx-2" aria-hidden="true"></i>Download Booking
          Report
        </button>
      </div> */}
      <div>
      <p className="mb-3">
        Select Date Range for Booking Report:{" "}
        <span className="bg-white px-5 py-2 rounded-xl ml-2 mb-2">{`${moment(
          rangeBooking[0].startDate
        ).format("MMM DD, YYYY")} to ${moment(rangeBooking[0].endDate).format(
          "MMM DD, YYYY"
        )}`}</span>
        <button
          className="bg-j-orange px-5 py-2 rounded-xl ml-6 text-white font-medium"
          onClick={() => getBookingReport()}
        >
          <i class="fa fa-download mx-2" aria-hidden="true"></i>Download Booking
          Report
        </button>
      </p>

      <DateRangePicker
        ranges={rangeBooking}
        inputRanges={[]}
        onChange={(e) => {
          setRangeBooking([e.selection]);
        }}
      />
      </div>
      <div className="pt-5"></div>
      <div>
      <p className="mb-3">
        Select Date Range for Wallet Report:{" "}
        <span className="bg-white px-5 py-2 rounded-xl ml-2 mb-2">{`${moment(
          range[0].startDate
        ).format("MMM DD, YYYY")} to ${moment(range[0].endDate).format(
          "MMM DD, YYYY"
        )}`}</span>
        <button
          className="bg-j-orange px-5 py-2 rounded-xl ml-10 text-white font-medium"
          onClick={() => getWalletReport()}
        >
          <i class="fa fa-download mx-2" aria-hidden="true"></i>Download Wallet
          Report
        </button>
      </p>

      <DateRangePicker
        ranges={range}
        inputRanges={[]}
        onChange={(e) => {
          setRange([e.selection]);
        }}
      />
      </div>
      
    </div>
  );
};

export default Reports;
