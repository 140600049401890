import React, { useState, Component, setUser, useEffect, useRef } from 'react';
import cx from "classnames";
import moment from "moment";
import Tile from "../../Tile/Tile";
import Joi from "joi";
import Button from "../../Button/Button";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { name, phone, email, date, phone_pattern } from "../../../utils/validations";
import Modal from "../../Modal/Modal";
import LoadingIcon from "../../Loading/LoadingIcon";
import { getToken } from "../../../utils/common";

const SummaryView = ({ booking, paymentPage, panToggle, totalPrice, taxType, setTaxType, taxRegime, setTaxRegime, travelExpense, setTravelExpense, isAbove7L, setIsAbove7L, tcsObject }) => {
  
  var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))
  return (<>
    {booking.giftCard ? (
      <GiftCardSummaryView booking={booking} currancyStatus={currancyStatus} />
    ) : (
      <ItinerarySummaryView booking={booking} paymentPage={paymentPage} currancyStatus={currancyStatus} panToggle={panToggle} totalPrice={totalPrice} taxType={taxType} setTaxType={setTaxType} taxRegime={taxRegime} setTaxRegime={setTaxRegime} travelExpense={travelExpense} setTravelExpense={setTravelExpense} isAbove7L={isAbove7L} setIsAbove7L={setIsAbove7L} tcsObject={tcsObject} />
    )}</>)
}

const GiftCardSummaryView = ({ booking, currancyStatus }) => (
  <>
    <h1>Order summary</h1>
    <Tile tiny theme="white" className="mb-5 border border-j-gray-lighter">
      <Tile.Inner className="pb-0">
        <OrderDetail title="Item">Gift card</OrderDetail>
        <OrderDetail title="Total amount payable" big>
          {currancyStatus.symbol} {booking.giftCard.value.toLocaleString("hi-IN")}
        </OrderDetail>
      </Tile.Inner>
    </Tile>
  </>
);

const ItinerarySummaryView = ({ booking, paymentPage, panToggle, currancyStatus, totalPrice, taxType, setTaxType, taxRegime, setTaxRegime, travelExpense, setTravelExpense, isAbove7L, setIsAbove7L, tcsObject }) => {
  const [fareOpen, setFareOpen] = useState(false);
  const [cancellationPolicy, setCancellationPolicy] = useState(null);
  const [reschedulePolicy, setReschedulePolicy] = useState(null);
  const [commissionOpen, setCommissionOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [modelEmail, setModelEmail] = useState(false);
  const [emailValue, setEmailValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [gst, setGst] = useState(false);
  const [isGstLoading, setIsGstLoading] = useState(false);
  const [isGstVerified, setIsGstVerified] = useState(false);
  const [gstVerifiedFailMsg, setGstVerifiedFailMsg] = useState('');
  const [knowMoreTcs, setKnowMoreTcs] = useState(false);
  const submitRef = useRef(null);
  let itiId = JSON.parse(localStorage.getItem('store'));
  itiId = itiId.booking.itinerary.id;
  let bookingArr = booking.rooms;
  let grandTotalValueIncTax = 0;
  let grandTotalValue = 0;
  let actualTotalValue = 0;
  let bookingLocalStorage = JSON.parse(sessionStorage.getItem('booking'));
  // alert(bookingLocalStorage)
  let tcsTaxes = JSON.parse(sessionStorage.getItem('tcs_taxes'));
  bookingArr.reduce((grandTotal, booking) => {
    grandTotalValue = grandTotalValue + booking.selected.price.total;
    actualTotalValue = actualTotalValue + booking.selected.price.actual_total;
  }, 0)
  grandTotalValueIncTax = tcsTaxes.tcs !== 0 || tcsTaxes.tcs_with_pan !== 0 ? (panToggle ? parseFloat(grandTotalValue) + parseFloat(bookingLocalStorage.gst) + parseFloat(tcsTaxes.tcs_with_pan.amount) : parseFloat(grandTotalValue) + parseFloat(bookingLocalStorage.gst) + parseFloat(tcsTaxes.tcs.amount)) : parseFloat(grandTotalValue) + parseFloat(bookingLocalStorage.gst);
  console.log('roh ', tcsTaxes);
  let bookingidEmailShare = bookingLocalStorage.id;

  var netPayableAmount = 0;
  netPayableAmount = parseInt(bookingLocalStorage.total) + parseInt(tcsTaxes.tcs_with_pan.amount) + (tcsTaxes.tcs != 0 ? parseInt(tcsTaxes.tcs.amount) : 0);
  const cancellationDiv = cancellationPolicy ? (
    <div>
      {cancellationPolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{ display: "revert" }}>{term}</li>
                );
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)
  const rescheduleDiv = reschedulePolicy ? (
    <div>
      {reschedulePolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{ display: "revert" }}>{term}</li>
                )
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)

  const modalClose = () => {
    setModelEmail(false)
  }

  const modalOpen = (e) => {
    setModelEmail(true);
  }

  const schema = Joi.object({
    email,
  })


  const { register, errors, handleSubmit, setError } = useForm({
    resolver: joiResolver(schema),
  });

  const shareEmailQuatation = () => {
    setSubmitted(true);
    let dataObj = {
      "booking_id": bookingidEmailShare,
      "step": "payment",
      "email": emailValue,
      "auth": getToken()
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/quotations`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(dataObj),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error("Verification failed");
      }
      )
      .then((response) => {
        alert("Quotation Send successfully");
        setSubmitted(false);
        modalClose()

      }).catch((err) => console.log(err));
  }

  const onChangeValue = (e) => {
    const email = e.target.value;
    setEmailValue(email);
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/itineraries/${itiId}/policies`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.json())
      .then((res) => {
        setCancellationPolicy(res.cancellation);
        setReschedulePolicy(res.reschedule);
      }).catch((err) => {
        alert("Error Fetching Cancellation and Reschedule Policies");
      });
  }, [])
  const { rooms } = booking;
  let departure = booking.departure;
  let departureTime = booking.itinerary.startTime;
  let arrival = booking.destination;
  let arrivalTime = booking.itinerary.endTime;
  let bookigStore = JSON.parse(window.sessionStorage.getItem('booking'));
  let shoreExcursion = JSON.parse(window.sessionStorage.getItem("shore_excursion"))
  let shoreExcursionNew = JSON.parse(window.sessionStorage.getItem("shore_excursion_new"))

  let enable_tcs = JSON.parse(window.sessionStorage.getItem('enable_tcs'))
  let is_international = JSON.parse(window.sessionStorage.getItem('is_international'))
  // const safetyCodeArray = JSON.parse(sessionStorage.getItem("safetyProtection"));

  // const safetyCodeStatus = safetyCodeArray.isApplied;
  // const safetyCodeAmount = safetyCodeArray.amount;
  // console.log('safetyCodeArray', safetyCodeArray);

  const guests = rooms
    .map((r) => r.adults + r.children + r.infants)
    .reduce((t, i) => t + i, 0);

  const selected = {};
  rooms.forEach((r) => {
    if (!selected[r.selected.name]) selected[r.selected.name] = 0;
    selected[r.selected.name]++;
  });
  // const totalPrice = rooms.reduce((x, r) => x + (r.total_price || r.selected.price.total), 0);
  // const totalPrice = bookigStore.total;


  // let x = booking.route.name;
  // x = x.split(" - ")

  const returnFormattedCurrency = (x) => {
    x = Number(x);
    x = x.toString();
    var afterPoint = '';
    if (x.indexOf('.') > 0)
      afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    return res;
  }

  const verifyGST = async (gstin) => {
    const _payload = {
      gstin: gstin
    }
    setIsGstLoading(true)

    await fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/verify_gstin_only`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_payload)
    })
      .then((res) => {
        return res.json()
      })
      .then((response) => {
        if (response.message == 'invalid gstin') {
          setIsGstLoading(false)
          setGstVerifiedFailMsg('Invalid GSTIN')
        } else {
          setIsGstVerified(true)
          setGstVerifiedFailMsg('Verified')
          setIsGstLoading(false)
        }
      })
      .catch((err) => {
        alert("Error Fetching Cancellation and Reschedule Policies");
      });
  }


  return (
    <>
      <Modal show={modelEmail} onClose={modalClose}>
        <div className="p-6">
          <p className="mb-4">Please enter your Email-ID:</p>
          {/* <InputField
                        placeholder="Email address"
                        type="email"
                        name="email"
                        lessSpace
                        // defaultValue={storageProfile.email}
                        // ref={register({ required: true })}
                        error={errors && errors.email && "Please enter a valid email address"}
                    /> */}
          <input
            required
            name={email}
            placeholder="Email address"
            type="email"
            className="w-full h-10 px-2 w-full text-black rounded mt-2 focus:outline-non flex-grow max-w-full placeholder-j-gray-lighter text-black text-sm shadow placeholder-gray-500 placeholder-opacity-75 self-center pt-1 leading-none mb-4 border-grey-600"
            // value={this.props.value} 
            // defaultValue={this.props.defaultValue} 
            onChange={(e) => onChangeValue(e)} />
          {!submitted && <Button
            className="bg-j-red-light text-white w-full mt-6 pt-1"
            disabled={submitted}
            onClick={() => shareEmailQuatation()}
          >
            Submit
          </Button>}
          {submitted && <Button
            className="bg-j-red-light text-white w-full mt-6 pt-1"
            disabled={submitted}
          >
            <LoadingIcon />
          </Button>}
        </div>
      </Modal>
      <div>

        <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg">
          <Tile.Inner className="pb-0">

            {booking.number ? (<OrderDetail title="Booking Reference no">
              {booking.number}
            </OrderDetail>) : null}

            {booking.invoice_url ? (<OrderDetail title="Invoice">
              <a href={booking.invoice_url}><i class="fa fa-download" aria-hidden="true"></i>
                Download</a>
            </OrderDetail>) : null}

            {booking.itinerary ? (
              <OrderDetail title="Ship">
                {booking.itinerary.ship.name}
              </OrderDetail>
            ) : (
              <OrderDetail title="Item">
                {booking.route
                  ? "Flexi Date Certificate"
                  : "Flexi Cruise Certificate"}
              </OrderDetail>
            )}

            <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-4">
              <div>
                <OrderDetail title="Departure">
                  {departure}
                  <br></br>
                  {moment(departureTime).format("ddd, D MMM YYYY, h:mm a")}
                </OrderDetail>
              </div>
              <div>
                <OrderDetail title="Arrival">
                  {arrival}
                  <br></br>
                  {moment(arrivalTime).format("ddd, D MMM YYYY, h:mm a")}
                </OrderDetail>
              </div>
            </div>
            {Object.keys(bookingArr)
              .map((k, index) =>

                <OrderDetail title={`Cabin ${index + 1}`}>
                  {bookingArr[index].selected.name}<br />
                  Deck No: {((bookingArr[index].room_number).toString()).charAt(0)}<br />
                  Room No: {bookingArr[index].room_number}<br />
                  {bookingArr[index].adults + bookingArr[index].children + bookingArr[index].infants}  Guest{(bookingArr[index].adults + bookingArr[index].children + bookingArr[index].infants) > 1 ? "s" : null}
                </OrderDetail>
              )}
            {/* <OrderDetail title="Itinerary">
            {Object.keys(selected)
              .map((k) => `${selected[k]} x ${k}`)
              .join(", ")}
            , {guests} Guest{guests > 1 ? "s" : null}
          </OrderDetail> */}
            {/* <OrderDetail title="Total amount payable" big>
            &#x20B9; {Math.round(totalPrice.toLocaleString("hi-IN"))}
          </OrderDetail> */}
          </Tile.Inner>
        </Tile>

        {enable_tcs &&
          <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg">
            <Tile.Inner className="pb-0">
              <>
                <div className=''>
                  <div className="border border-gray-400 shadow-allSide rounded-lg mb-4">
                    <div className='grid grid-cols-2'>
                      <div onClick={() => {
                        setTaxType('tcs')
                        setTaxRegime('new_regime')
                        setGst(false)
                      }} className={`py-3 lg:py-6 text-center cursor-pointer ${taxType == 'tcs' ? 'text-j-magenta border-b border-j-magenta' : 'bg-gray-200 text-gray-100'}`}>
                        <p className='text-sm lg:text-xl font-semibold lg:font-bold'>TCS (Tax Collected at Source)</p>
                      </div>
                      <div onClick={() => {
                        setTaxType('tds')
                        setTaxRegime('new_regime')
                        setGst(true)
                      }} className={`py-3 lg:py-6 text-center cursor-pointer ${taxType == 'tds' ? 'text-j-magenta border-b border-j-magenta' : 'bg-gray-200 text-gray-100'}`}>
                        <p className='text-sm lg:text-xl font-semibold lg:font-bold'>TDS (Tax Deducted at Source)</p>
                      </div>
                    </div>

                    {taxType == 'tcs' ?
                      <div>
                        <div className='px-4 lg:px-6 py-4 lg:py-6 border-t border-gray-300'>
                          <div className={`flex items-center ${isAbove7L ? 'cursor-not-allowed text-gray-100' : 'cursor-pointer'} my-4`}
                            onClick={() => {
                              if (!isAbove7L) {
                                setTravelExpense(!travelExpense);
                              }
                            }}
                          >
                            <input className={`text-j-magenta ${isAbove7L ? 'cursor-not-allowed text-gray-100' : 'cursor-pointer'} mr-2`} disabled={isAbove7L ? true : false} type="radio" name='travel_expense' checked={travelExpense} />
                            <p className='text-sm lg:text-lg font-semibold'>If Travel Expenses less than 7 Lakhs?</p>
                          </div>
                          <p className='text-gray-100 lg:pr-10 leading-6 lg:leading-7 text-xs lg:text-base'>I declare that total cumulative expense incurred by me on the overseas tour package in the current financial year is less than 7 lakhs.</p>
                          <p className='text-black lg:pr-10 leading-6 lg:leading-7 text-xs lg:text-base mt-4'><span className='font-semibold'>Note:  </span> If your Current Financial Year's Travel Expense exceeds 7 Lakhs then a <span className={`${travelExpense ? 'text-gray-700' : 'text-j-magenta'} lg:pr-10 leading-7 lg:leading-7 text-xs lg:text-base font-semibold`}>20% TCS (Tax Collected at Source) will be levied on the Total</span></p>
                        </div>
                        <div className='px-4 lg:px-6'>
                          {knowMoreTcs ? (
                            <div className="bg-gray-200 rounded-lg mb-4 grid gap-4 lg:gap-2 grid-cols-2 px-6 py-6">
                              <div className="flex col-span-3 lg:col-span-1">
                                <img
                                  src="https://images.cordeliacruises.com/cordelia_v2/public/assets/tax-charge.svg"
                                  className="h-4 lg:h-4 mr-2 mt-0"
                                  alt="Cruise"
                                />
                                <div className='lg:pr-5'>
                                  <p className='text-sm lg:text-[1rem] font-semibold leading-4'>Tax Charges</p>
                                  <p className='text-xs lg:text-sm text-gray-100 mt-3'> Tax collection at the source is mandatory as per government regulations</p>
                                </div>
                              </div>
                              <div className="flex col-span-3 lg:col-span-1 pb-3">
                                <img
                                  src="https://images.cordeliacruises.com/cordelia_v2/public/assets/claim-credit.svg"
                                  className="h-4 lg:h-4 mr-2 mt-0"
                                  alt="Cruise"
                                />
                                <div>
                                  <p className='text-sm lg:text-[1rem] font-semibold leading-4'>Claiming your credit</p>
                                  <p className='text-xs lg:text-sm text-gray-100 mt-3'>TCS collected is claimable as a credit against the tax payable during return filing. <span className='text-gray-100 mt-1 font-semibold'>(Applicable for Indians Only)</span></p>
                                </div>
                              </div>
                              <div className="flex col-span-3 lg:col-span-2 lg:border-t border-gray-300 lg:pt-6">
                                <img
                                  src="https://images.cordeliacruises.com/cordelia_v2/public/assets/tcs-collect.svg"
                                  className="h-4 lg:h-4 mr-2 mt-0"
                                  alt="Cruise"
                                />
                                <div className='lg:pr-6'>
                                  <p className='text-sm lg:text-[1rem] font-semibold leading-4'>TCS is collected via Cordelia Cruise</p>
                                  <p className='text-xs lg:text-sm text-gray-100 mt-3'>TCS credits reflect quarterly in Form 26AS, detailing deductions, collections, and payments, including seller-collected TCS. These updates aid in tracking and verifying tax statements.</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <p onClick={() => setKnowMoreTcs(!knowMoreTcs)} className='text-sm lg:text-base text-primary underline cursor-pointer inline'>{knowMoreTcs ? 'View Less' : 'Know More'}</p>
                        </div>
                      </div>
                      : null
                    }

                    <div className=' px-4 lg:px-6 py-4 lg:py-6'>
                      <p className='text-base lg:text-xl font-semibold'>Select Tax Regime</p>
                      <div className='flex mt-4'>
                        <div className={`flex items-center ${'old_regime' == taxRegime ? 'border border-j-magenta text-j-magenta' : 'bg-gray-200'}  rounded-lg cursor-pointer px-2 lg:px-6 py-3 mr-2 lg:mr-4`} onClick={() => setTaxRegime('old_regime')}>
                          <input className='text-j-magenta mr-2' type="radio" name='tax_regime' checked={'old_regime' == taxRegime ? true : false} />
                          <p className='text-sm lg:text-lg font-semibold'>Old Tax Regime</p>
                        </div>
                        <div className={`flex items-center ${'new_regime' == taxRegime ? 'border border-j-magenta text-j-magenta' : 'bg-gray-200'}  rounded-lg cursor-pointer px-2 lg:px-6 py-3`} onClick={() => setTaxRegime('new_regime')}>
                          <input className='text-j-magenta mr-2' type="radio" name='tax_regime' checked={'new_regime' == taxRegime ? true : false} />
                          <p className='text-sm lg:text-lg font-semibold'>New Tax Regime</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Tile.Inner>
          </Tile>
        }

        <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg">
          <Tile.Inner className="pb-0">
            {paymentPage && <>
              <div
                style={{ color: '#500E4B', fontWeight: 'bold' }}
                className="grid grid-cols-1 grid-cols-2 mt-4"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <span className="pt-1"> PRICE DETAILS</span>
                <i
                  style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                  className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                    "fa-angle-down": !open,
                    "fa-angle-up": open,
                  })}
                ></i>
              </div>
              {open && (
                <>
                  {bookingArr.map((booking, index) =>
                    <div key={index}>
                      <div style={{ color: "black", marginTop: '25' }}>
                        {booking.selected.price.individual.map((price, index) =>
                          <div key={index}>
                            <div style={{ width: '100%' }} className="mt-4">
                              <PaymentDetails content={price.type} details={returnFormattedCurrency(Math.round((price.total) * 100 / 100))} big={true} currancyStatus={currancyStatus} />
                              <PaymentDetails content="Cabin Fare:" details={returnFormattedCurrency(Math.round((price.fare) * 100 / 100))} big={false} currancyStatus={currancyStatus} />
                              {price.discount != '0' && <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
                                <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">Individual Discount {price?.discount_text ? <span className='text-xs'>{price?.discount_text}</span> : null}</div>
                                <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
                                  - {currancyStatus.symbol}{returnFormattedCurrency(price.discount)}
                                </div>
                              </div>}
                              <PaymentDetails content="Service Charges & Levies" details={returnFormattedCurrency(price.portCharges + price.gratuity)} big={false} />
                              <PaymentDetails content="Fuel Surcharge" details={returnFormattedCurrency(price.fuelSurcharge)} big={false} />
                              {/* <PaymentDetails content="Port Charges" details={returnFormattedCurrency(price.portCharges)} big={false} />
                            <PaymentDetails content="Gratuity:" details={returnFormattedCurrency(price.gratuity)} big={false} /> */}
                              {shoreExcursionNew && shoreExcursion && price.type !== 'INFANT' && price.type === 'ADULT' &&
                                <>
                                  {shoreExcursion.map((data) => {
                                    return (<PaymentDetails
                                      content={`Shore-excusrion: ${data.title}`}
                                      details={(data.guests.find(guest => (guest.type === 'ADULT')).amount)}
                                      big={false}
                                    />)
                                  })}
                                </>
                              }
                              {shoreExcursionNew && shoreExcursion && price.type !== 'INFANT' && price.type === 'CHILD' &&
                                <>
                                  {shoreExcursion.map((data) => {
                                    return (<PaymentDetails
                                      content={`Shore-excusrion: ${data.title}`}
                                      details={(data.guests.find(guest => (guest.type === 'CHILD')).amount)}
                                      big={false}
                                    />)
                                  })}
                                </>
                              }
                              {parseFloat(price.insurance) > 0 && <PaymentDetails
                                content="Insurance:"
                                details={returnFormattedCurrency(price.insurance)}
                                big={true}
                              />}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {bookingLocalStorage.agent_commission_type == "p2p" && <div style={{ marginTop: '15px' }}>
                    <PaymentDetails content="Discount:" details={returnFormattedCurrency(bookingLocalStorage.agent_commission)} big={true} currancyStatus={currancyStatus} />
                  </div>}
                </>
              )}
              {/* {open && <div style={{marginTop:'25'}}>
                  <PaymentDetails content="Promo Code:" details="0" big={true} currancyStatus={currancyStatus}/>
                  <PaymentDetails content="New Year Promo:" details="0" big={false} currancyStatus={currancyStatus}/>
                </div>
                } */}


              {open && <div style={{ marginTop: '25' }}>
                <PaymentDetails content="Sub-Total:" details={returnFormattedCurrency(Math.round(bookingLocalStorage.total - bookingLocalStorage.gst - bookingLocalStorage.shore_excursion_gst))} big={true} />
                <PaymentDetails content="Taxable Sub Total:" details={returnFormattedCurrency(Math.round(bookingLocalStorage.total - bookingLocalStorage.gst - bookingLocalStorage.shore_excursion_gst))} big={false} />
              </div>
              }
              {open && <div style={{ marginTop: '25' }}>
                <PaymentDetails content="Taxes:" details={returnFormattedCurrency(Math.round(bookingLocalStorage.gst+bookigStore.shore_excursion_gst))} big={true} />
                <PaymentDetails content="GST:" details={returnFormattedCurrency(Math.round(bookingLocalStorage.gst))} big={false} />
                <PaymentDetails
                    content="Shore Excursion GST:"
                    details={returnFormattedCurrency(Math.round(bookigStore.shore_excursion_gst))}
                    big={false}
                  />
              </div>
              }
              {open && tcsObject && tcsObject?.amount ? <div className='mt-3' style={{ marginTop: '25' }}>
                <PaymentDetails content="TCS:" details={returnFormattedCurrency(Math.round(tcsObject.amount))} big={true} />
              </div>
              :null}

              {/* {open && (
                <div style={{ marginTop: "15px" }}>
                  <PaymentDetails
                    content="Shore Excursion:"
                    details={returnFormattedCurrency(Math.round(bookigStore.shore_excursion_total))}
                    big={true}
                  />
                  <PaymentDetails
                    content="Net Amount:"
                    details={returnFormattedCurrency(Math.round(bookigStore.shore_excursion_net_total))}
                    big={false}
                  />
                  <PaymentDetails
                    content="Tax:"
                    details={returnFormattedCurrency(Math.round(bookigStore.shore_excursion_gst))}
                    big={false}
                  />
                </div>
              )} */}

              {/* {
                  safetyCodeStatus ?
                  <div style={{ marginTop: "15px" }}>
                    <PaymentDetails
                      content="Safety Plan:"
                      // details={returnFormattedCurrency(Math.round(bookigStore.shore_excursion_total))}
                      details={returnFormattedCurrency(Math.round(safetyCodeAmount*bookingArr.length))}
                      big={true}
                    />
                  </div>
                  : ``
              } */}


              <div style={{ marginTop: '15px' }}>
                {booking?.itinerary?.discount_pct != 0 ? <p className='text-right text-sm line-through'>&#x20B9;{bookigStore?.actual_gross_fare + (tcsObject?.amount || 0)}</p> : null}
                <PaymentDetails content="Gross Fare:" details={returnFormattedCurrency(parseInt(bookigStore.gross_fare + (tcsObject?.amount || 0)))} big={true} currancyStatus={currancyStatus} />
              </div>

              {bookingLocalStorage.coupon_code && <div style={{ marginTop: '15px' }}>
                <PaymentDetails content={`Applied Coupon (${bookingLocalStorage.coupon_code}):`} details={returnFormattedCurrency(bookingLocalStorage.coupon_discount)} big={true} />
              </div>}

              {/* {bookingLocalStorage.agent_commission_type == "p2p" && <div style={{marginTop:'15px'}}>
                <PaymentDetails content="Discount:" details={returnFormattedCurrency(bookingLocalStorage.agent_commission)} big={true} currancyStatus={currancyStatus}/>
              </div>} */}

              <div style={{ marginTop: '15px' }}>
                
                {bookigStore?.is_holded ? null : booking?.itinerary?.discount_pct != 0 ? <p className='text-right text-sm line-through'>&#x20B9;{returnFormattedCurrency(Math.round(actualTotalValue)+(tcsObject?.amount || 0))}</p> : null}
                <PaymentDetails content="Net Payable to Cordelia:" details={returnFormattedCurrency(bookigStore?.is_holded ? Math.round(totalPrice) : Math.round(totalPrice)+(tcsObject?.amount || 0))} big={true} currancyStatus={currancyStatus} />
              </div>
            </>}

            {/* <div className="grid justify-end mb-4">
                <a onClick={e => modalOpen(e)} className="cursor-pointer"><i className="fas fa-share"></i> Share</a>
              </div> */}
          </Tile.Inner>
        </Tile>

        <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg">
          <Tile.Inner className="pb-0 pt-0">
            {bookingLocalStorage.agent_commission_type == "p2a" && <>
              <div
                style={{ color: '#500E4B', fontWeight: 'bold', marginTop: '15px' }}
                className="grid grid-cols-1 grid-cols-2"
                onClick={() => {
                  setCommissionOpen(!commissionOpen);
                }}
              >
                <span className="pt-1"> COMMISSION DETAILS</span>
                <i
                  style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                  className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                    "fa-angle-down": !commissionOpen,
                    "fa-angle-up": commissionOpen,
                  })}
                ></i>
              </div>
              {commissionOpen && <div style={{ marginTop: '15px' }}>
                <PaymentDetails content="Commission:" details={returnFormattedCurrency(Math.round(bookingLocalStorage.agent_commission))} big={true} currancyStatus={currancyStatus} />
              </div>
              }
            </>
            }
            <div
              style={{
                color: "#500E4B",
                fontWeight: "bold",
                marginTop: '15px'
              }}
              className="grid grid-cols-1 grid-cols-2 pb-4"
              onClick={() => {
                setFareOpen(!fareOpen);
              }}
            >
              <span className="pt-1">Cancellation and Reschedule policy</span>
              <i
                style={{ color: "grey", fontSize: 28, textAlign: "right" }}
                className={cx(
                  "fas cursor-pointer text-j-gray-light text-4xl",
                  {
                    "fa-angle-down": !fareOpen,
                    "fa-angle-up": fareOpen,
                  }
                )}
              ></i>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "18px",
                color: "#500E4B",
                fontWeight: "bold",
              }}
              className="grid grid-cols-1"
            >
              <a href="https://images.cordeliacruises.com/static/insurance_web_document.pdf" target="_blank" className="text-j-magenta font-bold">
                Cordelia Cruises Insurance Benefit Document
              </a>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "18px",
                color: "#500E4B",
                fontWeight: "bold",
              }}
              className="grid grid-cols-1"
            >
              <a href="https://images.cordeliacruises.com/static/OTHER_ADDITIONAL_CHARGES.pdf" target="_blank" className="text-j-magenta font-bold">
                Other Additional Charges
              </a>
            </div>
            {fareOpen && (
              <div style={{ padding: "1rem" }}>
                {cancellationDiv}
                {rescheduleDiv}
              </div>
            )}
          </Tile.Inner>
        </Tile>
      </div>
    </>
  );
};

export default SummaryView;

const OrderDetail = ({ title, children, big }) => (
  <div className="mb-6">
    <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none">
      {title}
    </h1>
    <h4
      className={
        big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2 text-lg font-semibold"
      }
    >
      {children}
    </h4>
  </div>
);

const PaymentDetails = ({ content, details, big, currancyStatus }) => (
  <>
    <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
      {big && <>
        <div style={{ color: "black", fontSize: 16, fontWeight: 'bold' }} className="float-left bold">
          {content}
        </div>
        <div style={{ color: "black", fontSize: 16, textAlign: 'right', fontWeight: 'bold' }} className="float-right font-weight-bold text-capitalize">
          &#x20B9;{details}
        </div>
      </>
      }
      {!big && <>
        <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">{content}</div>
        <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
          &#x20B9;{details}
        </div>
      </>
      }
    </div>
  </>
);


