import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import Tile from "../../components/Tile/Tile";
import CardPaymentForm from "../../components/Booking/payment-forms/CardPaymentForm";
import BankPaymentForm from "../../components/Booking/payment-forms/BankPaymentForm";
import UPIPaymentForm from "../../components/Booking/payment-forms/UPIPaymentForm";
import WalletPayment from "../../components/Booking/payment-forms/WalletPayment";
import RadioField from "../../components/Form/RadioField";
import scrollTo from "../../utils/scrollTo";
import UserContext from "../../store/UserContext";
import moment from "moment";
import {
    useHistory,
    withRouter,
    useLocation
  } from "react-router-dom";
  import { getToken } from "../../utils/common";
  import LoadingIcon from "../../components/Loading/LoadingIcon";
import NimbblPaymentForm from '../../components/Booking/payment-forms/NimbblPaymentForm';

class PaymentDue extends Component {
    documentData;
    constructor(props) {
        super(props);
        this.state = {
            booking:[],
            selection: []
        }
    }

    handleSubmit = () => {

    }

    render() {
        return(
            <>
                <SummaryViewUI />
            </>
        )
    }
}

export default withRouter(PaymentDue);

const SummaryViewUI = ({ }) => {
    const [user, setUser] = React.useContext(UserContext);
    const bookingData = user.bookingID || {};
    const [renderView, setRenderView] = useState(false)
    const [isFailed, setFailed] = useState(
        new URLSearchParams(window.location.search).get("fail")
    );
    const [amountStatus, setAmount] = useState(0);
    const [bookingDeatils, setBookingDetails] = useState()
    let history = useHistory();
    useEffect(() => {
        setRenderView(false)
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/balance`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
            `Bearer ${getToken()}`,
        },
        })
        .then((response) => response.json())
        .then((response) => {
                setAmount(response.amount) 
        })

        var booking_id = new URLSearchParams(window.location.search).get("id");

        const bokkingIdSend = booking_id == undefined ?bookingData.id : booking_id

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${bokkingIdSend}/details`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                `Bearer ${getToken()}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                if(response.message == 'Invalid booking or unauthorized') {
                    alert('Invalid booking or unauthorized or Booking Not Found in your Account')
                    history.push('/admin/dashboard')
                  } else {
                   console.log(response)
                   setBookingDetails(response.booking)
                   setRenderView(true)
                  }
        })
    },[])
    return (
        <>
            <h1 className="text-xl font-bold pt-4 mr-20 ml-20"  id="payment">{isFailed ? "Request failed!" : "Payment"}</h1>
            {isFailed ? (
                <div className="bg-j-yellow text-j-black rounded-big p-3 my-4 mr-20 ml-20">
                    <h1 className="text-base font-medium pb-0">
                        <i className="fas fa-exclamation-triangle text-2xl pr-1" />
                        Payment failed. Please try again!
                    </h1>
                    <p className="text-xs leading-relaxed">
                        Somehow, we couldn't receive your payment. Any amount deducted from
                        your account will be refunded in 7 working days.
                    </p>
                </div>
            ) : null}
            {!renderView &&  <>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </>}
            {renderView && 
            <>
            {bookingDeatils.status == 'RESERVED' && <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-6 pt-0 mt-4 mr-20 ml-20">
                <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta">
                    <Tile.Inner className="pb-0">
                        <OrderDetail title="Booking Reference no">
                            {bookingDeatils.number}
                        </OrderDetail>
                        <OrderDetail title="Ship">
                            {bookingDeatils.itinerary.name}
                        </OrderDetail>
                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <OrderDetail title="Departure">
                                    {moment(bookingDeatils.itinerary.start_time).format("llll")}
                                </OrderDetail>
                                </div>
                                <div>
                                <OrderDetail title="Arrival">
                                    {moment(bookingDeatils.itinerary.end_time).format("llll")}
                                </OrderDetail>
                            </div>
                        </div>
                        <OrderDetail title="Status">
                            {bookingDeatils.status}
                        </OrderDetail>
                        <OrderDetail title="Total Amount">
                            &#x20B9;{Math.round(parseInt(bookingDeatils.total_amnt))}
                        </OrderDetail>
                        <OrderDetail title="Amount Paid">
                            &#x20B9;{Math.round(parseInt(bookingDeatils.total_amnt)-bookingDeatils.pending_amount)}
                        </OrderDetail>
                        <OrderDetail title="Balance amount">
                            &#x20B9;{Math.round(bookingDeatils.pending_amount)}
                        </OrderDetail>
                    </Tile.Inner>
                </Tile>
                <div>
                        <ConformBooking bookingData={bookingDeatils} amountStatus={amountStatus}/>
                </div>
            </div>}
            {bookingDeatils.status != 'RESERVED' &&
                <><p className="text-center text-4xl text-gray-400 pt-10 pb-10">No booking Found</p></>
            }
            </>}
        </>
    )
}

const ConformBooking = ({bookingData, amountStatus}) => {
    const submitRef = useRef(null);
    const [amount, setAmount] = useState(bookingData.pending_amount)
    const [bookingID, setBookingID] = useState(bookingData.id)
    const [isFailed, setFailed] = useState(
        new URLSearchParams(window.location.search).get("fail")
    );

    var userProfile= JSON.parse(localStorage.getItem('UserProfile'));

    const [backButtonstatus, setBackButtonstatus] = useState(true)

    const setFailedError = (error) => {
        setFailed(error);
        if (error) scrollTo("payment");
    };

    const handleWalletPayNow = () => {
        setBackButtonstatus(false)
    }

    const handleWalletPayNowTrue = () => {
        setBackButtonstatus(true)
    }

    return(
        <>
            <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta" >
                <Tile.Inner className="pb-0">
                <span className="text-j-gray" style={{width: "500px"}}>
                            <RadioField
                                name="paymentOption"
                                defaultValue="bank"
                                paymentStatus= {true}
                                handleWalletPayNowTrue={handleWalletPayNowTrue}
                                options={[
                                    {
                                        value: "mimbbl",
                                        label: "Pay Using Payment Gateway",
                                        view: (
                                            <NimbblPaymentForm
                                            bookingID={bookingID}
                                            billingData={userProfile}
                                            paymentOptionID={null}
                                            />
                                        ),
                                    },
                                      {
                                        value: "wallet",
                                        label: "Cordelia Wallet",
                                        view: (
                                          <WalletPayment
                                            booking=""
                                            bookingID={bookingID}
                                            amount={amount}
                                            paymentPath="wallet"
                                            billingData=""
                                            partialStatus= {false}
                                            amountStatus={amountStatus}
                                            submitRef={submitRef}
                                            setFailed={setFailedError}
                                            walletStatus={false}
                                            totalPrice={bookingData.pending_amount}
                                            bookingType="due_amount"
                                            handleWalletPayNow={handleWalletPayNow}
                                          />
                                        ),
                                      },
                                ]}
                            />
                        </span>
                </Tile.Inner>
            </Tile>
        </>
    )
}

const OrderDetail = ({ title, children, big }) => (
    <div className="mb-6">
      <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none">
        {title}
      </h1>
      <h4
        className={
          big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2 text-lg font-semibold"
        }
      >
        {children}
      </h4>
    </div>
  );