import React from "react";
import moment from "moment";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import Button from "../../Button/Button";
import Tile from "../../Tile/Tile";

export const ProductCard = (({ booking }) => {
  const title = `Selected ${booking.itinerary ? "cruise" : "certificate"}`;
  let history = useHistory();
  const onChange = () =>
    history.push("/admin/routes");

  return (
    <div className="grid-cols-1 md:grid-cols-3 gap-4">
    <Tile shadow className="mb-2 rounded-lg">
        <Tile.Inner
          className="rounded-lg flex justify-between bg-magenta text-white"
          theme="magenta"
          tiny
        >
          <p className="text-xs leading-none uppercase self-center">{title}</p>
          <Button
            className="border border-j-white bg-j-orange text-j-white"
            onClick={onChange}
          >
            Change
          </Button>
        </Tile.Inner>
    </Tile>
          
    </div>
  );
});

export default ProductCard;


