import React, { Component, useContext, useState, useEffect } from 'react';
import BookingFormView from "./General/BookingFormView";
import Tile from "../Tile/Tile";
import Button from "../Button/Button";
import cx from "classnames";
import InputField from "../Form/InputFieldHold";
import SelectField from "../Form/SelectField";
import Checkbox from "../Form/CheckBox";
import CountryStates from "../../public/country-states.json";
import CountryCodes from "../../public/country-phone";
import PhoneField from "../Form/PhoneFieldAlt";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { name, phone, email, date, gender, first_name, last_name, checkbox } from "../../utils/validations";
import UserContext from "../../store/UserContext";
import moment from "moment";
import { getToken } from "../../utils/common";
import { Link, useHistory } from "react-router-dom";
import StateCities from "../../public/state-cities.json"


class GuestDetailsView extends Component {
    documentData;
    constructor(props) {
        super(props);
        this.state = {
            booking:[],
            selection: []
        }
    }

    render() {
        return(
            <>
                {/* <div className="grid grid-cols-3 border-b p-6">
                    <div>
                        <button onClick={this.backbuttonpressed}>
                            Back
                        </button>
                    </div>
                    <div className="text-center text-basecolor font-bold">Title of cruise trip</div>
                </div> */}
                <GuestDetailsSubView nextStep={this.props}/>
            </>
        )
    }
}

export default GuestDetailsView;

const GuestDetailsSubView = ({nextStep}) => {
  let history = useHistory();
  const [user, setUser] = useContext(UserContext);
  const [showUpsell, setShowUpsell] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [renderView, setRenderView] = useState(false)
  const [tempData, settempData] = useState(eval(sessionStorage.getItem('tempData')))
  const booking = user.booking || {};
  const rooms = nextStep.bookingDetails.rooms;
  const joiObject = {};

  console.log('gfdjf', rooms)

  useEffect(() => {
    // if(user.success_status) {
    //     history.push('/admin/dashboard')
    // } else {
        setRenderView(true)
    // }
  },[])
  const referenceDate = booking.itinerary
    ? new Date(booking.itinerary.startTime)
    : new Date();

  const adultMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 12 * 12)
  );

  const firstAdultMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 18 * 12)
  );

  const childMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 2 * 12)
  );
  const infantMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 6)
  );

  rooms.forEach((room, roomIndex) => {
    const count = room.guests.length
    var lastCount = room.guests.length
    for (let guestIndex = 0; guestIndex < count ; guestIndex++) {
      const guestType = room.guests[guestIndex].type;
      console.log(guestType)
      const prefix = `${roomIndex}_${guestIndex}`;
      joiObject[`${prefix}_first_name`] = first_name;
      joiObject[`${prefix}_last_name`] = last_name;
      joiObject[`${prefix}_meal`] = name;
      joiObject[`${prefix}_gender`] = gender;
      joiObject[`${prefix}_dob`] =
        guestType === "ADULT"
          ?  (guestIndex===0?date.max(firstAdultMaxDate):date.max(adultMaxDate))
          : guestType === "CHILD"
            ? date.max(childMaxDate).min(adultMaxDate)
            : date.max(infantMaxDate).min(childMaxDate);
      joiObject[`${prefix}_country`] = name;
      joiObject[`${prefix}_state`] = name;
      joiObject[`${prefix}_city`] = name;
      joiObject[`${prefix}_nri`] = checkbox;
      if (guestType === "ADULT") {
        joiObject[`${prefix}_phone`] = phone;
        joiObject[`${prefix}_email`] = email;
      }

      lastCount--;
    }
  });

  const { register, errors, handleSubmit, setError } = useForm({
    resolver: joiResolver(Joi.object(joiObject)),
  });

  function createBookingApi(requestData)
  {
      return fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/hold_bookings/${nextStep.bookingDetails.id}/add_guests`, {
          method: 'PUT',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
            `Bearer ${getToken()}`,
        },
          body: JSON.stringify(requestData)
      }).then(response => response.json())
      .then(response => {
            console.log(response, '---response')
            setSubmitted(false);
            var setPartialPaymentDetails = {
                due_date: response.due_date,
                partial_payment_rule: response.partial_amount_rule,
                partial_payment_amount: response.partial_payable_amount,
                payment_id: response.payment_option_id
            }
            sessionStorage.setItem('hold-booking-partialPayment', JSON.stringify(setPartialPaymentDetails))
            sessionStorage.setItem('hold-booking-store',JSON.stringify(nextStep.bookingDetails))
            history.push('/admin/complete-hold-booking-payment')

    }).catch(err => err);
  }

  const onSubmit = (data) => {
    setSubmitted(true);
    console.log(data);
    const newRooms = [...rooms].map((room) =>
      Object.assign({}, room, {
        guests: [...(room.guests || [])].map((guest) =>
          Object.assign({}, guest)
        ),
      })
    );
    Object.keys(data).forEach((key) => {
      const [roomIndex, guestIndex, field] = key.split("_");
      const guest =
        newRooms[parseInt(roomIndex)].guests[parseInt(guestIndex)] || {};
        newRooms[parseInt(roomIndex)].guests[
        parseInt(guestIndex)
      ] = Object.assign({}, guest, { [field]: data[key] });
    });




    let bookingInput = {}
    bookingInput.rooms = newRooms.map((room) => ({
      id: room.id,
      guests: room.guests.map((guest, i) => ({
        id: guest.id,
        name: guest.first,
        first_name: guest.first,
        last_name: guest.last,
        type: guest.type,
        discountCategory: null,
          //i < room.adults ? getDiscountCategory(room.discounts[i]) : null, // TODO: what values? It can be pulled from room.discounts
        email: guest.email,
        phoneNumber: guest.phone,
        meal: guest.meal,
        idType: guest.doc,
        idNumber: guest.passNumber,
        gender: guest.gender.toUpperCase(),
        dateOfBirth: moment(guest.dob).format("YYYY-MM-DD"),
        country: guest.country,
        state: guest.state,
        city: guest.city,
        nri: guest.nri,
        citizenship: guest.country,
      })),
    }));

    console.log(bookingInput)

    const apiBody = {
        booking: {
            rooms: bookingInput.rooms
        }
    }

    createBookingApi(apiBody)
    // setUser(
    //   Object.assign({}, user, {
    //     booking: Object.assign({}, booking, {
    //       guestsFilled: true,
    //     }),
    //   })
    // );
  };

  //  const handleSubmit = (e) => {
  //    e.preventDefault()
  //   nextStep.nextStep.nextStep()
  //  }

  const backbuttonpressed = (e) => {
    e.preventDefault()
    nextStep.nextStep.prevStep()
  }

  return (
      <>
         {renderView && <><BookingFormView
          title="Now let's add the details of the guests."
          buttonText="Continue"
          onClick={handleSubmit(onSubmit)}
          lessSpace={true}
          id="guest-info-form"
          disabled={submitted}
          loading={submitted}
        >
          {/* <p className="text-j-gray">
            To save your reservation, we need the details of each traveler per
            stateroom. Remember, first and last names must match what appears on
            government-issued photo IDs.
          </p> */}
          <form onSubmit={handleSubmit(onSubmit)}>
              {rooms.map((r, i) => (
              <RoomCard
                  key={i}
                  room={r}
                  roomIndex={i}
                  errors={errors}
                  register={register}
                //   tempData={tempData != null ? tempData[i]: null}
              />
              ))}
          </form>
          {!!Object.keys(errors).length && (
            <p className="text-j-red pt-4 flex">
              <i className="fas fa-exclamation-triangle text-2xl px-4 self-center" />
              <span className="self-center">
                Please enter all guests' details correctly before continuing.
              </span>
            </p>
          )}
        </BookingFormView></>}
      </>
      )
}

const RoomCard = ({ room, roomIndex, errors, register, tempData }) => {

    const count = room.guests.length
    const guests = [...(room.guests || Array(count).fill({}))];
    // const [open, setOpen] = useState(roomIndex === 0);

    if (guests.length < count)
      guests.push(...Array(count - guests.length).fill({}));
      guests.splice(count);

    // if (roomIndex == 0 && !guests[0].name) {
    //   guests[0].name = user.name || user.booking.name
    //   guests[0].phone = user.phone || user.booking.phone
    // }

    useEffect(() => {
      if (
        !!Object.keys(errors).filter((key) => key.startsWith(`${roomIndex}_`))
          .length
      ) {
        // setOpen(true);
      }
    }, [errors]);


    const guestForms = guests.map((guest, i) => (
        <GuestForm
          // className={!open && "hidden"}
          guestType={guest.type}
          key={i}
          index={i}
          roomIndex={roomIndex}
          guest={guest}
          errors={errors}
          register={register}
        //   tempData={tempData !=null ? tempData.guests[i]: null}
        />
      ));

    return (
        <Tile theme="magenta" shadow className="my-5 rounded-lg" id={`room-${room.id}`}>
          <Tile.Inner>
          {roomIndex == 0 && <p className="text-j-white pb-4">
            To save your reservation, we need the details of each traveler per
            stateroom. Remember, first and last names must match what appears on
            government-issued photo IDs.
          </p>}
            <h4
              className="uppercase pb-4 flex justify-between cursor-pointer"
            //   onClick={() => setOpen(!open)}
            >
              <span className="text-white">Cabin {roomIndex + 1}</span>
              {/* <i
                className={cx("fal text-j-orange text-4xl leading-4", {
                  "fa-angle-down": !open,
                  "fa-angle-up": open,
                })}
              /> */}
            </h4>
            <p className="text-white">
                {room.category}, {room.guests.length} Guests
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
              {guestForms}
            </div>
          </Tile.Inner>
        </Tile>
      );
}

const GuestForm = ({
    index,
    roomIndex,
    guest,
    guestType,
    errors,
    register,
    className,
    tempData
  }) => {
    // defaultValue={moment(guest.dob).format("YYYY-MM-DD")}
    const [type, setType] = useState("text");
    const [countryStatesArray, setCountryState] = useState(["Maharashtra", "Tamil Nadu", "Karnataka", "Kerala", "Andaman and Nicobar Islands", "Andhra Pradesh", "Telangana", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Lakshadweep", "Madhya Pradesh", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Orissa", "Pondicherry", "Punjab", "Rajasthan", "Sikkim", "Tripura", "Uttaranchal", "Uttar Pradesh", "West Bengal"]);
    const [country, setCountry] = useState("India");
    // const [phoneCode, setCountryCode] = useState("+91");
    const prefix = `${roomIndex}_${index}`;
    const [state,setState] = useState(guest.state)
    const [nri,setNri] = useState(tempData?tempData.nri?true:false:false);

    var DobDate = moment(guest.date_of_birth).format('yyyy-MM-DD');
    console.log(DobDate);

    return (
      <div className={cx("pt-12", className)}>
        <h4 className="text-white">
          Guest {index + 1}: {guestType}
        </h4>
        <div className="grid md:grid-cols-2 lg:grid-cols-2">
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div>
                <label className="pl-4">First name:</label>
                <InputField
                    icon="fal fa-user"
                    // placeholder="Full name"
                    name={`${prefix}_first_name`}
                    withIcon
                    lessSpace
                    defaultValue={guest.first_name != null ? guest.first_name : null}
                    ref={register({ required: true })}
                    inverseError
                    error={
                        errors && errors[`${prefix}_first_name`] && "Please enter a valid first name"
                    }
                />
              </div>
              <div>
                <label className="pl-4">Last name:</label>
                <InputField
                    icon="fal fa-user"
                    // placeholder="Full name"
                    name={`${prefix}_last_name`}
                    withIcon
                    lessSpace
                    defaultValue={guest.last_name != null ? guest.last_name : null}
                    ref={register({ required: true })}
                    inverseError
                    error={
                        errors && errors[`${prefix}_last_name`] && "Please enter a valid last name"
                    }
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-2">
              <div className="mt-4">
                <label className="pl-4">Date of Birth:</label>
                <InputField
                    icon="fal fa-calendar-alt"
                    type="date"
                    onFocus={() => setType("date")}
                    onBlur={() => setType("date")}
                    // placeholder="DOB: "
                    max={"9999-12-31"}
                    name={`${prefix}_dob`}
                    withIcon
                    lessSpace
                    ref={register({ required: true })}
                    inverseError
                    error={
                        errors &&
                        errors[`${prefix}_dob`] &&
                        `Please enter a valid date for ${guestType}`
                    }
                    defaultValue={DobDate}
                />
              </div>
              <div className="mt-4">
                <label className="pl-4">Gender:</label>
                <SelectField
                    icon="fal fa-utensils"
                    name={`${prefix}_gender`}
                    withIcon
                    lessSpace
                    defaultValue={guest.gender != null ?guest.gender : null}
                    placeholder="Gender"
                    ref={register({ required: true })}
                    inverseError
                    error={errors && errors[`${prefix}_gender`] && "Please select a gender"}
                    >
                    <option selected value="" disabled>Choose gender Type</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </SelectField>
              </div>
            </div>
            <div>
                <label className="pl-4">Country:</label>
                <SelectField
                    icon="fal fa-globe"
                    name={`${prefix}_country`}
                    withIcon
                    lessSpace
                    ref={register({ required: true })}
                    value={country}
                    onChange={(event) =>  {
                        let x = event.target.value;
                        setCountry(x);
                        setCountryState(CountryStates.countries.filter((country) => country.country === x)[0].states);
                        // setCountryCode(`+${CountryCodes.filter((country) => country.Name === x)[0].Dial}`);
                    }}
                    inverseError
                    error={errors && errors[`${prefix}_country`] && "Please choose"}
                    >
                    {
                        CountryStates.countries.map((country) => {
                        return  <option value={country.country} selected>{country.country}</option>
                        })
                    }
                  </SelectField>
            </div>
            <div>
                <label className="pl-4">State:</label>
                {guest.state != null &&  <SelectField
                    icon="fal fa-building"
                    name={`${prefix}_state`}
                    withIcon
                    lessSpace
                    defaultValue={guest.state != null ?  guest.state : "Choose a State"}
                    ref={register({ required: true })}
                    inverseError
                    onChange={(event) =>  {
                      let x = event.target.value;
                      setState(x);
                    }}
                    error={errors && errors[`${prefix}_state`] && "Please choose"}
                    >
                      <option selected value="" disabled>Choose a State</option>
                    {
                        countryStatesArray.map((states) => {
                        return  <option value={states}>{states}</option>
                        })
                    }
                </SelectField>}
                {guest.state == null &&  <SelectField
                    icon="fal fa-building"
                    name={`${prefix}_state`}
                    withIcon
                    lessSpace
                    ref={register({ required: true })}
                    inverseError
                    onChange={(event) =>  {
                      let x = event.target.value;
                      setState(x);
                    }}
                    error={errors && errors[`${prefix}_state`] && "Please choose"}
                    >
                      <option selected value="" disabled>Choose a State</option>
                    {
                        countryStatesArray.map((states) => {
                        return  <option value={states}>{states}</option>
                        })
                    }
                </SelectField>}
               
                </div>
                <div>
                <label className="pl-4">City:</label>
                <SelectField
                  icon="fal fa-building"
                  name={`${prefix}_city`}
                  withIcon
                  lessSpace
                  defaultValue={guest.city ? guest.city : "Choose a City"}
                  ref={register({ required: true })}
                  inverseError
                  error={errors && errors[`${prefix}_city`] && "Please choose city"}
                >
                  <option selected value="" disabled>Choose a City</option>
                  {state&&StateCities[state]?
                    (StateCities[state].map((city) => {
                    return  <option value={city}>{city}</option>
                    })):null
                  }
                </SelectField>
            </div>
            {console.log(tempData)}
            <div>
              <label className="pl-4">NRI:</label>
              <div className='ml-4 mt-2'>
                <Checkbox 
                  name={`${prefix}_nri`} 
                  id={`${prefix}_nri`}
                  ref={register()}
                  onChange={(event) =>  {
                    let x = event.target.value;
                    setNri(x);
                  }}
                  value={nri}
                  >
                    I am an NRI
                </Checkbox>
              </div>
            </div>
            {guestType === "ADULT" && (
                <div>
                  <label className="pl-4">Mobile Number:</label>
                  <PhoneField
                    name={`${prefix}_phone`}
                    placeholder="A 10-digit mobile number"
                    ref={register({ required: true })}
                    defaultValue={guest.phone != null ? guest.phone : null}
                    // countryPhoneCode={phoneCode}
                    lessSpace
                    inverseError
                    error={
                      errors &&
                      errors[`${prefix}_phone`] &&
                      "Please enter a correct phone number"
                    }
                  />
                </div>
            )}
             {guestType === "ADULT" && (
                  <div>
                    <label className="pl-4">Email:</label>
                    <InputField
                          icon="fal fa-envelope"
                          name={`${prefix}_email`}
                          placeholder="Email Address"
                          ref={register({ required: true })}
                          defaultValue={guest.email != null ? guest.email: null}
                          lessSpace
                          inverseError
                          error={
                              errors &&
                              errors[`${prefix}_email`] &&
                              "Please enter a valid email"
                          }
                      />
                  </div>
            )}
            <div className="grid md:grid-cols-2 lg:grid-cols-2">
              <div>
                <label className="pl-4">Meal:</label>
                <SelectField
                  icon="fal fa-utensils"
                  name={`${prefix}_meal`}
                  withIcon
                  lessSpace
                  defaultValue={guest.meal != null ? guest.meal : null}
                  placeholder="Choose Meal Type"
                  ref={register({ required: true })}
                  inverseError
                  error={
                    errors &&
                    errors[`${prefix}_meal`] &&
                    `Please Pick your Meal Preference`
                  }
                >
                  <option selected value="" disabled>Choose Meal Type</option>
                  <option value="Veg">Vegetarian</option>
                  <option value="Jain">Jain</option>
                  <option value="Non. Veg">Non - Vegetarian</option>
                  <option value="Gluten Free">Gluten Free</option>
                </SelectField>
              </div>
            </div>
        </div>
        <p className="text-j-orange pb-4 pl-4">
              Only double vaccinated guests to be allowed on board
        </p>
      </div>
    );
  };
