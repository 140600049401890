import React, { useState } from "react";
import { getToken } from "../../../utils/common";
import Button from "../../Button/Button";
import LoadingIcon from "../../Loading/LoadingIcon";

const NimbblPaymentForm = ({
  bookingID,
  billingData,
  bookingType,
  amount,
  partialStatus = false,
  paymentOptionID,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const fetchPGData = () => {
    const bookingInput = {
      amount: parseInt(amount, 10),
      contact: {
        first_name: billingData.first_name,
        last_name: billingData.last_name,
        email: billingData.email,
        phoneNumber: billingData.phone,
        gstin: billingData.gst,
        gst_name: billingData.gstin_name,
        address: billingData.address,
        pan: billingData.pan,
        pan_name: billingData.name,
        state: billingData.state,
        expense_above_7l: billingData.expense_above_7l,
        tax_regime: billingData.tax_regime,
        tds_opted: billingData.tds_opted,
      },
      paymentInfo: {
        plan: "",
        voucherNumber: "",
        promoCode: "",
        partial: partialStatus,
        first_name: billingData.first_name,
        last_name: billingData.last_name,
        email: billingData.email,
      },
      ...(partialStatus&&{payment_option_id: paymentOptionID})
    };
    const _payload = {
      variables: { input: bookingInput },
      booking_id: bookingID,
      ...(bookingType === "postShorex"&&{payment_for: "shore_excursions"})
    };

    var API_ENDPOINT = "payments";
    if (bookingType === "recharge") {
      API_ENDPOINT = "wallet/recharge";
    } else if (bookingType === "hold") {
      window.localStorage.setItem('holdBookingStatus', true)
      API_ENDPOINT = '/payments/hold_booking';
    } else if (bookingType === "postShorex") {
      localStorage.setItem("shoreExbookingID",bookingID);
      API_ENDPOINT = '/payments/repay_due_amount';
    }

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/agent_portal/${API_ENDPOINT}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(_payload),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        localStorage.setItem("bookingID", bookingID);
        nimbblCheckout(response.pg_data);
      })
      .catch((err) => err);
  };
  const nimbblCheckout = (pg_data) => {
    const order_id = "order_9RZvg4gqbdYbL3Yx";
    var options = pg_data;
    // options.access_key = "access_key_6EAvqrDj8Arr93PD";

    window.checkout = new window.NimbblCheckout(options);
    window.checkout.open(order_id);
  };
  return (
    <div className="mb-5">
      <Button
        className="bg-j-red-light text-white w-full mt-6"
        disabled={submitted}
        onClick={() => {
          setSubmitted(true);
          fetchPGData();
        }}
      >
        {submitted ? <LoadingIcon /> : "Proceed"}
      </Button>
    </div>
  );
};

export default NimbblPaymentForm;
