import React from "react"
import cx from "classnames"
import Overlay from "../../Overlay/Overlay"
import Button from "../../Button/Button"

const BookingOverlay = ({ children, onCancel, open, style = "back" }) => {
  const button =
    style === "back" ? (
      <i
        className="fas fa-arrow-left cursor-pointer text-3xl"
        onClick={onCancel}
        style={{ position: "relative", right: "-4%", marginTop: "-8%" }}
      />
    ) : style === "close" ? (
      <i
        className="fas fa-times cursor-pointer text-4xl float-right"
        onClick={onCancel}
        style={{ position: "relative", right: "-4%", marginTop: "-8%" }}
      />
    ) : null

  const actionButton =
    button && onCancel ? (
      <div className={cx("flex", { "justify-end": style === "close" })}>
        {button}
      </div>
    ) : null

  return (
    <Overlay
      actionButton={actionButton}
      className="bg-magenta text-white px-5 pt-8 pb-16 itinerarymodal"
      open={open}
    >
      <div className="pr-3">{children}</div>
    </Overlay>
  )
}

export default BookingOverlay

export const SkipButton = ({ className, ...props }) => (
  <Button
    className={cx(
      "w-full border border-j-white bg-j-magenta text-j-white",
      className
    )}
    {...props}
  >
    Skip
  </Button>
)
