import React from "react";
import cx from "classnames";
import style from "./Select.module.css"

const Input = React.forwardRef(
  ({ className, withIcon, tiny, grow, noClear, onChangeValue, pathName, defaultenumber, ...rest }, ref) => {
    const [isFilled, setFilled] = React.useState(false);
    const [clear, setClear] = React.useState(false);
    const inputRef = React.useRef();

    React.useEffect(() => {
      if (!clear) return;

      const inputs =
        inputRef.current && inputRef.current.getElementsByTagName("input");
      if (inputs && inputs[0]) {
        inputs[0].value = "";
        inputs[0].focus();
        setFilled(false);
      }

      setClear(false);
    }, [clear]);

    const setValue = (e) => {
      if(pathName == 'payment') {
        onChangeValue(e)
      }
      setFilled(e.target.value.length > 0)

    }

    return (
      <div
        className={cx(
          "flex",
          { "w-full": !tiny, "flex-grow": grow },
          className
        )}
        ref={inputRef}
      >
        <input
          className={cx(
            "w-full h-10 px-2 w-full text-black rounded mt-2 focus:outline-non flex-grow max-w-full placeholder-j-gray-lighter text-black text-sm shadow placeholder-gray-500 placeholder-opacity-75 self-center pt-1 leading-none",
            { "ml-2": withIcon }
          )}
          onChange={(e) => setValue(e)}
          // value={defaultenumber}
          ref={ref}
          {...rest}
        />
        {!noClear && isFilled && (
          <i
            className="fas fa-times-circle text-white cursor-pointer text-lg ml-2 self-center"
            onClick={() => setClear(true)}
          />
        )}
      </div>
    );
  }
);

export const TextArea = React.forwardRef(
  ({ className, children, withIcon, onChangeValue, pathName, ...rest }, ref) => {
    const setValueText = (e) => {
      if(pathName == 'payment') {
        onChangeValue(e)
      }
      // setFilled(e.target.value.length > 0)

    }
    return(
    <textarea
      style={{padding: "1rem", borderRadius: "0.625rem"}}
      className={cx(
        "flex-grow placeholder-j-gray-lighter text-j-magenta text-sm leading-4 border-0 focus:outline-none mt-1",
        {
          "ml-2": withIcon,
        },
        className
      )}
      ref={ref}
      onChange={(e) => setValueText(e)}
      {...rest}
    >
      {children}
    </textarea>
  )
      });

export default Input;
