import React, { useRef, useState } from "react"
import InputField from "../../components/Form/InputField"
import TextField from "../../components/Form/TextField"
import Button from "../../components/Button/Button"
import { useHistory } from "react-router-dom"

export default function CardSettings() {
  let form = useRef(null)
  let history = useHistory()  
  // These three var appended as additional properties in formData() for tracking purpose.
  // Appended properties are as follows website, lead form, lead source and url.
  const currentUrl = process.env.REACT_APP_API_ENDPOINT + history.location.pathname
  const leadForm = "Contact Us Page"
  const leadSource = "Outbound"

  const onSubmitCrm = (contact) => {
    var data = new FormData()
    data.append(
      "xnQsjsdp",
      "71ea9139e1fbbeb931227dbcd956ad0785090d4748f3edaea8569aa56aca2c00"
    )
    data.append("zc_gad", "")
    data.append(
      "xmIwtLD",
      "bf78b667f9510fb07937393d25b8d789988012f5869cdfbfa4b4adfd02eeddff"
    )
    data.append("actionType", "TGVhZHM=")
    data.append("Last Name", contact.name)
    data.append("Mobile", contact.phone)
    data.append("Email", contact.email)
    data.append("Description", contact.message)
    data.append("Website", currentUrl)
    data.append("LEADCF3", leadForm)
    data.append("Lead Source", leadSource)
    data.append("LEADCF2", currentUrl)

    fetch("https://crm.zoho.in/crm/WebToLeadForm", {
      method: "post",
      body: data,
    })
      .then((res) => {
        history.push("/admin/contact/thank-you")
      })
      .catch(function (error) {
        alert("Error:", error)
      })
  }

  const handleContactUsSubmit = (event) => {
    event.preventDefault()
    const form_data = new FormData(form.current)
    let payload = {}
    form_data.forEach(function (value, key) {
      payload[key] = value
    })
    onSubmitCrm(payload)
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Write to Us</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-4">
          <form
            ref={form}
            name="WebToLeads144272000001575033"
            className="py-12"
            onSubmit={handleContactUsSubmit}
          >
            <label className="pl-2">Enter your name:</label>
            <InputField
              // placeholder="Enter your name"
              name="name"
              lessSpace
              required
            />
            <label className="pl-2">Your 10-digit mobile number:</label>
            <InputField
              name="phone"
              // placeholder="Your 10-digit mobile number"
              lessSpace
              required
            />
            <label className="pl-2">Your email address:</label>
            <InputField
              // placeholder="Your email address"
              type="email"
              name="email"
              lessSpace
              required
            />
            <label className="pl-2">Write your message here:</label>
            <TextField
              name="message"
              // placeholder="Write your message here"
              textClassName="h-32"
            />
            <Button
              type="submit"
              className="bg-magenta text-white active:bg-j-orange font-bold uppercase text-xs py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              Send
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}
