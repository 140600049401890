import React, { useContext, useState, useEffect } from "react";
import View from "../../components/View/View";
import cx from "classnames";
import Tile from "../../components/Tile/Tile";
import { useHistory } from "react-router-dom";

const WebCheckInDetails = ({ props }) => {
    let history = useHistory();
    const [webcheckinshow, setWebCheckInShow] = useState(false);

    const checkIn = () => {
        setWebCheckInShow(!webcheckinshow)
    }

    // const handleSubmit = () => {
    //     setWebCheckInShow(!webcheckinshow)
    // }

    const refNumber = (e) => {

       
    }

    return(
        <div>
            <main className="bg-auto">
                <p className="text-xl text-j-magenta font-bold mr-8 ml-8 mt-12">Web Check In</p>
                <div className="grid grid-cols-4 gap-4 ml-8 mr-8 mt-12">
                    <div>
                        <label className="text-sm">Booking Reference ID</label>
                        <input className="w-full border mt-2 h-10 rounded-lg text-sm p-4" placeholder="Enter your 12 digit PAN Number" onChange={(e) => refNumber(e)}/>    
                    </div>
                    <div>
                        <label className="text-sm">Last Name</label>
                        <input className="w-full border mt-2 h-10 rounded-lg text-sm p-4" placeholder="Enter your 12 digit PAN Number"/>    
                    </div>
                    <div>
                        <label className="text-sm">Choose Sailing Date</label>
                        <input type="date" className="w-full border mt-2 h-10 rounded-lg text-sm p-4" placeholder="Enter your 12 digit PAN Number"/>    
                    </div>
                    <div className="flex self-center justify-center">
                        <button className="bg-j-magenta p-2 text-j-white rounded-lg pl-10 pr-10 mt-7 flex" onClick={checkIn}>Check In</button>
                    </div>
                </div>
                {webcheckinshow && <>
                <div className="grid grid-cols-3 gap-6 mt-12 ml-20 mr-20 mb-6">
                    <div>
                        <p className="pt-1 mb-4"> Booking Details</p>
                        <Tile
                            className="mb-8"
                            shadow
                            style={{ minHeight: "300px", padding: "0" }}
                        >
                            <div className="p-8">
                                <OrderDetail title="Ship Name">
                                    The Knight Rider
                                </OrderDetail>
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <OrderDetail title="Departure">
                                            Mumbai Sat, 16 Oct 2021 08:00 PM
                                        </OrderDetail>
                                    </div>
                                    <div>
                                        <OrderDetail title="Arrival">
                                            Mumbai Mon, 16 Oct 2021 08:00 PM
                                        </OrderDetail>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <OrderDetail title="Cabin 1">
                                            Interior <br/>Deck No: 11 Room No: 9004 Guest: 4
                                        </OrderDetail>
                                    </div>
                                    <div>
                                        <OrderDetail title="Cabin 2">
                                            Interior <br/>Deck No: 11 Room No: 9004 Guest: 4
                                        </OrderDetail>
                                    </div>
                                </div>
                            </div>
                        </Tile>
                    </div>
                    <div>
                        <p className="pt-1 mb-4"> Guest Details</p>
                        <Tile
                            className="mb-8"
                            shadow
                            style={{ minHeight: "300px", padding: "0" }}
                        >
                            <div className="p-8">
                                <div className="mb-4">
                                    <p className="pb-4">Cabin 1</p>
                                    <div className="grid grid-cols-2">
                                        <div>
                                            <p>Guest-1 Adult</p>
                                            <p style={{ color: "grey", fontSize: 12 }}
                                                className="float-left bold pb-2">
                                                    Andy Sandra
                                            </p>
                                        </div>
                                        <div />
                                        <div>
                                            <p>Guest-1 Adult</p>
                                            <p style={{ color: "grey", fontSize: 12 }}
                                                className="float-left bold pb-2">
                                                    Andy Sandra
                                            </p>
                                        </div>
                                        <div />
                                        <div>
                                            <p>Guest-1 Adult</p>
                                            <p style={{ color: "grey", fontSize: 12 }}
                                                className="float-left bold pb-2">
                                                    Andy Sandra
                                            </p>
                                        </div>
                                        <div />
                                    </div>
                                </div>
                                <div>
                                <p className="pb-4">Cabin 2</p>
                                    <div className="grid grid-cols-2">
                                        <div>
                                            <p>Guest-1 Adult</p>
                                            <p style={{ color: "grey", fontSize: 12 }}
                                                className="float-left bold pb-2">
                                                    Andy Sandra
                                            </p>
                                        </div>
                                        <div />
                                        <div>
                                            <p>Guest-1 Adult</p>
                                            <p style={{ color: "grey", fontSize: 12 }}
                                                className="float-left bold pb-2">
                                                    Andy Sandra
                                            </p>
                                        </div>
                                        <div />
                                        <div>
                                            <p>Guest-1 Adult</p>
                                            <p style={{ color: "grey", fontSize: 12 }}
                                                className="float-left bold pb-2">
                                                    Andy Sandra
                                            </p>
                                        </div>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </Tile>
                    </div>
                    <div>
                        <p className="pt-1 mb-4"> Pricing Details</p>
                        <Tile
                            className="mb-8"
                            shadow
                            style={{ minHeight: "300px", padding: "0" }}
                        >
                            <div className="p-8">
                                <div>
                                    <p className="pb-4">Cabin 1</p>
                                    <div className="grid grid-cols-2">
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Gross Fare
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 50000
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Discount
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 4000
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Amount
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 54000
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Tax
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 9750
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: 14 }}
                                                className="float-left bold pb-2 text-j-magenta">
                                                   Net Payable Amount
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: 14 }}
                                                className="float-right bold pb-2 text-j-magenta">
                                                  &#x20B9; 63750
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2">
                                    <p className="pb-4">Cabin 2</p>
                                    <div className="grid grid-cols-2">
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Gross Fare
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 50000
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Discount
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 4000
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Amount
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 54000
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-left bold pb-2">
                                                   Tax
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ color: "grey", fontSize: 14 }}
                                                className="float-right bold pb-2">
                                                  &#x20B9; 9750
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: 14 }}
                                                className="float-left bold pb-2 text-j-magenta">
                                                   Net Payable Amount
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: 14 }}
                                                className="float-right bold pb-2 text-j-magenta">
                                                  &#x20B9; 63750
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 pt-4">
                                    <div>
                                        <p style={{ fontSize: 15 }}
                                                className="float-left bold pb-2 text-j-magenta">
                                                   Total Paid Amount
                                        </p>
                                    </div>
                                    <div>
                                        <p style={{ fontSize: 15 }}
                                                className="float-right bold pb-2 text-j-magenta">
                                                  &#x20B9; 63750
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </Tile>
                    </div>
                </div>
                <div className="pl-8 pr-8">
                    <div className="text-j-magenta text-base text-xs">Please Provide Following Details for web check in</div>
                        <form className="grid grid-cols-4 gap-4 mt-6">
                            <div>
                                <p className="pt-2 font-bold text-j-magenta">Guest 1-Adult</p>
                                <div className="mt-4">
                                    <label className="text-sm">Choose Identification Type</label>
                                    <select className="w-full border h-10 rounded-lg mt-2 text-sm text-grey p-2">
                                        <option value="A">PAN</option>
                                        <option value="B">Adhar ID</option>
                                        <option value="C">Passport</option>
                                    </select>
                                    <input className="w-full border mt-4 h-10 rounded-lg text-sm p-4" placeholder="Enter your 12 digit PAN Number"/>
                                </div>
                            </div>
                            <div>
                                <p className="pt-2 font-bold text-j-magenta">Guest 2-Adult</p>
                                <div className="mt-4">
                                    <label className="text-sm">Choose Identification Type</label>
                                    <select className="w-full border h-10 rounded-lg mt-2 text-sm text-grey p-2">
                                        <option value="A">PAN</option>
                                        <option value="B">Adhar ID</option>
                                        <option value="C">Passport</option>
                                    </select>
                                    <input className="w-full border mt-4 h-10 rounded-lg text-sm p-4" placeholder="Enter your 12 digit PAN Number"/>
                                </div>
                            </div>
                            <div>
                                <p className="pt-2 font-bold text-j-magenta">Guest 3-Adult</p>
                                <div className="mt-4">
                                    <label className="text-sm">Choose Identification Type</label>
                                    <select className="w-full border h-10 rounded-lg mt-2 text-sm text-grey p-2">
                                        <option value="A">PAN</option>
                                        <option value="B">Adhar ID</option>
                                        <option value="C">Passport</option>
                                    </select>
                                    <input className="w-full border mt-4 h-10 rounded-lg text-sm p-4" placeholder="Enter your 12 digit PAN Number"/>
                                </div>
                            </div>
                            <div>
                                <p className="pt-2 font-bold text-j-magenta">Guest 4-Adult</p>
                                <div className="mt-4">
                                    <label className="text-sm">Choose Identification Type</label>
                                    <select className="w-full border h-10 rounded-lg mt-2 text-sm text-grey p-2">
                                        <option value="A">PAN</option>
                                        <option value="B">Adhar ID</option>
                                        <option value="C">Passport</option>
                                    </select>
                                    <input className="w-full border mt-4 h-10 rounded-lg text-sm p-4" placeholder="Enter your 12 digit PAN Number"/>
                                </div>
                            </div>
                        </form>
                </div>
                </>
            }
            </main>
           </div>
    )
}

export default WebCheckInDetails;

const OrderDetail = ({ title, children, big }) => (
    <>
      <h1 className="pb-0 text-j-gray text-tiny leading-none">
        {title}
      </h1>
      <h4
        className={
          big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2"
        }
      >
        {children}
      </h4>
    </>
  );
  