import React, { useEffect, useState, useContext } from "react"
import cx from "classnames";
import Button from "../Button/Button";
// import { getToken } from "../../utils/common";
import UserContext from "../../store/UserContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./shoreExcursion.module.css"
import Slider from "react-slick";
import LoadingIcon from "../Loading/LoadingIcon";
import Modal from "../Modal/Modal";
import Swimming from "../../assets/images/swimming.png";
import Car from "../../assets/images/car.png";
import Insurance from "../../assets/images/insurance.png";
import { getToken } from "../../utils/common";
import Offer1 from "../../assets/images/shore_ex_banner.webp";


// import PostBookingConfirmView from './PostBookingConfirmView';



//summary view
import Tile from "../Tile/Tile";
//Confirm View
import RadioField from "../Form/RadioField";
import CardPaymentForm from "./post-booking-payment-form/PostBookingCardForm";
import BankPaymentForm from "./post-booking-payment-form/PostBookingBankForm";
import UPIPaymentForm from "./post-booking-payment-form/PostBookingUPIForm";
import WalletPayment from "./post-booking-payment-form/PostBookingWalletForm";
import NimbblPaymentForm from "./payment-forms/NimbblPaymentForm";

var guestArray = [];
var shoreExcursionArray = [];
var shoreExcursionObject;

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent", right:'0px' }}
        onClick={onClick}
      />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent", left:'0px', zIndex:1, width:'20px' }}
        onClick={onClick}
      />
    );
}

export default function ShoreExcursion({}) {
    const [user, setUser] = useContext(UserContext)
    const [booking, setBooking] = useState()
    const [portsData, setPortsData] = useState([])
    // const [shorexAmount, setShorexAmount] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)
    const [shoreExcursion, setShoreExcusrion] = useState([])
    const [bookingAmount, setBookingAmount] = useState(0)
    const [shoreExcursionAmount, setShoreExcursionAmount] = useState(0)
    const [shoreExcursionWithoutGST, setShoreExcursionWithoutGST] = useState(0)
    const [shoreExcursionWithGST, setShoreExcursionWithGST] = useState(0)
    const [loading, setLoading]= useState(false)
    const [transferType, setTransferType] = useState()
    const [openTab, setOpenTab] = React.useState(0);
    const [addStatus, setAddStatus] = useState()
    const [discount, setDiscount] = useState(0)
    const [shoreExcursionList, setShoreExcursionList] = useState([])
    const [buttonLoad, setButtonLoad] = useState(false)
    const [bookingDueAmount, setBookingDueAmount] = useState(0);
    const [bookingStatus, setBookingStatus] = useState(0);
    const [displayConfirmPage, setDisplayConfirmPage] = useState(false);
    const [walletLoader, setWalletLoader] = useState(false);

    useEffect(()=> {
        console.log('test')
        callIntialFunction()
    },[])

    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return results[2];
      }

    const getBooking =async(id)=>{
        fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/bookings/' + id + '/details', {
            method: 'GET',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                `Bearer ${getToken()}`,
            },
        }).then((response) => response.json())
        .then((response) => {
            setBooking(response.booking)
            getShoreExcursion(id,response.booking)
        })
    }

    const getShoreExcursion = async(id,tempBooking)=>{
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${id}/shore_excursions`, {
          method: "GET",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
              `Bearer ${getToken()}`,
          }
        }).then((response) => response.json())
        .then((response) => {
            console.log(response)
            console.log(tempBooking,booking,"nik-log")
            setBookingAmount(tempBooking.total_amnt)
            setBookingStatus(tempBooking.status)
            setBookingDueAmount(tempBooking.pending_amount)
            setGrandTotal(tempBooking.pending_amount)
            var shoreDetailsList;
            var shoreDetailsListArray = [];
            var portsSortedArray = [];
            var portsDummyArray = [];
            response.ports.map((ports)=> {
                if(ports.shore_excursions.length > 0 && !ports.shorex_confirmed) {
                    portsDummyArray.push(ports)

                console.log('ports', ports)
                    shoreDetailsListArray= [];
                    ports.shore_excursions.map((shore) => {
                        shoreDetailsList = {
                            list: shore,
                            is_selected: shore.selected,
                            transferSelectedValue: shore.transfer_types[0].id,
                            is_loader: false
                        }
                        shoreDetailsListArray.push(shoreDetailsList)
                        if(shore.selected&&!shoreExcursionArray.find(o => o.id === shore.id)){
                            shoreExcursionObject = new Object();
                            shoreExcursionObject.id = shore.id
                            shoreExcursionObject.port_id = shore.port_id
                            shoreExcursionArray.push(shoreExcursionObject)
                        }
                    })
                    portsSortedArray.push(shoreDetailsListArray)
                }

            })
            setPortsData(portsDummyArray)
            console.log(portsSortedArray)
            setShoreExcursionList(portsSortedArray)
            setShoreExcursionAmount(response.shore_excursion_total)
            setShoreExcursionWithoutGST(response.shore_excursion_net_total)
            setShoreExcursionWithGST(response.shore_excursion_gst)
            setBookingAmount(tempBooking.total_amnt-(response.shore_excursion_net_total+response.shore_excursion_gst))
            setBookingDueAmount(response.due_amount-(response.shore_excursion_net_total+response.shore_excursion_gst))
            console.log(shoreExcursionList)
            setLoading(false)
        })
    }

    const callIntialFunction = async() => {
        setLoading(true)
        var id = new URLSearchParams(window.location.search).get("bookId");
        console.log( await getBooking(id),"nik-log res")
        await getBooking(id)
        console.log('itinerary',  id)
    }

    const addShoreExcursion = (data, index, newData, newIndex, transferValue) => {
        setButtonLoad(true)
        setButtonLoad(true)
        let newArrLoader = [...shoreExcursionList];
        // console.log(shoreExcursionList[index][newIndex])
        newArrLoader[index][newIndex].is_loader = true;
        setShoreExcursionList(newArrLoader)

        let obj = shoreExcursionArray.find(o => o.id === newData.list.id);

        console.log(obj);

        if(obj == undefined) {
            let findSamePortShorex=shoreExcursionArray.find(o => o.port_id === data.port_id);
            if(findSamePortShorex){
                shoreExcursionArray=shoreExcursionArray.filter((o) => o.port_id !== data.port_id);
            }
            // shoreExcursionArray=shoreExcursionArray.filter((o) => o.port_name !== newData.port_name);
            shoreExcursionObject = new Object();
            shoreExcursionObject.id = data.shore_excursions[newIndex].id
            shoreExcursionObject.port_name = data.port_name
            shoreExcursionObject.port_id = data.port_id
            guestArray = []
        }else{
            shoreExcursionArray=shoreExcursionArray.filter((o) => o.id !== newData.list.id);
        }

        let objGuest = guestArray.find(o => o.id === data.shore_excursions[newIndex].id);

        console.log(data.shore_excursions[newIndex].id)

        if(objGuest == undefined) {
            booking.rooms.map((roomsData) => {
                roomsData.guests.map((guestID)=> {
                    var guests = new Object();
                    guests.id = guestID.id
                    if(transferType == null) {
                        guests.transfer_type_id = transferValue
                    } else {
                        guests.transfer_type_id = transferType[openTab]
                    }
                    guestArray.push(guests)
                })
            })
        } else {
            guestArray.splice(guestArray.findIndex(a => a.id === data.shore_excursions[newIndex].id) , 1)
        }

        shoreExcursionObject.guests = guestArray
        if(obj == undefined) {
            shoreExcursionArray.push(shoreExcursionObject)
        }

        var sendingObject = new Object()
        sendingObject.shore_excursions = shoreExcursionArray
        var id = booking.id
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${id}/update_shore_excursions`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                `Bearer ${getToken()}`,
            },
            body: JSON.stringify(sendingObject)
        }).then((response) => response.json())
        .then((response) => {
            console.log(response)
            let newArr = [...shoreExcursionList];
            console.log('newData-1', newArr[index])
            newArr[index].map((data,i) => {
                if(newData.port_name == data.port_name) {
                    if(i != newIndex) {
                        data.is_selected = false
                    }
                }
            })
            // console.log(shoreExcursionList[index][newIndex])
            newArr[index][newIndex].is_selected = !newArr[index][newIndex].is_selected;
            newArr[index][newIndex].is_loader = false
            setShoreExcursionList(newArr)
            window.sessionStorage.setItem(
                "booking",
                JSON.stringify(response.booking)
            )
            console.log(shoreExcursionList)
            setShoreExcursionAmount(response.booking.shore_excursion_total)
            setShoreExcursionWithoutGST(response.shore_excursion_net_total)
            setShoreExcursionWithGST(response.shore_excursion_gst)
            setDiscount(response.booking.discount)
            setGrandTotal(response.due_amount)
            setBookingDueAmount(response.due_amount-(response.shore_excursion_net_total+response.shore_excursion_gst));
            // setBookingAmount(response.shore_excursion_net_total+response.shore_excursion_gst)
            setButtonLoad(false)
            return 0
        })

        console.log(shoreExcursionObject)
    }

    const handleTransferSelected = (e) => {
        var objeCt = new Object()
        objeCt[openTab] = e.target.value;
        console.log(objeCt)
        setTransferType(objeCt)
        console.log(transferType)

    }

    const handleOpenTab = (i) => {
        setOpenTab(i)
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const confirmPage = (type,cardDetails) => {
        booking.addOns = true
        setUser(Object.assign({}, user, {booking: booking}))
        const contactDetails=booking.contact
        console.log(contactDetails,"contact nik-log",contactDetails.name.split(' '))
        let bookingInput = {
            contact: {
              first_name: contactDetails.name!=undefined?contactDetails.name.split(' ')[0]: null,
              last_name: contactDetails.name!=undefined?contactDetails.name.split(' ')[1]: null,
              email: contactDetails.email!=undefined?contactDetails.email: null,
              phoneNumber:  contactDetails.phone_number!=undefined?contactDetails.phone_number: null,
              gstin:  contactDetails.gstin!=undefined? contactDetails.gstin : null,
              gstin_name:  contactDetails.gstin_name!=undefined? contactDetails.gstin_name : null,
              gstin_phone:  contactDetails.gstin_phone!=undefined? contactDetails.gstin_phone : null,
              pan: contactDetails.pan!=undefined? contactDetails.pan : null,
              pan_name: contactDetails.pan_name!=undefined? contactDetails.pan_name : null
            },
            paymentInfo: {
              plan: "",
              voucherNumber: "",
              promoCode: "",
              first_name:  contactDetails.name!==undefined?contactDetails.name.split(' ')[0]:null,
              last_name:  contactDetails.name!==undefined?contactDetails.name.split(' ')[1]:null,
              email:  contactDetails.email!=undefined?contactDetails.email:null,
              type: type,
            },
          };
          if(type==="card"){
            bookingInput.paymentInfo.cardInfo = {
                cardNumber: cardDetails.cardNumber,
                cvv: cardDetails.cvv,
                expiryMonth: cardDetails.expiryMonth,
                expiryYear: cardDetails.expiryYear,
                first_name:  contactDetails.name!==undefined?contactDetails.name.split(' ')[0]:null,
                last_name:  contactDetails.name!==undefined?contactDetails.name.split(' ')[1]:null,
              }
          }
          if(type==="wallet"){
            bookingInput.paymentInfo.amount=grandTotal
          }
        createPaymentApi({
            variables: { input: bookingInput },booking_id: booking.id, payment_for:"shore_excursions"
          })
    }

    function createPaymentApi(requestData)
    {
        // sessionStorage.setItem('flowDirection', true);
        // if(paymentPath=="due") {
        //     var URL = '/payments/repay_due_amount'
        //     var header = {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${user.verificationToken.token}`,
        //     }
        // } else {
        var URL = '/agent_portal/payments/repay_due_amount';
        var header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`,
            }
        // }
        setWalletLoader(true);
        return fetch(`${process.env.REACT_APP_API_ENDPOINT}${URL}`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(requestData)
        }).then((res) => {
            if(res.status === 401) {
            setUser(
                Object.assign({}, user, {
                booking: Object.assign({}, booking, { OTPSent: false, verificationToken: false }),
                verificationToken: false
                })
            );
            // router.push('/my-bookings');
            throw new Error("Something went wrong");
            }
            if (res.ok) {
            return res.json();
            } else {
            throw new Error("Something went wrong");
            }
        })
        .then(response => {
            console.log(response)
            if(response.message != undefined) {
                if(response.message == "Invalid Itinerary")
                {
                    alert(response.message)
                } else {
                    if(response.auth_token == undefined) {
                    setUser(Object.assign({}, user, {booking: booking}));
                    } else {
                    setUser(
                        Object.assign({},user, {booking: booking}, {
                        verificationToken: response.auth_token,
                        })
                    );
                    }
                }
            } else {
                if(response.auth_token == undefined) {
                setUser(Object.assign({}, user, {booking: booking}));
                } else {
                setUser(
                    Object.assign({},user, {booking: booking}, {
                    verificationToken: response.auth_token,
                    })
                );
                }
            }
            sessionStorage.setItem("shoreExbookingID",booking.id);
            setWalletLoader(false);
            if(requestData.variables.input.paymentInfo.type==="wallet"){
                window.location="/admin/post-booking-addon/success";
            }
            else{
                send_payu_request(response.pg_data);
            }            
        }).catch(err => {sessionStorage.clear();});

    }

    function send_payu_request(payment_create_response){
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", payment_create_response.payu_url);
        for(var key in payment_create_response.payu_body) {
            if(payment_create_response.payu_body.hasOwnProperty(key)) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", payment_create_response.payu_body[key]);
                form.appendChild(hiddenField);
                }
        }
        document.body.appendChild(form);
        form.submit();
    }


    return(
        <>
        { !displayConfirmPage?<>
                {!loading && <div className={styles.container}>
                    <MainPriceDetailCard
                        bookingAmount={bookingAmount}
                        shoreExcursionAmount={shoreExcursionAmount}
                        discount={discount}
                        grandTotal={grandTotal}
                        confirmPage={()=>setDisplayConfirmPage(true)}
                        shoreExcursionWithGST={shoreExcursionWithGST}
                        shoreExcursionWithoutGST={shoreExcursionWithoutGST}
                        bookingDueAmount={bookingDueAmount}
                        bookingStatus={bookingStatus}
                    />
                    <ExploreActivities
                        portsData={portsData}
                        addShoreExcursion={addShoreExcursion}
                        handleTransferSelected={handleTransferSelected}
                        openTab={openTab}
                        handleOpenTab={handleOpenTab}
                        shoreExcursionList={shoreExcursionList}
                        buttonLoad={buttonLoad}
                        settings={settings}
                    />
                    <ExploreSpaActivities />
                </div>
                }
                {loading&&<LoadingIcon className="mt-5 text-3xl" />}
            </>:
            <div className={styles.container}>
                {!loading && booking && <PostBookingConfirmView booking={booking} grandTotal={grandTotal} shoreExcursionWithGST={shoreExcursionWithGST}
                        shoreExcursionWithoutGST={shoreExcursionWithoutGST} confirmPage={confirmPage} setDisplayConfirmPage={setDisplayConfirmPage} walletLoader={walletLoader}/>}
            </div>}
        </>
    )
}

const MainPriceDetailCard = ({
    bookingAmount,
    shoreExcursionAmount,
    discount,
    grandTotal,
    confirmPage,
    shoreExcursionWithGST,
    shoreExcursionWithoutGST,
    bookingStatus,
    bookingDueAmount,
}) => {
    return (
        <>
            <div>
                <div className={styles.mainContPrice}>
                    <div className={styles.mainContPriceSub1}>
                        <div className={styles.imageBGpROP} style={{ backgroundImage: `url(${Offer1})`, height: '100%' }}>
                            <div className={styles.packagechoosetxt}>
                               
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainContPriceSub2}>
                        <h3 className={styles.pricedtltitle}>Price Details</h3>
                        <table className={styles.tablepricedtl}>
                            <tbody>
                                <tr className={styles.trbookview}>
                                <td><b>Total Booking Amount</b></td>
                                <td><b>₹ {bookingAmount}</b></td>
                                </tr>
                                <tr>
                                    {bookingStatus == 'RESERVED' && <td><b>Due Amount</b></td>}
                                    {bookingStatus == 'RESERVED' &&  <td style={{textAlign:'right'}}><b>₹ {bookingDueAmount}</b></td>}
                                </tr>
                                {
                                shoreExcursionWithGST > 0 &&
                                <>
                                    <tr className={styles.trbookview}>
                                    <td><b>Shore Excursion</b></td>
                                    <td><b>₹ {shoreExcursionWithoutGST}</b></td>
                                    </tr>
                                    <tr className={styles.trbookview}>
                                    <td><b>Shore Excursion GST</b></td>
                                    <td><b>₹ {shoreExcursionWithGST}</b></td>
                                    </tr>
                                </>
                                }
                                {/* {
                                bookingSummary.discount > 0 &&
                                <tr className="tr-bookview">
                                    <td className="text-success"><b>Discount</b></td>
                                    <td className="text-success"><b>-₹ {bookingSummary.discount}</b></td>
                                </tr>
                                } */}
                                <tr className={styles.trbookview}>
                                    <td className={cx(styles.trbookview,"pt-2")}><b>Total Payable Amount</b></td>
                                    <td className={cx(styles.extrabooktd ,"pt-2")}>₹ {grandTotal}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-full flex-end mt-6 mb-6 flex justify-end">
                            <button
                                className={shoreExcursionWithoutGST <= 0 ? "h-10 pl-16 pr-16 text-white rounded transition duration-300 bg-j-gray-lighter ease-in-out" : "h-10 pl-16 pr-16 text-white rounded transition duration-300 bg-j-orange ease-in-out"}
                                onClick={()=>confirmPage()}
                                disabled={shoreExcursionWithoutGST <= 0 ? true : false}>Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const ExploreActivities = ({
    portsData,
    addShoreExcursion,
    handleTransferSelected,
    openTab,
    handleOpenTab,
    shoreExcursionList,
    buttonLoad,
    settings
}) => {

    console.log(portsData)
    console.log(openTab)
    const slideImages = [
        'http://placekitten.com/g/400/200',
        'http://placekitten.com/g/400/200',
        'http://placekitten.com/g/400/200',
      ];
    return(
        <div className="mt-4">
            <p className={cx(styles.activitiesHeading,"uppercase md:pl-4 lg:pl-4")}>EXPLORE Activities</p>
            <div>
                <div className="md:pl-4 lg:pl-4 pt-2">
                    <nav className={cx(styles.navBar,"grid grid-cols-3 lg:grid-cols-5 md:grid-cols-5 gap-4")}>
                        {/* loop this */}
                        {portsData.map((data, i) => {
                            console.log(portsData);
                            console.log(i)
                            return(<>
                                {data.shore_excursions.length > 0 && !data.shorex_confirmed &&
                                <button className={cx(styles.buttonTab,"pl-4 pr-4 pt-2 pb-2 block  focus:outline-none uppercase "+ (openTab == i ? "bg-magenta text-white" : "border-j-magenta border text-j-magenta"))}
                                onClick={e => {
                                    e.preventDefault();
                                    handleOpenTab(i);
                                }}>
                                    <p>{data.port_name}</p>
                                </button>
                        }</>)
                        })}
                    </nav>
                    <nav className={cx(styles.navBarMobile,"flex")}>
                        {portsData.map((data, i) => {
                            return(<>
                                {data.shore_excursions.length > 0 &&
                                <button className={cx(styles.buttonTab,"pl-4 pr-4 pt-2 pb-2 mr-2 block  focus:outline-none uppercase "+ (openTab == i ? "bg-j-magenta text-white" : "border-j-magenta border text-j-magenta"))}
                                onClick={e => {
                                    e.preventDefault();
                                    handleOpenTab(i);
                                }}>
                                    <p>{data.port_name}</p>
                                </button>
                        }</>
                            )
                        })}
                    </nav>
                </div>
                <div>
                     {/* loop this */}
                     {portsData.map((data, i) => {
                        //  console.log(shoreExcursionList[i])
                        return (<div className={openTab === i ? "block mt-6 norder" : "hidden"} id="link1">
                            {data.shore_excursions.length == 0 && data.shorex_confirmed && <p className="text-center text-4xl text-gray-400 pt-10 pb-10">No Add-On Found</p>}
                            {data.shore_excursions.length > 0 &&
                            <div  className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-1">
                                {/* <p className={cx(styles.activitiesHeading,"uppercase pt-2 pl-4")}> ActiVities in {data.port_name}</p> */}
                                {/* loop this */}
                                {shoreExcursionList[i].map((newData,index) => {
                                        return(
                                            <CardData
                                                newData={newData}
                                                index={index}
                                                settings={settings}
                                                buttonLoadNew={buttonLoad}
                                                addShoreExcursion={addShoreExcursion}
                                                handleTransferSelected={handleTransferSelected}
                                                i={i}
                                                data={data}
                                            />
                                        )
                                    })}
                            </div>}
                        </div>)
                    })}
                </div>
            </div>
            <div className="text-right">
                <a href="https://images.cordeliacruises.com/static/Terms%20&%20Conditions%20for%20Shore%20Excursions.pdf" target="_blank" className=" text-j-orange">Terms & Conditions</a>
            </div>
        </div>
    )
}

const ExploreSpaActivities = () => {
    return (
      <div>
      </div>
    )
}

const CardData = ({
    newData,
    index,
    settings,
    buttonLoadNew,
    addShoreExcursion,
    handleTransferSelected,
    i,
    data
}) => {
    const [buttonLoad ,setButtonLoad] = useState(false)
    const [modalShow, setModalShow] = useState(false)

    const addRemoveShore = (data, i, newData, index, transferSelectedValue) => {
        setButtonLoad(true)
        var status = addShoreExcursion(data, i, newData, index, transferSelectedValue)
        console.log(status)
        if(status == undefined) {
            setButtonLoad(false)
        }
    }

    const handleMoreInclusion = () => {
        setModalShow(!modalShow)
    }

    return (
        <div className="shadow-lg rounded-lg ml-2 mr-2 bg-white mb-4 flex-col justify-between flex">
            <div className="grid grid-cols-1">
                <div className={cx(styles.sliderContainer)}>
                    <Slider {...settings} className="no-padding-slick-active h-100">
                        {newData.list.images.map((images) => {
                            return(
                                <div>
                                    <img src={images} className={cx(styles.imageContainer,"w-full rounded-lg h-full")}/>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="flex">
                <p className={cx(styles.titleHead)}>{newData.list.title}</p>
                <p  className={cx(styles.topText)}><span><i class="far fa-clock"></i></span> {newData.list.hours} HRS</p>
            </div>
            <div>
                <p className="pl-4 pr-4 text-sm text-gray-600">{newData.list.description}</p>
            </div>
            <div>
                <p className={styles.inclusionStyles}>Inclusion:</p>
                <div className="grid grid-cols-4 pl-3 pr-3 pt-4">
                    <div className={styles.iconStyles}>
                        <img src={Car} className={styles.iconImageCar}/>
                        <p className="pt-2 text-sm">Transfer</p>
                    </div>
                    <div className={styles.iconStyles}>
                        <img src={Swimming} className={styles.iconImageSwimmer}/>
                        <p className="pt-2 text-sm">Activity</p>
                    </div>
                    <div className={styles.iconStyles}>
                        <img src={Insurance} className={styles.iconImage}/>
                        <p className="pt-2 text-sm">Insurance</p>
                    </div>
                    <div className={styles.iconStyles}>
                        <a className={styles.textColorSize} onClick={()=>handleMoreInclusion()}>More Inclusions</a>
                    </div>
                </div>
            </div>
            <div className="flex pt-4">
                <div className={cx(styles.titlediv)}>
                    <p className={styles.transferedStyle}>Transfered By:</p>
                    <p className="pl-4 pt-1">
                        {newData.list.transfer_types.map((transfer) => {
                            return(<p>
                                {transfer.name}
                                </p>)
                         })}
                    </p>
                </div>
                <div className={styles.prizeDetails}>
                    <p className="pt-2"><span className={styles.prizeText}>₹{newData.list.adult_price}</span> / Adult</p>
                    <p className="pt-3"><span  className={styles.prizeText}>₹{newData.list.child_price}</span> / Child</p>
                </div>
            </div>
            <div className="pl-4 pr-4 flex flex-col justify-between mb-4">
                <button className={newData.is_selected?"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-gray-500 ease-in-out":"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-j-orange ease-in-out"}
                   onClick={()=>addRemoveShore(data, i, newData, index,  newData.transferSelectedValue)}
                >
                    {!newData.is_loader ? newData.is_selected?"Remove":"Add": <LoadingIcon />}
                </button>
            </div>
            <Modal show={modalShow} onClose={() => handleMoreInclusion()}>
                <div className="p-8">
                    <h3 className="pb-4">All Inclusions</h3>
                        {newData.list.inclusions.map(inclusion=>{
                            return(
                                <div class="flex items-center mr-4 mb-2">
                                <input type="checkbox" id="A3-yes" name="A3-confirmation" value="yes" class="opacity-0 absolute h-8 w-8" />
                                <div class="bg-white border-2 rounded-md border-gray-500 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                                <svg class="fill-current w-3 h-3 text-j-orange pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                    <g transform="translate(-9 -11)" fill="#EA725B" fill-rule="nonzero">
                                        <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                                    </g>
                                    </g>
                                </svg>
                                </div>
                                <label for="A3-yes" class="select-none">{inclusion}</label>
                            </div>
                            );
                        })}
                    {/* <input type="checkbox">Transfer</input> */}
                </div>
            </Modal>
        </div>
    // <div className="shadow-lg rounded-lg ml-4 mr-4 bg-white mb-4">
    //     <div className={cx(styles.mobileImg,"relative")}>
    //         <img src={newData.list.images[0]} className="w-full rounded-t-lg h-56"/>
    //         <div className={cx(styles.topText,"top-right text-white text-sm")}>{newData.list.hours} HRS</div>
    //     </div>
    //     <p className="pl-4 pr-4 pt-4 pb-0 text-lg">{newData.list.title}</p>
    //         <div className="md:grid lg:grid grid-cols-2 gap-4">
    //             <div className={cx(styles.sliderContainer,"pl-4 pr-4")}>
    //                 <Slider {...settings}>
    //                     {newData.list.images.map((images) => {
    //                         return(
    //                             <div>
    //                             <img src={images} className={cx(styles.imageContainer,"w-full rounded-lg h-56")}/>
    //                             </div>
    //                         )
    //                     })}
    //                 </Slider>
    //             </div>
    //             <div className="pl-4 pr-4">
    //                 <p className={cx(styles.description,"pb-2 pt-4")}>{newData.list.description} </p>
    //                 <div className={cx(styles.inclusionMobile,"flex")}>
    //                     <p className="font-bold">Inclusion: - {
    //                         newData.list.inclusions.map((inclusion, index)=> {
    //                             return(
    //                                 <>
    //                                     {newData.list.inclusions.length-1 === i ? <span>{inclusion}</span>:  <span>{`${inclusion}, `}</span>}
    //                                 </>
    //                             )
    //                         })
    //                     }</p>
    //                 </div>
    //                 <p className={cx(styles.tranferBy,"pt-4 md:pl-0 lg:pl-0 md:pr-4 lg:pr-4 pr-0 pl-0")}>TRANSFERS BY</p>
    //                 <div className="md:flex lg:flex pt-2 md:pl-0 lg:pl-0 pl-0 md:pr-4 lg:pr-4 pr-0">
    //                     {newData.list.transfer_types.map((transfer) => {
    //                         return(
    //                         <div>
    //                             <input
    //                                 type="radio"
    //                                 value={transfer.id}
    //                                 checked={transfer.id == newData.transferSelectedValue}
    //                                 onChange={(e)=>handleTransferSelected(e)}

    //                             />
    //                             <label className={cx(styles.tranferByOption,"pl-2")}>{`${transfer.name} - ₹${newData.list.adult_price}/ person`}</label>
    //                         </div>)
    //                     })}
    //                 </div>
    //             </div>
    //         </div>
    //         <div className={cx(styles.inclusionWeb,"flex pl-4 pr-4 pb-4 pt-2")}>
    //             <div className="w-9/12 pr-4">
    //                 <p className={cx(styles.duration,"text-sm pb-2")}>DURATION - {newData.list.hours} HRS</p>
    //                 <p className={cx(styles.inclusion)}>Inclusion: - {
    //                     newData.list.inclusions.map((inclusion, index)=> {
    //                         return(
    //                             <>
    //                                 {newData.list.inclusions.length-1 === i ? <span>{inclusion}</span>:  <span>{`${inclusion}, `}</span>}
    //                             </>
    //                         )
    //                     })
    //                 }</p>
    //             </div>
    //             <div className="w-3/12">
    //             <button
    //                     className={newData.is_selected?"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-gray-500 ease-in-out":"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-j-orange ease-in-out"}
    //                     onClick={()=>addRemoveShore(data, i, newData, index,  newData.transferSelectedValue)}
    //                 >
    //                     {!buttonLoadNew ? newData.is_selected?"Remove":"Add": <LoadingIcon />}
    //                 </button>
    //             </div>
    //         </div>
    //         <div className={cx(styles.buttonMobile,"flex pb-4")}>
    //             {!buttonLoadNew && <button
    //                     className={newData.is_selected?"h-8 pl-10 pr-10 text-white w-10/12 mt-4 ml-6 mr-6 rounded transition duration-300 bg-gray-500 ease-in-out":"h-8 pl-10 pr-10  ml-6 mr-6 text-white w-10/12 mt-4 rounded transition duration-300 bg-j-orange ease-in-out"}
    //                     onClick={()=>addRemoveShore(data, i, newData, index,  newData.transferSelectedValue)}
    //                 >
    //                     {!buttonLoadNew ? newData.is_selected?"Remove":"Add": <LoadingIcon />}
    //                 </button>
    //                 }
    //         </div>
    // </div>
    )
}

const PostBookingConfirmView= ({booking,grandTotal,shoreExcursionWithGST,shoreExcursionWithoutGST, confirmPage, setDisplayConfirmPage,walletLoader}) =>{
    const [open, setOpen] = useState(false)
    return(
        <>
        <div><button className="bg-grey-light focus:outline-none text-j-magenta font-bold py-2 px-4 rounded inline-flex items-center justify-end" onClick={()=>setDisplayConfirmPage(false)}>
            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521,7.521.7.52z"/></svg>
            <span>Back</span>
            </button></div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2 pt-0 mt-4">
        <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg">
        <Tile.Inner className="pb-0">
          
          {booking.number ? (<OrderDetail title="Booking Reference no">
              {booking.number}
            </OrderDetail>) : null}
               <>
                <div style={{marginTop:'15px'}}>
                    <PaymentDetails content="Shore Excursion:" details={Math.round(shoreExcursionWithoutGST)} big={true} />
                </div>

              <div style={{marginTop:'15px'}}>
                <PaymentDetails content="Shore Excursion GST:" details={Math.round(shoreExcursionWithGST)} big={true} />
              </div>

              <div style={{marginTop:'15px'}}>
                <PaymentDetails content="Net Payable to Cordelia:" details={Math.round(grandTotal)} big={true} />
              </div>
              <div
                  style={{
                    marginTop:"10px",
                    marginBottom: "18px",
                    color: "#500E4B",
                    fontWeight: "bold",
                  }}
                  className="grid grid-cols-1"
                >
                  <a href="https://images.cordeliacruises.com/static/insurance_web_document.pdf" target="_blank" className="text-j-magenta font-bold">
                  Cordelia Cruises Insurance Benefit Document
                  </a>
                </div>
                <div
                  style={{
                    marginTop:"10px",
                    marginBottom: "18px",
                    color: "#500E4B",
                    fontWeight: "bold",
                  }}
                  className="grid grid-cols-1"
                >
                  <a href="https://cordelia-images-prod.s3.ap-south-1.amazonaws.com/static/Other_Policy.pdf" target="_blank" className="text-j-magenta font-bold">
                  Other Additional Charges
                  </a>
                </div>
              </>
        </Tile.Inner>
      </Tile>
        <div>
                <ConformBooking booking={booking} confirmPage={confirmPage} grandTotal={grandTotal} walletLoader={walletLoader}/>
        </div>
    </div></>);
}



//Summary view
const OrderDetail = ({ title, children, big }) => (
    <div className="mb-6">
      <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none">
        {title}
      </h1>
      <h4
        className={
          big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2 text-lg font-semibold"
        }
      >
        {children}
      </h4>
    </div>
  );

  const PaymentDetails = ({ content, details, big, currancyStatus }) => (
    <>
      <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
        {big && <>
          <div style={{ color: "black", fontSize: 16, fontWeight: 'bold' }} className="float-left bold">
            {content}
          </div>
          <div style={{ color: "black", fontSize: 16, textAlign: 'right', fontWeight: 'bold' }} className="float-right font-weight-bold text-capitalize">
          &#x20B9;{details}
          </div>
        </>
        }
        {!big && <>
          <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">{content}</div>
          <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
          &#x20B9;{details}
          </div>
        </>
        }
      </div>
    </>
  );
  

  //Billing details
  const ConformBooking = ({booking,confirmPage,grandTotal,walletLoader}) =>{
    const [user, setUser] = React.useContext(UserContext);
    const paymentOptionID = user.payment_option_id;
    const contactDetails=booking.contact
    const billingData = {
        contact: {
          first_name: contactDetails.name!==undefined?contactDetails.name.split(' ')[0]: null,
          last_name: contactDetails.name!==undefined?contactDetails.name.split(' ')[1]: null,
          email: contactDetails.email!==undefined?contactDetails.email: null,
          phoneNumber:  contactDetails.phone_number!==undefined?contactDetails.phone_number: null,
          gstin:  contactDetails.gstin!==undefined? contactDetails.gstin : null,
          gstin_name:  contactDetails.gstin_name!==undefined? contactDetails.gstin_name : null,
          gstin_phone:  contactDetails.gstin_phone!==undefined? contactDetails.gstin_phone : null,
          pan: contactDetails.pan!==undefined? contactDetails.pan : null,
          pan_name: contactDetails.pan_name!==undefined? contactDetails.pan_name : null
        },
        paymentInfo: {
          plan: "",
          voucherNumber: "",
          promoCode: "",
          first_name:  contactDetails.name!==undefined?contactDetails.name.split(' ')[0]:null,
          last_name:  contactDetails.name!==undefined?contactDetails.name.split(' ')[1]:null,
          email:  contactDetails.email!==undefined?contactDetails.email:null,
        },
      };
      return(
        <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg">
            <Tile.Inner className="pb-0">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2">
            <h1 className="alt">Payment options</h1>
            {/* { <button className="bg-grey-light focus:outline-none text-j-magenta font-bold py-2 px-4 rounded inline-flex items-center justify-end" onClick={()=>cardDetails(false)}>
            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521,7.521.7.52z"/></svg>
            <span>Back</span>
            </button>} */}
        </div>
        <span className="text-j-gray">
            <RadioField
                name="paymentOption"
                defaultValue="bank"
                paymentStatus= {true}
                // handleWalletPayNowTrue={handleWalletPayNowTrue}
                options={[
                    {
                        value: "mimbbl",
                        label: "Pay Using Payment Gateway",
                        view: (
                            <NimbblPaymentForm
                            bookingID={booking.id}
                            billingData={billingData}
                            paymentOptionID={paymentOptionID}
                            bookingType={"postShorex"}
                            />
                        ),
                    },
                      {
                        value: "wallet",
                        label: "Cordelia Wallet",
                        view: (
                          <WalletPayment
                            booking={booking}
                            paymentPath="booking"
                            billingData={billingData}
                            totalPrice={grandTotal}
                            walletLoader={walletLoader}
                            // partialStatus={partialStatus}
                            // submitRef={submitRef}
                            // setFailed={setFailedError}
                            // amountStatus={amountStatus}
                            // totalPrice={totalPrice}
                            // twentyPercentage={twentyPercentage}
                            // walletStatus={true}
                            // paymentOptionID={paymentOptionID}
                            // backButtonFunction={backButtonFunction}
                            // bookingType="normal"
                            // handleWalletPayNow={confirmPage}
                            customCheckout={confirmPage}
                          />
                        ),
                      },
                ]}
            />
        </span>
        </Tile.Inner>
    </Tile>
      )
  }