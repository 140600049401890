import React, { Component, useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTable, usePagination } from 'react-table';
import { useHistory } from "react-router-dom";
import Tile from "../../components/Tile/Tile";
import Button from "../../components/Button/Button";
import moment from "moment";
import styles from "./Table.module.css";
import cx from 'classnames';
import LogoImage from "../../assets/images/logo.png";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import UserContext from "../../store/UserContext";
import { getToken } from "../../utils/common";

export default function Table({ columns, data, bookingListApi, count, currentPages, totalPages, totalRecords, loading }) {

  let history = useHistory();
  let renderPrevBtn = null;
  let pageDecrementBtn = null;
  let moreData;
  const viewDetails = (id) => {
    history.push({
      pathname: '/admin/booking-details',
      state: { id: id }
    })
  }


  const payPayment = (data) => {
    console.log(data)
    setUser(Object.assign({}, user, { bookingID: data }));
    history.push({
      pathname: '/admin/pay-due-amount',
      state: { data: data }
    })
  }

  const [user, setUser] = React.useContext(UserContext);
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [sDate, setsDate] = useState("");
  const [eDate, seteDate] = useState("");
  const [cDate, setCruiceDate] = useState("");
  const [source, setSource] = useState([]);
  const [dataLength, setDataLength] = useState(data.length);
  const [openTab, setOpenTab] = useState(1);
  var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))

  const handleChange = (e) => {
    setSearch(e.target.value);
  }

  const showFilterToggle = () => {
    setsDate("")
    seteDate("")
    setCruiceDate("")
    setShowFilter(!showFilter);
  }

  // const dateClear = (e) => {
  //   setsDate("")
  //   seteDate("")
  // }

  //   const {
  //     getTableProps,
  //     getTableBodyProps,
  //     headerGroups,
  //     prepareRow,
  //     page,
  //     canPreviousPage,
  //     canNextPage,
  //     pageOptions,
  //     pageCount,
  //     gotoPage,
  //     nextPage,
  //     previousPage,
  //     setPageSize,
  //     state: { pageIndex, pageSize },
  // } = useTable(
  //     {
  //         columns,
  //         data,
  //         initialState: { pageIndex: 2, pageSize: 5 },
  //     },
  //     usePagination
  // )

  // const endDate = (e) => {
  //   seteDate(e.target.value)
  // }

  // const startDate = (e) => {
  //   setsDate(e.target.value)
  // }

  // const cruiceDate = (e) => {
  //   setCruiceDate(e.target.value)
  // }

  const handleStartReset = (e) => {
    e.preventDefault();
    e.target.reset();
    setsDate("")
    seteDate("")
    // setCruiceDate("")
    bookingListApi(1, search, "", "", cDate, moreData = false, null)
  }

  const handleCruiceReset = (e) => {
    e.preventDefault();
    e.target.reset();
    setCruiceDate("")
    bookingListApi(1, search, sDate, eDate, "", moreData = false, null)
  }

  const handleBookingSourceReset = (e) => {
    e.preventDefault();
    e.target.reset();
    setSource([])
    bookingListApi(1, search, sDate, eDate, "", moreData = false, null)
  }

  const handleBookingSourceChange = (value) => {
    setSource(value)
    bookingListApi(1, search, sDate, eDate, cDate, moreData = false, value)
  }

  const searchhandleSubmit = (e) => {
    e.preventDefault();
    setSearch("")
    e.target.reset();
    bookingListApi(1, "", sDate, eDate, cDate, moreData = false, null)
  }

  const searchData = (e) => {
    bookingListApi(1, search, sDate, eDate, cDate, moreData = false, null)
  }

  return (
    <>
      <div className="flex flex-col mt-20">
        <div className="bg-white shadow p-4 flex mb-4">
          <button type="button" class="btn btn-light mr-2 w-60 shadow-sm" onClick={showFilterToggle}>Filters <i class="fa fa-filter"></i></button>
          <span className="w-auto flex justify-end items-center text-gray-500 p-2">
            <i className="fas fa-search"></i>
          </span>
          <form onSubmit={searchhandleSubmit} className="w-full flex">
            <input className="w-full rounded p-2" type="text" placeholder="Try 'Los Angeles' or 'Refernce No' or 'Date'" onChange={e => setSearch(e.target.value)} />
            {search.length > 0 &&
              <button className=" text-white p-2 pl-4 pr-4">
                <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                  <i className="fas fa-times-circle text-2xl"></i>
                </span>
              </button>
            }
          </form>
          <button className="bg-j-orange hover:bg-red-300 rounded text-white p-2 pl-4 pr-4" onClick={searchData}>
            <p className="font-semibold text-xs">Search</p>
          </button>
        </div>
        <div>
          {showFilter && <div className="flex">
            <div className="text-center bg-white w-3/12 self-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal" +
                  (openTab === 1
                    ? "text-white bg-j-orange"
                    : "text-black bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Filter by date
                </a>
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal" +
                  (openTab === 2
                    ? "text-white bg-j-orange"
                    : "text-black bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Filter by cruise date
                </a>
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal" +
                  (openTab === 3
                    ? "text-white bg-j-orange"
                    : "text-black bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Filter by Source
                </a>
            </div>
            <div className="w-9/12 flex">
              <div className="bg-white w-full shadow-lg rounded ml-2">
                <div className="px-4 py-5">
                  <div>
                    <form onSubmit={handleStartReset}>
                      <div className={openTab === 1 ? "flex" : "hidden"} id="link1">
                        <div className="w-5/12">
                          <label className="pl-4">Start Date:</label>
                          <input
                            type="date"
                            className="h-10 rounded-lg p-4 ml-2 bg-grey"
                            onChange={e => setsDate(e.target.value)}
                          />
                        </div>
                        <div className="w-5/12">
                          <React.Fragment>
                            <label className="pl-4">End Date:</label>
                            <input
                              type="date"
                              className="h-10 rounded-xl mr-6 ml-2 p-4 bg-grey"
                              min={moment(sDate).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={e => seteDate(e.target.value)}
                            />
                          </React.Fragment>
                        </div>
                        <div className="w-2/12 self-center">
                          <button className="bg-j-orange hover:bg-red-300 rounded text-white p-2 pl-4 pr-4">
                            <p className="font-semibold text-xs">Reset Date</p>
                          </button>
                        </div>
                      </div>
                    </form>
                    <form onSubmit={handleCruiceReset}>
                      <div className={openTab === 2 ? "flex" : "hidden"} id="link2">
                        <div className="w-5/12">
                          <label className="pl-4">Cruice Date:</label>
                          <input
                            type="date"
                            className="h-10 rounded-lg p-4 ml-2 bg-grey"
                            onChange={e => setCruiceDate(e.target.value)}
                          />
                        </div>
                        <div className="w-2/12"></div>
                        <div className="w-2/12 self-center">
                          <button className="bg-j-orange hover:bg-red-300 rounded text-white p-2 pl-4 pr-4 text">
                            <p className="font-semibold text-xs">Reset Date</p>
                          </button>
                        </div>
                      </div>
                    </form>
                    <form onSubmit={handleBookingSourceReset}>
                      <div className={openTab === 3 ? "flex" : "hidden"} id="link3">
                        <div className="w-5/12">
                          <label>Booking Source</label>
                          <select
                            name="booking_source"
                            id="booking_source"
                            style={{
                              width: "100%",
                              padding: "0.625rem",
                              borderRadius: "0.625rem",
                              boxShadow:
                                "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                              marginTop: "10px",
                            }}
                            placeholder="Booking Source"
                            defaultValue={source}
                            onChange={e => handleBookingSourceChange(e.target.value)}

                          >
                            <option value={[]} selected disabled>Choose Here</option>
                            <option value="api">API</option>
                            <option value="b2b">B2B</option>
                          </select>
                        </div>
                        <div className="w-2/12"></div>
                        <div className="w-2/12 self-center">
                          <button className="bg-j-orange hover:bg-red-300 rounded text-white p-2 pl-4 pr-4 text">
                            <p className="font-semibold text-xs">Reset Date</p>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        <p className="text-black mb-2 mt-10">Data {data.length} of {totalRecords} showing</p>
        {loading && (
          <>
            <h1 className="text-4xl">
              <LoadingIcon className="py-20 text-j-magenta" />
            </h1>
          </>
        )}
        {!loading && (
          // {data.length === 0 ? (
          //     <p className="text-center text-4xl text-gray-400 pt-10 pb-10">No Result Found</p>
          //   ) : (
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {totalRecords == 0 ? (
              <p className="text-center text-4xl text-gray-400 pt-10 pb-10">No Result Found</p>
            ) : (<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              {data.map(data => <Tile shadow className="mb-4">
                <div className="flex">
                  <div
                    theme="white"
                    className="w-1/4"
                  >
                    <img
                      src={LogoImage}
                      className="h-60"
                      alt="Cruise"
                      style={{
                        borderTopLeftRadius: "0.625rem",
                      }}
                    ></img>
                  </div>
                  <div
                    theme="white"
                    className="w-3/4 p-6"
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ fontSize: "1.15rem", fontWeight: "700", alignSelf: 'center' }}>
                          {data.itinerary.name} ({data.status})
                          </p>
                        {/* <Button
                            className="bg-j-orange text-j-white"
                            style={{ width: "22%" }}
                          >
                            Download Ticket
                          </Button> */}
                        <OrderDetail style={{ width: "22%" }}>
                          {data.invoice_url != null && <a href={`${data.invoice_url}`} target="_blank">
                            <i class="fa fa-download" aria-hidden="true"></i> Download Invoice
                            </a>}
                        </OrderDetail>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{data.itinerary.nights} Night Cruise</p>
                         <OrderDetail style={{ width: "22%" }}>
                          {data.evoucher_url != null && <a href={`${data.evoucher_url}`} target="_blank">
                             <i className="fa fa-download" aria-hidden="true"></i> Download E-Ticket/Voucher
                            </a>}
                        </OrderDetail>
                      </div>
                      <p style={{ fontSize: "1.05rem" }} className="mt-2">{moment(data.itinerary.start_time).format(
                        "D MMM YYYY"
                      )}-{moment(data.itinerary.end_time).format(
                        "D MMM YYYY"
                      )}</p>
                    </div>
                    <div
                      style={{ marginTop: "0.625rem", marginBottom: "0.625rem" }}
                    >
                      <p style={{ fontSize: "1.05rem" }}>
                        Guests:{" "}
                        <span style={{ fontWeight: "500" }}>
                          {data.total_guests} Guest
                          </span>
                      </p>
                      <p style={{ fontSize: "1.05rem" }}>
                        Booking Number:{" "}
                        <span style={{ fontWeight: "500" }}>
                          {data.number}
                        </span>
                      </p>
                      <p style={{ fontSize: "1.05rem" }}>
                        Booking Date:{" "}
                        <span style={{ fontWeight: "500" }}>
                          {data.booked_on}
                        </span>
                      </p>
                      <p style={{ fontSize: "1.05rem" }}>
                        Room:{" "}
                        {data.rooms.map((roomData, i) => (
                          <span style={{ fontWeight: "500" }}>
                            {roomData.name}{data.rooms.length - 1 != i ? ", " : ""}
                          </span>
                        ))}
                      </p>
                      <p style={{ fontSize: "1.05rem" }}>
                        Total Fare:{" "}
                        <span style={{ fontWeight: "500" }}>{currancyStatus.symbol} {data.total_amount}</span>
                      </p>
                      <p style={{ fontSize: "1.05rem" }}>
                        Source of Booking:{" "}
                        <span style={{ fontWeight: "500" }}>{data.created_via.toUpperCase()}</span>
                      </p>
                      {data.status == 'RESERVED' && <OrderDetail style={{ width: "22%" }}>
                        <div onClick={() => payPayment(data)} className="cursor-pointer">
                          <i class="far fa-money-bill-alt" aria-hidden="true"></i> Pay Balance
                            </div>
                      </OrderDetail>
                      }
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // marginBottom: "0.625rem",
                      }}
                    >
                      <div>
                        {/* <p style={{ fontSize: "1.35rem" }}>
                            Card Balance:{" "}
                            <span style={{ fontWeight: "500" }}>Rs. 20,000</span>{" "}
                          </p> */}

                      </div>
                      <Button
                        className="bg-j-orange text-j-white"
                        style={{ width: "22%" }}
                        onClick={() => viewDetails(data.id)}
                      >
                        Details >>
                        </Button>
                    </div>
                  </div>
                </div>
              </Tile>
              )}


              {currentPages != totalPages && <div className="w-full flex justify-center">
                <a className="text-center text-j-magenta text-2xl underline cursor-pointer" onClick={() => bookingListApi(count + 1, search, sDate, eDate, cDate, moreData = true, null)}>More List</a>
              </div>
              }
              {/* <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"> */}
              {/* <table className="table min-w-full divide-y divide-gray-200" {...getTableProps()}>
                    <thead className="bg-magenta">
                      {columns.map(header => (
                        <th className="px-10 py-3 text-left text-xs font-medium text-white uppercase tracking-wider text-center">{header.Header}</th>
                      ))}
                    </thead>
                    <tbody className="bg-white w-full" {...getTableBodyProps()}>
                      {page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td className="px-10 py-2 whitespace-nowrap text-sm text-center" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                          )
                      })}
                    </tbody>
                  </table>
                </div>
                <ul className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px w-full mt-6">
                    <li className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <a className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">First</a>
                    </li>
                    <li className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <a className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">{'<'}</a>
                    </li>
                    <li className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50" onClick={() => nextPage()} disabled={!canNextPage}>
                        <a className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">{'>'}</a>
                    </li>
                    <li className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <a className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">Last</a>
                    </li>
                    <li className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <a className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </a>
                    </li>
                    <li className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <a className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <input
                                className="form-control"
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px', height: '20px' }}
                            />
                        </a>
                    </li>{' '}
                    <div>
                      <select
                          className="flex justify-end"
                          value={pageSize}
                          onChange={e => {
                              setPageSize(Number(e.target.value))
                          }}
                          style={{ width: '120px', height: '55px' }}
                      >
                          {[5, 10, 20, 30, 40, 50].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                  Show {pageSize}
                              </option>
                          ))}
                      </select>
                    </div>

                </ul> */}
            </div>)}
          </div>)}
      </div>
      {/* {showModal ? (
        <>
          <div className="text-black">dfhkkjdfh</div> */}
      {/* <div
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex">
                  <div className="bg-white p-4 pl-4 pr-4">
                    <div className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2">
                      <span className="text-j-gray" style={{width: "500px"}}>
                            <RadioField
                                name="paymentOption"
                                defaultValue="bank"
                                paymentStatus= {true}
                                options={[
                                    {
                                        value: "card",
                                        label: "Credit Card / Debit Card",
                                        view: (
                                          <CardPaymentForm
                                            amount={amount}
                                            bookingID={bookingID}
                                            paymentPath="wallet"
                                            booking=""
                                            billingData=""
                                            partialStatus=""
                                            submitRef={submitRef}
                                            setFailed={setFailedError}
                                            walletStatus={false}
                                            className="bg-black"
                                          />
                                        ),
                                    },
                                    {
                                        value: "bank",
                                        label: "Net Banking",
                                        view: (
                                          <BankPaymentForm
                                            amount={amount}
                                            bookingID={bookingID}
                                            paymentPath="wallet"
                                            booking=""
                                            billingData=""
                                            partialStatus=""
                                            submitRef={submitRef}
                                            setFailed={setFailedError}
                                            walletStatus={false}
                                          />
                                        ),
                                      },
                                      {
                                        value: "upi",
                                        label: "UPI",
                                        view: (
                                          <UPIPaymentForm
                                            amount={amount}
                                            bookingID={bookingID}
                                            paymentPath="wallet"
                                            booking=""
                                            billingData=""
                                            partialStatus=""
                                            submitRef={submitRef}
                                            setFailed={setFailedError}
                                            walletStatus={false}
                                          />
                                        ),
                                      },
                                      {
                                        value: "wallet",
                                        label: "Cordelia Wallet",
                                        view: (
                                          <WalletPayment
                                            booking=""
                                            bookingID={bookingID}
                                            amount={amount}
                                            paymentPath="wallet"
                                            billingData=""
                                            partialStatus=""
                                            submitRef={submitRef}
                                            setFailed={setFailedError}
                                            walletStatus={false}
                                          />
                                        ),
                                      },
                                ]}
                            />
                        </span>
                    </div>
                  </div>
                       
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null} */}
      {/* </>): null } */}
    </>
  )
}

const OrderDetail = ({ title, children, big }) => (
  <>
    <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none">
      {title}
    </h1>
    <h4
      className={
        big ? "text-j-magenta text-3xl font-bold" : "text-j-magenta"
      }
    >
      {children}
    </h4>
  </>
);
