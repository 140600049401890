import React, { useState, useEffect } from "react";
import Button from "../../Button/Button";
import ModifyRouteCard from "../../RouteCard/ModifyRouteCard";

export const ModifyCard = (cardsData) => {
    console.log(cardsData)
  const [selectedViewId, setSelectedViewId] = useState(null);
  let itinerary = sessionStorage.getItem("itinerary");
  itinerary = JSON.parse(itinerary);
  let array = cardsData.cardData;
  let groupedDays = null;
  groupedDays = array.reduce(function (r, a) {
    r[a.no_days] = r[a.no_days] || [];
    r[a.no_days].push(a);
    return r;
  }, Object.create(null));
  for (const key in groupedDays) {
    groupedDays[key] = groupedDays[key].reduce(function (r, a) {
      r[a.cruice_name] = r[a.cruice_name] || [];
      r[a.cruice_name].push(a);
      return r;
    }, Object.create(null));
  }
  return (
    <>
      {Object.keys(groupedDays).map((key) => (
        <RoutesView key={key} nightCount={key} mainObj={groupedDays} monthHere={cardsData.monthHere} viewID={key} selectedViewID={selectedViewId} setSelectedViewId={setSelectedViewId} getPriceSummary={cardsData.getPriceSummary} byDate={cardsData.byDate}/>
      ))}
    </>
  );
};

export default ModifyCard;

export const RoutesView = ({ nightCount, mainObj, monthHere, selectedViewID, setSelectedViewId, viewID, getPriceSummary, byDate }) => {
  const [open, setOpen] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const id = `${nightCount}-nights`;

  useEffect(() => {
    setOpen(false);
    if (viewID === selectedViewID) {
      setOpen(true);
    }
  }, [selectedViewID]);

  let itinerary = sessionStorage.getItem("itinerary");
  itinerary = JSON.parse(itinerary);
  let groupedDays = mainObj;
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  const getNightCountIti = () => {
    let temp = null;
    temp = [];
    let count = nightCount || 2;
    let routesData = groupedDays[count];
    let arr = Object.values(routesData);
    for (let i in arr) {
      if (arr[i]) {
        let x = null;
        x = arr[i][0].itiId;
        for (let j in itinerary) {
          if (itinerary[j].id === x) {
            temp.push(itinerary[j]);
          }
        }
      }
    }
    let unique = [];
    let allMonthsItineraries = [];
    let arrMonth = [];
    unique = temp.filter(onlyUnique);
    if(monthHere) {
      for(let i = 0; i < unique.length; i++) {
        arrMonth = [];
        allMonthsItineraries = unique[i].itineraries;
        for(let j = 0; j < allMonthsItineraries.length; j++) {
          let allMonthChecker = Number((new Date(allMonthsItineraries[j].startTime)).getMonth()) + 1;
          if (allMonthChecker === Number(monthHere)) {
            arrMonth.push(allMonthsItineraries[j]);
          }
        }
        unique[i].itineraries = arrMonth;
      }
    }
    setDataArr(unique);
  };
  return (
    <div className="mt-4" style={{ maxWidth: "90%", margin: "1rem auto" }}>
      <h2 className="flex justify-between cursor-pointer select-none" id={id} onClick={() => {setSelectedViewId(viewID); setOpen(!open);getNightCountIti();}}>
        <span className="pt-1">{nightCount}-Night Cruises</span>
        <Button
            className="bg-j-white text-j-orange border border-j-orange"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              setOpen(!open);
              getNightCountIti();
            }}
          >
            {open ? 'Hide cruises' : 'View Cruises'}
          </Button>
      </h2>
      <br></br>
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4">
        {open &&
          dataArr.map((route) => (
            <ModifyRouteCard
              route={route}
              key={route.id}
              nightCount={nightCount}
              light
              getPriceSummary={getPriceSummary}
              byDate={byDate}
            />
          ))}
      </div>
    </div>
  );
};
