import React,{ Component, useContext, useState, useEffect, useRef } from "react";
import CardStats from "../../components/Cards/CardStats";
import CardTable from "../../components/Cards/CardTable";
import { getToken } from "../../utils/common";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import Tile from "../../components/Tile/Tile";
import InputField from "../../components/Form/InputField";
import CardPaymentForm from "../../components/Booking/payment-forms/CardPaymentForm";
import BankPaymentForm from "../../components/Booking/payment-forms/BankPaymentForm";
import UPIPaymentForm from "../../components/Booking/payment-forms/UPIPaymentForm";
import RadioField from "../../components/Form/RadioField";
import scrollTo from "../../utils/scrollTo";
import UserContext from "../../store/UserContext";
import { Link, useHistory } from "react-router-dom";
import NimbblPaymentForm from "../../components/Booking/payment-forms/NimbblPaymentForm";


class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProf: JSON.parse(localStorage.getItem("UserProfile")),
            loading:true,
            amount: null,
            transactionDetails: [],
            last_recharged_on: null
        }
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/balance`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              `Bearer ${getToken()}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({amount:response.amount, last_recharged_on: response.last_recharged_on})
          })

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/transactions`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              `Bearer ${getToken()}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({loading:false, transactionDetails:response})
          })
    }

    render() {
      if(this.state.userProf.access_types.includes('portal')) {
        return(
          <WalletUI balance={this.state.amount} loading={this.state.loading} transactionDetails={this.state.transactionDetails} last_recharged_on={this.state.last_recharged_on} userProf={this.state.userProf}/>
           
        )
      } else {
        return (
          <div>
            You're Not Authorized to View this Page.
          </div>
        )
      }
    }
}

export default Wallet;

const WalletUI = ({balance, loading, transactionDetails, last_recharged_on, userProf}) => {
  let history = useHistory();
  const submitRef = useRef(null);
  const [user, setUser] = useContext(UserContext)
  const [amount, setAmount] = useState();
  const [amountStatus, setamountStaus] = useState(false);
  const [minAmount, setminAmount] = useState(true);
  const [isFailed, setFailed] = useState(
    new URLSearchParams(window.location.search).get("fail")
  );
  const [step, setStep] = useState(sessionStorage.getItem("step"))
  const setFailedError = (error) => {
    setFailed(error);
    if (error) scrollTo("payment");
  };

  const callPayment = () => {
    if(!amount == '' && amount>= 1000) {
      setamountStaus(true)
    }
  }

  const cardDetails = () => {
    setamountStaus(false)
  }

  const setAmountFunction = (event) => {
    if(event.target.value >= 1000){
      setminAmount(false)
    } else {
      setminAmount(true)
    }
    setAmount(event.target.value)
  }
  
  const handleBooking = () => {
    history.push("/admin/dashboard");
  }
  return(
    <>
              <div class="grid grid-cols-2 gap-6 p-6 pl-10 pr-10">
                <div className="p-4">
                  <div>
                    <Tile theme="white" shadow className="my-2 bg-white rounded-xl">
                      <Tile.Inner>
                        <p className="text-black font-bold">
                          Cordelia Wallet Balance
                        </p>
                        <p className="text-black font-bold text-3xl pt-4">
                          &#x20B9; {balance}
                        </p>
                        {last_recharged_on != null && <p className="text-j-gray pt-4 text-sm">Last recharged on {last_recharged_on}</p>}
                      </Tile.Inner>
                    </Tile>
                  </div>
                  {loading && <>
                    <h1>
                      <LoadingIcon className="py-20 text-black" />
                    </h1>
                    </>
                  }
                  {!loading &&
                      <CardTable transactionData={transactionDetails}/>
                  }
                </div> 
                <div className="p-4">
                  <Tile theme="white" shadow className="my-2 bg-j-orange rounded-xl">
                    {!amountStatus && 
                    <Tile.Inner>
                      <div className="grid">
                        <div className="ml-10 mr-10">
                          <p className="text-j-white text-center">
                             Enter the amount<br/>(Minium recharge amount Rs.1000)
                          </p>
                          <input
                            placeholder="Rs.00,00,00"
                            name="name"
                            required
                            type="number"
                            defaultValue={amount}
                            min="1000"
                            className="h-10 rounded-3xl w-full mt-4 mb-6 p-4 text-black"
                            onChange={(event) => setAmountFunction(event)}
                          />
                           <button type="button" className="focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-magenta hover:bg-blue-600 hover:shadow-lg w-full mb-4 disabled:opacity-50" disabled={minAmount} onClick={callPayment}>Recharge Now</button>
                        </div>
                      </div>
                    </Tile.Inner>
                    }
                    {amountStatus && 
                    <div className="bg-white p-10 pl-10 pr-10 w-full">
                         <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2">
                            <h1 className="alt text-j-gray">Payment options</h1>
                            <button className="bg-grey-light focus:outline-none text-j-magenta font-bold py-2 px-4 rounded inline-flex items-center justify-end" onClick={()=>cardDetails(false)}>
                            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521,7.521.7.52z"/></svg>
                            <span>Change</span>
                            </button>
                        </div>
                        <p className="text-j-orange font-bold text-2xl mb-6 break-words"> Amount: &#x20B9;{amount}</p>
                        <span className="text-j-gray">
                            <RadioField
                                name="paymentOption"
                                defaultValue="bank"
                                paymentStatus= {true}
                                options={[
                                    {
                                        value: "mimbbl",
                                        label: "Pay Using Payment Gateway",
                                        view: (
                                            <NimbblPaymentForm
                                            amount={amount}
                                            bookingID=""
                                            billingData={userProf}
                                            bookingType="recharge"
                                            />
                                        ),
                                    },
                                    // {
                                    //     value: "card",
                                    //     label: "Credit Card / Debit Card",
                                    //     view: (
                                    //       <CardPaymentForm
                                    //         amount={amount}
                                    //         paymentPath="wallet"
                                    //         booking=""
                                    //         billingData=""
                                    //         partialStatus=""
                                    //         submitRef={submitRef}
                                    //         setFailed={setFailedError}
                                    //         walletStatus={true}
                                    //         className="bg-black"
                                    //         bookingType="recharge"
                                    //       />
                                    //     ),
                                    // },
                                    // {
                                    //     value: "bank",
                                    //     label: "Net Banking",
                                    //     view: (
                                    //       <BankPaymentForm
                                    //         amount={amount}
                                    //         paymentPath="wallet"
                                    //         booking=""
                                    //         billingData=""
                                    //         partialStatus=""
                                    //         submitRef={submitRef}
                                    //         setFailed={setFailedError}
                                    //         walletStatus={true}
                                    //         bookingType="recharge"
                                    //       />
                                    //     ),
                                    //   },
                                    //   {
                                    //     value: "upi",
                                    //     label: "UPI",
                                    //     view: (
                                    //       <UPIPaymentForm
                                    //         amount={amount}
                                    //         paymentPath="wallet"
                                    //         booking=""
                                    //         billingData=""
                                    //         partialStatus=""
                                    //         submitRef={submitRef}
                                    //         setFailed={setFailedError}
                                    //         walletStatus={true}
                                    //         bookingType="recharge"
                                    //       />
                                    //     ),
                                    //   },
                                ]}
                            />
                        </span>
                      </div>
                    }
                  </Tile>
                  {balance>0 && user.rechargeStatus && step == 6 &&
                    <p className="p-6 underline text-center cursor-pointer" onClick={handleBooking}>Go to Last Booking</p>
                  }
                </div>

              </div>
                {/* <div className="relative bg-magenta pt-16 pb-16 ">
                    <div className="px-4 md:px-10 mx-auto w-full">
                        <div className="text-center text-white">
                            <CardStats
                                statSubtitle="Cordelia Wallet Balance"
                                statTitle={this.state.amount}
                                statArrow="up"
                                cashstatus={true}
                                // statPercent="3.48"
                                // statPercentColor="text-green-500"
                                statDescripiron="Recharge Now"
                                statIconName="far fa-chart-bar"
                                statIconColor="bg-red-500"
                            />
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div className="flex flex-wrap mt-4">
                            <div className="w-full mb-12 px-4">
                            {this.state.loading && <>
                                <h1>
                                    <LoadingIcon className="py-20 text-black" />
                                </h1>
                                </>
                            }
                            {!this.state.loading &&
                                <CardTable transactionData={this.state.transactionDetails}/>
                            }
                            </div>
                        </div> */}
            </>
  )
}