import React, { useState } from "react"
import cx from "classnames"
import Overlay from "../../Overlay/Overlay"
import LoadingIcon from "../../Loading/LoadingIcon"
import View from "../../View/View"
import Button from "../../Button/Button"

function fetchFormattedTime(curArDpTime) {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  let d = new Date(curArDpTime)
  let day = days[d.getDay()]
  let hr = d.getHours()
  let min = d.getMinutes()
  if (min < 10) {
    min = "0" + min
  }
  let ampm = "am";
  if(hr === 12) {
    ampm = "pm";
  } else if (hr === 24) {
    hr -= 12;
    ampm = "am";
  } else if (hr > 12 && hr !== 12 && hr !== 24) {
    hr -= 12;
    ampm = "pm";
  }
  let date = d.getDate()
  let month = months[d.getMonth()]
  let year = d.getFullYear()
  let formattedTime = date + "," + month + " " + hr + ":" + min + " " + ampm
  return formattedTime
}
const ItineraryCard = ({ line, length }) => {
  const [buttonFlag, setButtonFlag] = useState(true)
  let arrTime = fetchFormattedTime(line.arrival_time)
  let departTime = fetchFormattedTime(line.departure_time)
  return (
    <div
      className="mt-4 bg-white"
      style={{
        position: "relative",
        textAlign: "center",
        borderRadius: "0.625rem",
        margin: "0 auto",
        marginBottom: "2rem",
        boxShadow: "5px 5px 30px -9px rgba(0,0,0,0.75)",
        width: "88%",
      }}
    >
      <img
        src={`https://images.cordeliacruises.com${line.image_url}`}
        alt="Itinerary"
        style={{ borderRadius: "0.625rem", minHeight: "320px", opacity: 0.6 }}
      ></img>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black"
        }}
      >
        {buttonFlag && line.title && (
          <span class="itineraryText">{line.title}</span>
        )}
        <br></br>
        {buttonFlag && line.sub_title && (
          <span class="itineraryText">{line.sub_title}</span>
        )}
        <br></br>
        {buttonFlag && line.arrival_time && (line.title.toLowerCase() !== "day 1") && (
          <span class="itineraryText">Arrival: {arrTime}</span>
        )}
        <br></br>
        {buttonFlag && line.departure_time && (line.title.toLowerCase() !== `day ${length}`) && (
          <span class="itineraryText">Depart: {departTime}</span>
        )}
        <br></br>
        {buttonFlag && (
          <Button
            className="text-j-white"
            style={{
              position: "relative",
              left: "10%",
              background: "rgba(80, 14, 75, 0.6)",
              color: "white"
            }}
            onClick={() => setButtonFlag(false)}
          >
            Learn More
          </Button>
        )}
      </div>
      {!buttonFlag && (
        <div
          style={{ color: "black", textAlign: "left", marginLeft: "0.625rem" }}
        >
          <br></br>
          <i
            className="fas fa-times cursor-pointer text-4xl float-right text-j-black"
            style={{ position: "relative", right: "2%" }}
            onClick={() => setButtonFlag(true)}
          />
          <br></br>
          {line.title && <span class="itineraryText">{line.title}</span>}
          <br></br>
          {line.sub_title && (
            <span class="itineraryText">{line.sub_title}</span>
          )}
          <br></br>
          {line.arrival_time && line.departure_time && (
            <span class="itineraryText">Arrival: {arrTime}</span>
          )}
          <br></br>
          {line.arrival_time && line.departure_time && (
            <span class="itineraryText">Depart: {departTime}</span>
          )}
          <br></br>
          <p style={{ margin: "1rem auto" }}>{line.content}</p>
          <br></br>
        </div>
      )}
    </div>
  )
}

const ItineraryOverlay = ({ onCancel, itineraryId, style = "back" }) => {
  const [itineraryContent, setItineraryContent] = React.useState([])
  const [itineraryLength, setItineraryLength] = React.useState(null);
  const [loading, setLoading] = useState(true)
  const button =
    style === "back" ? (
      <i
        className="fas fa-arrow-left cursor-pointer text-3xl text-j-white"
        onClick={onCancel}
        style={{ position: "relative", right: "-4%", marginTop: "-8%" }}
      />
    ) : style === "close" ? (
      <i
        className="fas fa-times cursor-pointer text-4xl float-right text-j-white"
        onClick={onCancel}
        style={{ position: "relative", right: "-4%", marginTop: "-8%" }}
      />
    ) : null

  const actionButton =
    button && onCancel ? (
      <div className={cx("flex", { "justify-end": style === "close" })}>
        {button}
      </div>
    ) : null

  React.useEffect(() => {
    if (itineraryId) {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/itineraries/${itineraryId}`)
        .then((res) => res.json())
        .then((content) => {
          setItineraryContent(content)
          setItineraryLength(content.contents[1].length);
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }, [itineraryId, loading])

  const menu = loading ? (
    <View>
      <div
        style={{
          margin: 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <LoadingIcon className="py-20 text-j-white" />
      </div>
    </View>
  ) : (
    <View>
      <div
        className="mt-4 bg-white"
        style={{
          padding: "1rem",
          borderRadius: "10px",
          margin: "0 auto",
          marginBottom: "2rem",
          boxShadow: "5px 5px 30px -9px rgba(0,0,0,0.75)",
          width: "88%",
        }}
      >
        <h2>{itineraryContent.obj.title}</h2>
        <div
          className="mt-4"
          style={{
            background: "rgb(240, 244, 249)",
            padding: "1rem",
            borderRadius: "10px",
            margin: "0 auto",
            marginBottom: "2rem",
          }}
        >
          {itineraryContent.obj.ite.map((p, idx) => (
            <h4
              className={cx("flex uppercase leading-tiny", "text-j-magenta")}
              key={p + idx}
              style={{ paddingTop: "1rem", paddingBottom: "0px" }}
            >
              <div className={cx("text-center w-12")}>
                <i className="fas fa-anchor"></i>
              </div>
              <span>{p}</span>
            </h4>
          ))}
        </div>
      </div>
      {itineraryContent.contents[1].map((line, index) => (
        <ItineraryCard key={"unique_" + index} line={line} length={itineraryLength} />
      ))}
    </View>
  )

  return (
    <Overlay
      actionButton={actionButton}
      className="bg-magenta text-white px-5 pt-8 pb-16 itinerarymodal"
      open
    >
      {menu}
    </Overlay>
  )
}

export default ItineraryOverlay
