import React, { useState, useEffect,useRef } from "react";
import Offercomponent from "../../components/OfferComponent/Offercomponent";
import Offer1 from "../../assets/images/offer1.jpg";
import Offer2 from "../../assets/images/offer2.jpg";
import Offer3 from "../../assets/images/offer3.jpg";
import Offer4 from "../../assets/images/offer4.jpg";
import Offer5 from "../../assets/images/offer5.jpg";
import Offer6 from "../../assets/images/offer6.jpg";
import Offer7 from "../../assets/images/offer7.jpg";
import Offer8 from "../../assets/images/offer8.jpg";
import cx from "classnames";
import pageStyle from "./dashboard.module.css";
import ReactTable from "../../components/ReactTable/ReactTable";
import { getToken } from "../../utils/common";
import moment from "moment";
import MultiSelectOption from "./multiselectOption";
import Popover from "@material-ui/core/Popover";
import UserContext from "../../store/UserContext";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import Layout from "../../components/Layout/Layout";
import Countdown from "react-countdown";
import InputField from "../../components/Form/InputField";

const options = [
  { value: "status: CONFIRMED", label: "Status: Booked" },
  { value: "status: RESERVED", label: "Status: Reserved" },
  { value: "created_via: API", label: "Source: API" },
  { value: "created_via: B2B", label: "Source: B2B" },
  { value: "created_via: B2C", label: "Source: B2C" },
  { value: "night_count: 2", label: "Itinerary: 2 Nights" },
  { value: "night_count: 3", label: "Itinerary: 3 Nights" },
  { value: "night_count: 4", label: "Itinerary: 4 Nights" },
  { value: "night_count: 5", label: "Itinerary: 5 Nights" },
  { value: "night_count: 6", label: "Itinerary: 6 Nights" },
  { value: "night_count: 7", label: "Itinerary: 7 Nights" },
];

export default function Dashboard() {
  const itineraryRef = useRef(null);
  const searchRef = useRef(null);
  let history = useHistory();
  const [tableData, setData] = useState([]);
  const [offers, setOffers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  let userName = window.sessionStorage.getItem("user");
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState();
  const [itinerary, setItinerary] = useState(null);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);
  const [itineraryData, setItineraryData] = useState([]);
  //const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true);
  const [perPage, setPerpage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useContext(UserContext);

  useEffect(() => {
    intialApi(1, "", perPage);
  }, []);

  const intialApi = (count, params, perPage) => {
    //remove when multicurency implimentation
    sessionStorage.setItem(
      "currency",
      JSON.stringify({
        code: "INR",
        symbol: "₹",
      })
    );
    setLoading(true);
    fetch(process.env.REACT_APP_API_ENDPOINT + "/agent_portal/offers", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setOffers(response.data.offers);
      });

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/bookings?page=" +
        count +
        params +
        "&per_page=" +
        perPage,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        var dummySetdata = [];
        var responseData = response.bookings;
        setItineraryData(response.itineraries);
        if (response.bookings.length == 0) {
          setData(dummySetdata);
          setTotalPages(0);
          setLoading(false);
        } else {
          responseData.map((data) => {
            dummySetdata.push({
              booked_on: moment(data.booked_on).format("DD MMM'YY"),
              reference_number: {
                ref_id: data.number,
                id: data.id,
                web_check_in_status: data.web_checkin_completed,
              },
              sailing_date: moment(data.itinerary.start_time).format(
                "DD MMM'YY"
              ),
              itinerary: {
                nights: `${data.itinerary.nights} nights`,
                place: data.itinerary.name,
              },
              source: data.created_via,
              status: data.status,
              payable_amount: {
                amount: data.pending_amount,
                status: data.status,
              },
              payment_due_date: {
                due_date: data.due_by,
                id: data,
                status: data.status,
                holded: data.is_holded,
              },
              download: {
                invoice: data.invoice_url,
                evoucher: data.evoucher_url,
                status: data.status,
              },
            });
          });
          console.log(dummySetdata);
          setData(dummySetdata);
          setTotalPages(response.pagination.total_pages);
          setLoading(false);
        }
      });
  };

  const DeparturePortsData = {
    ports: [
      {
        image: `url(${Offer1})`,
        title: (
          <>
            KIDS SAIL <b>FREE*</b>
          </>
        ),
        description: <p>A DEAL YOU SHOULDN’T MISS</p>,
        link: "https://www.cordeliacruises.com/cruise-deals",
      },
      {
        image: `url(${Offer2})`,
        title: (
          <>
            <b>FREE</b> STATEROOM UPGRADE*
          </>
        ),
        description: <p>EXCLUSIVE OFFER FOR 5 NIGHT SAILINGS</p>,
        link: "https://www.cordeliacruises.com/cruise-deals",
      },
      // {
      //   image: `url(${Offer3})`,
      //   title:(<>BOOK NOW, <b>PAY LATER*</b></>),
      //   description: (<p>
      //     BOOK WITH JUST 25%</p>),
      //   link:'https://www.cordeliacruises.com/cruise-deals',
      // },
      // {
      //   image: `url(${Offer8})`,
      //   title:(<><b>FREE</b> RESCHEDULING*</>),
      //   description: (<p>
      //     UPTO 48 HRS PRIOR TO SAILING</p>),
      //   link:'https://www.cordeliacruises.com/cruise-deals',
      // },
      // {
      //   image: `url(${Offer4})`,
      //   title:(<><b>Upto 15% </b> Commission*</>),
      //   description: (<p>
      //     EXTENDED FLEXIBILITY NOW ON YOUR FLIGHTS TOO.</p>),
      //   link:'https://www.cordeliacruises.com/cruise-deals',
      // },
      {
        image: `url(${Offer7})`,
        title: (
          <>
            SUBSCRIBE US ON<b> YOUTUBE</b>
          </>
        ),
        description: (
          <p>
            FOLLOW US FOR <b>LATEST UPDATES</b>
          </p>
        ),
        link: "https://www.youtube.com/channel/UCIGZzyqWsbCH1-VNFsXrY9g",
      },
      {
        image: `url(${Offer6})`,
        title: (
          <>
            JOIN US ON<b> TELEGRAM</b>
          </>
        ),
        description: (
          <p>
            JOIN US FOR <b>DAILY UPDATES</b>
          </p>
        ),
        link: "https://t.me/cordeliacruises",
      },
    ],
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewDetails = (id, web_check_in_status) => {
    history.push({
      pathname: "/admin/booking-details",
      state: { id: id, web_check_in_status: web_check_in_status },
    });
  };

  const PDFS = [
    "https://www.cordeliacruises.com/cruise-deals",
    "https://www.cordeliacruises.com/cruise-deals",
    "https://www.cordeliacruises.com/cruise-deals",
  ];

  const handleCompleteBooking = (id) => {
    history.push({
      pathname: "/admin/complete-hold-booking",
      state: { bookingID: id },
    });
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    // Render a countdown
    console.log(hours, "----hours");
    return <span>{hours}</span>;
  };

  const handleRemainingHours = (props) => {
    var due = new Date(props.value.due_date);
    var todayDate = new Date();
    var milliseconds = due.getTime() - todayDate.getTime();
    var eventdate = moment(props.value.id.time_left_to_confirm_hold_booking);
    var todaysdate = moment();
    var extraHour = moment(eventdate).add(1, "hours");
    var timeRemains = extraHour.diff(todaysdate, "hours");
    // return ( moment(Date.now() + milliseconds).hour())
    return timeRemains;
    // return <Countdown
    //   date={Date.now() + 5000}
    //   renderer={renderer}
    // />;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Booking Date",
        accessor: "booked_on",
        Cell: (props) => {
          return <p style={{ width: "55px" }}>{props.value}</p>;
        },
      },
      {
        Header: "Reference ID",
        accessor: "reference_number",
        maxWidth: 60,
        Cell: (props) => {
          return (
            <p
              className="hover:underline font-bold"
              style={{
                width: "80px",
                wordBreak: "break-word",
                color: "#500E4B",
                fontSize: "bold",
                cursor: "pointer",
              }}
              onClick={() =>
                viewDetails(props.value.id, props.value.web_check_in_status)
              }
            >
              {props.value.ref_id}
            </p>
          );
        },
      },
      {
        Header: "Sailing Date",
        accessor: "sailing_date",
        maxWidth: 60,
        Cell: (props) => {
          return (
            <p style={{ width: "55px", wordBreak: "break-word" }}>
              {props.value}
            </p>
          );
        },
      },
      {
        Header: "Itinerary",
        accessor: "itinerary",
        width: 60,
        Cell: (props) => {
          return (
            <>
              <div
                className="flex justify-center"
                style={{ width: "60px", wordBreak: "break-word" }}
              >
                {props.value.nights}
                <span>
                  <Popup
                    trigger={(open) => (
                      <i className="fas fa-info-circle" variant="contained"></i>
                    )}
                    position="right center"
                    closeOnDocumentClick
                  >
                    <div>
                      <p className="bg-gray-300 pt-3 pl-3 pr-3 text-sm rounded-t-lg ml-2">
                        {" "}
                        {props.value.nights}{" "}
                      </p>
                      <p className="bg-gray-300 pb-3 pl-3 pr-3 text-sm rounded-b-lg ml-2">
                        {" "}
                        {props.value.place}{" "}
                      </p>
                    </div>
                  </Popup>
                </span>
              </div>
            </>
          );
        },
      },
      {
        Header: "Source",
        accessor: "source",
        maxWidth: 60,
        // Cell: (props) => {
        //   return (
        //     <div>
        //       <button className="bg-j-orange hover:bg-magenta text-white p-2 rounded-xl text-xs" onClick={()=>{
        //           handleDetails(props.value)
        //       }}>Details<span className="pl-1"><i className="fas fa-arrow-circle-right"></i></span></button>
        //     </div>
        //   );
        // },
      },
      {
        Header: "Status",
        accessor: "status",
        width: 60,
        Cell: (props) => {
          return (
            <>
              {props.value == "RESERVED" && (
                <>
                  <p className="text-orange-500 mr-2 font-bold">Reserved </p>
                </>
              )}
              {props.value == "CONFIRMED" && (
                <>
                  <p className="text-green-500 mr-2 font-bold">Confirmed </p>
                </>
              )}
              {props.value == "CANCELLED" && (
                <>
                  <p className="text-red-500 mr-2 font-bold">Cancelled </p>
                </>
              )}
              {props.value == "MODIFIED" && (
                <>
                  <p className="text-blue-500 mr-2 font-bold">Modified </p>
                </>
              )}
            </>
          );
        },
      },
      {
        Header: "Payable Amt.",
        accessor: "payable_amount",
        width: 60,
        Cell: (props) => {
          return (
            <>
              {props.value.status != "RESERVED" ? (
                <p className="flex justify-center">NA</p>
              ) : (
                <div className="flex justify-center">
                  &#x20b9; {props.value.amount}
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Payment Due Date",
        accessor: "payment_due_date",
        width: 300,
        Cell: (props) => {
          return (
            <>
              {props.value.status != "RESERVED" ? (
                props.value.holded ? (
                  props.value.status != "CANCELLED" ? (
                    <div
                      className="flex justify-center"
                      style={{
                        width: "200px",
                        wordBreak: "break-word",
                        cursor: "pointer",
                      }}
                    >
                      <span className="pt-1">
                        {handleRemainingHours(props) + " Hours"}
                      </span>{" "}
                      <span
                        className="pl-2 pr-2 pt-1 pb-1 bg-j-orange text-white rounded ml-2"
                        onClick={() => handleCompleteBooking(props.value.id)}
                      >
                        {"Complete booking"}
                      </span>
                    </div>
                  ) : (
                    <p className="flex justify-center">NA</p>
                  )
                ) : (
                  <p className="flex justify-center">NA</p>
                )
              ) : (
                <div
                  className="flex justify-center"
                  style={{
                    width: "200px",
                    wordBreak: "break-word",
                    cursor: "pointer",
                  }}
                >
                  <span className="pt-1">
                    {props.value.holded
                      ? handleRemainingHours(props) + " Hours"
                      : moment(props.value.due_date).format("DD MMM'YY")}
                  </span>
                  <span
                    className="pl-2 pr-2 pt-1 pb-1 bg-j-orange text-white rounded ml-2"
                    onClick={() =>
                      props.value.holded
                        ? handleCompleteBooking(props.value.id)
                        : payPayment(props.value.id)
                    }
                  >
                    {props.value.holded ? "Complete booking" : "Pay Now"}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Download",
        accessor: "download",
        width: 60,
        Cell: (props) => {
          return (
            <>
              <div
                className="flex justify-center text-j-magenta font-bold"
                style={{ width: "200px" }}
              >
                {props.value.invoice !== null && (
                  <span>
                    <a
                      className="bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white p-2 m-1"
                      href={props.value.invoice}
                    >
                      <i class="fa fa-download" aria-hidden="true"></i> Invoice
                    </a>{" "}
                  </span>
                )}
                {props.value.invoice !== null &&
                  props.value.evoucher !== null && <span>&nbsp;</span>}
                {props.value.evoucher !== null &&
                  props.value.status !== "RESERVED" && (
                    <span>
                      <a
                        className="bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white p-2 m-1"
                        href={props.value.evoucher}
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>{" "}
                        E-Voucher
                      </a>
                    </span>
                  )}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

  const payPayment = (data) => {
    console.log(data);
    setUser(Object.assign({}, user, { bookingID: data }));
    history.push({
      pathname: "/admin/pay-due-amount",
      state: { data: data },
    });
  };

  let data = tableData;

  const handlePageChange = (event, value) => {
    console.log(value);
    setPage(value);
    intialApi(value, "", perPage);
  };

  const handleFirst = () => {
    setPage(1);
    intialApi(1, "", perPage);
  };

  const handleLast = () => {
    setPage(totalPages);
    intialApi(totalPages, "", perPage);
  };

  const handleSelect = (selected, type) => {
    console.log("type", type);
    let itineraryId = '';
    let cNumber = '';
    let x = '';
  
    // Determine the type and construct the URL accordingly
    if (type === "search") {
      cNumber = `c_number=${selected.target.value}`;
    } else {
      itineraryId = `itinerary_ids=${selected.value}`;
    }
  
    // Construct the URL with both parameters if both are present
    if (itineraryId && cNumber) {
      x = `${itineraryId}&${cNumber}`;
    } else if (itineraryId) {
      x = itineraryId;
    } else if (cNumber) {
      x = cNumber;
    }
  
    console.log(x);

  
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings?page=1&${x}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        const dummySetdata = [];
        const responseData = response.bookings;
        
        if (response.bookings.length === 0) {
          setData(dummySetdata);
          setTotalPages(0);
          setLoading(false);
        } else {
          responseData.forEach((data) => {
            dummySetdata.push({
              booked_on: moment(data.booked_on).format("DD MMM'YY"),
              reference_number: {
                ref_id: data.number,
                id: data.id,
                web_check_in_status: data.web_checkin_completed,
              },
              sailing_date: moment(data.itinerary.start_time).format("DD MMM'YY"),
              itinerary: {
                nights: `${data.itinerary.nights} nights`,
                place: data.itinerary.name,
              },
              source: data.created_via,
              status: data.status,
              payable_amount: {
                amount: data.pending_amount,
                status: data.status,
              },
              payment_due_date: {
                due_date: data.due_by,
                id: data,
                status: data.status,
                holded: data.is_holded,
              },
              download: {
                invoice: data.invoice_url,
                evoucher: data.evoucher_url,
                status: data.status,
              },
            });
          });
          console.log(dummySetdata);
          setData(dummySetdata);
          setTotalPages(response.pagination.total_pages);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  

  const handleSelectChange = (values) => {
    console.log(values);
    setSelected(values);

    var params = "&";
    var created_via_param = "";
    var night_count_param = "";
    var status_param = "";
    values.map((data) => {
      var words = data.value.split(": ");
      console.log(words);
      if (words[0] == "created_via") {
        created_via_param += words[1] + ",";
      }
      if (words[0] == "night_count") {
        night_count_param += words[1] + ",";
      }
      if (words[0] == "status") {
        status_param += words[1] + ",";
      }
    });
    params +=
      "created_via=" +
      created_via_param +
      "&" +
      "night_count=" +
      night_count_param +
      "&" +
      "status=" +
      status_param;
    console.log(params);
    intialApi(1, params, perPage);
  };

  const handleClearAll = () => {
    setSelected([]);
    intialApi(1, "", perPage);
    setSearch('')
    setItinerary(null)
    if (itineraryRef.current) {
      itineraryRef.current.clearValue();
    }
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const handlePageCount = (e) => {
    console.log(e.target.value);
    setPerpage(e.target.value);
    intialApi(1, "", e.target.value);
  };

  const transformedOptions = itineraryData?.map((option) => ({
    value: option.id,
    label: option.option_name,
  }));

  return (
    <Layout>
      <div className="h-full bg-white">
        <main class=" h-full">
          <div>
            <Offercomponent
              DeparturePortsData={DeparturePortsData.ports}
              pdfs={PDFS}
            />
          </div>
          <div className="pl-8 pr-8">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <p className={cx(pageStyle["welcomeText"])}>Welcome Back</p>
                <p className={cx(pageStyle["username"])}>{userName}</p>
              </div>
              <div className="flex justify-center">
                <button
                  className=" view-cruises-btn rounded-lg transition duration-300 ease-in-out bg-j-orange text-sm text-white text-center pl-20 pr-20 pt-3 pb-3 font-normal"
                  onClick={() =>
                    history.push({
                      pathname: "/admin/routes",
                    })
                  }
                >
                  Book a Cabin
                </button>
              </div>
              <div className="flex justify-end">
                {/* <select  className="border border-gray text-gray pl-10 pr-10 pt-1 pb-1 rounded-lg">
                                  <option className="text-black p-2" value="INR">INR</option>
                                  <option className="text-black p-2" value="USD">USD</option>
                            </select> */}
              </div>
            </div>
            <div className="mt-8 grid grid-cols-4 gap-4 items-baseline ">
              <MultiSelectOption
                options={options}
                onChange={handleSelectChange}
                isMulti
                value={selected}
                label={"Filter By:"}
              />
              <MultiSelectOption
                options={transformedOptions}
                ref={itineraryRef}
                onChange={(e) => {handleSelect(e, "itinerary")
                setItinerary(e.value)
                }}

                value={selected}
                label={"Filter By Itinerary:"}
              />
              <div className="" >
              <label className="font-bold">Search by Booking Id:</label>
              <input
                className={'w-full mt-4 h-10 px-2 border border-gray-400 w-full text-black rounded flex-grow max-w-full placeholder-j-gray-lighter text-black text-md placeholder-gray-500 placeholder-opacity-75 self-center pt-1 leading-none'}
                name={`search`}
                ref={searchRef}
                placeholder="search"
                onChange={(e) => {handleSelect(e, "search")
                setSearch(e.target.value)}}
              />
              </div>
             
              <div>
                <div className="w-full flex justify-end">
                  <p
                    className="text-j-orange cursor-pointer"
                    onClick={handleClearAll}
                  >
                    Clear all
                  </p>
                </div>
              </div>
            </div>

            <div>
              <ReactTable
                columns={columns}
                data={data}
                // renderRowSubComponent={renderRowSubComponent}
                count={totalPages}
                page={page}
                handlePageChange={handlePageChange}
                handleFirst={handleFirst}
                handleLast={handleLast}
                loading={loading}
                handlePageCount={handlePageCount}
              />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
}

// const Tooltip = ({data}) => {
//   console.log(data)
//   return(
//     <ReactTooltip id="registerTip" place="right" effect="solid">
//       <p>{data.nights}</p>
//       <p>{data.place}</p>
//     </ReactTooltip>
//   )
// }
