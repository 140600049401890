import Select, { components as Components } from "react-select";


const MultiSelectOption = (props) => {
  const { isMulti, value, onChange,label } = props;

  const handleRemoveValue = (selectedValue) => {
      console.log(selectedValue)
      console.log(value)
    if (!onChange) return;
    const buttonName = selectedValue;
    const removedValue = value.find((val) => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter((val) => val.value !== buttonName),
    //   { name, action: "remove-value", removedValue }
    );
  };

  return (
    <div className="flex">
        <div className="w-10/12 ">
            <div className="w-full">
                <span className="w-3/12 pt-2 pr-2 font-bold">{label}</span>
                <Select {...props} controlShouldRenderValue={!isMulti} className="w-full mt-4" placeholder="Choose Filter"/>
            </div>
            <div className="w-7/12 pl-2 flex">
                {isMulti && label === 'Filter By:'
                ? value.map((val) => (
                    <div key={val.value}>
                        <div className="flex bg-gray-200 ml-2 pl-3 pr-3 pt-1 pb-1 mt-4 rounded-lg">
                            {val.label}
                            <div onClick={()=> handleRemoveValue(val.value)} className="pl-2 cursor-pointer ">
                                ✕
                            </div>
                        </div>
                    </div>
                    ))
                : null}
            </div>
        </div>
         
    </div>
  );
};

export default MultiSelectOption;
