import React, { Component, useRef, useEffect } from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Link, useHistory  } from "react-router-dom";

class Header extends Component {
    state = {
    }

    componentDidMount() {
      let userName = window.sessionStorage.getItem('user');
    }
  
    render() {
      return (
        <>
          <HeaderUI />
        </>
      )
    }
  }

export default Header;

const HeaderUI = () => {
  let history = useHistory();

  const wallet = () => {
    history.push('/wallet');
  }

  return(
    <div>
      <nav className="bg-basecolor">
        <div className=" relative">
          <div className="relative z-10 mx-auto lg:flex justify-between px-6 pt-8 lg:pt-8 pb-6 lg:pb-8">
            <div className="grid grid-cols grid-cols-2 w-full">
                <div className="text-white">Cordelia cruises</div>
                <div className="text-right text-white" onClick={wallet}>{window.sessionStorage.getItem('user')}</div>
            </div>
          </div>
        </div> 
      </nav>
    </div>
  )
}