import React, { useEffect } from "react";
import LoadingIcon from "../../components/Loading/LoadingIcon";

const PaymentLoader = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const res = queryParams.get("response");
    if (res) {
      triggerCallback(res);
    } else window.location = "/admin/dashboard";
  });

  const triggerCallback = (res) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/agent_portal/payments/callback`;
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);
    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "response");
    hiddenField.setAttribute("value", res);
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  };
  return (
    <div>
      <h2 className="text-center mt-4 m-2 p-2">
        Please wait for completing the booking process. We are re-directing you
        to booking confirmation page.
      </h2>
      <LoadingIcon className="text-4xl" />
    </div>
  );
};

export default PaymentLoader;
