import React,{ useEffect,useState } from 'react';
import ShoreExcursion from '../../components/Booking/PostBookingShoreExcursion'

const index=({})=> {
    return (
        <>
            <main className="bg-auto">
                <div className="view-override" theme="blue">
                    <ShoreExcursion />
                </div>
            </main>
        </>
    );
}

export default index;
