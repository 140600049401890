import React, {useState, Component, setUser, useEffect } from 'react';
import BookingFormView from "./General/BookingFormView";
import Tile from "../Tile/Tile";
import Button from "../Button/Button";
import cx from "classnames";
import scrollTo from "../../utils/scrollTo";
import UserContext from "../../store/UserContext";
import { Link, useHistory } from "react-router-dom";

var newArrayData = [];
class GuestSelectionView extends Component {

    render() {
        const { values } = this.props;
        return (
            <>
                {/* <div className="grid grid-cols-3 border-b p-6">
                    <div>

                    </div>
                    <div className="text-center text-basecolor font-bold">Title of cruise trip</div>
                </div> */}
                <BookingClass nextStep={this.props} />
            </>
        )
    }
}
export default GuestSelectionView;

const BookingClass = ({nextStep}) => {
    let history = useHistory();
    const [user, setUser] = React.useContext(UserContext);
    const booking = user.booking || {};

    // TODO change me:
    const maxRooms = nextStep.maxRoom;
    const [rooms, setRooms] = useState(booking.rooms || [createRoom(0)]);
    const maxRoomsReached = rooms.length >= maxRooms;
    // const [lastModifiedRoom, setLastModifiedRoom] = useState(null);
    const [renderView, setRenderView] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const [lastModifiedRoom, setLastModifiedRoom] = useState([]);

    useEffect(() => {
        setRenderView(true)
      },[])

    const changeRooms = (room) =>
        setRooms(
            rooms.map((r) => (r.id === room.id ? Object.assign({}, r, room) : r))
        );

    const removeRoom = (id) => {
        setRooms(
            rooms
                .filter((r) => r.id !== id)
                .map((r, i) => Object.assign({}, r, { id: i }))
        );
    };

    const handleSubmit = (e) => {
        rooms.map(room => {
            if(lastModifiedRoom.includes(room.id)) {
                room.selected = null;
                room.room_id = null;
                room.room_number = null;
                room.total_price = null;
            }
        })
        setUser(
            Object.assign({}, user, {
                booking: Object.assign({}, booking, { rooms }),
            })
        );
        e.preventDefault()
        setDisabled(true);
        nextStep.nextStep()
    }

    return (
        <>
            {renderView && <><BookingFormView
                title="Please add cabins and guests"
                id="room-form"
                lessSpace={true}
                // nextStep={nextStep.nextStep()}
                onClick={handleSubmit}
                disabled={disabled}
            >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {rooms.map((room) => (
                        <RoomView
                            key={room.id}
                            room={room}
                            visitorID={booking.visitorID}
                            onChange={changeRooms}
                            onRemove={room.id > 0 ? () => removeRoom(room.id) : null}
                            id={`room-${room.id}`}
                            setLastModifiedRoom={setLastModifiedRoom}
                        />
                    ))}
                    <div
                        className={cx(
                            "rounded-lg flex flex-col",
                            "text-black bg-white",
                            "shadow-xl",
                            "pb-14"
                        )}
                    >
                        <div className="bg-j-orange w-full -bottom-0 text-white p-4 mb-10 rounded-lg">
                            <p className="text-j-magenta font-medium text-base">Exclusive offers for your booking:</p>
                            <ul class="list-inside bg-rose-200 text-base ml-4 mt-4 text-white" style={{ listStyle: 'inside' }}>
                            <li className="text-white">Free sailing for kids upto 12 years of age, across stateroom categories*</li>
                            <li className="text-white">Free upgrade from Interior to Ocean View stateroom on full payment at the time of booking for 5 Night Sailings*</li>
                            </ul>
                            <p className="text-white font-medium text-base mt-4 mb-4">The offers are valid on all bookings made until 30th september 2024.</p>
                            <a className="text-sm text-white italic ">
                                Terms & Conditions apply*{" "}
                            </a>
                        </div>
                        <Tile.Inner style={{ alignSelf: 'center', justifyContent: 'center', padding: '0', margin: '0' }}>
                            {!maxRoomsReached && (
                                <Button
                                    className="bg-j-orange text-white w-full pt-1"
                                    onClick={() => {
                                        setRooms([
                                            ...rooms.map((r) => Object.assign({}, r, { open: false })),
                                            createRoom(rooms.length),
                                        ]);
                                        scrollTo(`room-${rooms.length}`);
                                    }}
                                >
                                    Add another cabin
                                </Button>
                            )}
                            {maxRoomsReached && (
                                <p className="font-medium text-base px-5 pb-4 mt-4 mb-4 italic text-center">For bookings more than {nextStep.maxRoom} cabins, please contact our customer support team via email or call. info@cordeliacruises.com / 02268811111</p>
                            )}
                        </Tile.Inner>
                    </div>
                    {/* <Tile className="mb-8 rounded-lg" shadow style={{alignItems:'center', justifyContent: 'center', minHeight: '300px', padding: '0'}}>
                    <div className="bg-j-orange w-full -bottom-0 text-white p-4">
                           <p>Book now to get benefits on May - July 2021 sailings:</p>
                           <ul class="list-inside bg-rose-200" style={{listStyle:'inside'}}>
                               <li>Kids sail free*</li>
                               <li>Book with just 25%*</li>
                               <li>Free rescheduling until 7 days prior to sailing*</li>
                           </ul>
                           <p>A perfect holiday getaway for your guests!</p>
                           <a>Terms and conditions apply* </a>
                        </div>
                        <Tile.Inner style={{alignSelf:'center', justifyContent: 'center',padding: '0', margin: '0'}}>
                        {!maxRoomsReached && (
                        <Button
                            className="bg-j-orange text-white w-full pt-1"
                            onClick={() => {
                                setRooms([
                                ...rooms.map((r) => Object.assign({}, r, { open: false })),
                                createRoom(rooms.length),
                                ]);
                                scrollTo(`room-${rooms.length}`);
                            }}
                        >
                            Add another room
                        </Button>
                        )}
                        </Tile.Inner>
                    </Tile> */}
                </div>
            </BookingFormView>
            </>
        }
        </>
    )
}

const RoomView = ({ room, onChange, onRemove, visitorID, setLastModifiedRoom, ...rest }) => {

    const isFull = room.adults + room.children + room.infants >= room.max;
    const roomHeader = (
        <div className="flex justify-between">
            <h4
                className="text-j-magenta uppercase self-center cursor-pointer"
            >
                Cabin {room.id + 1}
            </h4>
            {onRemove && (
                <p
                    className="leading-none text-xs text-j-orange pt-2 cursor-pointer text-xl"
                    onClick={onRemove}
                >
                    Remove
                </p>
            )}
        </div>
    );

    const updateRoom = (data) => {
        const newRoom = Object.assign({}, room, data);
        if(!newArrayData.includes(room.id)){
            newArrayData.push(room.id);
        }else{
          // newArrayData.splice(newArrayData.indexOf(room.id), 1);
        }
        //newArrayData.push(room.id)
        console.log(newArrayData)
        setLastModifiedRoom(newArrayData)
        onChange(newRoom);
    };

    return (
        <Tile className="rounded-lg" {...rest} shadow>
            <Tile.Top>
                {roomHeader}
                <p className="text-j-gray flex pt-4 pb-4 text-sm">
                    <i className="fas fa-bed pr-4 pt-1" />
                    Cabins can accommodate 2, 3 or 4 guests
                </p>
                <WidgetView
                    min={1}
                    value={room.adults}
                    disabled={isFull}
                    icon="fas fa-restroom"
                    title="Adults"
                    description="12 & above"
                    onChange={(value) => updateRoom({ adults: value })}
                />
                <WidgetView
                    value={room.children}
                    disabled={isFull}
                    icon="fas fa-child"
                    title="Children"
                    description="2 years - 12 years"
                    onChange={(value) => updateRoom({ children: value })}
                />
                <WidgetView
                    value={room.infants}
                    disabled={isFull}
                    icon="fas fa-baby"
                    title="Infants"
                    description="6 months - 2 years"
                    onChange={(value) => updateRoom({ infants: value })}
                />
            </Tile.Top>
            <p className="text-j-magenta flex pl-4 pt-2 pb-4 text-sm">
                *Infants below 6 months are not allowed to travel
            </p>
        </Tile>
    )
    // const [open, setOpen] = useState(false);
    // const isFull = room.adults + room.children + room.infants >= room.max;

}

const WidgetView = ({ value, min = 0, icon, title, description, disabled, onChange }) => (
    <div className="flex justify-between mb-4">
        <Button
            className={cx(
                "text-white text-4xl w-12 self-center",
                value > min ? "bg-j-orange" : "bg-j-gray-lighter"
            )}
            smallPadding
            unbold
            disabled={value <= min}
            onClick={() => onChange(value - 1)}
        >
            -
        </Button>
        <div className="flex-grow self-center text-center">
            <div className="h-9">
                {value ? (
                    <span className="text-3xl font-bold leading-none">{value}</span>
                ) : (
                    <i className={cx(icon, "text-3xl text-j-gray-lighter")} />
                )}
            </div>
            <p className="leading-4 text-sm uppercase text-j-magenta">{title}</p>
            <p className="text-xs uppercase text-j-gray">{description}</p>
        </div>
        <Button
            className={cx(
                "text-white text-4xl w-12 self-center",
                disabled ? "bg-grey-600" : "bg-j-orange"
            )}
            smallPadding
            unbold
            disabled={disabled}
            onClick={() => onChange(value + 1)}
        >
            +
            </Button>
    </div>
)

const createRoom = (id) => ({
    id,
    adults: 1,
    children: 0,
    infants: 0,
    min: 1,
    max: 4,
    open: true,
});
