import React from "react";
import Layout from "../../components/Layout/Layout";
// import Header from "../../components/Header/Header";
// import FooterView from "../../components/FooterAlt/Footer";
import cx from "classnames";
import View from "../../components/View/View";
import { useHistory } from 'react-router-dom'
import styles from "./managebooking.module.css";

const ManageBooking = ({}) => {
  let history = useHistory();
  const [bookingId, setBookingId] = React.useState();
  const [addGuest,setAddGuest] = React.useState(false);
  const [removeCabin,setRemoveCabin] = React.useState(false);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.id && params.removeCabin && params.addGuest) {
      setBookingId(params.id);
      if(params.removeCabin==="false"){
        setRemoveCabin(false);
      } else setRemoveCabin(true); 
      if(params.addGuest==="false"){
        setAddGuest(false);
      } else setAddGuest(true);
    } else window.location = "/my-bookings";
  }, [setBookingId]);

  return (
    <Layout title="My Bookings">
      {/* <Header
        className="bg-j-magenta text-j-white"
        bgImages={[
          "/images/my-booking-web.webp",
          "/images/my-booking-mobile.webp",
        ]}
        isManangeBooking
      ></Header> */}
      <main className="bg-auto">
        {/* <div className={cx("bg-j-magenta text-j-white p-6")} id="filters">
          <div className="flex justify-between cursor-pointer">
            <span className="self-center">My Bookings</span>
          </div>
        </div> */}
        <View>
          <div className="md:mx-10 py-8 h-screen">
            <h3 className="font-medium mb-9">What would you like to modify?</h3>

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-9">
              {addGuest&&<div
                className={styles.card}
                onClick={() =>
                  history.push(`/admin/manage-booking/add-guest?id=${bookingId}`)
                }
              >
                <img
                  src="/images/manage-booking/edit_icon.png"
                  alt="Edit"
                  className={styles.editIcon}
                />
                <img
                  src="/images/manage-booking/add_guest.png"
                  alt="Add guest"
                  className="mx-auto mt-4 mb-8"
                />
                <p className="text-center font-medium">Add guest</p>
              </div>}
              {removeCabin&&<div
                className={styles.card}
                onClick={() =>
                  history.push(`/admin/manage-booking/remove-cabin?id=${bookingId}`)
                }
              >
                <img
                  src="/images/manage-booking/edit_icon.png"
                  alt="Edit"
                  className={styles.editIcon}
                />
                <img
                  src="/images/manage-booking/remove_cabin.png"
                  alt="Add guest"
                  className="mx-auto mt-2 mb-6"
                />
                <p className="text-center font-medium">Remove Cabin</p>
              </div>}
              {/* <div
                className={styles.card}
                onClick={() =>
                  history.push(`/manage-booking/change-cabin?id=${bookingId}`)
                }
              >
                <img
                  src="/images/manage-booking/edit_icon.png"
                  alt="Edit"
                  className={styles.editIcon}
                />
                <img
                  src="/images/manage-booking/modify_cabin.png"
                  alt="Add guest"
                  className="mx-auto mt-4 mb-8"
                />
                <p className="text-center font-medium">Change Cabin</p>
              </div> */}
            </div>
            <div>
              <h3 className="font-bold mb-6 text-xl">Important Notes</h3>

              <h3 className="font-bold mb-4 text-xl">Add guest:</h3>
              <ul className={styles.list}>
              <li>
                Need room for more of your loved ones on your cruise trip? We’ve got you covered! 
                </li>
                <li>Just add the number of guests you’d like to add by clicking the “Add Guest” button, and that’s it!</li> 
              </ul>
              <p className="mb-2 text-sm">Please note that:</p>
                <ul className={styles.list}>
                  <li>
                    A modification fee and the additional fare difference will be added to your overall trip cost.
                  </li>
                </ul>

              <h3 className="font-bold mt-4 mb-4">Remove cabin:</h3>
              <ul className={styles.list}>
                <li>Booked multiple cabins but now some of your guests cannot make it to the trip? Don’t worry!</li>
                <li>Canceling a cabin is as easy as it gets. Simply click the “Cancel Cabin” button and choose the cabin(s) you wish to cancel</li>
                <li>Please note that you’ll have to pay an additional fee for the same as per our cancelation policy 
                </li>
              </ul>
            </div>
          </div>
        </View>
      </main>
      {/* <footer>
        <FooterView />
      </footer> */}
    </Layout>
  );
};

export default ManageBooking;
