import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
// import Header from "../../../components/Header/Header";
// import FooterView from "../../../components/FooterAlt/Footer";
import cx from "classnames";
import View from "../../../components/View/View";
// import { useRouter } from "next/router";
import UserContext from "../../../store/UserContext";
import { getToken } from "../../../utils/common";

const RemoveCabin = () => {
  const [user, setUser] = useContext(UserContext);
  const [bookingId, setBookingId] = useState();
  const [bookingNumber, setBookingNumber] = useState();
  const [cabins, setCabins] = useState();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.id) {
      setBookingId(params.id);
      fetchBookingDetails(params.id);
    } else window.location = "/my-bookings";
  }, [setBookingId]);

  const fetchBookingDetails = (id) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/agent_portal/bookings/" + id + "/details",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setCabins(response.booking.rooms);
        setBookingNumber(response.booking.number)
      });
  };

  const RenderCabin = ({ cabin, count, removeDisabled = false }) => {
    return (
      <div className="rounded-lg shadow-card bg-white">
        <div className="bg-j-orange-lighter rounded-t-lg p-4">
          {!removeDisabled && (
            <button
              className="bg-j-orange text-white p-2 px-3 rounded mb-2 mt-2 float-right"
              onClick={() => {
                const _data = {
                  removeCabin: {
                    cabin: cabin,
                  },
                };
                localStorage.setItem("manageBooking", JSON.stringify(_data));
                window.location = `/admin/manage-booking/confirm?action=remove-cabin&id=${bookingId}&cabinId=${cabin.id}`;
              }}
            >
              Remove Cabin
            </button>
          )}
          <p className="font-bold text-xl mb-2" style={{minHeight:'4rem'}}>
            Cabin {count + 1}: {cabin.category}
          </p>

          <p className="text-j-gray-light">
            Deck No. {cabin.deck_no} | Room No.: {cabin.number}
          </p>
        </div>
        <div className="bg-j-white rounded-b-lg p-4">
          <div className="grid grid-cols-2 gap-5">
            {cabin.guests.map((guest, count) => {
              return (
                <div>
                  <p className="text-j-gray-light mb-3 text-base">
                    Guest {count + 1}
                  </p>
                  <p className="mb-2 text-base font-bold">
                    {guest.first_name + " " + guest.last_name}
                  </p>
                  <p className="text-j-gray-light text-base uppercase">{guest.gender}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Layout title="My Bookings">
      {/* <Header
        className="bg-j-magenta text-j-white"
        bgImages={[
          "/images/my-booking-web.webp",
          "/images/my-booking-mobile.webp",
        ]}
        isManangeBooking
      ></Header> */}
      <main className="bg-auto">
        <div className={cx("bg-j-magenta text-j-white p-6")} id="filters">
          <div className="flex justify-between cursor-pointer">
            {/* <span className="self-center">My Bookings</span> */}
          </div>
        </div>
        <View>
          <div className="md:mx-10 py-8 h-full">
            <h3 className="text-xl text-j-magenta font-medium mb-9">
              Booking No: {bookingNumber}
            </h3>
            <h3 className="font-medium mb-9">
              Select Cabin You want to Remove
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {cabins &&
                (cabins.length > 1
                  ? cabins.map((cabin, count) => (
                      <RenderCabin cabin={cabin} count={count} />
                    ))
                  : cabins.map((cabin, count) => (
                      <RenderCabin cabin={cabin} count={count} removeDisabled />
                    )))}

              {/* <div className="rounded-lg shadow-card">
                <div className="bg-j-orange-lighter rounded-t-lg p-4">
                  <button className="bg-j-orange text-white p-2 px-3 rounded mb-2 mt-2 float-right">
                    Remove Cabin
                  </button>
                  <p className="font-bold text-xl mb-2">Cabin 01: Interior</p>

                  <p className="text-j-gray-light">
                    Deck No. 05 | Room No.: 9062
                  </p>
                </div>
                <div className="bg-j-white rounded-b-lg p-4">
                  <div className="grid grid-cols-2 gap-5">
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-lg shadow-card">
                <div className="bg-j-orange-lighter rounded-t-lg p-4">
                  <button className="bg-j-orange text-white p-2 px-3 rounded mb-2 mt-2 float-right">
                    Remove Cabin
                  </button>
                  <p className="font-bold text-xl mb-2">Cabin 01: Interior</p>

                  <p className="text-j-gray-light">
                    Deck No. 05 | Room No.: 9062
                  </p>
                </div>
                <div className="bg-j-white rounded-b-lg p-4">
                  <div className="grid grid-cols-2 gap-5">
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                    <div>
                      <p className="text-j-gray-light mb-3 text-base">
                        Guest 1
                      </p>
                      <p className="mb-2 text-base font-bold">
                        Manish Ambaliya
                      </p>
                      <p className="text-j-gray-light text-base">Male</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </View>
      </main>
      {/* <footer>
        <FooterView />
      </footer> */}
    </Layout>
  );
};

export default RemoveCabin;
