import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import cx from "classnames";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./slick-customized.css";
import pageStyle from "../../pages/Dashboard/dashboard.module.css";
import Image from "../../assets/images/offer1.png"
import "@fortawesome/fontawesome-free/css/all.min.css";


const Offercomponent = ({DeparturePortsData,pdfs}) => {

    console.log(DeparturePortsData)

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1450,
              settings: {
                dots: true,
                slidesToShow: 4,
                slidesToScroll: 2,
                infinite: false,
              },
            },
            {
              breakpoint: 1028,
              settings: {
                dots: true,
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: false,
              },
            },
            {
              breakpoint: 992,
              settings: {
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
              },
            },
            {
              breakpoint: 767,
              settings: {
                dots:true,
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                dots:true,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
    };

    const PlaceCard = ({ data, wrapperclass, index }) => {
        return (
            <div 
                className={cx("flex flex-col justify-end", pageStyle["this-destination-box"], wrapperclass)}
                style={{ backgroundImage: data.image }}
            >
              <div className={cx(pageStyle["this-title-container"])}>
                <p className={cx("text-white w-full text-center ")}>{data.title}</p>
              </div>
              <div className={cx(pageStyle["this-description-container"],"flex flex-col justify-end")}>
                   {!data.fromDine && <div className={cx(pageStyle["this-description-area"])}> 
                        {data.description}
                        <a
                            href={data.link}
                            target="_blank"
                            class="mx-auto view-cruises-btn transition duration-300 ease-in-out bg-j-orange text-sm text-white text-center mb-40 pl-10 pr-10 pt-2 pb-2"
                        >
                            Know More
                        </a>
                    </div>}
                </div>
           </div>
        );
    };

    return(
        <div className="p-6">
            <div className="flex flex-row items-center justify-between mb-2">
            <p className={cx(pageStyle["heading"],"pl-2 pb-3")}>Ongoing Offers</p>
            <a href="https://www.linkedin.com/company/cordelia-cruises/mycompany/?viewAsMember=true">
            <div className="flex flex-row justify-center items-center text-j-magenta">
              <p className="text-xs text-center pr-2 text-2xl">For latest updates and offers follow us on : </p>
              <i className="fab fa-linkedin text-4xl"></i>
            </div>
            </a>
            </div>
            <Slider {...settings} className="packagesCardSlider -mr-10">
                {DeparturePortsData.map((data,index) => (
                    <PlaceCard data={data} index={index} wrapperclass={pageStyle["placecard"]} />
                ))}
            </Slider>
        </div>
    )
}

export default Offercomponent