import React, { useState, useEffect } from "react";
import cx from 'classnames';
import Button from '../../Button/Button'
import BookingOverlay from "../General/BookingOverlay";
import SelectionDetailsView from './SelectionDetailsView';
import { getToken } from "../../../utils/common";

import styles from './DeckOverlay.module.css'

const DeckOverlay = ({ itinerary, deck, rooms, roomIndex, selections, onClose, onDeckClose, onSelect, selectedCategory, itineraryID }) => {
    const room = rooms[roomIndex]
    const [openDetails, setOpenDetails] = useState(false);
    const [openID, setOpenID] = useState(null);
    const [deckRooms, setDeckRooms] = useState([]);
    // const [openFromFlag, setOpenFromFlag] = useState(true);
    // const [deckRoom, setDeckRoom] = useState(null);
    const deckRoom = deckRooms.find(room => room.number === openID);
    var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT+'/agent_portal/itineraries/'+itinerary.id+'/decks/'+deck.id+'/layout?beds='+ (room.adults + room.children + room.infants) +'&category_id='+selectedCategory.category_id,{
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                `Bearer ${getToken()}`,
              },
            })
          .then((res) => res.json())
          .then((deckrooms) => {
            var selectedRooms = rooms.map(function(room){return room.room_id})
            deckrooms = deckrooms.map(addDeckPrice).map(function(item){
                if(item.itinerary_room_id!==null&&selectedRooms.includes(item.itinerary_room_id)){
                    item.available = null;
                    item.selection = null;
                }
                return item;
            })
            setDeckRooms(deckrooms);
          });
    }, [setDeckRooms]);

    function addDeckPrice(room){
        var total_price, category, actual_total_price
        if(room.available){
            category = selections[roomIndex].categories.find(category => category.priceKey === selectedCategory.priceKey)
            // For sub categories
            if(!category){
                category= selections[roomIndex].categories.find(category => category.sub_category.find(subCategory=>subCategory.priceKey === selectedCategory.priceKey))
                category =category.sub_category.find(subCategory=>subCategory.priceKey === selectedCategory.priceKey)
            }
            // category = selections[roomIndex].categories.find(category => (category.name === room.category))
        }
        else{
            category = null
        }
        if(category){
            total_price = category.price.total + deck.price
            actual_total_price = category.price.actual_total + deck.price
        }
        return {...room, total_price: total_price, actual_total_price: actual_total_price, selection: category }
    }

    const onSelectRoom = (index, selected, deckRoom) => {
        setOpenDetails(false);
        onSelect(index, selected, deckRoom);
    }

    if (deckRoom && openDetails) {
        let selection = selections[roomIndex];
        let subCategory=[];
        selection.categories.map(category=>{if(category.sub_category) category.sub_category.map(sub=>subCategory.push(sub))})
        selection.categories=selection.categories.concat(subCategory)
        const selectionIndex = selection.categories.findIndex(cat => cat.priceKey === deckRoom.selection.priceKey)
        return (
            <SelectionDetailsView
                rooms={rooms}
                selections={selection.categories}
                roomIndex={roomIndex}
                selectionIndex={selectionIndex}
                onClose={() => setOpenDetails(false)}
                onSelect={onSelectRoom}
                deckRoom={deckRoom}
                noSwitch
                itineraryID={itineraryID}
                itinerary={itinerary}
            />
        )
    }

    return (
        <BookingOverlay
            style="close"
            onCancel={onClose}
        >
            <h2 className="uppercase pb-4">Cabin {roomIndex + 1}</h2>
            <h2>Choose a room</h2>
            <p className="pb-8">Select an available room on the map below to see price and category details</p>
            <div className={cx(styles.container)} style={{ gridTemplateAreas: "'" + buildLayoutGrid(deckRooms).join("' '") + "'"}}>
                <ShipFront />
                <h4 style={{ gridArea: 'dck' }} className="text-center py-4 uppercase font-bold">
                    Deck {deck.id}
                </h4>
                <div style={{ gridArea: 'pre' }} className={styles.item}>{deck.description}</div>
                <div style={{ gridArea: 'exp' }} className="flex justify-around uppercase text-xs mt-6 mb-14">
                    <div>
                        <i className="fas fa-circle text-xl pr-2" /> Available
                    </div>
                    <div>
                        <i className="far fa-circle text-xl pr-2" /> Unavailable
                    </div>
                    <div>
                        <i className="fas fa-circle text-xl pr-2 text-j-green-lightest" /> Obstructed
                    </div>
                </div>
                {deckRooms.map(room => (
                    <Room
                        key={room.number}
                        room={room}
                        deck={deck}
                        onClick={() => setOpenID(room.number)}
                        open={openID === room.number}
                    />
                ))}
            </div>
            {deckRoom && (
                <div className={styles.modal}>
                    <div className="text-xs uppercase text-black">
                        Deck {deck.id}: <span className="text-magenta font-medium">Room {deckRoom.number}</span> <br />
                        Category: <span className="font-bold">{deckRoom.category}</span>
                    </div>
                    <h4 className="flex justify-between pt-4 pb-6 text-j-red-light uppercase font-medium">
                        <div>
                        {itinerary.discount_pct != 0 ? <span className="mr-3 line-through text-sm">{currancyStatus.symbol} {(deckRoom.actual_total_price).toLocaleString("hi-IN")}</span> : null}
                        <span>{currancyStatus.symbol} {(deckRoom.total_price).toLocaleString("hi-IN")}</span>
                        </div>
                        <span className="cursor-pointer select-none" onClick={() => {
                            setOpenDetails(true);
                            // onSelect(roomIndex, deckRoom.selection, deckRoom);
                            }}>
                            SEE DETAILS
                        </span>
                    </h4>
                    <Button
                        className="w-full border border-j-red-light bg-j-red-light text-white mb-3 pt-1"
                        onClick={() => {
                            setOpenID(null);
                            onSelect(roomIndex, deckRoom.selection, deckRoom);
                        }}
                    >
                        Select this room
                    </Button>
                    <Button
                        className="w-full border text-j-magenta border-j-magenta bg-white pt-1"
                        onClick={() => setOpenID(null)}
                    >
                        Cancel
                    </Button>
                </div>
            )}
        </BookingOverlay>
    )
}

export default DeckOverlay;

const Room = ({ room, open, onClick }) => {
    return (
        <div
            className={cx(
                "border border-j-white rounded-lg flex px-2 py-4 justify-center items-center text-center text-xs uppercase select-none",
                room.selection && "cursor-pointer",
                room.selection ? (open ? "bg-j-orange" : room.obstructed_room ? "bg-j-green-lightest text-white":"bg-white text-black") : (room.available == null ? "bg-agentGreen" : null))

            }
            style={{ gridArea: `r${room.y}${room.x}`}}
            onClick={() => room.selection && onClick()}>
            { room.number}
        </div >
    )
}

const ShipFront = () => (
    <div style={{ gridArea: 'tri' }} className="relative">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335 90" className="w-full">
            <g fill="transparent">
                <path d="M 167.4999694824219 2.352119445800781 C 166.0182800292969 2.352119445800781 164.5452575683594 2.722793579101562 163.2401428222656 3.424079895019531 L 35.47882080078125 72.07197570800781 C 33.53909301757812 73.11422729492188 32.11114501953125 74.67193603515625 31.34933471679688 76.57675933837891 C 30.65557861328125 78.31137084960938 30.53530883789062 80.30712127685547 31.0107421875 82.19636535644531 C 31.48617553710938 84.08557891845703 32.53671264648438 85.78668975830078 33.96881103515625 86.98633575439453 C 35.54147338867188 88.30368804931641 37.53665161132812 89.00000762939453 39.7386474609375 89.00000762939453 L 295.2613525390625 89.00000762939453 C 297.4633483886719 89.00000762939453 299.45849609375 88.30368804931641 301.0311279296875 86.98633575439453 C 302.4632873535156 85.78668975830078 303.5138244628906 84.08557891845703 303.9892578125 82.19633483886719 C 304.4646301269531 80.30712127685547 304.3444213867188 78.31137084960938 303.6506652832031 76.57675933837891 C 302.8888549804688 74.67193603515625 301.4608764648438 73.11422729492188 299.5211791992188 72.07197570800781 L 171.7598266601562 3.424079895019531 C 170.4546813964844 2.722793579101562 168.9816436767578 2.352119445800781 167.4999694824219 2.352119445800781 M 167.4999847412109 1.35211181640625 C 169.1276550292969 1.35211181640625 170.7553405761719 1.749137878417969 172.2331390380859 2.543190002441406 L 299.9945068359375 71.19108581542969 C 309.1502990722656 76.11061859130859 305.6551208496094 90.00000762939453 295.2613525390625 90.00000762939453 L 39.7386474609375 90.00000762939453 C 29.3448486328125 90.00000762939453 25.84970092773438 76.11061859130859 35.0054931640625 71.19108581542969 L 162.7668151855469 2.543190002441406 C 164.24462890625 1.749137878417969 165.8722991943359 1.35211181640625 167.4999847412109 1.35211181640625 Z" stroke="none" fill="#fff" />
            </g>
        </svg>
        <div className="absolute text-center text-sm w-full" style={{ bottom: 20, left: 0 }}>Front of the ship</div>
    </div>
)


function getDeckRooms(deck){

    return Array(44).fill(0).map((_, i) => {
        const number = i + 1;
        const did = `${deck.id}`
        const rid = `${number}`
        const name = `${deck.id}${new Array(6 - did.length - rid.length).join('0')}${rid}`
        const selection = (deck.rooms && deck.rooms.find(room => room.number === number)) || null;
        return { number, name, selection: selection ? selection.category : null }
    })
}

function buildLayoutGrid(rooms) {
  let gridmap = {};
  let maxX = 0;
  let maxY = 0;
  rooms.forEach(function(room){
    if (!gridmap[room.y]) {
      gridmap[room.y] = {};
    }
    gridmap[room.y][room.x] = room;

    if (room.y > maxX) maxX = room.y;
    if (room.x > maxY) maxY = room.x;
  });
  let gridString = [new Array(maxY+1).fill("tri").join(" "),
        new Array(maxY+1).fill("dck").join(" "),
        new Array(maxY+1).fill("pre").join(" "),
        new Array(maxY+1).fill("exp").join(" ")];
  let roomNo = 1;
  for (let x=0; x <= maxX; x++) {
    let gridLine = "";
    for (let y=0; y <= maxY; y++) {
      if (gridLine != "") {
        gridLine += " "
      }
      if (gridmap[x] && gridmap[x][y]){
        if (gridmap[x][y].number.indexOf('lift') != -1){
            gridLine += ("lift" );    
        }else{
            gridLine += ("r" + x + y);
        }
      } else {
        gridLine += ".";
      }
    }
    gridString.push(gridLine);
  }
  return gridString;
}

