import React, { Component, useContext, useEffect, useState } from "react";
import Tile from "../../components/Tile/Tile";
import UserContext from "../../store/UserContext";
import SummaryView from "../../components/Booking/General/SummaryView";
import { getToken } from "../../utils/common";
import moment from "moment";
import Button from "../../components/Button/Button";
// import Header from "../../components/Header/header";
// import Footer from "../../components/Footer/footer";
// import Cards, { Card } from "../../components/Booking/Cards/cards";
// import { IoCaretDownOutline } from "react-icons/io5";
// import LoadingIcon from "../../components/Loading/LoadingIcon"

const Success = () => {
      const [invoice_url, setInvoiceUrl] = useState("")
      const [evoucher_url, setEvoucherUrl] = useState("")
      const [displayStatus, setDisplayStatus] =useState(false)
      const [evoucherdisplayStatus, setevoucherDisplayStatus] =useState(false)
      const [user, setUser] = useContext(UserContext);
      const [responseData, setResponseData] = useState(null)
      const [apiSuccess, setapiSuccess] = useState(false)
      // setUser(Object.assign({}, user, {bookingID:null}));
      // displayStatus: true

  useEffect(() => {
    let data = sessionStorage.getItem('shoreExbookingID');
    console.log(data)
    setUser(Object.assign({}, user, {bookingID:null, success_status: true , rechargeStatus: false}))
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${data}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (response) => {
        console.log(response)
        if (response.status === 200) {
          let data = await response.json();
          setResponseData(data)
          if(data.booking.invoice_url != null){
            setDisplayStatus(true);
          } 
          if(data.booking.evoucher_url != null) {
            setevoucherDisplayStatus(true);
          }
          sessionStorage.getItem('booking', data)
          setInvoiceUrl(`${data.booking.invoices[0]}`)
          setapiSuccess(true)
          // this.setState({ invoice_url: `${process.env.REACT_APP_API_ENDPOINT}/${data.invoice_url}` });
        } else {
          let data = await response.json();
          alert(data.message);
        }
      })
      .catch((err) => err);
  },[])

  return (
      <>
        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-magenta m-10 h-full">
          <div className="grid grid-cols-2">
            <span className="inline-block align-middle mr-8">
              <b className="capitalize">Booking</b> Success
              <h4 className="pb-5">
              <i className="fas fa-check-circle text-j-green text-3xl pr-2 pt-2" />
              We have received your payment. 
              </h4>
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2">
          {displayStatus && <div className="text-center justify-center text-j-magenta">
            <i className="fas fa-download text-3xl pr-2 pt-2" />
              <a href={invoice_url} target="_blank" className="text-center">Download the Invoice</a>
            </div>}
            </div>
            {apiSuccess && <TicketPreview responseData={responseData} />}
      </>
    );
  }

const OrderDetail = ({ title, children, big }) => (
    <>
      <h1 className="uppercase pb-0 text-tiny leading-none" style={{color: "black"}}>
        {title}
      </h1>
      <h4  className="pb-6 text-j-magenta">
        {children}
      </h4>
    </>
  );

export default Success;

const TicketPreview = ({responseData}) => {

  console.log(responseData)
  
  const [user, setUser] = useContext(UserContext);
  const booking = user.booking || {};
  let bookigStore = JSON.parse(window.sessionStorage.getItem('booking'));
  // const totalPrice = bookigStore.amount_paid;
  
  return(
    <>
      
      <div className="m-8 mb-12 pb-12">
        {/* <SummaryView booking={booking} /> */}
        <Tile tiny className="mb-5 border border-j-gray-lighter">
          <Tile.Inner className="pb-0">
              <OrderDetail title="ORDER ID">{responseData.booking.number}</OrderDetail>
              <OrderDetail title="Ship">
                {responseData.booking.itinerary.name}
              </OrderDetail>

              <div className="grid grid-cols-2">
                <div>
                  <OrderDetail title="Departure">
                    {responseData.booking.itinerary.ports[0].city}
                    <br></br>
                    {moment(responseData.booking.itinerary.start_time).format("ddd, D MMM YYYY, h:mm a")}
                  </OrderDetail>
                </div>
                <div>
                  <OrderDetail title="Arrival">
                    {responseData.booking.itinerary.ports[responseData.booking.itinerary.ports.length-1].city}
                    <br></br>
                    {moment(responseData.booking.itinerary.end_time).format("ddd, D MMM YYYY, h:mm a")}
                  </OrderDetail>
                </div>
              </div>
              {responseData.booking.rooms
                          .map((k, index) => 
                <OrderDetail title={`Cabin ${index+1}`}>
                {k.category}<br/>
                                Deck No: {k.deck_no}<br/>
                                Room No: {k.number}<br/>
                </OrderDetail>
                          )}
                 <div
                  style={{
                    marginTop:"10px",
                    marginBottom: "18px",
                    color: "#500E4B",
                    fontWeight: "bold",
                  }}
                  className="grid grid-cols-1"
                >
                  <a href="https://images.cordeliacruises.com/static/insurance_web_document.pdf" target="_blank" className="text-j-magenta font-bold">
                  Cordelia Cruises Insurance Benefit Document
                  </a>
                </div>
                {responseData.booking.status==="CONFIRMED"&&responseData.check_in_open&&
                <Button className="mx-auto bg-magenta text-white mb-5" onClick={()=> window.location=`${process.env.REACT_APP_API_WEB_CHECK_IN_URL}/web-check-in/guest-details/${responseData.booking.number}`}>
                  Proceed to Web Check In
                </Button>
                }
                {responseData&&responseData.booking.shorex_available &&<Button className="mx-auto bg-magenta text-white mb-5" onClick={()=> window.location=`/admin/post-booking-addon?bookId=${booking.id}`}>
                  Choose Addon 
                </Button>}
            </Tile.Inner>
      </Tile>
  </div>
  </>
  )
  
}
