import React, { Component, useRef, useMemo, useState, useEffect } from "react";
// import ReactLoading from 'react-loading';
import Pagination from "@material-ui/lab/Pagination";
import { useTable, useExpanded } from "react-table";
import { makeStyles } from '@material-ui/core/styles';
import LoadingIcon from "../Loading/LoadingIcon";


const useStyles = makeStyles((theme) => ({
    ul: {
        // "& .MuiPaginationItem-root": {
        //   backgroundColor: "#500E4B",
        //   color:'#fff'
        // }
    }
  }));

export default function ReactTable({columns, data, loading, renderRowSubComponent, count, page, handlePageChange, handleFirst, handleLast, handlePageCount}) {

    // const classes = useStyles();

    console.log(loading)

    const tableInstance = useTable({ columns, data },  useExpanded)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { expanded },
      } = tableInstance

    return (
        <>
            <div className="w-full lg:w-5/6">
                <div className="bg-white shadow-lg rounded my-4">
                    <table class="w-full table-auto rounded--xl" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr class="bg-magenta text-white rounded-t-xl text-xs" {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th class="pt-3 pb-3 text-center pl-3 text-xs" 
                                            {...column.getHeaderProps({
                                                style: { maxWidth: column.maxWidth}
                                            })}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                                    </thead>
                                    <tbody class="text-gray-600 text-xs font-light" {...getTableBodyProps()}>
                                        {(rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <React.Fragment>
                                                    {!loading && <tr class="border-b border-gray-200 hover:bg-gray-100 text-left" {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                        return (
                                                            <td class="text-left pt-4 pb-4 text-gray-900 pl-2" {...cell.getCellProps()}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        )})}
                                                    </tr> }
                                                    {row.isExpanded ? (
                                                        <tr>
                                                        <td colSpan={visibleColumns.length}>
                                                            {renderRowSubComponent({ i })}
                                                            </td>
                                                        </tr>
                                                    ):null}
                                                </React.Fragment>
                                            )
                                        }))}
                                        {loading && <tr class="border-b border-gray-200 hover:bg-gray-100">
                                            <td class="py-3 px-6 text-left whitespace-nowrap text-gray-900" colSpan="9"><div className="opacity-60 flex justify-center"><LoadingIcon /></div></td>
                                        </tr>}
                                    </tbody>
                                </table>
                                {data.length == 0 && !loading &&  <div className="w-full">
                                    <p className="w-full text-center text-4xl text-gray-400 pt-10 pb-10">No Result Found</p>
                                    </div>
                                }
                            </div>
                            <div className="flex">
                                <div className="flex justify-start w-3/12 pt-4">
                                    <p className="pr-2 pt-1">Show</p>
                                    <select onChange={(e)=>handlePageCount(e)} className="border rounded p-1 h-8">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                    </select>
                                    <p className="pl-2 pt-1">Entries per page</p>
                                </div>
                                <div className="flex w-2/12 pt-6 justify-end">
                                    <p className="text-sm">Page{page}/{count}</p>
                                </div>
                                <div className="pt-2 flex justify-end mr-6 w-7/12">
                                    <p className="my-5" onClick={handleFirst}>First</p>
                                    <Pagination
                                        className="my-3"
                                        count={count}
                                        page={page}
                                        siblingCount={1}
                                        boundaryCount={1}
                                        shape="rounded"
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                    <p className="my-5" onClick={handleLast}>Last</p>
                                </div>
                            </div>
                            
                        </div>
        </>
    )
}