import React, { Component, useRef } from "react";
import Input from "../../components/Form/LoginInput";
import DisabledInput from "../../components/Form/DisabledInput";
import { getToken } from "../../utils/common";
import LoadingIcon from "../../components/Loading/LoadingIcon";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      phoneNumber: "",
      loaderFlag: false,
    };
  }

  setPhoneNumber = (value) => {
    this.setState({
      phoneNumber: Number(value),
    });
  };

  switchSteps = (value) => {
    this.setState({
      step: Number(value),
    });
  };

  setLoaderFlag = (value) => {
    this.setState({
      loaderFlag: value,
    });
  };

  switchState = () => {
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <PhoneComponent
            switchSteps={this.switchSteps}
            setPhoneNumber={this.setPhoneNumber}
            onState={this.state}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
      case 2:
        return (
          <VerifyPhoneNumber
            switchSteps={this.switchSteps}
            setPhoneNumber={this.setPhoneNumber}
            onState={this.state}
            setLoaderFlag={this.setLoaderFlag}
          />
        );
    }
  };

  render() {
    return <>{this.switchState()}</>;
  }

}

const phonenumber = (inputtxt) => {
  let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneno.test(inputtxt);
}

const ChangeNumberApi = (payload, switchSteps, setPhoneNumber, onState, setLoaderFlag) => {
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/update_mobile`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      if (response.status === 200) {
        let data = await response.json();
        setPhoneNumber(payload.phone_number);
        switchSteps(2);
        setLoaderFlag(false)
      } else {
        let data = await response.json();
        alert(data.message);
        setLoaderFlag(false)
      }
    })
    .catch((err) => err);
};

const ChangeNumberVerifyApi = (payload, switchSteps, setPhoneNumber, onState, setLoaderFlag) => {
  const {
    step,
    phoneNumber,
    loaderFlag,
  } = onState;
  payload.phone_number = phoneNumber;
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/verify_update_mobile_otp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      if (response.status === 200) {
        let data = await response.json();
        alert("Phone Number Change Successful");
        setPhoneNumber(null);
        switchSteps(1);
        setLoaderFlag(false);
      } else {
        let data = await response.json();
        alert("Invalid OTP");
        setLoaderFlag(false);
      }
    })
    .catch((err) => err);
};

const PhoneComponent = ({ switchSteps, setPhoneNumber, onState, setLoaderFlag }) => {
  const {
    step,
    phoneNumber,
    loaderFlag,
  } = onState;
  let form = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoaderFlag(true);
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    if(phonenumber(payload.phone_number)) {
      ChangeNumberApi(payload, switchSteps, setPhoneNumber, onState, setLoaderFlag);
    } else {
      alert("Please Enter a Valid 10 Digit Phone Number");
      setLoaderFlag(false);
    }
  };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Change Number</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-4">
          <form
            ref={form}
            onSubmit={handleSubmit}
            className="w-full text-j-magenta p-20"
          >
            <div>
              <Input
                id="phone_number"
                name="phone_number"
                placeholder="New Phone Number"
                type="number"
              />
            </div>
            <div className="px-2 sm:px-6">
              {
                (!loaderFlag) ? (
                  <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                    Submit
                  </button>) : (
                  <div>
                    <h1 className="text-4xl">
                      <LoadingIcon className="py-20 text-j-magenta" />
                    </h1>
                  </div>)
              }
            </div>

          </form>
        </div>
      </div>
    </>
  );
}

const VerifyPhoneNumber = ({ switchSteps, setPhoneNumber, onState, setLoaderFlag }) => {
  const {
    step,
    phoneNumber,
    loaderFlag,
  } = onState;
  let form = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoaderFlag(true);
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    ChangeNumberVerifyApi(payload, switchSteps, setPhoneNumber, onState, setLoaderFlag);
  };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Change Password</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-4">
          <form
            ref={form}
            onSubmit={handleSubmit}
            className="w-full text-j-magenta p-20"
          >
            <div>
              <DisabledInput
                id="phone_number"
                name="phone_number"
                placeholder="Phone Number"
                type="number"
                value={phoneNumber}
              />
              <Input
                id="otp"
                name="otp"
                placeholder="OTP"
                type="number"
              />
            </div>
            <div className="px-2 sm:px-6">
              {
                (!loaderFlag) ? (
                  <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-j-orange rounded text-white px-8 py-3 text-sm mt-6">
                    Submit
                  </button>) : (
                  <div>
                    <h1 className="text-4xl">
                      <LoadingIcon className="py-20 text-j-magenta" />
                    </h1>
                  </div>)
              }
              <p className="mt-4 text-xs text-center cursor-pointer">
                <a
                  className="underline"
                  onClick={()=>{
                    switchSteps(1)
                    setPhoneNumber(null)
                  }}
                >
                  Cancel
              </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}


export default Contact;
