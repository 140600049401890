import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/styles/tailwind.css";
import App from "./routing/index";
import Login from "./pages/Login/index";
import "@fortawesome/fontawesome-free/css/all.min.css";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import PaymentLoader from "./pages/PaymentLoader/paymentLoader";
// import WalletPaymentLoader from "./pages/PaymentLoader/walletPaymentLoader";
console.disableYellowBox = true;

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/admin">
        <App />
      </PrivateRoute>
      <PublicRoute path="/login" exact>
        <Login />
      </PublicRoute>
      {/* <PublicRoute path="/paymentLoader" exact>
        <PaymentLoader />
      </PublicRoute>
      <PublicRoute path="/walletPaymentLoader">
        <WalletPaymentLoader />
      </PublicRoute> */}
      <Redirect from="*" to="/login" />
    </Switch>
  </BrowserRouter>,
  document.querySelector("#root")
);
