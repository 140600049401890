import React, { useState, useEffect } from "react";
import cx from "classnames";
// import Gallery from "react-photo-gallery";
import Button from "../../Button/Button";
import Slider from "../../Slider/Slider";
import CDN from "../../../utils/cdn";
import BookingOverlay from "../General/BookingOverlay";
import { CancelView } from "../../View/FAQView";
import DetailCard from "./DetailCard";

export const SelectionDetailsView = ({
  rooms,
  selections,
  roomIndex: defaultRoomIndex,
  selectionIndex: defaultSelectionIndex,
  onClose,
  onSelect,
  deckRoom = null,
  noSwitch,
  itineraryID,
  itinerary
}) => {
  const [roomIndex, setRoomIndex] = useState(defaultRoomIndex);
  const [selectionIndex, setSelectionIndex] = useState(defaultSelectionIndex);
  const [cancellationPolicy, setCancellationPolicy] = useState(null);
  const [reschedulePolicy, setReschedulePolicy] = useState(null);

  let itiIdLocal = JSON.parse(localStorage.getItem('store'));
  console.log(itiIdLocal)
  let itiId = itiIdLocal != null ? itiIdLocal.booking == null ? itineraryID.id : itiIdLocal.booking.itinerary.id : itineraryID.id;
  const cancellationDiv = cancellationPolicy ? (
    <div>
      {cancellationPolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{ display: "revert" }}>{term}</li>
                );
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)
  const rescheduleDiv = reschedulePolicy ? (
    <div>
      {reschedulePolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{ display: "revert" }}>{term}</li>
                )
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/itineraries/${itiId}/policies`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.json())
      .then((res) => {
        setCancellationPolicy(res.cancellation);
        setReschedulePolicy(res.reschedule);
      }).catch((err) => {
        alert("Error Fetching Cancellation and Reschedule Policies");
      });
  }, [])
  const getIndex = (currentIndex, length, delta) =>
    delta > 0
      ? delta + currentIndex === length
        ? 0
        : delta + currentIndex
      : delta + currentIndex < 0
        ? length - 1
        : delta + currentIndex;

  const room = rooms[roomIndex];
  for (let i = 0; i < selections.length; i++) {
    selections[i].images.map((ele) => {
      ele.source = `${process.env.NEXT_PUBLIC_IMAGE_HOSTNAME}${ele.source}`;
      return ele;
    })
  }
  const selection = selections[selectionIndex];
  console.log(itinerary, selection, 'aaaaa roh')
  const selectButton = deckRoom ? (
    <div>
      <Button
        className="w-full bg-j-red-light text-white my-8 pt-1"
        onClick={() => {
          onSelect(roomIndex, deckRoom.selection, deckRoom);
          let switchedToNext = false;
          if (!switchedToNext) onClose();
        }}
      >
        Proceed
      </Button>
    </div>
  ) : (
    <div>
      <Button
        className="w-full bg-j-red-light text-white my-8 pt-1"
        onClick={() => onSelect()}
      >
        Select
      </Button>
    </div>
  );

  return (
    <BookingOverlay
      style={noSwitch ? "back" : "close"}
      onCancel={onClose}
    >
      <Slider
        onStep={(delta) => {
          const newRoomIndex = getIndex(roomIndex, rooms.length, delta);
          setRoomIndex(newRoomIndex);
          setSelectionIndex(
            rooms[newRoomIndex].selected
              ? selections
                .map((s) => s.name)
                .indexOf(rooms[newRoomIndex].selected.name)
              : 0
          );
        }}
        className="mb-14"
        inverse
        uppercase
        showFirst={!noSwitch && roomIndex > 0}
        showLast={!noSwitch && roomIndex < rooms.length - 1}
      >
        Cabin {roomIndex + 1}
      </Slider>
      <Slider
        onStep={(delta) =>
          setSelectionIndex(
            getIndex(selectionIndex, selections.length, delta)
          )
        }
        showFirst={!noSwitch && selectionIndex > 0}
        showLast={!noSwitch && selectionIndex < selections.length - 1}
        className="mb-8"
      >
        {selection?.name}
      </Slider>
      <div className="overflow-hidden w-full">
        {/* <Gallery
          photos={selection.images.map((img) =>
            Object.assign({}, img, { thumbnail: CDN(img.src), src: CDN(img.src)})
          )}
          targetRowHeight={120}
      /> */}
      </div>

      {/* {selectButton} */}

      <div className="pt-2">
        <DetailCard
          title="Room features"
          description={[selection.features[0]]}
          id="features"
        >
          {
            selection.features.map(feature => <p className="p-2 text-sm">{feature}</p>)
          }
        </DetailCard>
        <DetailCard
          title="Inclusions"
          description={[selection.inclusions[0]]}
          id="features"
        >
          {
            selection.inclusions.map(feature => <p className="p-2 text-sm">{feature}</p>)
          }
        </DetailCard>
        <DetailCard
          title="Price details"
          description={[
            <span key="total">Total</span>,
            <span key="price">
              {itinerary?.discount_pct != 0 && <span className="mr-2 text-sm font-medium line-through">{(Math.floor(selection.price.actual_total) || 0).toLocaleString("hi-IN")}</span>}
              {(selection.price.total || 0).toLocaleString("hi-IN")}
            </span>,
          ]}
          id="features"
        >
          <PriceDetails {...selection.price} discount_pct={itinerary?.discount_pct} />
          <h4 className="flex justify-between font-bold text-j-magenta">
          </h4>
        </DetailCard>
        <DetailCard title="Cancellation and Reschedule Policy">
          {cancellationDiv}
          {rescheduleDiv}
        </DetailCard>
      </div>

      {selectButton}
    </BookingOverlay>
  );
};

export default SelectionDetailsView;

const PriceDetails = ({ individual, discounts, taxes, total, actual_total, discount, discount_text, gst, discount_pct}) => {
  const subTotal =
    individual.reduce((sum, i) => sum + i.total, 0) +
    (discounts ? discounts.reduce((sum, i) => sum + i.total, 0) : 0);
  const totalTaxes = taxes.reduce((sum, i) => sum + i.value, 0);

  return (
    <div>
      {individual.map((x, i) => (
        <PriceBlock
          key={i}
          title={x.type || `Person ${i + 1}`}
          value={x.total}
          positions={[
            { description: "Fare", value: x.fare },
            { description: "Individual Discount", value: x.discount, sub_text: x.discount_text },
            { description: "Service Charges & Levies", value: x.portCharges + x.gratuity },
            { description: "Fuel Surcharge", value: x.fuelSurcharge },
            // { description: "Port charges", value: x.portCharges },
            // { description: "Gratuity", value: x.gratuity },
          ]}

        />
      ))}
      {discounts && (
        <PriceBlock
          title={"Group discount"}
          value={discounts.total}
          positions={discounts.details}

        />
      )}
      <PriceBlock
        title={"Sub-total"}
        value={subTotal}
        positions={[{ description: "Taxable sub-total", value: subTotal }]}
        bold

      />

      {gst && (
        <PriceBlock
          title={"GST"}
          value={gst}
        // positions={discount}
        />
      )}

      <h2 className="flex justify-between items-center font-bold">
        <span className="text-2xl">Total</span>
        <div className="">
          {discount_pct != 0 && <span className="text-lg mr-3 font-medium line-through">&#x20B9; {(actual_total || 0).toLocaleString("hi-IN")}</span>}
          <span className="text-2xl">&#x20B9; {(total || 0).toLocaleString("hi-IN")}</span>
        </div>
      </h2>
    </div>
  );
};

const PriceBlock = ({ title, value, bold, positions }) => (
  <div className="mb-8">
    <h4
      className={cx(
        "flex justify-between leading-loose pb-0",
        bold ? "font-bold" : "font-medium"
      )}
    >
      <div className="flex flex-col">
        <span>{title}</span>
      </div>
      <span> {(value || 0).toLocaleString("hi-IN")}</span>
    </h4>
    {positions && positions.map((p, i) => (
      <p className="flex justify-between leading-loose">
        <div>
          <span>{p.description}</span><br/>
          {p.sub_text && <span className="text-sm">{p.sub_text}</span>}
        </div>
        <span> {p.description == 'Individual Discount' ? '-' : null}  &#x20B9; {(p.value || 0).toLocaleString("hi-IN")}</span>
      </p>
    ))}
  </div>
);
