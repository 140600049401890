import React, { Component, useContext, useState, useEffect, useRef } from "react";
import SummaryView from "../../components/Booking/General/SummaryViewHoldBooking";
import Tile from "../../components/Tile/Tile";
import { getToken } from "../..//utils/common";
import RadioField from "../../components/Form/RadioField";
import CardPaymentForm from "../../components/Booking/payment-forms/HoldCardPaymentForm";
import BankPaymentForm from "../../components/Booking/payment-forms/HoldBankPaymentForm";
import UPIPaymentForm from "../../components/Booking/payment-forms/UPIPaymentForm";
import WalletPayment from "../../components/Booking/payment-forms/HoldWalletPayment";
import scrollTo from "../../utils/scrollTo";
import moment from "moment";
import NimbblPaymentForm from "../../components/Booking/payment-forms/NimbblPaymentForm";

class CompletePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            booking:[],
            selection: []
        }
    }

    componentWillMount() {
    }

    render() {
        return(
            <>
                <SummaryViewUI  />
            </>
        )
    }
}

export default CompletePayment;

const SummaryViewUI = () => {
    const [renderView, setRenderView] = useState(true)
    const [isFailed, setFailed] = useState(false); 
    const [panToggle, setPanToggle] = useState(true);
    const [amountStatus, setAmount] = useState(0);
    const [backButtonStatus, setBackButtonStatus] = useState(false);

    let booking = JSON.parse(sessionStorage.getItem('hold-booking-store'));
    let partialPayment = JSON.parse(sessionStorage.getItem('hold-booking-partialPayment'));
    let itiId = booking.itinerary.id;
    const totalPrice = booking.pending_amount;

    let twentyPercentage = partialPayment.partial_payment_amount ;
    const paymentRule = partialPayment.partial_payment_rule;

    const remaingAmount = totalPrice-twentyPercentage;

    const due_Date = booking.due_by;

    const paymentOptionID = partialPayment.payment_id;

    const backButtonFunction = () => {
        setBackButtonStatus(false)
    }

    useEffect(() => {
        const URL_status = new URLSearchParams(window.location.search).get("fail");
        if(URL_status==1)
        {
            setFailed(true);
        }
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/wallet/balance`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
            `Bearer ${getToken()}`,
        },
        })
        .then((response) => response.json())
        .then((response) => {
                setAmount(response.amount) 
        })
    },[])

    return(
        <>
            {renderView && <> <h1 className="text-xl font-bold mt-4"  id="payment">{isFailed ? "Request failed!" : "Payment"}</h1>
            {isFailed ? (
                <div className="bg-j-yellow text-j-black rounded-big p-3 my-4">
                <h1 className="text-base font-medium pb-0">
                    <i className="fas fa-exclamation-triangle text-2xl pr-1" />
                    Payment failed. Please try again!
                </h1>
                <p className="text-xs leading-relaxed">
                    Somehow, we couldn't receive your payment. Any amount deducted from
                    your account will be refunded in 7 working days.
                </p>
                </div>
            ) : null}
            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2 pt-0 mt-4 ml-4 mr-4">
                <SummaryView booking={booking} paymentPage={true} itineraryID={itiId} panToggle={panToggle}/>
                <div>
                        <ConformBooking 
                            amountStatus={amountStatus}
                            booking={booking}
                            totalPrice={totalPrice}
                            twentyPercentage={twentyPercentage}
                            partialPayment={twentyPercentage} 
                            remaingAmount={remaingAmount} 
                            paymentRule={paymentRule} 
                            due_Date={due_Date} 
                            paymentOptionID={paymentOptionID} 
                            backButtonFunction={backButtonFunction} 
                            setPanToggle={setPanToggle}
                        />
                </div>
            </div>
            </>
            }
        </>
    )
}


const ConformBooking = ({amountStatus, booking, totalPrice, twentyPercentage, partialPayment, remaingAmount, paymentRule, due_Date, paymentOptionID, backButtonFunction, setPanToggle}) => {

    const [cardOpen, cardDetails] = useState(false);
    const [billingData, setBillingData] = useState(null);
    const [backButtonstatus, setBackButtonstatus] = useState(true);
    const [partialStatus, setPartialStatus] = useState(false);
    const [fullPay, setFullPay ] = useState(true);
    const [displayAmount, setDisplayAmount] = useState("Full")

    let UserProfile= JSON.parse(localStorage.getItem('UserProfile'));
    
    const [isFailed, setFailed] = useState(
        new URLSearchParams(window.location.search).get("fail")
    );

    const submitRef = useRef(null);

    const handleWalletPayNow = () => {
        setBackButtonstatus(false)
    }

    const handleWalletPayNowTrue = () => {
        setBackButtonstatus(true)
    }

    const setFailedError = (error) => {
        setFailed(error);
        if (error) scrollTo("payment");
    };

    const onChangeValue = (status ,e) => {
        setFullPay(!fullPay);
        if(status==false) {
            setPartialStatus(true);
        } else {
            setPartialStatus(false);
        }
        // setPartialStatus(status);
        if(displayAmount == 'Full') {
            setDisplayAmount('Partial')
        } else {
            setDisplayAmount('Full');
        }
    }

    const handleSubmitPay = () => {
        cardDetails(true)
    }


    return(
        <>
            <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg bg-magenta" >
                <Tile.Inner className="pb-0">

                    {!cardOpen && <>
                        <div className="flex">
                            <h1 className="text-grey-600 text-sm w-9/12">Billing details</h1>
                        </div>
                        <div className="pt-10">
                            <h3 className="text-sm text-j-magenta font-semibold mt-4">GRAND TOTAL</h3>
                            <h1 className="text-2xl text-j-magenta font-bold"> {Math.round(parseInt(booking.total_amnt))}</h1> 
                            <div className="flex mt-2">
                                    <div className="w-9/12">
                                        {partialPayment>0 && <div className="radio">
                                            <label>
                                                <input
                                                type="radio"
                                                value="Partial"
                                                checked={displayAmount === "Partial"}
                                                onChange={(e)=>onChangeValue(partialStatus, e)}
                                                // className="pt-4"
                                                />
                                                 <span className="text-xs pl-2 font-bold">Reserve now by paying {paymentRule}</span><br />
                                                {!fullPay && <span className="pl-5" style={{fontSize:"11px"}}>DUE: {Math.round(remaingAmount)} before {moment(due_Date).format("D MMMM YYYY")}</span>}
                                            </label>
                                        </div>}
                                        <div className="radio w-full mt-2 mb-4">
                                            <label>
                                                <input
                                                type="radio"
                                                value="Male"
                                                checked={displayAmount === "Full"}
                                                onChange={(e)=>onChangeValue(partialStatus, e)}
                                                />
                                                 <span className="text-xs pl-2 font-bold">Book Now by paying 100%</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div  className="w-3/12">
                                        <p className="text-xs" style={{fontSize:"8px"}}>AMOUNT PAYABLE</p>
                                        <h1 className="text-xl text-j-magenta font-bold"> {fullPay?Math.round(totalPrice) : Math.round(twentyPercentage)}</h1> 
                                        {!fullPay && <p className="text-xs text-j-magenta" style={{fontSize:"9px"}}>DUE AMOUNT : {Math.round(remaingAmount)}</p>}
                                    </div>
                            </div>                        
                        </div>
                        <input type="submit" ref={submitRef} className="bg-j-red-light text-white w-full mb-12 p-3 rounded-small" onClick={()=>handleSubmitPay()}/>
                    </>}
                    {cardOpen && <> 
                        <div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2 mb-6">
                                    <h1 className="alt">Payment options</h1>
                                    <button className="bg-grey-light focus:outline-none text-j-magenta font-bold py-2 px-4 rounded inline-flex items-center justify-end" onClick={()=>cardDetails(false)}>
                                        <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521,7.521.7.52z"/></svg>
                                        <span>Back</span>
                                    </button>
                            </div>
                        </div>
                        <span className="text-j-gray">
                            <RadioField
                                    name="paymentOption"
                                    defaultValue="bank"
                                    paymentStatus= {true}
                                    handleWalletPayNowTrue={handleWalletPayNowTrue}
                                    options={[
                                        {
                                            value: "mimbbl",
                                            label: "Pay Using Payment Gateway",
                                            view: (
                                                <NimbblPaymentForm
                                                bookingID={booking.id}
                                                billingData={UserProfile} 
                                                paymentOptionID={paymentOptionID}
                                                partialStatus={partialStatus}
                                                />
                                            ),
                                        },
                                          {
                                            value: "wallet",
                                            label: "Cordelia Wallet",
                                            view: (
                                              <WalletPayment
                                                booking={booking}
                                                paymentPath="booking"
                                                billingData={UserProfile}
                                                partialStatus={partialStatus}
                                                submitRef={submitRef}
                                                setFailed={setFailedError}
                                                amountStatus={amountStatus}
                                                totalPrice={totalPrice}
                                                twentyPercentage={twentyPercentage}
                                                walletStatus={true}
                                                paymentOptionID={paymentOptionID}
                                                backButtonFunction={backButtonFunction}
                                                bookingType="normal"
                                                handleWalletPayNow={handleWalletPayNow}
                                              />
                                            ),
                                          },
                                    ]}
                                />
                        </span>
                    </>}
                </Tile.Inner>
            </Tile>
        </>
    )

}