import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './common';

// handle the public routes
function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => !getToken() ? children : <Redirect to={{ pathname: '/admin/dashboard' }} />}
    />
  )
}

export default PublicRoute;