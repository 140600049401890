import React from "react";
import cx from "classnames";
import styles from "./Select.module.css";

const Select = React.forwardRef(
  ({ children, className, withIcon, tiny, grow, defaultValue, onChange=()=>{}, ...rest }, ref) => {
    const [isFilled, setFilled] = React.useState(false);
    const [clear, setClear] = React.useState(false);
    const inputRef = React.useRef();
    

    React.useEffect(() => {
      if (!clear) return;

      const inputs =
        inputRef.current && inputRef.current.getElementsByTagName("select");
      if (inputs && inputs[0]) {
        inputs[0].value = "";
        inputs[0].focus();
        setFilled(false);
      }

      setClear(false);
    }, [clear]);

    return (
      <div
        className={cx(
          "flex",
          { "w-full": !tiny, "flex-grow": grow },
          className
        )}
        ref={inputRef}
      >
        <select
          className={cx(styles.selectInput,
            "bg-white w-full h-10 px-2 w-full text-black rounded mt-2 focus:outline-non shadow flex-grow max-w-full placeholder-j-gray-lighter text-black text-sm self-center pt-1 leading-none border-0 focus:outline-none ",
            { "ml-2": withIcon }
          )}
          onChange={(e) => {setFilled(e.target.value.length > 0);onChange(e)}}
          ref={ref}
          defaultValue={defaultValue}
          {...rest}
        >
          {children}
        </select>
        {/* {isFilled && (
          <i
            className="fas fa-times-circle text-white cursor-pointer text-lg ml-2 self-center"
            onClick={() => setClear(true)}
          />
        )} */}
      </div>
    );
  }
);

export default Select;
