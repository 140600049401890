import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './common';
import { useLocation } from 'react-router-dom'

// handle the private routes
function PrivateRoute({ children, ...rest }) {
  const location = useLocation();
  console.log(location);
  if(location.pathname == "/admin/pay-due-amount") {
    localStorage.setItem('redirectURL', location.pathname+location.search) 
  } else {
    localStorage.setItem('redirectURL', 'dashboard')
  }
  return (
    <Route
      {...rest}
      render={(props) => getToken() ? children : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

export default PrivateRoute;