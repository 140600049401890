import React from "react";
// import Head from "next/head";
import ReactHtmlParser from "react-html-parser";
import styles from "./Layout.module.css";

const Layout = ({
  children,
  title,
  description,
  keywords,
  hash,
  canoLink,
  bannerImage = null,
  twitterMetaObj = null,
  ogMetaObj = null,
}) => {
  let gtm = "AW-392257755/T4RtCOiR2I4CENvBhbsB";
  let gtmTracker = "AW-392257755";
  let gtmMain = "GTM-N6NDXX3";
  let gtmUA = "UA-189041422-1";

  let reactHtml = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmMain}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
  let url = `https://www.googletagmanager.com/gtag/js?id=${gtm}`;
  let urlTracker = `https://www.googletagmanager.com/gtag/js?id=${gtmTracker}`;
  let urlGtmUA = `https://www.googletagmanager.com/gtag/js?id=${gtmUA}`;
  let assetsPath = process.env.REACT_APP_API_WEB_CHECK_IN_URL;

  return (
    <>
      <head>
        <meta charSet="utf-8" />
        <link rel="preconnect" href={assetsPath} />
        <link rel="dns-prefetch" href={assetsPath} />
        {bannerImage && (
          <link rel="preload" href={bannerImage} as="image" importance="high"/>
        )}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="version" content={hash} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
        />
        {
         twitterMetaObj && (
           <>
            <meta name="twitter:card" content={twitterMetaObj.card} />
            <meta name="twitter:site" content={twitterMetaObj.site} />
            <meta name="twitter:description" content={twitterMetaObj.description} />
            <meta name="twitter:title" content={twitterMetaObj.title} />
            <meta name="twitter:image" content={twitterMetaObj.image} />
           </>
         )
        }
        {
         ogMetaObj && (
           <>
            <meta name="og:title" content={ogMetaObj.title} />
            <meta name="og:site_name" content={ogMetaObj.site_name} />
            <meta name="og:url" content={ogMetaObj.url} />
            <meta name="og:description" content={ogMetaObj.description} />
            <meta name="og:type" content={ogMetaObj.type} />
            <meta name="og:image" content={ogMetaObj.image} />
           </>
         )
        }
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
          importance="low"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
          importance="low"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
          importance="low"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
          importance="low"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
          importance="low"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
          importance="low"
        />
        <link rel="canonical" href={canoLink} />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#500E4B" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "WebSite",
              name: "Cordelia Cruises",
              url: "https://www.cordeliacruises.com/",
              potentialAction: {
                
                "@type": "SearchAction",
                target: "{search_term_string}",
                "query-input": "required name=search_term_string",
              },
            }),
          }}
          rel="prefetch"
          importance="low"
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Cordelia Cruises",
              url: "https://www.cordeliacruises.com/",
              logo: "https://www.cordeliacruises.com/images/logo.webp",
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "02268811111",
                contactType: "customer service",
                areaServed: "IN",
                availableLanguage: "en",
              },
              sameAs: [
                "https://m.facebook.com/cordeliacruises/",
                "https://twitter.com/CordeliaCruises",
                "https://www.instagram.com/cordeliacruises/?hl=en",
                "https://www.linkedin.com/company/cordelia-cruises",
              ],
            }),
          }}
          rel="prefetch"
          importance="low"
        />

        {/* <script async src={url}></script> */}
        <script async src={urlTracker} rel="prefetch" importance="low"></script>
        <script async src={urlGtmUA} rel="prefetch" importance="low"></script>
        {/* <script
          async
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag("js", new Date());

                gtag("config", "${gtm}");`,
          }}
        /> */}
        {/* <script
          async
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag("js", new Date());

                gtag("config", "${gtmUA}");`,
          }}
        /> */}
        {/* <script
          async
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag("js", new Date())
                gtag("config", "${gtmMain}");`,
          }}
        /> */}
        <script>{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N6NDXX3')}</script>
        <script type="text/javascript" src="https://checkout.razorpay.com/v1/razorpay.js"></script>
      </head>
      {ReactHtmlParser(reactHtml)}
      <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${gtmMain}`}
      height="0" width="0" style={{display:"none",visibility:"hidden"}}></iframe></noscript>
      <div className={styles.container}>{children}</div>
    </>
  );
};

export default Layout;
