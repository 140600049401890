import React, { Component, useRef, useEffect } from "react";
import { getUser, removeUserSession } from '../../utils/common';
import { Link, useHistory  } from "react-router-dom";

class Footer extends Component {
    state = {
    }
  
    handleLogout= () => {

    }
  
    render() {
      return (
        <>
          <FooterPart />
        </>
      )
    }
}

const FooterPart = () => {

  let history = useHistory();

  const handleLogout = () => {
    removeUserSession();
    sessionStorage.clear();
    history.push('/login');
  }

  return(
    <footer>
      <div className=" relative bg-basecolor">
          <div className="relative z-10 mx-auto lg:flex justify-between px-6 pt-8 lg:pt-8 pb-6 lg:pb-8">
            <div className="grid grid-cols grid-cols-2 w-full">
                <div className="text-white">Cordelia cruises</div>
                <div className="text-right text-white" onClick={handleLogout}>Logout</div>
            </div>
          </div>
      </div>
    </footer>
  )
}

export default Footer;
