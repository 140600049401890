import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import Tile from "../../components/Tile/Tile";
import cx from "classnames";
import Button from "../../components/Button/Button";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import SelectorOverlay from "../../components/Booking/Selector/SelectorOverlay";
import SelectionDetailsView from "../../components/Booking/Selector/SelectionDetailsView";
import styles from "../../components/Booking/roomSelectionView.module.css";

var selectedRoomValue;

export default function DeckSelection({
    checkAvailability,
    booking,
    itineraryID,
    rooms,
    updateRoom,
    selections,
    stepHandleNex,
    loadingContinueStatus
}) {
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const [roomIndex, setRoomIndex] = useState(null);
    const [open, setOpen] = useState(false);
    const [roomSelected, setRoomSelected] = useState(null)
    const [selectedRoom, setSelectedRoom] = useState(rooms)
    const [preview, setPreview] = useState(null);

    console.log(itineraryID)
    const onSelect = (index, selected, fromSelector, selected_room) => {

        console.log(selected_room)
        console.log(selected)
        const newRooms = [...rooms];
        console.log(newRooms)
        console.log(index)
        if (selected_room) {
            newRooms[index] = Object.assign(
              {},
              newRooms[index],
              { selected },
              {
                room_id: selected_room.itinerary_room_id,
                total_price: selected_room.total_price,
                room_number: selected_room.number,
              }
            );
            let array= []
            newRooms.map(room=>{array.push(room.room_number)})
            console.log(array,"nik-log array")
            sessionStorage.setItem("newRoomSelected", JSON.stringify(array))
        } else {
            newRooms[index] = Object.assign(
              {},
              newRooms[index],
              { selected },
              { room_id: null, total_price: null, room_number: null }
            );
        }
        setSelectedRoom(newRooms)
        updateRoom(newRooms)
        console.log(index,selectedRoom,newRooms,"new room",newRooms[index],selected,"nik-log")

        selectedRoomValue = index == newRooms.length - 1 && !newRooms.filter((room) => !room.selected).length;

        console.log(selectedRoomValue)
        setRoomSelected(selectedRoomValue)
    }

    console.log(checkAvailability)
    const isComplete = !rooms.filter((room) => !room.selected).length;
    return(
        <>
            {open && (
                <div style={{ zIndex: 999999 }}>
                    <div
                        style={{
                        position: "fixed",
                        backgroundColor: "rgba(0,0,0, 0.2)",
                        zIndex: 999,
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        bottom: "0px",
                        }}
                        onClick={() => {
                        setSelectedCategoryId(null);
                        setOpen(false);
                        }}
                    ></div>
                    <SelectorOverlay
                        className="disable-scrollbars itineraryModal"
                        onClose={() => {
                        setOpen(false);
                        setSelectedCategoryId(null);
                        }}
                        rooms={rooms}
                        selections={selections}
                        itinerary={itineraryID}
                        onSelect={onSelect}
                        selectedCategory={open}
                        roomIndex={roomIndex}
                        itineraryID={itineraryID}
                    />
                </div>
            )}
             <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
                {rooms.map((room, i) => (
                    <RoomView
                        room={room}
                        changePop={(selectedCategory, index) => {
                        setRoomIndex(index);
                        setOpen(selectedCategory);
                        }}
                        selections={selections[i].categories}
                        roomIndex={i}
                        onSelect={(selection) => onSelect(i, selection)}
                        onDetails={(room, selection, selectionIndex) => {
                        setPreview(Object.assign([room.id, selectionIndex]));
                        }}
                        selectedCategoryId={selectedCategoryId}
                        setSelectedCategoryId={setSelectedCategoryId}
                        selectedRoomId={selectedRoomId}
                        setSelectedRoomId={setSelectedRoomId}
                        roomSelected={roomSelected}
                        selectedRoom={selectedRoom[i]}
                        itineraryID={itineraryID}
                    />
                ))}            
            </div>
            {preview && (
                <div style={{ zIndex: 999999 }}>
                    <div
                    style={{
                        position: "fixed",
                        backgroundColor: "rgba(0,0,0, 0.2)",
                        zIndex: 999,
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        bottom: "0px",
                    }}
                    onClick={() => setPreview(null)}
                    ></div>
                    <SelectionDetailsView
                        rooms={rooms}
                        selections={selections[preview[0]].categories}
                        selectionIndex={preview[1]}
                        roomIndex={preview[0]}
                        onClose={() => setPreview(null)}
                        onSelect={() => {
                            setRoomIndex(preview[0]);
                            setOpen(selections[0].categories[preview[1]]);
                            setPreview(null);
                        }}
                        itineraryID={itineraryID}
                    />
                </div>
                )}
            <div className="grid grid-cols-1 gap-4">
                <Button
                    disabled={!roomSelected}
                    className={cx(
                        "w-full text-j-white",
                        roomSelected ? "bg-j-orange" : "bg-j-gray"
                    )}
                    onClick={()=>{
                        stepHandleNex(selectedRoom)
                    }}
                    // {...rest}
                    >
                       {loadingContinueStatus? <LoadingIcon /> : "Continue" }
                </Button>
                </div>
        </>
    )
}

const RoomView = ({
  room,
  onSelect,
  selections,
  onDetails,
  changePop,
  roomIndex,
  selectedCategoryId,
  setSelectedCategoryId,
  selectedRoomId,
  setSelectedRoomId,
  roomSelected,
  selectedRoom,
  itineraryID
}) => {
  console.log(selectedRoom,roomSelected,'nik-log selected room')
  return(
    <>
        <Tile theme="magenta" shadow className="my-5 col-span-4" id={`room-${room.id}`}>
            <Tile.Inner>
                {selectedRoom&&selectedRoom.selected ? (
                    <RoomCard
                        room={selectedRoom.selected}
                        roomId={room.id}
                        selected
                        changePop={changePop}
                        onChange={() => onSelect(null)}
                        onDetails={(selection) =>
                            onDetails(
                            room,
                            selection,
                            selections.map((s) => s.name).indexOf(selectedRoom.selected.name)
                            )
                        }
                        roomIndex={roomIndex}
                        roomNumber={selectedRoom.room_number}
                        selectedCategoryId={selectedCategoryId}
                        setSelectedCategoryId={setSelectedCategoryId}
                        selectedRoomId={selectedRoomId}
                        setSelectedRoomId={setSelectedRoomId}
                        itineraryID={itineraryID}
                    />): (
                        selections.map((r, i) => (
                            <RoomCard
                                room={r}
                                roomId={i}
                                key={i}
                                changePop={changePop}
                                onChange={(selection) => onSelect(selection)}
                                onDetails={(selection) => onDetails(room, selection, i)}
                                roomIndex={roomIndex}
                                selectedCategoryId={selectedCategoryId}
                                setSelectedCategoryId={setSelectedCategoryId}
                                selectedRoomId={selectedRoomId}
                                setSelectedRoomId={setSelectedRoomId}
                                itineraryID={itineraryID}
                            />
                        ))
                    )
                }
            </Tile.Inner>
        </Tile>
    </>
  )
}

const RoomCard = ({
    room,
    roomId,
    onChange,
    selected,
    onDetails,
    changePop,
    roomIndex,
    roomNumber,
    selectedCategoryId,
    setSelectedCategoryId,
    selectedRoomId,
    setSelectedRoomId,
    itineraryID
  }) => {
    const [selectedRoom, setSelectedRoom] = useState(false);

    console.log(room)
    useEffect(() => {
        if (room.category_id === selectedCategoryId && roomId === selectedRoomId) {
          setSelectedRoom(true);
        } else {
          setSelectedRoom(false);
        }
    }, [selectedCategoryId, selectedRoomId]);
    
    function onSelectIfSelected() {
        onChange(room);
    }

    function onSelectNotSelected(roomId) {
        changePop(room, roomIndex);
        setSelectedCategoryId(room.category_id);
        setSelectedRoomId(roomId)
    }

    return (
            <div className="mb-8 relative rounded-lg overflow-hidden text-white bg-white shadow-xl flex ">
                    {room.onboard_credit_offer ? 
                        <div className={cx("w-1/12 bg-j-orange", styles.wrapper)}>
                            <h3>Offer</h3>
                        </div> 
                    : null 
                    }
                    {room.offer_description ? 
                        <p class="ribbon">
                            <span class="ribbon-text"><strong class="ribbon-bold">{room.offer_description}</strong></span>
                        </p> 
                    : null
                    }
                    <Tile.Inner
                        tiny
                        className={cx(
                            "rounded-big",
                            room.offer_description ? "mt-16" : "",
                            room.onboard_credit_offer ? " w-11/12" : "w-full",
                            room.popular ? "bg-j-orange" : "bg-j-white",
                            selectedRoom ? styles.highlighter : ""
                        )}
                        theme={room.popular ? "orange" : "white"}
                    >
                        <div className="flex justify-between">
                            <h4 className={cx(
                                "font-bold pb-0 self-center",
                                room.popular ? "text-j-white" : "text-j-magenta"
                                )}
                            >
                                {room.name}
                            </h4>
                            <p className={cx("self-center px-2 hidden text-j-magenta",styles.xlBlock)}>Deck No: <span className="font-bold">{room.deck_no.join(', ')}</span></p>
                            <Button className={cx("border self-center",
                                    room.popular
                                        ? "border-j-white bg-j-orange text-j-white"
                                        : "border-j-gray bg-j-white text-j-gray"
                                    )}
                                    style={{ outline: 0 }}
                                    onClick={() => {
                                        selected ? onSelectIfSelected() : onSelectNotSelected(roomId);
                                    }}
                            >
                                {selected ? "Change" : "Select"}
                            </Button>
                        </div>
                        <p className="self-center xl:hidden text-j-magenta text-left" >Deck No: <span className="font-bold">{room.deck_no.join(', ')}</span></p>
                        {selected && (
                            <div className="pt-3">
                                <div className="text-xs text-j-green" style={{fontSize: "0.95rem", fontWeight: "bold", textAlign: 'left'}}>Room No: {roomNumber}</div>
                            </div>
                        )}
                        {room.onboard_credit_offer ?
                            <div className="flex w-8/12 pt-2">
                                <span ><img src="/images/discount.png" alt="discount" /></span><span className="text-black text-xs pl-2 pr-2">{room.onboard_credit_offer}</span>
                            </div> : null
                        }
                        <div className="flex justify-between pt-5">
                            <a className={cx("text-xs uppercase cursor-pointer self-center",
                                    room.popular ? "text-j-white" : "text-j-orange"
                                )}
                                onClick={() => {
                                    onDetails(room);
                                }}
                            >
                                {/* Details */}
                            </a>
                            <h2 className={cx("leading-none self-center pb-0 font-normal font-bold",
                                    room.popular ? "text-j-white" : "text-j-black"
                                )}
                            >
                                <p className="text-j-gray text-sm font-normal text-right pb-2">From</p>
                                <p className="text-black">&#x20B9; {(room.price.total || 0).toLocaleString("hi-IN")}</p>
                            </h2>
                        </div>
                    </Tile.Inner>
            </div>
    )
  }