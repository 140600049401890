import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../store/UserContext";
import Layout from "../../../components/Layout/Layout";
// import Header from "../../../components/Header/Header";
// import FooterView from "../../../components/FooterAlt/Footer";
import cx from "classnames";
import View from "../../../components/View/View";
import Modal from "../../../components/Modal/Modal";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import RadioField from "../../../components/Form/RadioField";
import { useForm } from "react-hook-form";
import { getToken } from "../../../utils/common";

const ConfirmCabin = () => {
  const [user, setUser] = useContext(UserContext);
  const [bookingId, setBookingId] = useState();
  const [action, setAction] = useState();
  const [cabin, setCabin] = useState();
  const [RoomNo, setRoomNo] = useState();
  const [removeCabinData, setRemoveCabinData] = useState();
  const [guestData, setGuestData] = useState();
  const [guestPricingSummary, setGuestPricingSummary] = useState();
  const [changeCabinPricingSummary, setChangeCabinPricingSummary] = useState();
  const [showRemoveCabinAlert, setShowRemoveCabinAlert] = useState(false);
  const [loadingRemoveCabin, setLoadingRemoveCabin] = useState(false);
  const [loadingAddGuest, setLoadingAddGuest] = useState(false);
  const [loadingChangeCabin, setLoadingChangeCabin] = useState(false);
  const [openPaymentOptions, setOpenPaymentOptions] = useState(false);
  const [paymentdata, setPaymentData] = useState();
  const [payload, setPayload] = useState();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.id) {
      setBookingId(params.id);
      if (params.action === "add-guest") {
        const _data = JSON.parse(localStorage.getItem("manageBooking"));
        setCabin(_data.addGuest.cabin);
        setGuestData(_data.addGuest.guestData);
        fetchAddGuestSummary(
          params.id,
          params.cabinId,
          _data.addGuest.guestData
        );
        setAction(params.action);
      } else if (params.action === "remove-cabin") {
        const _data = JSON.parse(localStorage.getItem("manageBooking"));
        setCabin(_data.removeCabin.cabin);
        fetchRemoveCabinSummary(params.id, params.cabinId);
        setAction(params.action);
      } else if (params.action === "change-cabin") {
        const _data = JSON.parse(localStorage.getItem("manageBooking"));
        setAction(params.action);
        setCabin({ id: params.cabinId });
        setRoomNo(params.roomNo);
        setGuestData(_data.changeCabin.guestData);
        fetchChangeCabinSummary(
          params.id,
          params.cabinId,
          _data.changeCabin.guestData,
          params.roomNo
        );
      }
    } else window.location = "/my-bookings";
  }, [setBookingId, setCabin, setAction, setRoomNo]);

  const fetchRemoveCabinSummary = (id, cabinId) => {
    const _payload = { id: id, bkroom_id: cabinId };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/bookings/partial_cancellation_fees",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(_payload),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setRemoveCabinData(response.data);
      });
  };

  const fetchAddGuestSummary = (id, cabinId, guestData) => {
    const _payload = {
      id: id,
      bkroom_id: cabinId,
      guests: guestData.map((guest) => {
        return { type: guest.type };
      }),
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/bookings/add_guest_pricing",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(_payload),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setGuestPricingSummary(response.data);
      });
  };

  const fetchChangeCabinSummary = (id, cabinId, guestData, roomNo) => {
    const _payload = {
      id: id,
      bkroom_id: cabinId,
      guests: guestData.map((guest) => {
        return { type: guest.type };
      }),
      room_no: roomNo,
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/bookings/add_guest_pricing",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(_payload),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setGuestPricingSummary(response.data);
      });
  };

  // const proceedChangeCabin = (paymentdata) => {
  //   const _payload = {
  //     id: bookingId,
  //     bkroom_id: cabin.id,
  //     room_no: RoomNo,
  //     confirm: true,
  //   };
  //   fetch(process.env.REACT_APP_API_ENDPOINT + "/bookings/change_cabin", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getToken()}`,
  //     },
  //     body: JSON.stringify(_payload),
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.status === "success") {
  //         const bookingInput = {
  //           contact: {
  //             first_name:
  //               guestData && guestData[0] ? guestData[0].first_name : null,
  //             last_name:
  //               guestData && guestData[0] ? guestData[0].last_name : null,
  //             email: guestData && guestData[0] ? guestData[0].email : null,
  //             phoneNumber:
  //               guestData && guestData[0] ? guestData[0].phone : null,
  //             gstin:
  //               paymentdata.isGstSelected === "true"
  //                 ? paymentdata.gst_number
  //                 : null,
  //             gstin_name:
  //               paymentdata.isGstSelected === "true"
  //                 ? paymentdata.gst_name
  //                 : null,
  //             gstin_phone:
  //               paymentdata.isGstSelected === "true"
  //                 ? paymentdata.gst_phone
  //                 : null,
  //             pan:
  //               paymentdata.isPanSelected === "true"
  //                 ? paymentdata.pan_number
  //                 : null,
  //             pan_name:
  //               paymentdata.isPanSelected === "true"
  //                 ? paymentdata.pan_name
  //                 : null,
  //           },
  //           paymentInfo: {
  //             plan: "",
  //             voucherNumber: "",
  //             promoCode: "",
  //             first_name:
  //               guestData && guestData[0] ? guestData[0].first_name : null,
  //             last_name:
  //               guestData && guestData[0] ? guestData[0].last_name : null,
  //             email: guestData && guestData[0] ? guestData[0].email : null,
  //           },
  //         };
  //         createPaymentApi({
  //           variables: { input: bookingInput },
  //           booking_id: bookingId,
  //           payment_for: "change_cabin",
  //         });
  //       }
  //     });
  // };

  const proceedChangeCabin = (paymentdata) => {
    setPayload({
      id: bookingId,
      bkroom_id: cabin.id,
      guests: guestData,
      room_no: RoomNo,
    });
    setPaymentData(paymentdata);
    setOpenPaymentOptions(true);
  };

  const proceedCancelCabin = (id, cabinId) => {
    setLoadingRemoveCabin(true);
    const _payload = { id: id, bkroom_id: cabinId };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/agent_portal/bookings/cancel_partially",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(_payload),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setLoadingRemoveCabin(false);
        if (response.status === "success")
          window.location = `/admin/manage-booking/success?id=${bookingId}&action=remove-cabin`;
      });
  };

  const proceedAddGuest = (paymentdata) => {
    setPayload({
      id: bookingId,
      bkroom_id: cabin.bkroom_id,
      guests: guestData,
    });
    setPaymentData(paymentdata);
    setOpenPaymentOptions(true);
  };

  const proceedPayment = (data) => {
    setLoadingAddGuest(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/agent_portal/bookings/save_guests",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const bookingInput = {
            contact: {
              first_name:
                guestData && guestData[0] ? guestData[0].first_name : null,
              last_name:
                guestData && guestData[0] ? guestData[0].last_name : null,
              email: guestData && guestData[0] ? guestData[0].email : null,
              phoneNumber:
                guestData && guestData[0] ? guestData[0].phone : null,
              gstin:
                paymentdata.isGstSelected === "true"
                  ? paymentdata.gst_number
                  : null,
              gstin_name:
                paymentdata.isGstSelected === "true"
                  ? paymentdata.gst_name
                  : null,
              gstin_phone:
                paymentdata.isGstSelected === "true"
                  ? paymentdata.gst_phone
                  : null,
              pan:
                paymentdata.isPanSelected === "true"
                  ? paymentdata.pan_number
                  : null,
              pan_name:
                paymentdata.isPanSelected === "true"
                  ? paymentdata.pan_name
                  : null,
            },
            paymentInfo: {
              plan: "",
              voucherNumber: "",
              promoCode: "",
              first_name:
                guestData && guestData[0] ? guestData[0].first_name : null,
              last_name:
                guestData && guestData[0] ? guestData[0].last_name : null,
              email: guestData && guestData[0] ? guestData[0].email : null,
              ...(data.paymentOption === "wallet" && { type: "wallet" }),
            },
          };
          createPaymentApi({
            variables: { input: bookingInput },
            booking_id: bookingId,
            payment_for: "extra_guests",
          });
        }
      });
  };

  const nimbblCheckout = (pg_data) => {
    const order_id = "order_9RZvg4gqbdYbL3Yx";
    var options = pg_data;
    // options.access_key = "access_key_6EAvqrDj8Arr93PD";

    window.checkout = new window.NimbblCheckout(options);
    window.checkout.open(order_id);
  };

  function createPaymentApi(requestData) {
    const URL = "/agent_portal/payments/repay_due_amount";
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    };
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}${URL}`, {
      method: "POST",
      headers: header,
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoadingAddGuest(false);
        if (response.pg_data) nimbblCheckout(response.pg_data);
        else if (response.status === "Success")
          window.location = `/admin/manage-booking/success?id=${bookingId}&action=${action}`;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const RenderCabin = ({ cabin, count }) => {
    return (
      <div className="rounded-lg shadow-card">
        <div className="bg-j-orange-lighter rounded-t-lg p-4 px-8">
          {/* <button
            className="bg-j-orange text-white p-2 px-3 rounded mb-2 mt-2 float-right"
            onClick={() => {
              const _data={
                "removeCabin":{
                  "cabin": cabin
                }
              }
              localStorage.setItem('manageBooking', JSON.stringify(_data));
              window.location = `/manage-booking/confirm?action=remove-cabin&id=${bookingId}&cabinId=${cabin.id}`;
            }}
          >
            Remove Cabin
          </button> */}
          <p className="font-bold text-xl mb-2">
            Cabin {count?count + 1:null}: {cabin.category}
          </p>

          <p className="text-j-gray-light">
            Deck No. {cabin.deck_no} | Room No.: {cabin.number}
          </p>
        </div>
        <div className="bg-j-white rounded-b-lg p-4 px-8 pb-8">
          <div className="grid grid-cols-2 gap-5">
            {cabin.guests.map((guest, count) => {
              return (
                <div>
                  <p className="text-j-gray-light mb-3 text-base">
                    Guest {count + 1}
                  </p>
                  <p className="mb-2 text-base font-bold">
                    {guest.first_name + " " + guest.last_name}
                  </p>
                  <p className="text-j-gray-light text-base uppercase">{guest.gender}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const RenderGuestPricing = () => {
    return (
      <div className="rounded-t-lg p-10">
        {guestPricingSummary.individual &&
          guestPricingSummary.individual.map((guest) => {
            return (
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <p className="font-medium text-lg mb-2">{guest.type}</p>
                  <p className="text-j-gray-light text-base mb-2">
                    Cabin Fare:
                  </p>
                  <p className="text-j-gray-light text-base mb-2">
                    Service Charges & Levies:
                  </p>
                  <p className="text-j-gray-light text-base mb-2">
                    Fuel Surcharge
                  </p>
                  <p className="text-j-gray-light text-base mb-2">
                    Shore Excursion amount:
                  </p>
                  {guest.discount > 0 && (
                    <p className="text-j-gray-light text-base mb-2">
                      Applied {guest.discountCategory}
                    </p>
                  )}
                  {/* <p className="text-j-gray-light text-base mb-2">
                                    Gratuity:
                                  </p> */}
                  {/* <p className="text-j-gray-light text-base mb-2">
                                    GST:
                                  </p> */}
                </div>
                <div className="text-right">
                  <p className="font-medium text-lg mb-2">&nbsp;</p>
                  <p className="text-j-gray-light text-base mb-2">
                    ₹{guestPricingSummary.actual_fare}
                  </p>
                  <p className="text-j-gray-light text-base mb-2">
                    ₹{guest.portCharges+guest.gratuity}
                  </p>
                  <p className="text-j-gray-light text-base mb-2">
                    ₹{guest.fuelSurcharge}
                  </p>
                  <p className="text-j-gray-light text-base mb-2">
                    ₹{guest.shore_excursion_total}
                  </p>
                  {guest.discount > 0 && (
                    <p className="text-j-gray-light text-base mb-2">
                      ₹{Math.round(guest.discount)}
                    </p>
                  )}
                  {/* <p className="text-j-gray-light text-base mb-2">
                                    ₹{guest.gratuity}
                                  </p> */}
                  {/* <p className="text-j-gray-light text-base mb-2">
                                    ₹{guest.otherCharges}
                                  </p> */}
                </div>
              </div>
            );
          })}

        {guestPricingSummary.agent_commission > 0 && (
          <div className="grid grid-cols-2 gap-5">
            <div>
              <p className="text-j-gray-light text-base mb-2">Discount</p>
            </div>
            <div className="text-right">
              <p className="text-j-gray-light text-base mb-2">
                ₹{Math.round(guestPricingSummary.agent_commission)}
              </p>
            </div>
          </div>
        )}

        {guestPricingSummary.modification_fee > 0 && (
          <div className="grid grid-cols-2 gap-5 mt-2">
            <div>
              <p className="text-j-gray-light text-base mb-2">Modification fee</p>
            </div>
            <div className="text-right">
              <p className="text-j-gray-light text-base mb-2">
                ₹{Math.round(guestPricingSummary.modification_fee)}
              </p>
            </div>
          </div>
        )}

        {guestPricingSummary.tcs > 0 && (
          <div className="grid grid-cols-2 gap-5">
            <div>
              <p className="text-j-gray-light text-base mb-2">TCS</p>
            </div>
            <div className="text-right">
              <p className="text-j-gray-light text-base mb-2">
                ₹{Math.round(guestPricingSummary.tcs)}
              </p>
            </div>
          </div>
        )}

        {guestPricingSummary.gst > 0 && (
          <div className="grid grid-cols-2 gap-5 mt-2">
            <div>
              <p className="text-j-gray-light text-base mb-2">GST</p>
            </div>
            <div className="text-right">
              <p className="text-j-gray-light text-base mb-2">
                ₹{Math.round(guestPricingSummary.gst)}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout title="My Bookings">
      {/* <Header
        className="bg-j-magenta text-j-white"
        bgImages={[
          "/images/my-booking-web.webp",
          "/images/my-booking-mobile.webp",
        ]}
        isManangeBooking
      ></Header> */}
      <main className="bg-auto">
        <div className={cx("bg-j-magenta text-j-white p-6")} id="filters">
          <div className="flex justify-between cursor-pointer">
            {/* <span className="self-center">My Bookings</span> */}
          </div>
        </div>
        <View>
          <div className="md:mx-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              {action &&
                (action === "add-guest" ? (
                  <div>
                    <p className="text-j-gray-light mb-3">Pricing Details</p>
                    <div className="rounded-lg shadow-card bg-j-white">
                      {guestPricingSummary && <RenderGuestPricing />}
                      <div className="rounded-b-lg bg-j-orange-light px-10 py-5">
                        <div className="grid grid-cols-2 gap-5">
                          <p className="font-medium text-2xl">Amount Payable</p>
                          <p className="text-right font-medium text-2xl">
                            ₹
                            {guestPricingSummary &&
                              Math.round(guestPricingSummary.total)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : action === "remove-cabin" ? (
                  <div>
                    <p className="text-j-gray-light mb-3">Pricing Details</p>
                    <div className="rounded-lg shadow-card bg-j-white">
                      <div className="rounded-t-lg p-10 pb-6">
                        <div className="grid grid-cols-2 gap-5">
                          <div>
                            <p className="font-medium text-lg mb-9">
                              Total amount paid for cabin
                            </p>
                          </div>
                          <div className="text-right">
                            <p className="font-medium text-lg mb-9">
                              ₹
                              {removeCabinData
                                ? removeCabinData.cancellation_amount
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-5">
                          <div>
                            <p className="text-j-gray-light text-base mb-2">
                              Cancellation Fee:
                            </p>
                          </div>
                          <div className="text-right">
                            <p className="text-j-gray-light text-base mb-2">
                              ₹{removeCabinData ? removeCabinData.fee : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-b-lg bg-j-orange-light px-10 py-5">
                        <div className="grid grid-cols-2 gap-5">
                          <p className="font-medium text-2xl">
                            Amount Refundable
                          </p>
                          <p className="text-right font-medium text-2xl">
                            ₹
                            {removeCabinData
                              ? removeCabinData.refund_amount
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Modal
                      show={showRemoveCabinAlert}
                      onClose={() => setShowRemoveCabinAlert(false)}
                    >
                      <div className="bg-j-white text-center rounded p-5 px-10">
                        <span
                          className="float-right text-j-orange"
                          onClick={() => setShowRemoveCabinAlert(false)}
                        >
                          <i className="fas fa-times cursor-pointer"></i>
                        </span>
                        <h4 className="mt-5 font-bold text-xl pb-4">
                          Cabin Cancellation Confirmation
                        </h4>
                        <p className="w-4/5 mx-auto mb-4 text-base text-j-gray-light">
                          Are you sure, you want to Cancel this cabin?
                        </p>
                        <div className="w-7/12 mx-auto mb-4">
                          <div className="font-medium">
                            <div className="grid grid-cols-2 gap-4">
                              <p className="text-left text-lg">Cabin:</p>
                              <p className="text-right text-lg">
                                {cabin.category}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <p className="text-left text-lg">Deck No:</p>
                              <p className="text-right text-lg">
                                {cabin.deck_no}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <p className="text-left text-lg">Room No.</p>
                              <p className="text-right text-lg">
                                {cabin.number}
                              </p>
                            </div>
                          </div>
                          {/* <div className="text-right">
                            <p className="font-medium text-lg">
                              {cabin.category}
                            </p>
                            <p className="font-medium text-lg">{cabin.deck_no}</p>
                            <p className="font-medium text-lg">{cabin.number}</p>
                          </div> */}
                        </div>
                        <div className="text-center">
                          <button
                            className="bg-j-orange text-white p-2 px-3 rounded mb-2"
                            onClick={() => {
                              proceedCancelCabin(bookingId, cabin.id);
                            }}
                          >
                            {loadingRemoveCabin ? (
                              <LoadingIcon className="mx-8" />
                            ) : (
                              "CONTINUE"
                            )}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                ) : action === "change-cabin" ? (
                  <div>
                    <p className="text-j-gray-light mb-3">Pricing Details</p>
                    <div className="rounded-lg shadow-card bg-j-white">
                      {guestPricingSummary && <RenderGuestPricing />}
                      <div className="rounded-b-lg bg-j-orange-light px-10 py-5">
                        <div className="grid grid-cols-2 gap-5">
                          <p className="font-medium text-2xl">Amount Payable</p>
                          <p className="text-right font-medium text-2xl">
                            ₹
                            {guestPricingSummary &&
                              Math.round(guestPricingSummary.total)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                ))}

              {action &&
                (action === "add-guest" ? (
                  <div>
                    {!openPaymentOptions ? (
                      <div>
                        <p className="text-j-gray-light mb-3">
                          Billing Details
                        </p>
                        <div className="rounded-lg shadow-card p-10 bg-j-white">
                          {/* <p className="mb-2 text-base font-medium">
                        Apply Promo Code
                      </p>
                      <div className="grid grid-cols-4 mb-7">
                        <input
                          className=" rounded-l px-2 bg-j-inputGray col-span-3"
                          name="coupon"
                          placeholder="EX: HAPPY769"
                          ref={register}
                        />
                        <button
                          className="bg-j-orange text-white p-2 rounded-r"
                          onClick={() => {}}
                        >
                          APPLY
                        </button>
                      </div> */}
                          <p className="mb-2 text-base font-medium md:flex">
                            <span className="mr-2">Do You have GSTIN?</span>
                            <RadioField
                              radioStyle={{
                                border: "2px solid #EA725B",
                                width: "1em",
                                height: "1em",
                              }}
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                              horizontal
                              lessSpace
                              inverseError
                              name="isGstSelected"
                              ref={register}
                              className="mt-2 md:mt-0"
                              defaultValue={true}
                            />
                          </p>
                          {watch("isGstSelected") === "true" && (
                            <div className="grid md:grid-cols-3 gap-2 mb-7">
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="gst_number"
                                  placeholder="GSTIN"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.gst_number && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter GST number
                                  </p>
                                )}
                              </div>
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="gst_name"
                                  placeholder="Name"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.gst_name && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter GST name
                                  </p>
                                )}
                              </div>
                              <div>
                                <input
                                  type="tel"
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="gst_phone"
                                  placeholder="Mobile Number"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.gst_phone && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter Number
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                          <p className="mb-2 text-base font-medium md:flex">
                            <span className="mr-2">Do you have PAN Card?</span>
                            <RadioField
                              radioStyle={{
                                border: "2px solid #EA725B",
                                width: "1em",
                                height: "1em",
                              }}
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                              horizontal
                              lessSpace
                              inverseError
                              name="isPanSelected"
                              ref={register}
                              className="mt-2 md:mt-0"
                              defaultValue={true}
                            />
                          </p>
                          {watch("isPanSelected") === "true" && (
                            <div className="grid md:grid-cols-2 gap-2 mb-7">
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="pan_number"
                                  placeholder="Enter your PAN no."
                                  ref={register({ required: true })}
                                />
                                {errors && errors.pan_number && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter Pan Number
                                  </p>
                                )}
                              </div>
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="pan_name"
                                  placeholder="Enter your PAN Name"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.pan_name && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter Phone Name
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="text-j-gray-light mb-3">Payment Option</p>
                        <div className="rounded-lg shadow-card p-10 bg-j-white">
                          <RadioField
                            radioStyle={{
                              border: "2px solid #EA725B",
                              width: "1em",
                              height: "1em",
                            }}
                            options={[
                              {
                                value: "pg",
                                label: "Pay Using Payment Gateway",
                              },
                              { value: "wallet", label: "Cordelia Wallet" },
                            ]}
                            lessSpace
                            inverseError
                            name="paymentOption"
                            ref={register}
                            className="mt-2 md:mt-0"
                            defaultValue={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : action === "remove-cabin" ? (
                  <div>
                    <p className="text-j-gray-light mb-3">Cabin Details</p>
                    <RenderCabin cabin={cabin} />
                  </div>
                ) : action === "change-cabin" ? (
                  <div>
                    {!openPaymentOptions ? (
                      <div>
                        <p className="text-j-gray-light mb-3">
                          Billing Details
                        </p>
                        <div className="rounded-lg shadow-card p-10 bg-j-white">
                          {/* <p className="mb-2 text-base font-medium">
                        Apply Promo Code
                      </p>
                      <div className="grid grid-cols-4 mb-7">
                        <input
                          className=" rounded-l px-2 bg-j-inputGray col-span-3"
                          name="coupon"
                          placeholder="EX: HAPPY769"
                          ref={register}
                        />
                        <button
                          className="bg-j-orange text-white p-2 rounded-r"
                          onClick={() => {}}
                        >
                          APPLY
                        </button>
                      </div> */}
                          <p className="mb-2 text-base font-medium md:flex">
                            <span className="mr-2">Do You have GSTIN?</span>
                            <RadioField
                              radioStyle={{
                                border: "2px solid #EA725B",
                                width: "1em",
                                height: "1em",
                              }}
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                              horizontal
                              lessSpace
                              inverseError
                              name="isGstSelected"
                              ref={register}
                              className="mt-2 md:mt-0"
                              defaultValue={true}
                            />
                          </p>
                          {watch("isGstSelected") === "true" && (
                            <div className="grid md:grid-cols-3 gap-2 mb-7">
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="gst_number"
                                  placeholder="GSTIN"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.gst_number && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter GST number
                                  </p>
                                )}
                              </div>
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="gst_name"
                                  placeholder="Name"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.gst_name && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter GST name
                                  </p>
                                )}
                              </div>
                              <div>
                                <input
                                  type="tel"
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="gst_phone"
                                  placeholder="Mobile Number"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.gst_phone && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter Phone Number
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                          <p className="mb-2 text-base font-medium md:flex">
                            <span className="mr-2">Do you have PAN Card?</span>
                            <RadioField
                              radioStyle={{
                                border: "2px solid #EA725B",
                                width: "1em",
                                height: "1em",
                              }}
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                              horizontal
                              lessSpace
                              inverseError
                              name="isPanSelected"
                              ref={register}
                              className="mt-2 md:mt-0"
                              defaultValue={true}
                            />
                          </p>
                          {watch("isPanSelected") === "true" && (
                            <div className="grid md:grid-cols-2 gap-2 mb-7">
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="pan_number"
                                  placeholder="Enter your PAN no."
                                  ref={register({ required: true })}
                                />
                                {errors && errors.pan_number && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter Pan Number
                                  </p>
                                )}
                              </div>
                              <div>
                                <input
                                  className="rounded p-2 bg-j-inputGray w-full"
                                  name="pan_name"
                                  placeholder="Enter your PAN Name"
                                  ref={register({ required: true })}
                                />
                                {errors && errors.pan_name && (
                                  <p className=" text-xs pt-1 text-j-orange">
                                    Please Enter Phone Name
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="text-j-gray-light mb-3">Payment Option</p>
                        <div className="rounded-lg shadow-card p-10 bg-j-white">
                          <RadioField
                            radioStyle={{
                              border: "2px solid #EA725B",
                              width: "1em",
                              height: "1em",
                            }}
                            options={[
                              {
                                value: "pg",
                                label: "Pay Using Payment Gateway",
                              },
                              { value: "wallet", label: "Cordelia Wallet" },
                            ]}
                            lessSpace
                            inverseError
                            name="paymentOption"
                            ref={register}
                            className="mt-2 md:mt-0"
                            defaultValue={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div />
                ))}
            </div>

            <div className="text-center mt-20 mb-5">
              <button
                className="bg-j-orange text-white p-2 px-8 rounded mb-2 uppercase"
                onClick={() => {
                  if (action === "add-guest") {
                    if (!openPaymentOptions) {
                      handleSubmit(proceedAddGuest)();
                    } else {
                      handleSubmit(proceedPayment)();
                    }
                  } else if (action === "remove-cabin") {
                    setShowRemoveCabinAlert(true);
                  } else if (action === "change-cabin") {
                    if (!openPaymentOptions) {
                      handleSubmit(proceedChangeCabin)();
                    } else {
                      handleSubmit(proceedPayment)();
                    }
                  }
                }}
              >
                {action === "add-guest" ? (
                  loadingAddGuest ? (
                    <LoadingIcon className="mx-8" />
                  ) : (
                    "PROCEED TO PAY"
                  )
                ) : action === "remove-cabin" ? (
                  "CANCEL CABIN"
                ) : action === "change-cabin" ? (
                  loadingAddGuest ? (
                    <LoadingIcon className="mx-8" />
                  ) : (
                    "PROCEED TO PAY"
                  )
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </View>
      </main>
      {/* <footer>
        <FooterView />
      </footer> */}
    </Layout>
  );
};

export default ConfirmCabin;
