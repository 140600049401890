import React from "react";
import cx from "classnames";

const Slider = ({
  onStep,
  children,
  inverse,
  uppercase,
  className,
  showFirst,
  showLast,
}) => (
  <div
    className={cx(
      "border rounded-l-full rounded-r-full border-j-gray flex justify-between p-3",
      inverse
        ? "bg-j-magenta text-j-white border-j-white"
        : "bg-white text-j-magenta border-j-magenta",
      className
    )}
  >
    <i
      className={cx(
        "fas fa-arrow-circle-left cursor-pointer self-center text-3xl",
        !showFirst && (inverse ? "text-j-magenta" : "text-white")
      )}
      onClick={() => showFirst && onStep(-1)}
    />
    <h2
      className={cx(
        "leading-none pb-0 self-center pt-1",
        uppercase && "uppercase"
      )}
    >
      {children}
    </h2>
    <i
      className={cx(
        "fas fa-arrow-circle-right cursor-pointer self-center text-3xl",
        !showLast && (inverse ? "text-j-magenta" : "text-white")
      )}
      onClick={() => showLast && onStep(1)}
    />
  </div>
);

export default Slider;
