import React, { Component, useContext, useState, useEffect } from "react";
import Slider from "react-slick";
import cx from "classnames";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../components/OfferComponent/slick-customized.css";
import pageStyle from "../../pages/Dashboard/dashboard.module.css";
import BookingNw from "../../assets/images/Bookingnw.png"
import holdbooking from "../../assets/images/holdBooking.png"
import { useHistory } from "react-router-dom";

function PreBooking() {
    const bookList = [
        'Book your Cabin Now',
        'Confirm With 100% Payment',
    ]

    const holdList = [
        'Hold Interior & Ocean View Cabin at ₹2000/Cabin',
        'Hold Balcony & Suite Cabin at ₹5000/Cabin',
        'Hold Time: 24 Hours',
        'You can hold Up to 2 Cabin/Itinerary',
        'Hold Payment is Non Refundable'
    ]

    const [specialFare, setSpecialFare] = useState(false);
    const [specialFare1, setSpecialFare1] = useState(false);
    const [specialFareAvailable, setSpecialFareAvailable] = useState(false);
    const [specialPricing, setSpecialPricing] = useState(false);

    let history = useHistory();

    useEffect(() => {
        // var fare = JSON.parse(localStorage.getItem('specialFare'));
        var User = JSON.parse(localStorage.getItem('UserProfile'));
        var booking = JSON.parse(localStorage.getItem('store'));
        // if(fare){
        //     setSpecialFare(fare)
        // }
      
            if(booking?.booking?.itinerary?.special_pricing_available){
                setSpecialPricing(true)
            }
        
    }, [])
    
    const onSubmit = (redirectURL) => {
        let fareType = redirectURL == '/admin/hold-booking' ? specialFare1 : specialFare
        localStorage.setItem('specialFare', fareType);
        history.push(redirectURL)
    }
    const PlaceCard = ({ heading, data, bgImage, wrapperclass, index, buttonText, redirectURL }) => {

        return (
            <div
                className={cx("flex flex-col justify-end md:m-6 lg:m-6", pageStyle['containerHold'])}
                style={{ backgroundImage: `url(${bgImage})` }}
            >
                <div className="p-6" style={{ height: '100%' }}>
                    <p className={cx("text-white w-full text-xl font-bold pb-6")}>{heading}</p>
                    <ul>
                        {data.map((val) => {
                            return (
                                <li className={cx("text-white w-full pt-2 pl-2")}>{val}</li>
                            )
                        })}
                    </ul>
                    <div className={pageStyle['containerHoldButton']}>
                        {heading == 'BOOK A CABIN' && specialPricing &&
                            <div className="flex items-center mb-3">
                                <div
                                    className="bg-white rounded-md py-1.5 px-4 cursor-pointer mr-3"
                                    onClick={(e) => setSpecialFare(false)}
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            name="fare"
                                            className="cursor-pointer"
                                            checked={!specialFare}
                                        />
                                        <span className="text-sm pl-2 cursor-pointer">Regular Fare </span>
                                    </label>
                                    {/* <p className="text-sm">Regular Fare</p> */}
                                </div>
                               <div
                                    className="bg-white rounded-md py-1.5 px-4 cursor-pointer"
                                    onClick={(e) => setSpecialFare(true)}
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            name="fare"
                                            className="cursor-pointer"
                                            checked={specialFare}
                                        />
                                        <span className="text-sm pl-2 cursor-pointer">Special Fare </span>
                                    </label>
                                    {/* <p className="text-sm">Special Fare</p> */}
                                </div> 
                            </div>
                        }
                        {heading == 'HOLD A CABIN' && specialPricing &&
                            <div className="flex items-center mb-3">
                                <div
                                    className="bg-white rounded-md py-1.5 px-4 cursor-pointer mr-3"
                                    onClick={(e) => setSpecialFare1(false)}
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            name="fare1"
                                            className="cursor-pointer"
                                            checked={!specialFare1}
                                        />
                                        <span className="text-sm pl-2 cursor-pointer">Regular Fare </span>
                                    </label>
                                    {/* <p className="text-sm">Regular Fare</p> */}
                                </div>
                                 <div
                                    className="bg-white rounded-md py-1.5 px-4 cursor-pointer"
                                    onClick={(e) => setSpecialFare1(true)}
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            name="fare1"
                                            className="cursor-pointer"
                                            checked={specialFare1}
                                        />
                                        <span className="text-sm pl-2 cursor-pointer">Special Fare </span>
                                    </label>
                                    {/* <p className="text-sm">Special Fare</p> */}
                                </div> 
                              
                            </div>
                        }
                        <button
                            className=" rounded-lg transition duration-300 ease-in-out bg-j-orange text-sm text-white text-center pl-20 pr-20 pt-3 pb-3 font-normal"
                            onClick={() => onSubmit(redirectURL)}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="md:p-10 lg:p-10">
            <p className="text-xl font-bold pl-6 pb-4">Select Your Booking Option</p>
            <div
                className={cx(pageStyle['cardContainer'])}
            >
                <PlaceCard
                    heading="BOOK A CABIN"
                    data={bookList}
                    index={1}
                    wrapperclass={pageStyle["placecard"]}
                    bgImage={BookingNw}
                    buttonText="BOOK NOW"
                    redirectURL="/admin/booking"
                />
                <PlaceCard
                    heading="HOLD A CABIN"
                    data={holdList}
                    index={1}
                    wrapperclass={pageStyle["placecard"]}
                    bgImage={holdbooking}
                    buttonText="HOLD NOW"
                    redirectURL="/admin/hold-booking"
                />
            </div>
        </div>
    )
}

export default PreBooking;