import React, { Component, useContext, useState } from "react";
import UpgradeRoomSelectionView from "../../components/UpgradeBooking/UpgradeRoomSelectionView";
import UpgradePaymentSummary from "../../components/UpgradeBooking/UpgradePaymentSummary";

var bookingID;
var rooms;
class UpgradeBooking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
        }
    };

    // componentDidMount() {
    //     const { match, location, history } = this.props;
    //     bookingID = location.state.id;
    //     // rooms = location.state.rooms;
    // }
  
    nextStep = () => {
      const { step } = this.state;
      this.setState({
        step: step + 1,
      });
      
    };
  
    prevStep = () => {
      const { step } = this.state;
      this.setState({ 
        step: step - 1,
      });
    };

    switchState = () => {
        const { step } = this.state;
        switch (step) {
          case 1:
            return (
              <>
                <UpgradeRoomSelectionView
                    // id={bookingID}
                    // rooms={rooms}
                    nextStep={this.nextStep}
                    // values={values}
                />
              </>
            )
        case 2:
            return(
                <>
                    <UpgradePaymentSummary
                    // id={bookingID}
                    // rooms={rooms}
                    nextStep={this.nextStep}
                    // values={values}
                    />
                </>
            )
        }
    }

    render() {
        return (
          <>
            <div className="flex flex-col h-screen">
              {/* <Header /> */}
              <main class="flex-grow overflow-y-auto pr-14 pl-14">
                {this.switchState()}
              </main>
              {/* <Footer /> */}
            </div>
          </>
        );
      }
}  

export default UpgradeBooking;