import React, { Component, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const PaymentFail = () => {
  let history = useHistory();
  useEffect(() => {
    const rechedule_status = new URLSearchParams(window.location.search).get("rechedule");
    const booking_status = new URLSearchParams(window.location.search).get("booking");
    const upgrade_status = new URLSearchParams(window.location.search).get("upgrade");
    if(rechedule_status == 1) {
      history.push('/admin/paymentGateway?fail=1')
    } else if(booking_status == 1) {
      history.push('/admin/booking?fail=1')
    } else if(upgrade_status == 1) {
      history.push('/admin/paymentGateway?fail=1')
    }

  },[])

  return(
    <></>
  )
}
export default PaymentFail;