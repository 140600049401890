import React, { useEffect, useState, useContext } from "react"
import cx from "classnames";
import Button from "../Button/Button";
// import { getToken } from "../../utils/common";
import { getToken } from "../../utils/common";
import UserContext from "../../store/UserContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./shoreExcursion.module.css"
import Slider from "react-slick";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import Offer1 from "../../assets/images/shore_ex_banner.webp";
import Swimming from "../../assets/images/swimming.png";
import Car from "../../assets/images/car.png";
import Insurance from "../../assets/images/insurance.png";
import Modal from "../Modal/Modal";
import ReadMore from "../../utils/ReadMore";

var guestArray = [];
var shoreExcursionArray = [];
var shoreExcursionObject;

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "transparent", right: '0px' }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "transparent", left: '0px', zIndex: 1, width: '20px' }}
            onClick={onClick}
        />
    );
}

export default function ShoreExcursion({ nextStep }) {
    const [user, setUser] = useContext(UserContext)
    const booking = user.booking || {}
    const [portsData, setPortsData] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)
    const [shoreExcursion, setShoreExcusrion] = useState([])
    const [bookingAmount, setBookingAmount] = useState(0)
    const [actualBookingAmount, setActualBookingAmount] = useState(0)
    const [shoreExcursionAmount, setShoreExcursionAmount] = useState(0)
    const [shoreExcursionWithoutGST, setShoreExcursionWithoutGST] = useState(0)
    const [shoreExcursionWithGST, setShoreExcursionWithGST] = useState(0)
    const [loading, setLoading] = useState(false)
    const [transferType, setTransferType] = useState()
    const [openTab, setOpenTab] = React.useState(0);
    const [addStatus, setAddStatus] = useState()
    const [discount, setDiscount] = useState(0)
    const [shoreExcursionList, setShoreExcursionList] = useState()
    const [buttonLoad, setButtonLoad] = useState(false)

    useEffect(() => {
        console.log('test')
        callIntialFunction()
    }, [])

    const callIntialFunction = () => {
        setLoading(true)
        var sessionBookingID = JSON.parse(window.sessionStorage.getItem('booking_id'))
        var id = booking.id
        console.log('itinerary', id)
        // api call
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${sessionBookingID}/shore_excursions`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                var bookingDetails = JSON.parse(sessionStorage.getItem("booking"))
                console.log(bookingDetails.total)
                setBookingAmount(bookingDetails.total - (response.shore_excursion_net_total + response.shore_excursion_gst))
                setActualBookingAmount(bookingDetails.actual_total - (response.shore_excursion_net_total + response.shore_excursion_gst))
                setGrandTotal(bookingDetails.total)
                var shoreDetailsList;
                var shoreDetailsListArray = [];
                var portsSortedArray = [];
                var portsDummyArray = [];
                response.ports.map((ports) => {
                    if (ports.shore_excursions.length > 0) {
                        portsDummyArray.push(ports)
                        shoreDetailsListArray = [];
                        ports.shore_excursions.map((shore) => {
                            shoreDetailsList = {
                                list: shore,
                                is_selected: shore.selected,
                                port_name: ports.port_name,
                                transferSelectedValue: shore.transfer_types[0].id,
                                is_loader: false
                            }
                            shoreDetailsListArray.push(shoreDetailsList)
                            let shoreExcursionNew = JSON.parse(window.sessionStorage.getItem("shore_excursion_new"))
                            if (shoreExcursionNew == null) {
                                shoreExcursionArray = [];
                            } else {
                                if (shore.selected && !shoreExcursionArray.find(o => o.id === shore.id)) {
                                    console.log('objGuest', shoreExcursionNew);
                                    if (shoreExcursionNew !== null) {
                                        shoreExcursionObject = new Object();
                                        shoreExcursionObject.id = shore.id
                                        shoreExcursionObject.port_id = shore.port_id
                                        shoreExcursionArray.push(shoreExcursionObject)
                                    }
                                }
                            }
                        })
                        portsSortedArray.push(shoreDetailsListArray)
                    }
                })
                setPortsData(portsDummyArray)
                console.log(portsSortedArray)
                setShoreExcursionList(portsSortedArray)
                setShoreExcursionAmount(response.shore_excursion_total)
                setShoreExcursionWithoutGST(response.shore_excursion_net_total)
                setShoreExcursionWithGST(response.shore_excursion_gst)
                console.log(shoreExcursionList)
                setLoading(false)
            })

    }

    const addShoreExcursion = (data, index, newData, newIndex, transferValue, selectedName) => {
        setButtonLoad(true)
        setButtonLoad(true)
        console.log('shoreex', shoreExcursionArray)
        console.log('shoreex', newData)
        console.log('shoreex', data)

        let newArrLoader = [...shoreExcursionList];

        // console.log(shoreExcursionList[index][newIndex])
        newArrLoader[index][newIndex].is_loader = true;
        setShoreExcursionList(newArrLoader)

        // return 0
        let obj = shoreExcursionArray.find(o => o.id === newData.list.id);
        console.log('objGuest', obj);

        if (obj == undefined) {
            let findSamePortShorex = shoreExcursionArray.find(o => o.port_id === data.port_id);
            if (findSamePortShorex) {
                shoreExcursionArray = shoreExcursionArray.filter((o) => o.port_id !== data.port_id);
            }
            console.log('objGuest', shoreExcursionArray);
            console.log('objGuest', newData.port_name)
            // if(shor)
            shoreExcursionObject = new Object();
            shoreExcursionObject.id = data.shore_excursions[newIndex].id
            shoreExcursionObject.port_name = data.port_name
            shoreExcursionObject.port_id = data.port_id
            shoreExcursionObject.selectedPackage = selectedName
            guestArray = []
        } else {
            shoreExcursionArray = shoreExcursionArray.filter((o) => o.id !== newData.list.id);
        }

        let objGuest = guestArray.find(o => o.id === data.shore_excursions[newIndex].id);

        console.log('objGuest', objGuest)

        if (objGuest == undefined) {
            guestArray = []
            console.log('objGuest', user.rooms)
            user.rooms.map((roomsData) => {
                console.log('objGuest', roomsData)
                roomsData.booked_data.guest_data.map((guestID) => {
                    var guests = new Object();
                    guests.id = guestID.id
                    if (transferType == null) {
                        guests.transfer_type_id = transferValue
                    } else {
                        guests.transfer_type_id = transferType[openTab]
                    }
                    guestArray.push(guests)
                })
            })
        } else {
            guestArray.splice(guestArray.findIndex(a => a.id === data.shore_excursions[newIndex].id), 1)
        }

        console.log('objGuest', guestArray)
        shoreExcursionObject.guests = guestArray
        if (obj == undefined) {
            shoreExcursionArray.push(shoreExcursionObject)
        }

        var sendingObject = new Object()
        shoreExcursionArray = shoreExcursionArray.filter((o) => o.port_name !== undefined);
        sendingObject.shore_excursions = shoreExcursionArray

        var id = booking.id;
        var sessionBookingID = JSON.parse(window.sessionStorage.getItem('booking_id'))
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${sessionBookingID}/update_shore_excursions`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(sendingObject)
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                sessionStorage.setItem('partialPayment', response.partial_payable_amount);
                let newArr = [...shoreExcursionList];
                console.log('newData-1', newArr[index])
                newArr[index].map((data, i) => {
                    if (newData.port_name == data.port_name) {
                        if (i != newIndex) {
                            data.is_selected = false
                        }
                    }
                })
                // console.log(shoreExcursionList[index][newIndex])
                newArr[index][newIndex].is_selected = !newArr[index][newIndex].is_selected;
                newArr[index][newIndex].is_loader = false

                setShoreExcursionList(newArr)

                console.log('vblack', shoreExcursionList)
                window.sessionStorage.setItem(
                    "booking",
                    JSON.stringify(response.booking)
                )
                window.sessionStorage.setItem(
                    "shore_excursion",
                    JSON.stringify(response.shore_excursions)
                )
                window.sessionStorage.setItem(
                    "shore_excursion_new",
                    JSON.stringify(sendingObject)
                )
                window.sessionStorage.setItem(
                    "tcs_taxes",
                    JSON.stringify({
                        tcs: response.tcs && response.tcs !== null ? response.tcs : 0,
                        tcs_with_pan: response.tcs_with_pan && response.tcs_with_pan !== null ? response.tcs_with_pan : 0
                    })
                );
                window.sessionStorage.setItem(
                    "payment_option_tcs_response",
                    JSON.stringify({
                        tcs: response.payment_option_tcs_response.tcs && response.payment_option_tcs_response.tcs !== null ? response.payment_option_tcs_response.tcs : 0,
                        tcs_with_pan: response.payment_option_tcs_response.tcs_with_pan && response.payment_option_tcs_response.tcs_with_pan !== null ? response.payment_option_tcs_response.tcs_with_pan : 0
                    })
                );
                console.log(shoreExcursionList)
                setShoreExcursionAmount(response.booking.shore_excursion_total)
                setShoreExcursionWithoutGST(response.shore_excursion_net_total)
                setShoreExcursionWithGST(response.shore_excursion_gst)
                setDiscount(response.booking.discount)
                setGrandTotal(response.total)
                setButtonLoad(false)
                return 0
            })

        // console.log(shoreExcursionObject)
    }

    const addTotalAmount = () => {

    }

    const handleTransferSelected = (e) => {
        var objeCt = new Object()
        objeCt[openTab] = e.target.value;
        console.log(objeCt)
        setTransferType(objeCt)
        console.log(transferType)

    }

    const handleOpenTab = (i) => {
        setOpenTab(i)
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const confirmPage = () => {
        booking.addOns = true
        setUser(Object.assign({}, user, { booking: booking }))
        nextStep();
    }

    return (
        <>
            {!loading && <div className={styles.container}>
                <MainPriceDetailCard
                    bookingAmount={bookingAmount}
                    shoreExcursionAmount={shoreExcursionAmount}
                    discount={discount}
                    grandTotal={grandTotal}
                    confirmPage={confirmPage}
                    shoreExcursionWithGST={shoreExcursionWithGST}
                    shoreExcursionWithoutGST={shoreExcursionWithoutGST}
                />
                <ExploreActivities
                    portsData={portsData}
                    addShoreExcursion={addShoreExcursion}
                    handleTransferSelected={handleTransferSelected}
                    openTab={openTab}
                    handleOpenTab={handleOpenTab}
                    shoreExcursionList={shoreExcursionList}
                    buttonLoad={buttonLoad}
                    settings={settings}

                />
                <ExploreSpaActivities />
            </div>
            }
        </>
    )
}

const MainPriceDetailCard = ({
    bookingAmount,
    shoreExcursionAmount,
    discount,
    grandTotal,
    confirmPage,
    shoreExcursionWithGST,
    shoreExcursionWithoutGST
}) => {

    console.log(shoreExcursionWithGST)
    return (
        <>
            <div>
                {/* <div className={cx(styles.offerContainer,"pt-3")}>
                    <div className={cx(styles.heading, "bg-j-orange w-full text-white pl-4")}>
                        Choose PACKAGES and Save upto 25%
                    </div>
                </div>
                <div className={cx(styles.offerContainerMob)}>
                    <div className={cx(styles.headingMob, "bg-j-magenta w-full text-white pl-4")}>
                        Choose PACKAGES and Save upto 25%
                    </div>
                </div> */}
                <div className={styles.mainContPrice}>
                    <div className={styles.mainContPriceSub1}>
                        <div className={styles.imageBGpROP} style={{ backgroundSize: 'cover', backgroundImage: `url(${Offer1})`, height: '100%' }}>
                            <div className={styles.packagechoosetxt}>
                                {/* <h3>Choose PACKAGES and Save upto 25%</h3>
                                <p>
                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                                duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.1
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainContPriceSub2}>
                        <h3 className={styles.pricedtltitle}>Price Details</h3>
                        <div className="w-full">
                            <div className="flex justify-between">
                                <p className="font-bold">Total Booking Amount</p>
                                <p className="font-bold">₹ {bookingAmount}</p>
                            </div>
                            <div className="flex justify-between mt-4">
                                <p className="font-bold">Shore Excursion</p>
                                <p className="font-bold">₹ {shoreExcursionWithoutGST}</p>
                            </div>
                            <div className="flex justify-between mt-4">
                                <p className="font-bold">Shore Excursion GST</p>
                                <p className="font-bold">₹ {shoreExcursionWithGST}</p>
                            </div>
                            <div className="flex justify-between mt-4">
                                <p className="font-bold">Total Payable Amount</p>
                                <p className="font-bold">₹ {grandTotal}</p>
                            </div>
                        </div>
                        {/* <table className={styles.tablepricedtl}>
                            <tbody>
                                <tr className={styles.trbookview}>
                                    <td><b>Total Booking Amount</b></td>
                                    <td><span className="line-through text-sm">₹ {bookingAmount}</span><br/> <b>₹ {bookingAmount}</b></td>
                                </tr>
                                {
                                    <>
                                        <tr className={styles.trbookview}>
                                            <td><b>Shore Excursion</b></td>
                                            <td><b>₹ {shoreExcursionWithoutGST}</b></td>
                                        </tr>
                                        <tr className={styles.trbookview}>
                                            <td><b>Shore Excursion GST</b></td>
                                            <td><b>₹ {shoreExcursionWithGST}</b></td>
                                        </tr>
                                    </>
                                } */}
                                {/* {
                                bookingSummary.discount > 0 &&
                                <tr className="tr-bookview">
                                    <td className="text-success"><b>Discount</b></td>
                                    <td className="text-success"><b>-₹ {bookingSummary.discount}</b></td>
                                </tr>
                                } */}
                                {/* <tr className={styles.trbookview}>
                                    <td className={cx(styles.trbookview, "pt-2")}><b>Total Payable Amount</b></td>
                                    <td className={cx(styles.extrabooktd, "pt-2")}>₹ {grandTotal}</td>
                                </tr>
                            </tbody>
                        </table> */}
                        <div className="w-full flex-end mt-6 mb-6 flex justify-end">
                            <button
                                className="h-10 pl-16 pr-16 text-white rounded transition duration-300 bg-j-orange ease-in-out"
                                onClick={() => confirmPage()}>Proceed</button>
                        </div>
                    </div>
                </div>
                {/* <div className="pl-4 pr-4 pt-3 pb-2">
                    <p className={cx(styles.priceDetails)}>Price Details</p>
                    <div className="md:flex lg:flex">
                        <div className="md:flex lg:flex flex-col w-full md:w-7/12 lg:w-7/12 pb-4">
                            <div className="border-b-2 dotted mb-2 border-dotted border-gray-400">
                                <div className={"flex pt-2"}>
                                    <div className={cx(styles.priceHeading,"w-8/12")}>
                                        TOTAL BOOKING AMOUNT
                                    </div>
                                    <div className={cx(styles.priceHeading,"w-4/12 text-right")}>
                                        ₹ {bookingAmount}
                                    </div>
                                </div>
                                <div className={"flex pb-2"}>
                                    <div className={cx(styles.priceHeadingDiscount,"w-8/12")}>
                                        DISCOUNT
                                    </div>
                                    <div className={cx(styles.priceHeadingDiscount,"w-4/12 text-right")}>
                                        - ₹ {discount}
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className={cx(styles.priceHeading,"w-8/12")}>
                                    TOTAL PAYABLE AMOUNT
                                </div>
                                <div className={cx(styles.priceHeading,"w-4/12 text-right")}>
                                        ₹ {grandTotal}
                                </div>
                            </div>
                        </div>
                        <div className="md:w-5/12 lg:w-5/12 w-full flex justify-center item-center md:justify-end lg:justify-end lg:items-end md:items-end mb-6">
                            <button 
                                className="h-10 pl-16 pr-16 text-white flex items-center rounded transition duration-300 bg-j-orange ease-in-out"
                                onClick={()=>confirmPage()}>Proceed to Pay</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

const ExploreActivities = ({
    portsData,
    addShoreExcursion,
    handleTransferSelected,
    openTab,
    handleOpenTab,
    shoreExcursionList,
    buttonLoad,
    settings
}) => {

    console.log(portsData)
    const slideImages = [
        'http://placekitten.com/g/400/200',
        'http://placekitten.com/g/400/200',
        'http://placekitten.com/g/400/200',
    ];
    return (
        <div className="mt-4">
            <p className={cx(styles.activitiesHeading, "uppercase md:pl-4 lg:pl-4")}>EXPLORE Activities</p>
            <div>
                <div className="md:pl-4 lg:pl-4 pt-2">
                    <nav className={cx(styles.navBar, "grid grid-cols-3 lg:grid-cols-5 md:grid-cols-5 gap-4")}>
                        {/* loop this */}
                        {portsData.map((data, i) => {
                            return (<>
                                {data.shore_excursions.length > 0 &&
                                    <button className={cx(styles.buttonTab, "pl-4 pr-4 pt-2 pb-2 block  focus:outline-none uppercase " + (openTab == i ? "bg-magenta text-white" : "border-j-magenta border text-j-magenta"))}
                                        onClick={e => {
                                            e.preventDefault();
                                            handleOpenTab(i);
                                        }}>
                                        <p>{data.port_name}</p>
                                    </button>
                                }</>)
                        })}
                    </nav>
                    <nav className={cx(styles.navBarMobile, "flex")}>
                        {portsData.map((data, i) => {
                            return (<>
                                {data.shore_excursions.length > 0 &&
                                    <button className={cx(styles.buttonTab, "pl-4 pr-4 pt-2 pb-2 mr-2 block  focus:outline-none uppercase " + (openTab == i ? "bg-j-magenta text-white" : "border-j-magenta border text-j-magenta"))}
                                        onClick={e => {
                                            e.preventDefault();
                                            handleOpenTab(i);
                                        }}>
                                        <p>{data.port_name}</p>
                                    </button>
                                }</>
                            )
                        })}
                    </nav>
                </div>
                <div>
                    {/* loop this */}
                    {portsData.map((data, i) => {
                        //  console.log(shoreExcursionList[i])
                        return (<div className={openTab === i ? "block mt-6 norder" : "hidden"} id="link1">
                            {data.shore_excursions.length == 0 && <p className="text-center text-4xl text-gray-400 pt-10 pb-10">No Add-On Found</p>}
                            {data.shore_excursions.length > 0 &&
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8">
                                    {/* <p className={cx(styles.activitiesHeading,"uppercase pt-2 pl-4")}> ActiVities in {data.port_name}</p> */}
                                    {/* loop this */}
                                    {shoreExcursionList[i].map((newData, index) => {
                                        return (
                                            <CardData
                                                newData={newData}
                                                index={index}
                                                settings={settings}
                                                buttonLoadNew={buttonLoad}
                                                addShoreExcursion={addShoreExcursion}
                                                handleTransferSelected={handleTransferSelected}
                                                i={i}
                                                data={data}
                                            />
                                        )
                                    })}
                                </div>}
                        </div>)
                    })}
                </div>
            </div>
            <div className="text-right">
                <a href="https://images.cordeliacruises.com/static/Terms%20&%20Conditions%20for%20Shore%20Excursions.pdf" target="_blank" className=" text-j-orange">Terms & Conditions</a>
            </div>
        </div>
    )
}

const ExploreSpaActivities = () => {
    return (
        <div className={styles.packagesectionwraper}>

        </div>
    )
}

const CardData = ({
    newData,
    index,
    settings,
    buttonLoadNew,
    addShoreExcursion,
    handleTransferSelected,
    i,
    data
}) => {
    const [buttonLoad, setButtonLoad] = useState(false)
    const [modalShow, setModalShow] = useState(false)

    const addRemoveShore = (data, i, newData, index, transferSelectedValue, selectedName) => {
        setButtonLoad(true)
        var status = addShoreExcursion(data, i, newData, index, transferSelectedValue, selectedName)
        console.log(status)
        if (status == undefined) {
            setButtonLoad(false)
        }
    }

    const handleMoreInclusion = () => {
        setModalShow(!modalShow)
    }


    let x = newData.list.terms[0].split('_');
    console.log('xxxx', x);
    return (
        <div className="shadow-lg rounded-lg bg-white grid grid-cols-5">
            <div className='lg:col-span-2 col-span-5 shore-ex grid grid-cols-9 gap-x-6'>
                <div className='col-span-4 lg:col-span-9'>
                    <div className={cx(styles.sliderContainer, "")}>
                        <Slider {...settings} className="no-padding-slick-active h-100">
                            {newData.list.images.map((images) => {
                                return (
                                    <div>
                                        <img src={images} className={cx(styles.imageContainer, "w-full rounded-lg h-290 lg:h-310")} />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
                <div className='col-span-5 lg:hidden px-3 py-2'>
                    <p className={cx("font-bold")}>{newData.list.title}</p>
                    <div className='mt-3'>
                        <ReadMore text={newData.list.description} />
                    </div>
                </div>
            </div>
            <div className='lg:col-span-3 col-span-5 pb-0 lg:pt-3 pt-0'>
                <div className='col-span-2 px-4 hidden lg:block py-2'>
                    <p className={cx("font-bold")}>{newData.list.title}</p>
                    <div className='mt-1'>
                        <ReadMore text={newData.list.description} />
                    </div>
                </div>
                <div className='border-b col-span-10 border-gray-100/20 lg:mb-4 mb-1 lg:mt-4 mt-1' />
                <div className=" px-4 mt-2">
                    <div className='flex justify-between items-center'>
                        <p className='text-sm font-semibold'>Transferred by: <span
                            className='text-brand-primary font-bold'>{newData?.list?.transfer_types[0].name}</span></p>
                        <p className='text-sm font-bold'>₹ {newData?.list?.adult_price} / Adult</p>
                    </div>
                    <div className='flex justify-between items-center'>
                        <p className='text-sm font-semibold'>Capicity: <span>{newData?.list?.remaining_capacity}/{newData?.list?.total_capacity}</span></p>
                        <p className='text-sm font-bold'>₹ {newData?.list?.child_price} / Children</p>
                    </div>
                </div>
                <div className="flex flex-col justify-between pl-4 pr-4 py-2" >
                    <button className={newData.is_selected ? "h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-gray-500 ease-in-out" : "h-8 pl-10 pr-10 text-white w-full mt-2 rounded transition duration-300 bg-j-orange ease-in-out"}
                        onClick={() => addRemoveShore(data, i, newData, index, newData.transferSelectedValue, newData.list.title)}
                    >
                        {!newData.is_loader ? newData.is_selected ? "Remove" : "Add" : <LoadingIcon />}
                    </button>
                </div>
                <div className='px-4 mt-1'>
                    <p className={cx("text-j-orange underline font-semibold text-sm cursor-pointer")} onClick={() => handleMoreInclusion()}
                    >Tour Instructions</p>
                    {!newData?.list?.children_allowed ?
                      <div className='mt-2 flex items-center'>
                        <img className='h-4 mr-1' src="https://images.cordeliacruises.com/cordelia_v2/public/assets/child-not-allowed-icon.svg" alt="" />
                        <p className='text-xs underline font-semibold'>Child below 12 years are not allowed</p>
                      </div>
                      : null
                    }
                </div>
            </div>
            {/* <div className="grid grid-cols-1">
                <div className={cx(styles.sliderContainer, "overflow-hidden")}>
                    <Slider {...settings} className="no-padding-slick-active h-100">
                        {newData.list.images.map((images) => {
                            return(
                                <div>
                                    <img src={images} className={cx(styles.imageContainer,"w-full rounded-lg h-full")}/>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="flex">
                <p className={cx(styles.titleHead)}>{newData.list.title}</p>
                <p  className={cx(styles.topText)}><span><i class="far fa-clock"></i></span> {newData.list.hours} HRS</p>
            </div>
            <div>
                <ReadMore text={newData.list.description} />
            </div>
            <div>
                <p className={styles.inclusionStyles}>Inclusion:</p>
                <div className="grid grid-cols-4 pl-3 pr-3 pt-4">
                    <div className={styles.iconStyles}>
                        <img src={Car} className={styles.iconImageCar}/>
                        <p className="pt-2 text-sm">Transfer</p>
                    </div>
                    <div className={styles.iconStyles}>
                        <img src={Swimming} className={styles.iconImageSwimmer}/>
                        <p className="pt-2 text-sm">Activity</p>
                    </div>
                    <div className={styles.iconStyles}>
                        <img src={Insurance} className={styles.iconImage}/>
                        <p className="pt-2 text-sm">Insurance</p>
                    </div>
                    <div className={styles.iconStyles}>
                        <a className={styles.textColorSize} onClick={()=>handleMoreInclusion()}>More Inclusions</a>
                    </div>
                </div>
            </div>
            <div className="flex pt-4">
                <div className={cx(styles.titlediv)}>
                    <p className={styles.transferedStyle}>Transfered By:</p>
                    <p className="pl-4 pt-1">
                        {newData.list.transfer_types.map((transfer) => {
                            return(<p>
                                {transfer.name}
                                </p>)
                         })}
                    </p>   
                </div>
                <div className={styles.prizeDetails}>
                    <p className="pt-2"><span className={styles.prizeText}>₹{newData.list.adult_price}</span> / Adult</p>
                    <p className="pt-3"><span  className={styles.prizeText}>₹{newData.list.child_price}</span> / Child</p>
                </div>
            </div>
            <div className="flex flex-col justify-between pl-4 pr-4" >
                <button className={newData.is_selected?"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-gray-500 ease-in-out":"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-j-orange ease-in-out"}
                   onClick={()=>addRemoveShore(data, i, newData, index,  newData.transferSelectedValue, newData.list.title)}
                   style={{'marginBottom': '15px'}}
                >
                    {!newData.is_loader ? newData.is_selected?"Remove":"Add": <LoadingIcon />}
                </button>
            </div> */}
            <Modal show={modalShow} onClose={() => handleMoreInclusion()}>
                <div className="p-8">
                    <h3 className="pb-4 font-bold text-lg">Tour Instructions</h3>
                    {x.map(inclusion => {
                        return (
                            <div class="flex items-start mr-4 mb-2">
                                <input type="checkbox" id="A3-yes" name="A3-confirmation" value="yes" class="opacity-0 absolute h-8 w-8" />
                                <div class="bg-white border-2 mt-1 rounded-md border-gray-500 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                                    <svg class="fill-current w-3 h-3 text-j-orange pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <g transform="translate(-9 -11)" fill="#EA725B" fill-rule="nonzero">
                                                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <label for="A3-yes" class="select-none">{inclusion}</label>
                            </div>
                        );
                    })}
                    {/* <input type="checkbox">Transfer</input> */}
                </div>
            </Modal>
        </div>
        // <div className="shadow-lg rounded-lg ml-4 mr-4 bg-white mb-4 pb-4">
        //     <div className={cx(styles.mobileImg,"relative")}>
        //         <img src={newData.list.images[0]} className="w-full rounded-t-lg h-56"/>
        //         <div className={cx(styles.topText,"top-right text-white text-sm")}>{newData.list.hours} HRS</div>
        //     </div>
        //     <div className="md:grid lg:grid grid-cols-2 gap-4">
        //         <div className={cx(styles.sliderContainer,"pl-4 pr-4")}>
        //             <Slider {...settings}>
        //                 {newData.list.images.map((images) => {
        //                     return(
        //                         <div>
        //                             <img src={images} className={cx(styles.imageContainer,"w-full rounded-lg h-full")}/>
        //                         </div>
        //                     )
        //                 })}
        //             </Slider>
        //         </div>
        //         <div className="pl-4 pr-4">
        //                 <p className={styles.titleHead}>{newData.list.title}</p>
        //                 <div className="flex">
        //                     <p className="font-bold text-xl">Inclusion:- </p>
        //                     <p className={cx(styles.inclustioText,"text-xl")}>{newData.list.inclusions.map((inclusion, index)=> {
        //                             return(
        //                                 <>
        //                                     {newData.list.inclusions.length-1 === i ? <span>{inclusion}</span>:  <span>{`${inclusion}, `}</span>}
        //                                 </>
        //                             )
        //                         })}</p>
        //                 </div>
        //                 <p className={cx(styles.description,"pb-2 pt-4")}>{newData.list.description} </p>
        //                 <div className="flex  pt-4">
        //                     <div className={styles.transferType}>
        //                         <div className="flex">
        //                             <p className={cx(styles.tranferBy,"pt-4 md:pl-0 lg:pl-0 md:pr-4 lg:pr-4 pr-0 pl-0")}>Duration:</p>
        //                             <p className={cx(styles.tranferByOption,"pt-4")}>{newData.list.hours} HRS</p>
        //                         </div>
        //                         <div className="flex">
        //                             <p className={cx(styles.tranferBy,"pt-4 md:pl-0 lg:pl-0 md:pr-4 lg:pr-4 pr-0 pl-0")}>Transfered By:</p>
        //                             <div className="md:flex lg:flex pt-2 md:pl-0 lg:pl-0 pl-0 md:pr-4 lg:pr-4 pr-0 pt-3">
        //                                 {newData.list.transfer_types.map((transfer) => {
        //                                     return(
        //                                     <div>
        //                                         <input 
        //                                             type="radio" 
        //                                             value={transfer.id}
        //                                             checked={transfer.id == newData.transferSelectedValue}
        //                                             onChange={(e)=>handleTransferSelected(e)}

        //                                         />
        //                                         <label className={cx(styles.tranferByOption,"pl-2")}>{transfer.name}</label>
        //                                     </div>)
        //                                 })}
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="pt-2 md:pl-0 lg:pl-0 pl-0 md:pr-4 lg:pr-4 pr-0">
        //                         <p className="pt-2"><span className="text-lg">₹{newData.list.adult_price}</span>/Per Adult Price</p>
        //                         <p className="pt-2"><span className="text-lg">₹{newData.list.child_price}</span>/Per Child Price</p>
        //                         <button 
        //                             className={newData.is_selected?"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-gray-500 ease-in-out":"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-j-orange ease-in-out"}
        //                             onClick={()=>addRemoveShore(data, i, newData, index,  newData.transferSelectedValue)}
        //                         >
        //                             {!buttonLoadNew ? newData.is_selected?"Remove":"Add": <LoadingIcon />}
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //  <div className={cx(styles.inclusionWeb,"flex pl-4 pr-4 pb-4 pt-2")}>
        //         <div className="w-9/12 pr-4">
        //             <p className={cx(styles.duration,"text-sm pb-2")}>DURATION - {newData.list.hours} HRS</p>
        //             <p className={cx(styles.inclusion)}>Inclusion: - {
        //                 newData.list.inclusions.map((inclusion, index)=> {
        //                     return(
        //                         <>
        //                             {newData.list.inclusions.length-1 === i ? <span>{inclusion}</span>:  <span>{`${inclusion}, `}</span>}
        //                         </>
        //                     )
        //                 })
        //             }</p>
        //         </div>
        //         <div className="w-3/12">
        //            <button 
        //                 className={newData.is_selected?"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-gray-500 ease-in-out":"h-8 pl-10 pr-10 text-white w-full mt-4 rounded transition duration-300 bg-j-orange ease-in-out"}
        //                 onClick={()=>addRemoveShore(data, i, newData, index,  newData.transferSelectedValue)}
        //             >
        //                  {!buttonLoadNew ? newData.is_selected?"Remove":"Add": <LoadingIcon />}
        //             </button>
        //         </div>
        //     </div>
        //     <div className={cx(styles.buttonMobile,"flex pb-4")}>
        //         {!buttonLoadNew && <button 
        //                 className={newData.is_selected?"h-8 pl-10 pr-10 text-white w-10/12 mt-4 ml-6 mr-6 rounded transition duration-300 bg-gray-500 ease-in-out":"h-8 pl-10 pr-10  ml-6 mr-6 text-white w-10/12 mt-4 rounded transition duration-300 bg-j-orange ease-in-out"}
        //                 onClick={()=>addRemoveShore(data, i, newData, index,  newData.transferSelectedValue)}
        //             >
        //                 {!buttonLoadNew ? newData.is_selected?"Remove":"Add": <LoadingIcon />}
        //             </button>
        //             }
        //     </div> 
        // </div>
    )
}