import React, { useEffect, useState, useRef, useContext } from "react";
import UserContext from "../../../store/UserContext";
import moment from "moment";
import { getToken } from "../../../utils/common";
import { Link, useHistory } from "react-router-dom";

const CheckoutForm = ({
  booking,
  billingData,
  cancel,
  cardNumber,
  cvv,
  month,
  year,
  bank,
  code,
  upi,
  wallet,
  partialStatus,
  paymentPath,
  amount,
  walletStatus,
  bookingID,
  paymentOptionID,
  backButtonFunction,
  normalBooking,
  totalPrice,
  bookingType
}) => {
  console.log(billingData)
  const [submitted, setSubmitted] = useState(false);
  const formRef = useRef(null);
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();
  var storage = JSON.parse(localStorage.getItem('UserProfile'));

  const bookingInput = {
    amount: paymentPath == "wallet" ? parseInt(amount, 10) : parseInt(amount, 10),
    contact: {
      first_name: paymentPath == "wallet" ? storage.first_name : billingData.first_name,
      last_name: paymentPath == "wallet" ? storage.last_name : billingData.last_name,
      email: paymentPath == "wallet" ? storage.email : billingData.email,
      phoneNumber: paymentPath == "wallet" ? storage.phone_number : billingData.phone,
      gstin: paymentPath == "wallet" ? storage.gstin : billingData.gst,
      address: paymentPath == "wallet" ? storage.address : billingData.address,
      pan: paymentPath == "wallet" ? storage.pan : billingData.pan,
      pan_name: paymentPath == "wallet" ? storage.name : billingData.name,
      state: paymentPath == "wallet" ? storage.state : billingData.state,
      expense_above_7l: billingData.expense_above_7l,
      tax_regime: billingData.tax_regime,
      tds_opted: billingData.tds_opted,
    },
    paymentInfo: {
      plan: "",
      voucherNumber: "",
      promoCode: "",
      partial: partialStatus,
      first_name: paymentPath == "wallet" ? storage.first_name : billingData.first_name,
      last_name: paymentPath == "wallet" ? storage.last_name : billingData.last_name,
      email: paymentPath == "wallet" ? storage.email : billingData.email,
    },
  };

  if (partialStatus) {
    bookingInput.payment_option_id = paymentOptionID;
  }

  if (cardNumber) {
    // bookingInput.paymentInfo.cardInfo = {
    //   bin: cardNumber.slice(0, 6),
    // };
    bookingInput.paymentInfo.type = "card"
    bookingInput.paymentInfo.cardInfo = {
      "cardNumber": cardNumber,
      "first_name": paymentPath == "wallet" ? storage.first_name : billingData.first_name,
      "last_name": paymentPath == "wallet" ? storage.last_name : billingData.last_name,
      "cvv": cvv,
      "expiryMonth": month,
      "expiryYear": year
    }
  } else if (upi) {
    bookingInput.paymentInfo.type = "upi"
    bookingInput.paymentInfo.vpa = upi
  } else if (bank) {
    bookingInput.paymentInfo.type = "nb"
    bookingInput.paymentInfo.bankCode = code
  } else if (wallet) {
    if (!normalBooking) {
      bookingInput.paymentInfo.amount = totalPrice;
    } else {
      let bookigStore = JSON.parse(window.sessionStorage.getItem('booking'));
      let amount_paid_new = sessionStorage.getItem('amount-paid')
      bookingInput.paymentInfo.amount = bookigStore == null ? amount_paid_new : bookigStore.amount_paid;
    }
    bookingInput.paymentInfo.type = "wallet"
  }

  function send_payu_request(payment_create_response) {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", payment_create_response.payu_url);
    for (var key in payment_create_response.payu_body) {
      if (payment_create_response.payu_body.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", payment_create_response.payu_body[key]);
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  const [bookingData, setData] = useState(null);

  function createPaymentApi(requestData) {
    sessionStorage.setItem('flowDirection', true);
    let urlExt = "";
    if (paymentPath == "wallet") {
      if (walletStatus) {
        urlExt = '/wallet/recharge';
        window.sessionStorage.setItem('holdBookingStatus', false)
      } else {
        if (normalBooking) {
          urlExt = '/payments';
          window.sessionStorage.setItem('holdBookingStatus', false)
        } else {
          urlExt = '/payments/repay_due_amount';
          window.sessionStorage.setItem('holdBookingStatus', false)
        }
      }
    } else if (paymentPath == "booking") {
      if (walletStatus) {
        backButtonFunction.backButtonFunction();
      }
      if (bookingType == 'hold') {
        window.sessionStorage.setItem('holdBookingStatus', true)
        urlExt = '/payments/hold_booking';
      } else {
        urlExt = '/payments';
        window.sessionStorage.setItem('holdBookingStatus', false)
      }
    }
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal${urlExt}`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(requestData)
    }).then(response => response.json())
      .then(response => {
        console.log(response)
        sessionStorage.setItem("bookingID", paymentPath == "wallet" ? bookingID : booking.id)
        if (wallet) {
          if (response.status == 'failed') {
            history.push("/admin/booking/fail");
          } else if (response.status == 'Success') {
            history.push("/admin/booking/success");
          }
        } else {
          if (paymentPath == "booking") {
            setUser(Object.assign({}, user, { booking: booking }));
          }
          send_payu_request(response.pg_data);
        }
      }).catch(err => err);
    //   return fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/payments`, {
    //       method: 'POST',
    //       headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //           Authorization:`Bearer ${getToken()}`,
    //       },
    //       body: JSON.stringify(requestData)
    //   }).then(response => response.json())
    //   .then(response => {
    //       if(wallet) {
    //           if(response.status == 'failed') {
    //               history.push("/admin/booking/fail");
    //           } else if (response.status == 'Success') {
    //               history.push("/admin/booking/success");
    //           }
    //       } else {
    //           setUser(Object.assign({}, user, {booking: booking}));
    //           send_payu_request(response.pg_data);
    //       }
    // }).catch(err => err);
  }

  useEffect(() => {
    if (!submitted) {
      createPaymentApi({
        variables: { input: bookingInput }, booking_id: paymentPath == "wallet" ? bookingID : booking.id
      })
      setSubmitted(true);
    }
  }, [submitted]);

  useEffect(() => {
    if (submitted && bookingData) {
      if (formRef && formRef.current) {
        formRef.current.submit();
      }
    }
  }, [submitted, bookingData]);
  if (!bookingData) return <div />;

  const pg = cardNumber ? "CC" : bank ? "NB" : "UPI";
  const bankCode = code || (upi ? "UPI" : "CC");

  // TODO: set different endpoints for different products. Pass txnid.
  const successUrl = `${window.location.origin}/admin/booking/success`;
  const failUrl = `${window.location.origin}/admin/booking/fail`;

  return (
    <form action={bookingData.pg_data.payu_url} method="post" ref={formRef}>
      <input type="hidden" name="hash" value={bookingData.pg_data.payu_body.hash} />
      <input type="hidden" name="firstname" value={bookingData.pg_data.payu_body.firstname} />
      <input type="hidden" name="surl" value={bookingData.pg_data.payu_body.surl} />
      <input type="hidden" name="phone" value={bookingData.pg_data.payu_body.phone} />
      <input type="hidden" name="furl" value={bookingData.pg_data.payu_body.furl} />
      <input
        type="hidden"
        name="key"
        value={bookingData.pg_data.payu_body.key}
      />
      <input type="hidden" name="txnid" value={bookingData.pg_data.payu_body.txnid} />
      <input
        type="hidden"
        name="Amount"
        value={parseFloat(bookingData.pg_data.payu_body.amount).toFixed(2)}
      />
      <input type="hidden" name="productinfo" value={bookingData.pg_data.payu_body.productinfo} />
      <input type="hidden" name="email" value={bookingData.pg_data.payu_body.email} />
      <input type="hidden" name="pg" value={bookingData.pg_data.payu_body.pg} />
      <input type="hidden" name="bankcode" value={bookingData.pg_data.payu_body.bankcode} />
      {pg === "CC" && (
        <>
          <input type="hidden" name="ccnum" value={bookingData.pg_data.payu_body.ccnum} />
          <input type="hidden" name="ccname" value={bookingData.pg_data.payu_body.ccname} />
          <input type="hidden" name="ccvv" value={bookingData.pg_data.payu_body.ccvv} />
          <input
            type="hidden"
            name="ccexpmon"
          // value={moment(date).format("MM")}
          />
          <input
            type="hidden"
            name="ccexpyr"
          // value={moment(date).format("YYYY")}
          />
        </>
      )}
      {pg === "UPI" && <input type="hidden" name="vpa" value={upi} />}
      {/*<button type="submit">Submit</button>*/}
    </form>
  )
}

export default CheckoutForm;