import React, { Component, useContext, useState, useEffect } from 'react';
import BookingFormView from "./General/BookingFormView";
import Tile from "../Tile/Tile";
import Button from "../Button/Button";
import cx from "classnames";
import InputField from "../Form/InputField";
import SelectField from "../Form/SelectField";
import Checkbox from "../Form/CheckBox";
import CountryStates from "../../public/country-states.json";
import CountryCodes from "../../public/country-phone";
import PhoneField from "../Form/PhoneFieldAlt";
import Joi, { string } from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { name, phone, email, date, gender, first_name, last_name, checkbox } from "../../utils/validations";
import UserContext from "../../store/UserContext";
import moment from "moment";
import { getToken, removeUserSession } from "../../utils/common";
import { Link, useHistory } from "react-router-dom";
import StateCities from "../../public/state-cities.json";
import ModalError from "../Modal/ModalError"
import images from "../../assets/images/error.jpg";
import Modal from '../Modal/Modal';
import { stringify } from 'postcss';


class GuestDetailsView extends Component {
  documentData;
  constructor(props) {
    super(props);
    this.state = {
      booking: [],
      selection: []
    }
  }

  render() {
    return (
      <>
        {/* <div className="grid grid-cols-3 border-b p-6">
                    <div>
                        <button onClick={this.backbuttonpressed}>
                            Back
                        </button>
                    </div>
                    <div className="text-center text-basecolor font-bold">Title of cruise trip</div>
                </div> */}
        <GuestDetailsSubView nextStep={this.props} />
      </>
    )
  }
}

export default GuestDetailsView;

const GuestDetailsSubView = (nextStep) => {
  let history = useHistory();
  const [user, setUser] = useContext(UserContext);
  const [showUpsell, setShowUpsell] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [renderView, setRenderView] = useState(false)
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState('')
  const [primaryGuests, setPrimaryGuests] = useState({});
  const [tempData, settempData] = useState(eval(sessionStorage.getItem('tempData')))
  const booking = user.booking || {};
  const { rooms } = booking;
  const joiObject = {};
  let is_international = JSON.parse(window.sessionStorage.getItem('is_international'))

  useEffect(() => {
    setRenderView(true)
  }, [])
  const referenceDate = booking.itinerary
    ? new Date(booking.itinerary.startTime)
    : new Date();

  const adultMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 12 * 12)
  );

  const firstAdultMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 18 * 12)
  );

  const childMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 2 * 12)
  );
  const infantMaxDate = new Date(
    new Date(referenceDate).setMonth(referenceDate.getMonth() - 6)
  );

  rooms.forEach((room, roomIndex) => {
    const count = room.adults + room.children + room.infants;
    for (let guestIndex = 0; guestIndex < count; guestIndex++) {
      const guestType =
        guestIndex < room.adults
          ? "adult"
          : guestIndex < room.adults + room.children
            ? "child"
            : "infant";
      const prefix = `${roomIndex}_${guestIndex}`;
      joiObject[`${prefix}_first_name`] = first_name;
      joiObject[`${prefix}_last_name`] = last_name;
      joiObject[`${prefix}_meal`] = name;
      joiObject[`${prefix}_gender`] = gender;
      joiObject[`${prefix}_dob`] =
        guestType === "adult"
          ? (guestIndex === 0 ? date.max(firstAdultMaxDate) : date.max(adultMaxDate))
          : guestType === "child"
            ? date.max(childMaxDate).min(adultMaxDate)
            : date.max(infantMaxDate).min(childMaxDate);
      joiObject[`${prefix}_country`] = name;
      joiObject[`${prefix}_state`] = name;
      joiObject[`${prefix}_city`] = name;
      joiObject[`${prefix}_nri`] = checkbox;
      joiObject[`${prefix}_primary_key`] = checkbox;
      if (guestType === "adult") {
        joiObject[`${prefix}_phone`] = phone;
        joiObject[`${prefix}_email`] = email;
      }
      if(is_international){
        joiObject[`${prefix}_passportIssueDate`] = date;
        joiObject[`${prefix}_passportExpiryDate`] = date;
        joiObject[`${prefix}_passportNumber`] = name;
        joiObject[`${prefix}_passportAddress`] = name;
      }
    }
  });

  const { register, errors,getValues,setValue, handleSubmit, setError } = useForm({
    resolver: joiResolver(Joi.object(joiObject)),
  });
console.log('get------------------',getValues())


  function createBookingApi(requestData) {
    let bookingID = window.sessionStorage.getItem('booking');
    let sessionBookingID = JSON.parse(window.sessionStorage.getItem('booking_id'))
    bookingID = JSON.parse(bookingID);
    bookingID = bookingID.id;
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${sessionBookingID}`, {
      method: 'PUT',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          `Bearer ${getToken()}`,
      },
      body: JSON.stringify(requestData)
    }).then(response => {
      setErrorStatus(response.status)
      if (response.status == 200) {
        return response.json()
      } else {
        setErrorMessage(response.message)
        setErrorModal(true)
      }
    })
      .then(response => {
        if (response.booking) {
          booking.id = response.booking.id
          booking.guestsFilled = true
          setUser(Object.assign({}, user, {
            booking: booking,
            onboard_credit_offer: response.onboard_credit_offer,
            partial_onboard_credit_offer: response.partial_onboard_credit_offer,
            rooms: response.rooms,
            due_date: response.due_date,
            payment_option_id: response.payment_option_id,
            success_status: false
          }));
          sessionStorage.setItem('partialPayment', response.partial_payable_amount);
          sessionStorage.setItem('partialRule', response.partial_amount_rule);
          sessionStorage.setItem('flowDirection', false);
          setShowUpsell(false);
          nextStep.nextStep.nextStep()
        } else {
          setSubmitted(false);
          setErrorMsg(response.data);
        }
      }).catch(err => err);
  }

  const handleOk = () => {
    setErrorModal(false)
    removeUserSession();
    history.push('/login');
  }

  const onSubmit = (data) => {
    setSubmitted(true);
    console.log('data----------', data);
    const newRooms = [...rooms].map((room) =>
      Object.assign({}, room, {
        guests: [...(room.guests || [])].map((guest) =>
          Object.assign({}, guest)
        ),
      })
    );
    Object.keys(data).forEach((key) => {
      const [roomIndex, guestIndex, field] = key.split("_");
      console.log('field:-', field);
      const guest =
        newRooms[parseInt(roomIndex)].guests[parseInt(guestIndex)] || {};
      newRooms[parseInt(roomIndex)].guests[
        parseInt(guestIndex)
      ] = Object.assign({}, guest, { [field]: data[key] });
    });
    setUser(
      Object.assign({}, user, {
        booking: Object.assign({}, booking, {
          rooms: newRooms,
        }),
      })
    );

    console.log('newRooms', newRooms)

    sessionStorage.setItem("tempData", JSON.stringify(newRooms))

    let bookingInput = {}
    bookingInput.rooms = newRooms.map((room) => ({
      priceKey: room.selected.priceKey,
      roomId: room.room_id,
      guests: room.guests.map((guest, i) => ({
        passport_address: guest.passportAddress,
        passport_expiry_date: moment(guest.passportExpiryDate).format("YYYY-MM-DD"),
        passport_issue_date: moment(guest.passportIssueDate).format("YYYY-MM-DD"),
        passport_number: guest.passportNumber,
        name: guest.first,
        first_name: guest.first,
        last_name: guest.last,
        type:
          i < room.adults
            ? "ADULT"
            : i < room.adults + room.children
              ? "CHILD"
              : "INFANT",
        discountCategory: null,
        //i < room.adults ? getDiscountCategory(room.discounts[i]) : null, // TODO: what values? It can be pulled from room.discounts
        email: guest.email,
        phoneNumber: guest.phone,
        meal: guest.meal,
        idType: guest.doc,
        idNumber: guest.passNumber,
        gender: guest.gender.toUpperCase(),
        dateOfBirth: moment(guest.dob).format("YYYY-MM-DD"),
        country: guest.country,
        state: guest.state,
        city: guest.city,
        nri: guest.nri,
        is_primary: guest.primary,
        citizenship: guest.country,
      })),
    }));
    setUser(
      Object.assign({}, user, {
        booking: Object.assign({}, booking, {
          guests: bookingInput.rooms.guests,
        }),
      })
    );

    console.log('bookingInput', bookingInput)

    createBookingApi({
      variables: { input: bookingInput },
    })
    // setUser(
    //   Object.assign({}, user, {
    //     booking: Object.assign({}, booking, {
    //       guestsFilled: true,
    //     }),
    //   })
    // );
  };

  //  const handleSubmit = (e) => {
  //    e.preventDefault()
  //   nextStep.nextStep.nextStep()
  //  }

  const backbuttonpressed = (e) => {
    e.preventDefault()
    nextStep.nextStep.prevStep()
  }

  return (
    <>
      {renderView && <><BookingFormView
        title="Now let's add the details of the guests."
        buttonText="Choose Add-On"
        onClick={handleSubmit(onSubmit)}
        lessSpace={true}
        id="guest-info-form"
        disabled={submitted}
        loading={submitted}
      >
        {/* <p className="text-j-gray">
            To save your reservation, we need the details of each traveler per
            stateroom. Remember, first and last names must match what appears on
            government-issued photo IDs.
          </p> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {rooms.map((r, i) => (
            <RoomCard
              key={i}
              room={r}
              rooms={rooms}
              roomIndex={i}
              setPrimaryGuests={setPrimaryGuests}
              primaryGuests={setPrimaryGuests}
             getValues={getValues}
             setValue={setValue}
              errors={errors}
              register={register}
              tempData={tempData != null ? tempData[i] : null}
            />
          ))}
        </form>
        {!!Object.keys(errors).length && (
          <p className="text-j-red pt-4 flex">
            <i className="fas fa-exclamation-triangle text-2xl px-4 self-center" />
            <span className="self-center">
              Please enter all guests' details correctly before continuing.
            </span>
          </p>
        )}
      </BookingFormView></>}
      <ModalError show={errorModal}>
        <div id="menu" className="w-full h-full">
          <div className="2xl:container  2xl:mx-auto py-3 px-4 md:px-28 flex justify-center items-center">
            <div className="w-96 md:w-auto dark:bg-gray-800 relative flex flex-col justify-center items-center bg-white py-6 px-4 md:px-24 xl:py-6 xl:px-36">
              <div role="banner">
                <img src={images} className="w-full rounded-lg h-full" />
              </div>
              <div class="mt-3">
                <h1 role="main" class="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-center text-gray-800">{errorStatus}</h1>
              </div>

              <div class="mt">
                <p class="mt-2 sm:w-80 text-base dark:text-white leading-7 text-center text-gray-800">Some thing went wrong!</p>
                <p class="mt-2 sm:w-80 text-base dark:text-white leading-7 text-center text-white">{errorMessage}</p>
              </div>
              <button className="w-full dark:text-gray-800 dark:hover:bg-gray-100 dark:bg-white sm:w-auto mt-6 text-base leading-4 text-center text-white py-3 px-16 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 bg-j-orange hover:bg-j-purple" onClick={() => handleOk()}>OK</button>
            </div>
          </div>
        </div>
      </ModalError>
    </>
  )
}

const RoomCard = ({ room,rooms, roomIndex,primaryGuests,setPrimaryGuests,getValues,setValue, errors, register, tempData }) => {

  const count = room.adults + room.children + room.infants;
  const guests = [...(room.guests || Array(count).fill({}))];
  // const [open, setOpen] = useState(roomIndex === 0);

  if (guests.length < count)
    guests.push(...Array(count - guests.length).fill({}));
  guests.splice(count);

  // if (roomIndex == 0 && !guests[0].name) {
  //   guests[0].name = user.name || user.booking.name
  //   guests[0].phone = user.phone || user.booking.phone
  // }

  useEffect(() => {
    if (
      !!Object.keys(errors).filter((key) => key.startsWith(`${roomIndex}_`))
        .length
    ) {
      // setOpen(true);
    }
  }, [errors]);


  const guestForms = guests.map((guest, i) =>{
return(
  (
    <GuestForm
      // className={!open && "hidden"}
      guestType={
        i < room.adults
          ? "Adult"
          : i < room.adults + room.children
            ? "Child"
            : "Infant"
      }
      key={i}
      setValue={setValue}
      getValues={getValues}
      index={i}
      rooms={rooms}
      roomIndex={roomIndex}
      guest={guest}
      primaryGuests={primaryGuests}
      setPrimaryGuests={setPrimaryGuests}
      errors={errors}
      register={register}
      tempData={tempData != null ? tempData.guests[i] : null}
    />
  )
)
  });

  return (
    <Tile theme="magenta" shadow className="my-5 rounded-lg" id={`room-${room.id}`}>
      <Tile.Inner>
        {roomIndex == 0 && <p className="text-j-white pb-4">
          To save your reservation, we need the details of each traveler per
          stateroom. Remember, first and last names must match what appears on
          government-issued photo IDs.
        </p>}
        <h4
          className="uppercase pb-4 flex justify-between cursor-pointer"
        //   onClick={() => setOpen(!open)}
        >
          <span className="text-white">Cabin {room.id + 1}</span>
          {/* <i
                className={cx("fal text-j-orange text-4xl leading-4", {
                  "fa-angle-down": !open,
                  "fa-angle-up": open,
                })}
              /> */}
        </h4>
        <p className="text-white">
          {room.selected.name}, {count} Guests
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
          {guestForms}
        </div>
      </Tile.Inner>
    </Tile>
  );
}

const GuestForm = ({
  index,
  roomIndex,
  guest,
  getValues,
  setValue,
  rooms,
  guestType,
  errors,
  primaryGuests,
  setPrimaryGuests,
  register,
  className,
  tempData
}) => {
  // defaultValue={moment(guest.dob).format("YYYY-MM-DD")}
  var updated;
  const [type, setType] = useState("text");
  const [countryStatesArray, setCountryState] = useState(["Maharashtra", "Tamil Nadu", "Karnataka", "Kerala", "Andaman and Nicobar Islands", "Andhra Pradesh", "Telangana", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Lakshadweep", "Madhya Pradesh", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Orissa", "Pondicherry", "Punjab", "Rajasthan", "Sikkim", "Tripura", "Uttaranchal", "Uttar Pradesh", "West Bengal"]);
  const [country, setCountry] = useState("India");
  // const [phoneCode, setCountryCode] = useState("+91");
  const prefix = `${roomIndex}_${index}`;
  const [state, setState] = useState(tempData ? tempData.state : null)
  const [nri, setNri] = useState(tempData ? tempData.nri ? true : false : false);
  const [checked, setChecked] = useState(tempData ? tempData.is_primary ? true : false : false);
  const [primaryCheck, setPrimaryCheck] = useState(tempData ? tempData.is_primary ? true : false : false);
  const [showNotice, setShowNotice] = useState(true);
  let is_international = JSON.parse(window.sessionStorage.getItem('is_international'))

console.log(tempData)
  const handlePrimaryChange = (e) => {
    const { checked } = e.target;
    setPrimaryGuests((prev) => ({
      ...prev,
      [roomIndex]: checked ? index : null,
    }));
  
    if (checked) {
      const values = getValues();
      Object.keys(values).forEach((key) => {
        const [rIndex, gIndex, field] = key.split("_");
        if (rIndex === `${roomIndex}` && field === 'primary' && `${gIndex}` !== `${index}`) {
          setValue(key, false);
        }
      });
    }
  };
  
 useEffect(()=>{
  if(tempData){
    setValue(`${prefix}_primary_key`,tempData?.primary)
  }else{
    setValue(`${roomIndex}_0_primary_key`,true)
  }  
 },[])

  return (
    <div className={cx("pt-12", className)}>
      <h4 className="text-white">
        Guest {index + 1}: {guestType}
      </h4>
      <div className='ml-4 mt-4 flex flex-wrap items-center '>
      <input 
        type='checkbox'
  name={`${prefix}_primary_key`}
  ref={register()}
  className='h-4 w-4 rounded-0 accent-gray-700 cursor-pointer'
  checked={getValues(`${prefix}_primary_key`)}
  onChange={(e) => handlePrimaryChange(e)}
      />
       <label className="pl-4">Set as Primary</label>
      {/* <Checkbox
         name={`${prefix}_primary_key`}
  ref={register()}
  value={getValues(`${prefix}_primary_key`) ? true : false}
  onChange={(e) => handlePrimaryChange(e)}
        > Set as Primary
            </Checkbox> */}
          </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-2">
        <div className="mt-4 grid grid-cols-2 gap-2">
          <div>
            <label className="pl-4">First name:</label>
            <InputField
              icon="fal fa-user"
              // placeholder="Full name"
              name={`${prefix}_first_name`}
              withIcon
              lessSpace
              defaultValue={tempData != null ? tempData.first : null}
              ref={register({ required: true })}
              inverseError
              error={
                errors && errors[`${prefix}_first_name`] && "Please enter a valid first name"
              }
            />
          </div>
          <div>
            <label className="pl-4">Last name:</label>
            <InputField
              icon="fal fa-user"
              // placeholder="Full name"
              name={`${prefix}_last_name`}
              withIcon
              lessSpace
              defaultValue={tempData != null ? tempData.last : null}
              ref={register({ required: true })}
              inverseError
              error={
                errors && errors[`${prefix}_last_name`] && "Please enter a valid last name"
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-2">
          <div className="mt-4">
            <label className="pl-4">Date of Birth:</label>
            <InputField
              icon="fal fa-calendar-alt"
              type="date"
              onFocus={() => setType("date")}
              onBlur={() => setType("date")}
              // placeholder="DOB: "
              max={"9999-12-31"}
              name={`${prefix}_dob`}
              withIcon
              lessSpace
              ref={register({ required: true })}
              inverseError
              error={
                errors &&
                errors[`${prefix}_dob`] &&
                `Please enter a valid date for ${guestType}`
              }
            />
          </div>
          <div className="mt-4">
            <label className="pl-4">Gender:</label>
            <SelectField
              icon="fal fa-utensils"
              name={`${prefix}_gender`}
              withIcon
              lessSpace
              defaultValue={tempData != null ? tempData.gender : null}
              placeholder="Gender"
              ref={register({ required: true })}
              inverseError
              error={errors && errors[`${prefix}_gender`] && "Please select a gender"}
            >
              <option selected value="" disabled>Choose gender Type</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </SelectField>
          </div>
        </div>
        <div>
          <label className="pl-4">Country:</label>
          <SelectField
            icon="fal fa-globe"
            name={`${prefix}_country`}
            withIcon
            lessSpace
            ref={register({ required: true })}
            value={country}
            onChange={(event) => {
              let x = event.target.value;
              setCountry(x);
              setCountryState(CountryStates.countries.filter((country) => country.country === x)[0].states);
              // setCountryCode(`+${CountryCodes.filter((country) => country.Name === x)[0].Dial}`);
            }}
            inverseError
            error={errors && errors[`${prefix}_country`] && "Please choose"}
          >
            {
              CountryStates.countries.map((country) => {
                return <option value={country.country} selected>{country.country}</option>
              })
            }
          </SelectField>
        </div>
        <div>
          <label className="pl-4">State:</label>
          <SelectField
            icon="fal fa-building"
            name={`${prefix}_state`}
            withIcon
            lessSpace
            // defaultValue={tempData ?  tempData.state : "Choose a State"}
            ref={register({ required: true })}
            inverseError
            onChange={(event) => {
              let x = event.target.value;
              setState(x);
            }}
            error={errors && errors[`${prefix}_state`] && "Please choose"}
          >
            <option selected value="" disabled>Choose a State</option>
            {
              countryStatesArray.map((states) => {
                return <option value={states}>{states}</option>
              })
            }
          </SelectField>
        </div>
        <div>
          <label className="pl-4">City:</label>
          <SelectField
            icon="fal fa-building"
            name={`${prefix}_city`}
            withIcon
            lessSpace
            defaultValue={tempData ? tempData.city : "Choose a City"}
            ref={register({ required: true })}
            inverseError
            error={errors && errors[`${prefix}_city`] && "Please choose city"}
          >
            <option selected value="" disabled>{tempData ? tempData.city : "Choose a City"}</option>
            {state && StateCities[state] ?
              (StateCities[state].map((city) => {
                return <option value={city}>{city}</option>
              })) : null
            }
          </SelectField>
        </div>
        {console.log(tempData)}
        <div>
          <label className="pl-4">NRI:</label>
          <div className='ml-4 mt-2'>
            <Checkbox
              name={`${prefix}_nri`}
              id={`${prefix}_nri`}
              ref={register()}
              onChange={(event) => {
                let x = event.target.value;
                setNri(x);
              }}
              value={nri}
            >
              I am an NRI
            </Checkbox>
          </div>
        </div>


        {is_international ?
          <>
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div>
                <label className="pl-4">Passport Issue Date:</label>
                <InputField
                  icon="fal fa-calendar-alt"
                  type="date"
                  onFocus={() => setType("date")}
                  onBlur={() => setType("date")}
                  // placeholder="DOB: "
                  max={"9999-12-31"}
                  name={`${prefix}_passportIssueDate`}
                  withIcon
                  lessSpace
                  ref={register({ required: true })}
                  inverseError
                  error={
                    errors &&
                    errors[`${prefix}_passportIssueDate`] &&
                    `Please enter a valid date`
                  }
                />
              </div>
              <div>
                <label className="pl-4">Passport Expiry Date:</label>
                <InputField
                  icon="fal fa-calendar-alt"
                  type="date"
                  onFocus={() => setType("date")}
                  onBlur={() => setType("date")}
                  // placeholder="DOB: "
                  max={"9999-12-31"}
                  name={`${prefix}_passportExpiryDate`}
                  withIcon
                  lessSpace
                  ref={register({ required: true })}
                  inverseError
                  error={
                    errors &&
                    errors[`${prefix}_passportExpiryDate`] &&
                    `Please enter a valid date`
                  }
                />
              </div>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div>
                <label className="pl-4">Passport Number:</label>
                <InputField
                  icon="fal fa-user"
                  placeholder="Passport Number"
                  name={`${prefix}_passportNumber`}
                  withIcon
                  lessSpace
                  defaultValue={tempData != null ? tempData.passportNumber : null}
                  ref={register({ required: true })}
                  inverseError
                  error={
                    errors && errors[`${prefix}_passportNumber`] && "Please enter passport number"
                  }
                />
              </div>
              <div>
                <label className="pl-4">Address:</label>
                <InputField
                  icon="fal fa-user"
                  placeholder="Passport Address"
                  name={`${prefix}_passportAddress`}
                  withIcon
                  lessSpace
                  defaultValue={tempData != null ? tempData.passportAddress : null}
                  ref={register({ required: true })}
                  inverseError
                  error={
                    errors && errors[`${prefix}_passportAddress`] && "Please enter passport address"
                  }
                />
              </div>
            </div>
          </>
          : null
        }
        {guestType === "Adult" && (
          <div>
            <label className="pl-4">Mobile Number:</label>
            <PhoneField
              name={`${prefix}_phone`}
              placeholder="A 10-digit mobile number"
              ref={register({ required: true })}
              defaultValue={tempData != null ? tempData.phone : null}
              // countryPhoneCode={phoneCode}
              lessSpace
              inverseError
              error={
                errors &&
                errors[`${prefix}_phone`] &&
                "Please enter a correct phone number"
              }
            />
          </div>
        )}
        {guestType === "Adult" && (
          <div>
            <label className="pl-4">Email:</label>
            <InputField
              icon="fal fa-envelope"
              name={`${prefix}_email`}
              placeholder="Email Address"
              ref={register({ required: true })}
              defaultValue={tempData != null ? tempData.email : null}
              lessSpace
              inverseError
              error={
                errors &&
                errors[`${prefix}_email`] &&
                "Please enter a valid email"
              }
            />
          </div>
        )}
        <div className="grid md:grid-cols-2 lg:grid-cols-2">
          <div>
            <label className="pl-4">Meal:</label>
            <SelectField
              icon="fal fa-utensils"
              name={`${prefix}_meal`}
              withIcon
              lessSpace
              defaultValue={tempData != null ? tempData.meal : null}
              placeholder="Choose Meal Type"
              ref={register({ required: true })}
              inverseError
              error={
                errors &&
                errors[`${prefix}_meal`] &&
                `Please Pick your Meal Preference`
              }
            >
              <option selected value="" disabled>Choose Meal Type</option>
              <option value="Veg">Vegetarian</option>
              <option value="Jain">Jain</option>
              <option value="Non. Veg">Non - Vegetarian</option>
              <option value="Gluten Free">Gluten Free</option>
            </SelectField>
          </div>
        </div>
      </div>
      <p className="text-j-orange pb-4 pl-4">
        Only double vaccinated guests to be allowed on board
      </p>
      <Modal show={showNotice} >
        <div className='p-10 text-black text-center'>
          <h3 className='text-2xl font-bold mb-8'>Important Note</h3>
          <p className='text-j-gray mb-6 px-5'>
            All key details like Customer Name, Date of birth, Email id and
            Phone number will be verified at the time of onboarding. Check-in
            will be denied in case of failed verification.
          </p>
          <button className='px-10 py-2 bg-j-orange rounded text-white' onClick={() => setShowNotice(false)}>OK</button>
        </div>
      </Modal>
    </div>
  );
};
