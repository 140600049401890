import React, { useState, useEffect } from "react";
import cx from 'classnames';
import styles from './Modal.module.css'

class Modal extends React.Component {

    render() {
        if(!this.props.show){
            return null;
        }
        return (
            <div className={cx(styles.modal)}>
                <div className={cx(styles.modalcontent, "rounded-xl")} 
                    onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}>
                    <div>{this.props.children}</div>
                </div>
            </div>
          );
    }
}

export default Modal;