import React, { useState, Component, setUser, useEffect, useRef } from 'react';
import cx from "classnames";
import moment from "moment";
import Tile from "../../Tile/Tile";
import Joi from "joi";
import Button from "../../Button/Button";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { name, phone, email, date, phone_pattern } from "../../../utils/validations";
import Modal from "../../Modal/Modal";
import LoadingIcon from "../../Loading/LoadingIcon";
import { getToken } from "../../../utils/common";

const SummaryViewHoldBooking = ({ booking, paymentPage, panToggle }) => {
  var currancyStatus = JSON.parse(sessionStorage.getItem('currency'))
  return(<>
    {booking.giftCard ? (
    <GiftCardSummaryView booking={booking} currancyStatus={currancyStatus}/>
  ) : (
    <ItinerarySummaryView booking={booking} paymentPage={paymentPage} currancyStatus={currancyStatus} panToggle={panToggle}/>
  )}</>)
}

const GiftCardSummaryView = ({ booking, currancyStatus }) => (
  <>
    <h1>Order summary</h1>
    <Tile tiny theme="white" className="mb-5 border border-j-gray-lighter">
      <Tile.Inner className="pb-0">
        <OrderDetail title="Item">Gift card</OrderDetail>
        <OrderDetail title="Total amount payable" big>
          {currancyStatus.symbol} {booking.giftCard.value.toLocaleString("hi-IN")}
        </OrderDetail>
      </Tile.Inner>
    </Tile>
  </>
);

const ItinerarySummaryView = ({ booking, paymentPage, panToggle,currancyStatus }) => {
  const [fareOpen, setFareOpen] = useState(false);
  const [cancellationPolicy, setCancellationPolicy] = useState(null);
  const [reschedulePolicy, setReschedulePolicy] = useState(null);
  const [commissionOpen, setCommissionOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [modelEmail, setModelEmail] = useState(false);
  const [emailValue, setEmailValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const submitRef = useRef(null);
  let bookingHold = JSON.parse(sessionStorage.getItem('hold-booking-store'));;
  let itiId = bookingHold.itinerary.id;
  let bookingArr = bookingHold.rooms;
  let grandTotalValueIncTax = 0;
  let grandTotalValue = 0;
  let bookingLocalStorage = JSON.parse(sessionStorage.getItem('booking'));
  // alert(bookingLocalStorage)
  bookingArr.reduce((grandTotal, booking) => {
    grandTotalValue = grandTotalValue + booking.total_amnt;
  }, 0)
  grandTotalValueIncTax = booking.price_details.grand_total.tcs!==0 || booking.price_details.grand_total.tcs_with_pan!==0?(panToggle?parseFloat(grandTotalValue) + parseFloat(booking.gst) + parseFloat(booking.price_details.grand_total.tcs_with_pan):parseFloat(grandTotalValue) + parseFloat(booking.gst) + parseFloat(booking.price_details.grand_total.tcs)):parseFloat(grandTotalValue) + parseFloat(booking.gst);
  let bookingidEmailShare = booking.id;
  const cancellationDiv = cancellationPolicy ? (
    <div>
      {cancellationPolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return (
                  <li style={{display: "revert"}}>{term}</li>
                );
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)
  const rescheduleDiv = reschedulePolicy ? (
    <div>
      {reschedulePolicy.map((ele) => {
        return (
          <>
            <h3 style={{ color: "#500E4B", fontWeight: "600" }}>
              <u>
                {ele.title}
              </u>
            </h3>
            <br></br>
            <table style={{ color: "black", borderCollapse: "collapse", width: "100%" }}>
              <tr>
                {Object.keys(ele.fees[0]).map((heading) => {
                  heading = heading.replaceAll("_", " ")
                  return (
                    <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", textTransform: "capitalize" }}>{heading}</th>
                  )
                })}
              </tr>
              {ele.fees.map((rows) => {
                return (
                  <tr>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["days_to_depature"]}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", }}>{rows["fee"]}</td>
                  </tr>
                );
              })}
            </table>
            <br></br>
            <ul style={{ color: "black", listStyle: "disc", marginLeft: "1rem", }}>
              {ele.terms.map((term) => {
                return(
                  <li style={{display: "revert"}}>{term}</li>
                )
              })}
            </ul>
            <br></br>
          </>
        );
      })}
    </div>
  ) : (<></>)

  const modalClose = () => {
    setModelEmail(false)
  }

  const modalOpen = (e) => {
    setModelEmail(true);
  }

  const schema = Joi.object({
    email,
  })


  const { register, errors, handleSubmit, setError } = useForm({
    resolver: joiResolver(schema),
  });

  const shareEmailQuatation = () => {
    setSubmitted(true);
    let dataObj = {
      "booking_id": bookingidEmailShare,
      "step": "payment",
      "email": emailValue,
      "auth": getToken()
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/quotations`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(dataObj),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error("Verification failed");
      }
      )
      .then((response) => {
        alert("Quotation Send successfully");
        setSubmitted(false);
        modalClose()

      }).catch((err) => console.log(err));
  }

  const onChangeValue = (e) => {
    const email = e.target.value;
    setEmailValue(email);
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/itineraries/${itiId}/policies`, {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) => res.json())
      .then((res) => {
        setCancellationPolicy(res.cancellation);
        setReschedulePolicy(res.reschedule);
      }).catch((err) => {
        alert("Error Fetching Cancellation and Reschedule Policies");
      });
  }, [])
  const { rooms } = booking;
  let departure = booking.departure;
  let departureTime = booking.itinerary.start_time;
  let arrival = booking.destination;
  let arrivalTime = booking.itinerary.end_time;

  const guests = rooms
    .map((r) => r.adults + r.children + r.infants)
    .reduce((t, i) => t + i, 0);

  const selected = {};
  // rooms.forEach((r) => {
  //   if (!selected[r.selected.name]) selected[r.selected.name] = 0;
  //   selected[r.selected.name]++;
  // });
  // const totalPrice = rooms.reduce((x, r) => x + (r.total_price || r.selected.price.total), 0);

  
  // let x = booking.route.name;
  // x = x.split(" - ")

  const returnFormattedCurrency = (x) => {
    x = Number(x);
    x=x.toString();
    var afterPoint = '';
    if(x.indexOf('.') > 0)
      afterPoint = x.substring(x.indexOf('.'),x.length);
    x = Math.floor(x);
    x=x.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    return res;
  }

  return (
    <>
      <Modal show={modelEmail} onClose={modalClose}>
        <div className="p-6">
          <p className="mb-4">Please enter your Email-ID:</p>
          {/* <InputField
                        placeholder="Email address"
                        type="email"
                        name="email"
                        lessSpace
                        // defaultValue={storageProfile.email}
                        // ref={register({ required: true })}
                        error={errors && errors.email && "Please enter a valid email address"}
                    /> */}
          <input
            required
            name={email}
            placeholder="Email address"
            type="email"
            className="w-full h-10 px-2 w-full text-black rounded mt-2 focus:outline-non flex-grow max-w-full placeholder-j-gray-lighter text-black text-sm shadow placeholder-gray-500 placeholder-opacity-75 self-center pt-1 leading-none mb-4 border-grey-600"
            // value={this.props.value} 
            // defaultValue={this.props.defaultValue} 
            onChange={(e) => onChangeValue(e)} />
          {!submitted && <Button
            className="bg-j-red-light text-white w-full mt-6 pt-1"
            disabled={submitted}
            onClick={() => shareEmailQuatation()}
          >
            Submit
          </Button>}
          {submitted && <Button
            className="bg-j-red-light text-white w-full mt-6 pt-1"
            disabled={submitted}
          >
            <LoadingIcon />
          </Button>}
        </div>
      </Modal>
      <Tile tiny className="mb-5 border border-j-gray-lighter rounded-lg">
        <Tile.Inner className="pb-0">
          
          {booking.number ? (<OrderDetail title="Booking Reference no">
              {booking.number}
            </OrderDetail>) : null}

          {booking.invoice_url ? (<OrderDetail title="Invoice">
              <a href={booking.invoice_url}><i class="fa fa-download" aria-hidden="true"></i>
 Download</a>
            </OrderDetail>) : null}

          {booking.itinerary ? (
            <OrderDetail title="Ship">
              {booking.itinerary.name}
            </OrderDetail>
          ) : (
            <OrderDetail title="Item">
              {booking.route
                ? "Flexi Date Certificate"
                : "Flexi Cruise Certificate"}
            </OrderDetail>
          )}

          <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-4">
                    <div>
                      <OrderDetail title="Departure">
                      {departure}
                      <br></br>
                      {moment(departureTime).format("ddd, D MMM YYYY, h:mm a")}
                      </OrderDetail>
                    </div>
                    <div>
                      <OrderDetail title="Arrival">
                        {arrival}
                        <br></br>
                        {moment(arrivalTime).format("ddd, D MMM YYYY, h:mm a")}
                      </OrderDetail>
                    </div>
                  </div>
          {/* {Object.keys(bookingArr)
                  .map((k, index) => 
                  
                    <OrderDetail title={`Cabin ${index+1}`}>
                        {bookingArr[index].category}<br/>
                        Deck No: {((bookingArr[index].room_number).toString()).charAt(0)}<br/>
                        Room No: {bookingArr[index].room_number}<br/>
                        {bookingArr[index].adults+bookingArr[index].children+bookingArr[index].infants}  Guest{(bookingArr[index].adults+bookingArr[index].children+bookingArr[index].infants) > 1 ? "s" : null}
                    </OrderDetail>
                  )} */}
          {/* <OrderDetail title="Itinerary">
            {Object.keys(selected)
              .map((k) => `${selected[k]} x ${k}`)
              .join(", ")}
            , {guests} Guest{guests > 1 ? "s" : null}
          </OrderDetail> */}
          {/* <OrderDetail title="Total amount payable" big>
            &#x20B9; {Math.round(totalPrice.toLocaleString("hi-IN"))}
          </OrderDetail> */}
              {paymentPage && <>
                <div
                  style={{ color: '#500E4B', fontWeight: 'bold' }}
                  className="grid grid-cols-1 grid-cols-2 mt-4"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <span className="pt-1"> PRICE DETAILS</span>
                  <i
                    style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                    className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                      "fa-angle-down": !open,
                      "fa-angle-up": open,
                    })}
                  ></i>
                </div>
                {open && bookingArr.map((booking, index) =>
                  <div key={index}>
                    <div style={{ color: "black", marginTop:'25' }}>
                      {booking.price.guest_prices.map((price, index) =>
                        <div key={index}>
                          <div style={{ width: '100%' }} className="mt-4">
                            <PaymentDetails content={price.type} details={returnFormattedCurrency(Math.round((price.total) * 100 / 100))} big={true} currancyStatus={currancyStatus}/>
                            <PaymentDetails content="Cabin Fare:" details={returnFormattedCurrency(Math.round((price.fare) * 100 / 100))} big={false} currancyStatus={currancyStatus}/>
                            {price.discount != '0' && <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
                              <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">Individual Discount</div>
                              <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
                                - {currancyStatus.symbol}{returnFormattedCurrency(price.discount)}
                              </div>
                            </div>}
                            <PaymentDetails content="Service Charges & Levies" details={returnFormattedCurrency(price.portCharges+price.gratuity)} big={false} />
                            <PaymentDetails content="Fuel Surcharge" details={returnFormattedCurrency(price.fuelSurcharge)} big={false} />
                           
                            
                            {parseFloat(price.insurance) > 0 && <PaymentDetails
                              content="Insurance:"
                              details={returnFormattedCurrency(price.insurance)}
                              big={true}
                            />}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* {open && <div style={{marginTop:'25'}}>
                  <PaymentDetails content="Promo Code:" details="0" big={true} currancyStatus={currancyStatus}/>
                  <PaymentDetails content="New Year Promo:" details="0" big={false} currancyStatus={currancyStatus}/>
                </div>
                } */}
                {open && <div style={{marginTop:'25'}}>
                  <PaymentDetails content="Sub-Total:" details={returnFormattedCurrency(bookingArr.reduce((grandTotal, booking) => {
                    return ((Math.round(grandTotal + parseInt(booking.price.total))))
                  }, 0))} big={true} />
                  <PaymentDetails content="Taxable Sub Total:" details={returnFormattedCurrency(bookingArr.reduce((grandTotal, booking) => { return ((Math.round(grandTotal + parseInt(booking.price.total)))) }, 0))} big={false} />
                </div>
                }
                {open && <div style={{marginTop:'25'}}>
                  <PaymentDetails content="Taxes:" details={returnFormattedCurrency(Math.round(parseInt(booking.price_details.grand_total.tcs)!==0 || parseInt(booking.price_details.grand_total.tcs_with_pan)!==0?(parseFloat(booking.gst)+parseFloat(booking.price_details.grand_total.tcs)):booking.gst))} big={true} />
                  <PaymentDetails content="GST:" details={returnFormattedCurrency(Math.round(booking.gst))} big={false} />
                  {parseInt(booking.price_details.grand_total.tcs)!==0|| parseInt(booking.price_details.grand_total.tcs_with_pan)!==0 ? <PaymentDetails content="TCS:" details={returnFormattedCurrency(Math.round(panToggle?parseInt(booking.price_details.grand_total.tcs):parseInt(booking.price_details.grand_total.tcs_with_pan)))} big={false} />:null}
                </div>
                }

              {booking.coupon_code && <div style={{ margin: '25px' }}>
                <PaymentDetails content={`Applied Coupon (${booking.coupon_code}):`} details={returnFormattedCurrency(booking.coupon_discount)} big={true} />
              </div>}

              {booking.agent_commission_type == "p2p" && <div style={{marginTop:'15px'}}>
                <PaymentDetails content="Discount:" details={returnFormattedCurrency(booking.agent_commission)} big={true} currancyStatus={currancyStatus}/>
              </div>}

              <div style={{marginTop:'15px'}}>
                <PaymentDetails content="Net Payable to Cordelia:" details={returnFormattedCurrency(Math.round(booking.pending_amount))} big={true} currancyStatus={currancyStatus}/>
              </div>

              {booking.agent_commission_type == "p2a" && <>
                <div
                  style={{ color: '#500E4B', fontWeight: 'bold', marginTop:'15px' }}
                  className="grid grid-cols-1 grid-cols-2"
                  onClick={() => {
                    setCommissionOpen(!commissionOpen);
                  }}
                >
                  <span className="pt-1"> COMMISSION DETAILS</span>
                  <i
                    style={{ color: "grey", fontSize: 28, textAlign: 'right' }}
                    className={cx("fas cursor-pointer text-j-gray-light text-4xl", {
                      "fa-angle-down": !commissionOpen,
                      "fa-angle-up": commissionOpen,
                    })}
                  ></i>
                </div>
                {commissionOpen && <div style={{marginTop:'15px'}}>
                  <PaymentDetails content="Commission:" details={returnFormattedCurrency(Math.round(booking.agent_commission))} big={true} currancyStatus={currancyStatus}/>
                </div>
                }
              </>
              }
              <div
                style={{
                  color: "#500E4B",
                  fontWeight: "bold",
                  marginTop:'15px'
                }}
                className="grid grid-cols-1 grid-cols-2 pb-4"
                onClick={() => {
                  setFareOpen(!fareOpen);
                }}
              >
                <span className="pt-1">Cancellation and Reschedule policy</span>
                <i
                  style={{ color: "grey", fontSize: 28, textAlign: "right" }}
                  className={cx(
                    "fas cursor-pointer text-j-gray-light text-4xl",
                    {
                      "fa-angle-down": !fareOpen,
                      "fa-angle-up": fareOpen,
                    }
                  )}
                ></i>
              </div>
              <div
                  style={{
                    marginTop:"10px",
                    marginBottom: "18px",
                    color: "#500E4B",
                    fontWeight: "bold",
                  }}
                  className="grid grid-cols-1"
                >
                  <a href="https://images.cordeliacruises.com/static/insurance_web_document.pdf" target="_blank" className="text-j-magenta font-bold">
                  Cordelia Cruises Insurance Benefit Document
                  </a>
                </div>
                <div
                  style={{
                    marginTop:"10px",
                    marginBottom: "18px",
                    color: "#500E4B",
                    fontWeight: "bold",
                  }}
                  className="grid grid-cols-1"
                >
                  <a href="https://cordelia-images-prod.s3.ap-south-1.amazonaws.com/static/Other_Policy.pdf" target="_blank" className="text-j-magenta font-bold">
                  Other Additional Charges
                  </a>
                </div>
              </>}
              {fareOpen && (
                <div style={{ padding: "1rem" }}>
                  {cancellationDiv}
                  {rescheduleDiv}
                </div>
              )}
              {/* <div className="grid justify-end mb-4">
                <a onClick={e => modalOpen(e)} className="cursor-pointer"><i className="fas fa-share"></i> Share</a>
              </div> */}
        </Tile.Inner>
      </Tile>
    </>
  );
};

export default SummaryViewHoldBooking;

const OrderDetail = ({ title, children, big }) => (
  <div className="mb-6">
    <h1 className="uppercase pb-0 text-j-gray text-tiny leading-none">
      {title}
    </h1>
    <h4
      className={
        big ? "text-j-magenta text-3xl font-bold pt-4" : "text-j-magenta pt-2 text-lg font-semibold"
      }
    >
      {children}
    </h4>
  </div>
);

const PaymentDetails = ({ content, details, big, currancyStatus }) => (
  <>
    <div className="grid grid-cols-1 grid-cols-2" style={{ marginBottom: '7px' }}>
      {big && <>
        <div style={{ color: "black", fontSize: 16, fontWeight: 'bold' }} className="float-left bold">
          {content}
        </div>
        <div style={{ color: "black", fontSize: 16, textAlign: 'right', fontWeight: 'bold' }} className="float-right font-weight-bold text-capitalize">
        &#x20B9;{details}
        </div>
      </>
      }
      {!big && <>
        <div style={{ color: "grey", fontSize: 14 }} className="float-left bold">{content}</div>
        <div style={{ color: "grey", fontSize: 14, textAlign: 'right' }} className="float-right font-weight-bold text-capitalize">
        &#x20B9;{details}
        </div>
      </>
      }
    </div>
  </>
);
