import React, { Component, useContext, useState } from "react";
import CardStats from "../../components/Cards/CardStats";
import CardSettings from "../../components/Cards/CardSettings";
import Tile from "../../components/Tile/Tile";
import Button from "../../components/Button/Button";
import cx from 'classnames';
import styles from "./booking.module.css"
import Modal from "../../components/Modal/Modal";
import { getToken } from "../../utils/common";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import Checkbox from "../../components/Form/CheckBox";
import moment from "moment";
import {
  useHistory,
  withRouter,
  useLocation
} from "react-router-dom";
import UserContext from "../../store/UserContext";

var bookingID = null;
var webCheckInStatus= null

class BookingListDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelbookingModel: false,
      details: false,
      guestEditStatus: false,
      step: 1,
      reshedulebookingModel: false,
      bookingDetailsData: [],
      nights: 0,
      ports: [],
      boarding_port: '',
      bording_time: '',
      ports_pin: null,
      rooms: [],
      guests: [],
      adult: 0,
      child: 0,
      infant: 0,
      amount_paid: 0,
      refund_amount: 0,
      loading: true,
      cancelStatus: true,
      resheduleData: [],
      selectedDate: null,
      checked: 0,
      recheduleStep: 1,
      rescheduling_price: 0,
      bookingStatus: '',
      selectedItinary: null,
      submitted: false,
      webCheckInStatusState: null,
      priceLoading:false,
    }
    this.reshedulebooking = this.reshedulebooking.bind(this);
  }

  componentDidMount() {
    var currancyStatusLocal = JSON.parse(sessionStorage.getItem('currency'))
    this.setState({currancyStatus: currancyStatusLocal})
    const { match, location, history } = this.props;
    bookingID = location.state.id;
    webCheckInStatus = location.state.web_check_in_status
    this.detailApi(bookingID, webCheckInStatus)
  }

  detailApi = (bookingID, webCheckInStatus) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/bookings/' + bookingID + '/details', {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          `Bearer ${getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ bookingDetailsData: response.booking });
        if (response.booking.status == "CANCELLED") {
          this.setState({ cancelStatus: false })
        }
        this.setState({webCheckInStatusState: webCheckInStatus})
        this.setState({ nights: this.state.bookingDetailsData.itinerary.nights });
        this.setState({ ports: this.state.bookingDetailsData.itinerary.ports });
        this.setState({ bording_port: `${this.state.bookingDetailsData.bording_port.city}, ${this.state.bookingDetailsData.bording_port.full_name}` })
        this.setState({ bording_time: this.state.bookingDetailsData.bording_port.boarding_time })
        this.setState({ rooms: this.state.bookingDetailsData.rooms });
        this.setState({ bookingStatus: this.state.bookingDetailsData.status });
        this.setState({ ports_pin: this.state.bookingDetailsData.bording_port })
        this.setState({ loading: false })
      })
  }

  cancelbooking = () => {
    this.setState({ cancelbookingModel: true });
  }

  showModal = e => {
    this.setState({
      cancelbookingModel: !this.state.cancelbookingModel,
      step: 1,
    });
  };

  exitReshedule = e => {
    this.setState({
      reshedulebookingModel: !this.state.reshedulebookingModel,
      recheduleStep: 1,
    })
  }

  showMore = () => {
    this.setState({
      details: !this.state.details
    })
  }

  editDetails = () => {
    this.setState({ guestEditStatus: !this.state.guestEditStatus })
  }

  showNext = (step) => {
    if (step == 1) {
      this.setState({priceLoading: true})
      fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/bookings/' + bookingID + '/cancellation_charges', {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            `Bearer ${getToken()}`,
        },
      }).then((response) => response.json())
        .then((response) => {
          this.setState({ amount_paid: response.total_paid, refund_amount: response.total_refund })
          sessionStorage.setItem('amount-paid', response.total_paid)
          this.setState({priceLoading: false})
        })
    } else if (step == 3) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/bookings/' + bookingID + '/cancel', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            `Bearer ${getToken()}`,
        },
      }).then((response) => response.json())
        .then((response) => { })
      this.setState({ cancelStatus: false })
      this.setState({ bookingStatus: "CANCELLED" })
    }
    this.setState({ step: this.state.step + 1 })
  }

  returnToHome = () => {
    const { match, location, history } = this.props;
    history.push('/admin/dashboard')

  }

  webCheckin = () => {
    window.open(process.env.REACT_APP_API_WEB_CHECK_IN_URL+'/web-check-in/guest-details/'+this.state.bookingDetailsData.number, '_blank').focus();
  }

  webCheckinBoarding = () => {
    console.log(this.state.bookingDetailsData)
    window.open(process.env.REACT_APP_API_WEB_CHECK_IN_URL+'/web-check-in/boarding-pass/'+this.state.bookingDetailsData.id, '_blank').focus();
  }

  reshedulebooking = () => {
    const { match, location, history } = this.props;
    const modify_data = {
      "booking_reference": this.state.bookingDetailsData.number,
      "start_date": this.state.bording_time,
      "number_of_guest": this.state.bookingDetailsData.guests,
      "shipName": this.state.bookingDetailsData.itinerary.name,
      "bookingID": this.state.bookingDetailsData.id,
      "booking": this.state.bookingDetailsData
    }
    sessionStorage.setItem("modifyData", JSON.stringify(modify_data))
    history.push('/admin/modify-booking')
    // const bookingInput = {
    //   itinerary: this.state.bookingDetailsData.id,
    //   booking_reference: this.state.bookingDetailsData.number
    // }
    // fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/reschedule/available_dates?booking_reference=' + this.state.bookingDetailsData.number, {
    //   method: 'GET',
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization:
    //       `Bearer ${getToken()}`,
    //   },
    // }).then((response) => response.json())
    //   .then((response) => {
    //     if (response.message == 'Confirmed booking not found') {
    //       this.setState({ loadingData: false })
    //     } else {
    //       this.setState({ resheduleData: response.itineraries })
    //     }
    //   })
    // this.setState({ reshedulebookingModel: true });
  }

  handleSelected = (e) => {
    this.setState({
      selectedItinary: e.target.value
    })
  }

  showNextReshedule = (step) => {
    const { match, location, history } = this.props;
    if (step == 1) {
      const reshudilingInput = {
        itinerary: this.state.selectedItinary,
        booking_reference: this.state.bookingDetailsData.number
      }
      fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/reschedule/pricing', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            `Bearer ${getToken()}`,
        },
        body: JSON.stringify(reshudilingInput)
      }).then((response) => response.json())
        .then((response) => {
          this.setState({ rescheduling_price: response.rescheduling_fee })
          this.setState({ recheduleStep: step + 1 })
        })
    } else if (step == 2) {
      this.setState({ submitted: true })
      const reshudilingInput = {
        itinerary: this.state.selectedItinary,
        booking_reference: this.state.bookingDetailsData.number
      }
      fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/reschedule/confirm', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            `Bearer ${getToken()}`,
        },
        body: JSON.stringify(reshudilingInput)
      }).then((response) => response.json())
        .then((response) => {
          this.setState({ submitted: false })
          if (response.rescheduling_fee > 0) {
            var roomCategory;
            var rooms_data = [];
            var roomNumber;
            var roomCode;
            this.state.rooms.map((guest, i) => {
              var adultCount = 0;
              var childCount = 0;
              var infantCount = 0;
              guest.guests.map((guestData) => {
                if (guestData.type == "ADULT") {
                  adultCount = adultCount + 1
                  // this.setState({adult:this.state.adult+1})
                } else if (guestData.type == "CHILD") {
                  childCount = childCount + 1
                } else {
                  infantCount = infantCount + 1
                }
              })
              roomCategory = guest.category;
              roomNumber = guest.number;
              roomCode = guest.code;
              rooms_data.push({
                "adults": adultCount,
                "infants": infantCount,
                "children": childCount,
                "roomCatogory": roomCategory,
                "roomNumber": roomNumber,
                "roomCode": roomCode,
                "id": i
              })
            })
            const itineraryData = {
              itineraries: this.state.bookingDetailsData.itinerary,
              amount: this.state.rescheduling_price,
              rechedule: true,
              rooms: rooms_data,
              number: this.state.bookingDetailsData.number,
              itinerary: this.state.bookingDetailsData.itinerary,
              bookingID: this.state.bookingDetailsData.id
            }
            sessionStorage.setItem("itinerary", JSON.stringify(itineraryData))
            history.push({
              pathname: '/admin/paymentGateway',
            })
          } else {
            history.push({
              pathname: '/admin/dashboard',
            })
          }
        })
    }
  }

  reshedule = () => {
    const { recheduleStep } = this.state;
    switch (recheduleStep) {
      case 1:
        return (
          <>
            <div className="pr-4 pl-4 pb-4 pt-10">
              <p className="text-j-magenta text-normal font-bold mb-4">Reschedule</p>
              <p className="text-xs font-bold">Please select a preferred cruise date from the available dates below :</p>
              {this.state.resheduleData.length == 0 && <p className="text-center mt-4">No Date Found</p>}
              <form className="pt-4">
                {this.state.resheduleData.map((data) => {
                  var availability = data.available;
                  return (
                    <>
                      {availability && <div className="flex items-center mr-4 mb-4">
                        <input type="radio" className="w-4 h-4 inline-block mr-2 rounded-full border border-j-orange flex-no-shrink" id={data.itinerary} checked={this.state.selectedItinary === data.itinerary} value={data.itinerary} onChange={(e) => this.handleSelected(e)} />
                        <label for={data.itinerary} className="flex items-center cursor-pointer text-sm">
                          {moment(data.start_time).format("ddd, D MMM YYYY")}
                        </label>
                      </div>
                      }
                    </>
                  )
                })}
                {/* <div className="flex items-center mr-4 mb-4">
                  <input id="radio2" type="radio" name="radio" className="hidden" />
                  <label for="radio2" className="flex items-center cursor-pointer text-sm">
                    <span className="w-4 h-4 inline-block mr-2 rounded-full border border-j-orange flex-no-shrink"></span>
                    Sat, 02 March 2021
                  </label>
                </div> */}
              </form>
              {this.state.resheduleData.length > 0 && <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-4 uppercase"
                style={{ height: "37px" }}
                onClick={() => this.showNextReshedule(recheduleStep)}
                disabled={this.state.selectedItinary == null ? true : false}
              >
                Reschedule
              </Button>
              }
              <Button
                className="bg-j-orange text-white w-full pt-1 mt-4"
                style={{ height: "37px" }}
                onClick={() => this.exitReshedule()}
              >
                EXIT
              </Button>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <div className="pr-4 pl-4 pb-4 pt-10">
              <p className="text-j-magenta text-normal font-bold mb-4">Reschedule</p>
              <div className="grid grid-cols-2 mt-2">
                <div>
                  <p className="text-black text-sm">Extra Charge:</p>
                  <p className="text-black text-sm">Rescheduling fee:</p>
                  <p className="text-black text-sm mt-2 font-bold">Net Payable Amount:</p>
                </div>
                <div className="grid justify-end">
                  <p className="text-black text-sm">{this.state.currancyStatus.symbol} 0</p>
                  <p className="text-black text-sm">{this.state.currancyStatus.symbol}{this.state.rescheduling_price}</p>
                  <p className="text-black text-lg mt-2 font-bold">{this.state.currancyStatus.symbol}{this.state.rescheduling_price}</p>
                </div>
              </div>
              <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-4 uppercase"
                style={{ height: "37px" }}
                onClick={() => this.showNextReshedule(recheduleStep)}
                disabled={this.state.submitted}
              >
                {this.state.submitted ? <LoadingIcon /> : 'CONFIRM'}
              </Button>
              <Button
                className="bg-j-orange text-white w-full pt-1 mt-4"
                style={{ height: "37px" }}
                onClick={() => this.exitReshedule()}
              >
                EXIT
                  </Button>
            </div>
          </>
        )
    }
  }

  openMap = () => {
    var url = "https://maps.google.com/?q=" + this.state.ports_pin.lat + "," + this.state.ports_pin.lng;
    window.open(url);
  }

  switchState = () => {
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <>
            <div className="pr-4 pl-4 pb-4 pt-10">
              <p className="text-j-magenta text-xl font-bold">Cancel Booking</p>
              <p className="text-black mt-4">Are you sure you want to cancel?</p>
              <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-6 uppercase"
                style={{ height: "37px" }}
                onClick={() => this.showNext(step)}
              >
                Calculate Cancellation fee
                  </Button>
              <Button
                className="bg-j-orange text-white w-full pt-1 mt-4"
                style={{ height: "37px" }}
                onClick={() => this.showModal()}
              >
                EXIT
                  </Button>
            </div>
          </>
        )
      case 2:
        return (
          <>
          {this.state.priceLoading ? <LoadingIcon className="py-20 text-j-magenta" /> :
            <div className="pr-4 pl-4 pb-4 pt-10">
              <p className="text-j-magenta text-xl font-bold">Summary</p>
              <p className="text-black mt-4">Here is a summary of your cancellation request:</p>
              <div className="grid grid-cols-2 mt-2">
                <div>
                  <p className="text-black text-sm">Amount paid:</p>
                  <p className="text-black text-sm">Cancellation fee:</p>
                  <p className="text-black text-sm mt-2 font-bold">Refund Amount:</p>
                </div>
                <div className="grid justify-end">
                  <p className="text-black text-sm">{this.state.currancyStatus.symbol}{this.state.amount_paid}</p>
                  <p className="text-black text-sm">{this.state.currancyStatus.symbol}{this.state.amount_paid - this.state.refund_amount}</p>
                  <p className="text-black text-lg mt-2 font-bold">{this.state.currancyStatus.symbol}{this.state.refund_amount}</p>
                </div>
              </div>
              <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-6 uppercase"
                style={{ height: "37px" }}
                onClick={() => this.showNext()}
              >
                PROCEED TO CANCEL BOOKING
                </Button>
              <Button
                className="bg-j-orange text-white w-full pt-1 mt-4"
                style={{ height: "37px" }}
                onClick={() => this.showModal()}
              >
                EXIT
                </Button>
            </div>}
          </>
        )
      case 3:
        return (
          <>
            <div className="pr-4 pl-4 pb-4 pt-10">
              <p className="text-j-magenta text-normal font-bold mb-2">Confirm Cancellation</p>
              <BookingPreview
                bookingID={this.state.bookingDetailsData.number}
                roomsLength={this.state.rooms.length}
                guests={this.state.bookingDetailsData.guests}
                nights={this.state.nights}
                ports={this.state.ports}
                rooms={this.state.rooms}
              />
              <div className="grid grid-cols-2 mt-2">
                <p className="text-black font-bold text-sm">Refund Amount:</p>
                <p className="flex justify-end text-black font-bold text-sm">{this.state.currancyStatus.symbol}{this.state.refund_amount}</p>
              </div>
              <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-4 uppercase"
                style={{ height: "37px" }}
                onClick={() => this.showNext(step)}
              >
                CONFIRM CANCEL
                </Button>
              <Button
                className="bg-j-orange text-white w-full pt-1 mt-4"
                style={{ height: "37px" }}
                onClick={() => this.showModal()}
              >
                EXIT
                </Button>
            </div>
          </>
        )
      case 4:
        return (
          <>
            <div className="pr-4 pl-4 pb-4 pt-10">
              <p className="text-j-magenta text-normal font-bold mb-2">Cancellation Successful</p>
              <p className="text-xs font-bold">Your cruise booking was successfully cancelled.</p>
              <p className="text-xs pt-4 pb-2">Any refund payable to you will be processed within 10-15 business days.</p>
              <BookingPreview
                bookingID={this.state.bookingDetailsData.number}
                roomsLength={this.state.rooms.length}
                guests={this.state.bookingDetailsData.guests}
                nights={this.state.nights}
                ports={this.state.ports}
                rooms={this.state.rooms}
              />
              <Button
                className="bg-j-orange text-white w-full pt-1 mt-4 mb-4"
                style={{ height: "37px" }}
                onClick={() => this.returnToHome()}
              >
                RETURN TO HOMEPAGE
                  </Button>
            </div>
          </>
        )
    }
  }


  render() {
    return (
      <>
        {this.state.loading && (
          <>
            <h1 className="text-4xl">
              <LoadingIcon className="py-20 text-j-magenta" />
            </h1>
          </>
        )}
        {!this.state.loading && (
          <>
            <div className="p-4 ml-6 mr-6">
              <h1 className="mt-2 text-xl text-j-magenta font-bold">MANAGE BOOKING</h1>
              {/* <h2 className="mt-2 text-3xl text-j-grey ">Hello Samuel!</h2>
              <h3 className="mt-2 mb-4 text-xl text-j-grey">15 days left for your dream voyage!</h3> */}
              <div className="grid grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-10 mt-4">
                <div>
                  <p className="text-j-gray text-sm pl-1">BOOKING SUMMARY</p>
                  <Tile theme="magenta" shadow className="my-2 bg-j-orange rounded-xl">
                    <div className="bg-magenta rounded-t-xl text-white pl-14 pt-4">
                      <h1 className="text-xl font-bold">Booking Id : {this.state.bookingDetailsData.number} (<span className="text-sm">{this.state.bookingDetailsData.status}</span>)</h1>
                      <h2 className="text-lg mt-2"> {this.state.rooms.length} Cabins ; {this.state.bookingDetailsData.guests} Guests</h2>
                      <h3 className="text-j-grey mt-2 mb-4">{this.state.nights}-Night cruise</h3>
                    </div>
                    <Tile.Inner>
                      <div className="pl-10 w-full">
                        {this.state.ports.map((data, i) =>
                          <>
                            <div className="flex">
                              <div className={cx(styles.dot)}></div>
                              <div className={cx(styles.spanv, "text-sm, w-48")}>{data.city}</div>
                              <div className={cx(styles.dateSpan, "font-normal, text-sm")}>{data.arrival_date}</div>
                            </div>
                            {!(i + 1 === this.state.ports.length) &&
                              <div className={cx(styles.vl)}></div>
                            }
                          </>
                        )}
                        {/* <div className="flex">
                          <div className={cx(styles.dot)}></div>
                          <div className={cx(styles.spanv, "text-sm, w-48")}>Mumbai</div>
                          <div className={cx(styles.dateSpan, "font-normal, text-sm")}>27 Mon 2021</div>
                        </div>
                        <div className={cx(styles.vl)}></div>
                        <div className="flex">
                          <div className={cx(styles.dot)}></div>
                          <div className={cx(styles.spanv, "text-sm, w-48")}>Lakshadweep</div>
                          <div className={cx(styles.dateSpan, "font-normal, text-sm")}>27 Mon 2021</div>
                        </div>
                        <div className={cx(styles.vl)}></div>
                        <div className="flex">
                          <div className={cx(styles.dot)}></div>
                          <span className={cx(styles.spanv, "text-sm, w-48")}>Cochin</span>
                          <span className={cx(styles.dateSpan, "font-normal, text-sm")}>27 Mon 2021</span>
                        </div> */}
                      </div>
                    </Tile.Inner>
                  </Tile>
                  <p className="text-j-gray text-sm pl-1">BOARDING DETAILS</p>
                  <Tile theme="white" shadow className="my-2 bg-white rounded-xl">
                    <Tile.Inner>
                      <div className="pb-2 pl-10">
                        <h4 className="text-j-gray">Report at</h4>
                      </div>
                      <div className="pl-10 flex">
                        <div className="w-10 pt-2">
                          <i className="fas fa-map-marker-alt text-j-magenta text-3xl"></i>
                        </div>
                        <div className="lg:w-11/12">
                          <div>
                            <p className="text-j-magenta font-bold text-lg">{this.state.bording_port}</p>
                            <p className="text-j-magenta font-bold text-lg">{moment(this.state.bording_time).format("ddd, D MMM YYYY")}</p>
                            <a onClick={this.openMap} className="text-j-penBlue pt-2 font-normal">Google Maps Pin</a>
                          </div>
                        </div>
                      </div>
                    </Tile.Inner>
                  </Tile>
                </div>
                <div>
                  <p className="text-j-gray text-sm pl-1">BOOKING DETAILS</p>
                  {this.state.rooms.map((data, i) => <HidenData hidenData={data} index={i} guestEditStatus={this.state.guestEditStatus} />
                  )
                  }
                  {/* {this.state.bookingDetailsData.status != "CANCELLED" && this.state.bookingDetailsData.status != "RESERVED" && <UpgradeComponent rooms={this.state.rooms} number={this.state.bookingDetailsData.number} itinerary={this.state.bookingDetailsData}/> } */}
                </div>
              </div>
              {this.state.bookingStatus != "CANCELLED" && this.state.bookingStatus != "RESERVED" && this.state.webCheckInStatusState == false && this.state.bookingDetailsData.check_in_open && <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-10"
                style={{height:"37px"}}
                onClick={() => {this.webCheckin()}}
                // disabled={true}
              >
                 Web check In
              </Button>}
              {this.state.bookingStatus != "CANCELLED" && this.state.bookingStatus != "RESERVED" && this.state.webCheckInStatusState == true && <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-10"
                style={{height:"37px"}}
                onClick={() => {this.webCheckinBoarding()}}
                // disabled={true}
              >
                   Boarding Pass
              </Button>}
              {this.state.bookingStatus != "CANCELLED" && this.state.bookingDetailsData.actions.reschedule && <Button
                className="bg-j-orange text-white w-full pt-1 mt-4"
                style={{height:"37px"}}
                onClick={() => {this.reshedulebooking()}}
                // disabled={true}
              >
                  RESCHEDULE
              </Button>
              }
              {this.state.bookingStatus != "CANCELLED" && this.state.bookingDetailsData.actions.cancel && <Button
                className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-4"
                style={{ height: "37px" }}
                onClick={() => { this.cancelbooking() }}
                disabled={!this.state.cancelStatus ? true : false}
              >
                {!this.state.cancelStatus ? "ALREADY CANCELLED" : "CANCEL BOOKING"}
              </Button>
              }
              {this.state.bookingDetailsData.shorex_available && this.state.bookingDetailsData.created_via != "ADMIN" && this.state.bookingDetailsData.is_partial == false &&
                 <Button
                 className="border bg-j-orange text-white border-j-orange w-full pt-1 mt-6"
                 style={{height:"37px"}}
                 onClick={()=>window.location=`/admin/post-booking-addon?bookId=${this.state.bookingDetailsData.id}`}
               >
                   Choose Add on
               </Button>
                 }
                 {console.log('nik',this.state.bookingDetailsData)}
                {this.state.bookingDetailsData.rooms &&
                this.state.bookingDetailsData.rooms.length > 0 && (this.state.bookingDetailsData.actions && (this.state.bookingDetailsData.actions.remove_cabin || this.state.bookingDetailsData.actions.add_pax )) && (
                  <Button
                    className="border bg-j-orange text-white border-j-orange w-full pt-1 mt-6"
                    style={{ height: "37px" }}
                    onClick={() =>
                      (window.location = `/admin/manage-booking?id=${this.state.bookingDetailsData.id}&addGuest=${this.state.bookingDetailsData.actions.add_pax}&removeCabin=${this.state.bookingDetailsData.actions.remove_cabin}`)
                    }
                  >
                    Manage Booking
                  </Button>
                )}
            </div>
          </>
        )}
        <Modal show={this.state.cancelbookingModel} onClose={this.showModal}>
          {this.switchState()}
        </Modal>
        <Modal show={this.state.reshedulebookingModel} onClose={this.exitReshedule}>
          {this.reshedule()}
        </Modal>
      </>
    )
  }
}

export default withRouter(BookingListDetails);

const HidenData = ({ hidenData, index, guestEditStatus }) => {
  console.log(hidenData);
  const [details, setDetails] = useState(false);

  const showMore = () => {
    setDetails(!details)
  }
  const handlePrimaryChange=(e,gId,rId)=>{
    console.log(e,gId,rId)
  let data = {
    "bkroom_id":rId,
    "guest_id":gId
}
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${bookingID}/update_primary_guest`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data)
    }).then(response => response.json())
      .then(response => {
      window.location.reload();
        console.log(response);
      })

  }
  const hasPrimaryGuest = hidenData.guests.some(guest => guest.is_primary === true);
  console.log(hasPrimaryGuest )

  return (<Tile theme="white" shadow className="my-2 bg-white rounded-xl">
    <Tile.Inner>
      <div className="flex mb-8">
        <div className="lg:w-1/12 p-  4">
          <i className="fas fa-key text-j-magenta text-2xl mt-4"></i>
        </div>
        <div className="w-full lg:w-11/12">
          <h4 className="text-j-gray pl-4 text-sm">Cabin {index + 1}</h4>
          <div className="pl-4 pt-2">
            <p className="text-j-magenta font-bold text-lg">{hidenData.category}</p>
            <p className="text-j-magenta font-normal">Room: {hidenData.number}; Deck: {hidenData.deck_no}</p>
            {/* <p className="text-black pt-2">
                                    {data.guests.map((count) =>
                                      {
                                        if(count.type == "ADULT") {
                                        }
                                      }
                                    )}

                                  </p> */}
          </div>
        </div>
      </div>
      {details && <div className="pt-4">
      
        {hidenData.guests.map((personalData, i) =>
          <>
            <h4 className="text-j-gray text-sm pt-2">Guest {i + 1}</h4>
            <div className="grid grid-cols-3 pt-0">
              <div>
                <p className="text-j-magenta font-bold text-normal">{personalData.name}</p>
              </div>
              <div>
                <h4 className="text-j-gray-lighter text-sm">{personalData.age}yrs</h4>
              </div>
              <div className="flex justify-end mr-4">
                {/* <i className="far fa-edit text-black" onClick={()=> this.editDetails()}></i> */}
              </div>
            </div>
            <div>
              {!guestEditStatus && <p className="text-j-magenta font-bold text-sm pt-2">{personalData.email}</p>}
              {!guestEditStatus && <p className="text-j-magenta font-bold text-sm">{personalData.phone}</p>}

{hasPrimaryGuest ? personalData.is_primary ? <p className="text-j-magenta text-semibold" >Primary Guest </p> : null  :<div className="flex flex-wrap gap-2" >
  <label className="text-j-magenta font-bold text-sm" >Set as Primary - </label>
  <input
  className="cursor-pointer checked:bg-j-magenta"
              type="checkbox"
          name={`primary_key`}
          onChange={(e) => handlePrimaryChange(e,personalData.id,hidenData.id)}
        />
</div>}

             
            


              {/* {this.state.guestEditStatus && <div class="flex flex-col mb-4">
                                      <label class="mt-2 text-j-magenta text-sm" for="email">E-mail</label>
                                      <input class="border border-black py-2 px-3 text-black" type="email" name="email" id="email" defaultValue="test.1234@test.com"/>
                                    </div>
                                  }
                                  {this.state.guestEditStatus && <div class="flex flex-col mb-4">
                                      <label class="mt-2 text-j-magenta text-sm" for="email">Mobile number</label>
                                      <input class="border border-black py-2 px-3 text-black" type="email" name="email" id="email" defaultValue="+91 9876543210"/> */}
              {/* </div>} */}
            </div>
          </>
        )}
      </div>
      }
      <Button
        className="border bg-transparent text-j-orange border-j-orange w-full pt-1 mt-6"
        style={{ height: "37px" }}
        onClick={showMore}
      >
        {details ? "HIDE DETAILS" : "SHOW DETAILS"}
      </Button>
    </Tile.Inner>
  </Tile>)
}

const BookingPreview = ({ bookingID, roomsLength, guests, nights, ports, rooms }) => {
  return (
    <>
      <div className="border rounded-xl border-black pl-6 pt-2">
        <h1 className="text-sm font-bold text-j-magenta">Booking Id : {bookingID}</h1>
        <h2 className="text-xs text-j-magenta"> {roomsLength} Cabins ; {guests} Guests</h2>
        <h3 className="text-xs text-j-gray">{nights}-Night cruise</h3>
        <div className="w-full mt-2">
          {ports.map((data, i) =>
            <>
              <div className="flex">
                <div className={cx(styles.dotCancel)}></div>
                <div className={cx(styles.spanv, "text-xs w-44")}>{data.city}</div>
                <div className={cx(styles.dateSpan, "font-normal text-xs")}>{data.arrival_date}</div>
              </div>
              {!(i + 1 === ports.length) &&
                <div className={cx(styles.vlCancel)}></div>
              }
            </>
          )}
        </div>
        {rooms.map((data, i) =>
          <div className="flex mb-2 mt-1">
            <div className="lg:w-1/12 pt-4">
              <i className="fas fa-key text-j-magenta text-lg"></i>
            </div>
            <div className="w-full lg:w-11/12">
              <h4 className="text-j-gray pl-2 text-xs">Cabin {i + 1}</h4>
              <div className="pl-2">
                <p className="text-j-magenta font-bold text-sm">{data.category}</p>
                <p className="text-j-magenta font-normal text-xs">Room: {data.number}; Deck: {data.deck_no}</p>
                {/* <p className="text-black pt-1 text-xs">2 Adults, 1 Child , 1 Infant1</p> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const UpgradeComponent = ({ rooms, number, itinerary }) => {
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();

  const upgradePack = () => {
    var roomCategory;
    var rooms_data = [];
    var roomNumber;
    var roomCode;
    rooms.map((guest, i) => {
      var adultCount = 0;
      var childCount = 0;
      var infantCount = 0;
      guest.guests.map((guestData) => {
        if (guestData.type == "ADULT") {
          adultCount = adultCount + 1
          // this.setState({adult:this.state.adult+1})
        } else if (guestData.type == "CHILD") {
          childCount = childCount + 1
        } else {
          infantCount = infantCount + 1
        }
      })
      roomCategory = guest.category;
      roomNumber = guest.number;
      roomCode = guest.code;
      rooms_data.push({
        "adults": adultCount,
        "infants": infantCount,
        "children": childCount,
        "roomCatogory": roomCategory,
        "roomNumber": roomNumber,
        "roomCode": roomCode,
        "id": i
      })
    })
    const upgradeBooking = {
      itinaryID: { id: 'bedb2cfb-25d6-49b2-a8ab-38ef2940e0b0' },
      roomNumber: number,
      rooms: rooms_data,
      itinerary: itinerary.itinerary
    }
    setUser(
      Object.assign({}, user, {
        upgradeBooking: upgradeBooking
      })
    );
    const itineraryData = {
      rechedule: false
    }
    sessionStorage.setItem("itinerary", JSON.stringify(itineraryData))
    // sessionStorage.setItem('upgradeBooking', JSON.stringify(upgradeBooking));
    history.push({
      pathname: '/admin/upgrade-booking-details',
    })
  }
  return (
    <Button
      className="bg-j-orange text-white w-full pt-1 mt-6"
      style={{ height: "37px" }}
      onClick={() => upgradePack()}
    // disabled={true}
    >
      UPGRADE
    </Button>
  )
}
