import React, { Component, useRef, useState, useEffect } from "react";
import Input from "../../components/Form/LoginInput";
import DisabledInput from "../../components/Form/DisabledInput";
import { getToken } from "../../utils/common";
import States from "../../public/state-city.json";
import CountryStates from "../../public/country-states.json";

class AgentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileObj: {},
      profileData: null,
      loading: true
    };
  }

  componentWillMount() {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/profile`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          let data = await response.json();
          console.log(data)
          console.log(data.agency.name)
          this.setState({ profileObj: data });
          this.setState({ profileData: data });
          this.setState({ loading: false })
        } else {
          let data = await response.json();
          alert(data.message);
        }
      })
      .catch((err) => err);
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <ProfileComp
            data={this.state.profileObj}
            onChange={this.onChange}
            fullData={this.state.profileData}
          />
        }
      </>
    );
  }
}

const updateProfileAPI = (payload, cityState) => {
  console.log(cityState)
  let updateProfilePayload = {};
  updateProfilePayload = payload
  updateProfilePayload["travel_agency_attributes"] = {
    "name": payload["name"],
    "address_line_1": payload["address_line_1"],
    "address_line_2": payload["address_line_2"],
    "area": payload["area"],
    "city": payload["city"],
    "district": payload["district"],
    "state": payload["state"],
    "country": payload["country"],
    "pincode": payload["pincode"],
    "gstin": payload["gstin"] || null,
    "owner": payload["first_name"],
    "phone_number": payload["phone_number"],
    "email": payload["email"],
    "portal_agent_type": payload["portal_agent_type"],
    "bank_accounts_attributes": [{ "bank_name": payload["bank_name"], "account_number": payload["account_number"], "beneficiary_name": payload["beneficiary_name"], "ifsc_code": payload["ifsc_code"], "branch_name": payload["branch_name"] }]
  }
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/update_profile`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(updateProfilePayload),
  })
    .then(async (response) => {
      if (response.status === 200) {
        var newData = {
          "gstin": payload["gstin"] || null,
        }
        let data = await response.json();
        let localStorageData = JSON.parse(localStorage.getItem("UserProfile"));
        let newlocalData = {
          "access_types": localStorageData.access_types,
          "address": payload["address_line_1"] + ',' + payload["address_line_2"] + ',' + payload["area"] + ',' + payload["city"] + ',' + payload["district"] + ',' + payload["state"] + ',' + payload["pincode"],
          "email": localStorageData.email,
          "gstin": payload["gstin"] || null,
          "first_name": localStorageData.first_name,
          "last_name": localStorageData.last_name,
          "pan": localStorageData.pan,
          "phone_number": localStorageData.phone_number
        }
        localStorage.setItem("UserProfile", JSON.stringify(newlocalData));
        console.log(data);
        alert(data.message);
      } else {
        let data = await response.json();
        alert(data.message);
      }
    })
    .catch((err) => err);
};

const ProfileComp = ({ data, fullData }) => {

  let form = useRef(null);
  let profileDataVar = null;
  profileDataVar = data;
  const [country, setCountry] = useState(data.agency.country);
  const [countryStatesArray, setCountryStatesArray] = useState();
  const [countryState, setCountryState] = useState(data.agency.state);
  const [commission, setCommission] = useState(data.commission_type);
  const [stateCityArray, setstateCityArray] = useState([])
  const [cityState, setCityState] = useState(data.agency.city)
  console.log(data, fullData, countryState, "nik-log init")
  useEffect(() => {
    cityFilterFun();
  }, [])

  const cityFilterFun = () => {
    let x = countryState;
    console.log(x)
    var result = States.states.filter(function (states) {
      return states.state === x;
    })

    if (result.length != 0) {
      setstateCityArray(States.states.filter((states) => states.state === x)[0].cities);
    } else {
      // setCountryState('Kerala')
      setstateCityArray(States.states.filter((states) => states.state === 'Kerala')[0].cities)
    }
    if (data.is_international && country) {
      setCountryStatesArray(CountryStates.countries.filter((countries) => countries.country === country)[0].states);
    }
    console.log(result, "result nik-log")

  }

  const updateProfile = (event) => {
    // console.log(cityState)
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    console.log('nik-log', payload)
    updateProfileAPI(payload, cityState);
  };

  if (Object.keys(profileDataVar).length !== 0) {
    // setCountryState(profileDataVar.agency["state"])
    var storageProfile = JSON.parse(localStorage.getItem('UserProfile'));
    console.log(countryState, "country state ")
    return (
      <form
        ref={form}
        onSubmit={updateProfile}
        className="w-full text-j-magenta p-10"
      >
        <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight mb-6">
          My Profile
        </h3>
        <div tiny className=" border border-j-gray-lighter rounded-lg bg-j-orange p-10" >
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-2 ">
            <div className="px-2 flex flex-col items-center justify-center">
              <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold leading-tight">
                {storageProfile.name}
              </h3>
              <div className="mt-12 w-full px-2 sm:px-6">
                <div className="grid grid-cols-1">
                  <label className="text-white">Code</label>
                  <DisabledInput
                    id="code"
                    name="code"
                    placeholder="Code"
                    type="text"
                    value={profileDataVar.code}
                  />
                </div>
                <div className="grid grid-cols-2 gap-2 mt-6">
                  <div>
                    <label className="text-white">First Name</label>
                    <DisabledInput
                      id="first_name"
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      value={profileDataVar.first_name}
                    />
                  </div>
                  <div>
                    <label className="text-white">Last Name</label>
                    <DisabledInput
                      id="last_name"
                      name="last_name"
                      placeholder="Last Name"
                      type="text"
                      value={profileDataVar.last_name}
                    />
                  </div>
                  <div>
                    <label className="text-white">Email</label>
                    <DisabledInput
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="text"
                      value={profileDataVar.email}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <label className="text-white">Travel Agency Name</label>
                  {profileDataVar.agency["name"] !== "" && profileDataVar.agency["name"] !== null ? <DisabledInput
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Travel Agency Name"
                    className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                    value={profileDataVar.agency["name"]}
                  /> :
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Travel Agency Name"
                      className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75 uppercase"
                    //  value={profileDataVar.agency["gstin"] || ""}
                    />}
                </div>
                <div className="grid grid-cols-2 gap-4 mt-6">
                  <div>
                    <label className="text-white">GSTIN</label>
                    <div className="flex flex-col mt-1">
                      {/* {data.is_international? */}
                      <DisabledInput
                        id="gstin"
                        name="gstin"
                        type="text"
                        placeholder="GSTIN"
                        className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                        value={profileDataVar.agency["gstin"] || ""}
                      />
                      {/* :
                          <input
                             id="gstin"
                             name="gstin"
                             type="text"
                             placeholder="GSTIN"
                             className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75 uppercase"
                             defaultValue={profileDataVar.agency["gstin"] || ""}
                            //  value={profileDataVar.agency["gstin"] || ""}
                            />} */}
                    </div>
                  </div>
                  <div>
                    <label className="text-white">PAN</label>
                    <DisabledInput
                      id="pan"
                      name="pan"
                      placeholder="PAN"
                      type="text"
                      value={profileDataVar.pan}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 mt-6">
                  <div>
                    <label className="text-white">Phone Number</label>
                    <DisabledInput
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      type="text"
                      value={profileDataVar.agency["phone_number"] || "Phone Number"}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 mt-6">
                  <label className="text-white">Commission Model</label>
                  <select
                    name={`commission_type`}
                    id="commission_type"
                    placeholder="commission_type"
                    className="h-10 px-2 w-full text-black rounded mt-3 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                    onChange={(event) => {
                      let x = event.target.value;
                      console.log('event', x);
                      setCommission(x);
                    }}
                    defaultValue={commission || "Commission Type"}
                  >
                    {/* <option selected value={commission} disabled>{commission}</option> */}

                    <option value="p2p">p2p</option>
                    <option value="p2a">p2a</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-12 w-full px-2 sm:px-6">
              <label className="text-white">Address Line - 1</label>
              <Input
                id="address_line_1"
                name="address_line_1"
                // placeholder="Address Line - 1"
                type="text"
                defaultValue={profileDataVar.agency["address_line_1"] || "Address Line 1"}
              />
              <div className="grid grid-cols-2 gap-4 mt-6">
                <div>
                  <label className="text-white">Address Line - 2</label>
                  <Input
                    id="address_line_2"
                    name="address_line_2"
                    // placeholder="Address Line - 2"
                    type="text"
                    defaultValue={profileDataVar.agency["address_line_2"] || "Address Line 2"}

                  />
                </div>
                {!data.is_international && <div>
                  <label className="text-white">Area</label>
                  <Input id="area" name="area" type="text" defaultValue={profileDataVar.agency["area"] || "Area"} />
                </div>}
              </div>
              {!data.is_international ? <div className="grid grid-cols-2 gap-4 mt-6">
                <div>
                  <label className="text-white">State</label>
                  {/* <Input id="state" name="state" type="text" defaultValue={profileDataVar.agency["state"] || "State"} /> */}
                  <select
                    name={`state`}
                    id="state"
                    placeholder="State"
                    className="h-10 px-2 w-full text-black rounded mt-3 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                    onChange={(event) => {
                      let x = event.target.value;
                      setCountryState(x);
                      setstateCityArray(States.states.filter((states) => states.state === x)[0].cities);
                    }}
                    defaultValue={countryState}
                  >
                    <option selected value={countryState} disabled>{countryState}</option>
                    {States.states.map((states) => {
                      return <option value={states.state}>{states.state}</option>
                    })}
                  </select>
                </div>
                <div>
                  <label className="text-white">District</label>
                  <Input
                    id="district"
                    name="district"
                    // placeholder="District"
                    type="text"
                    defaultValue={profileDataVar.agency["district"] || "District"}

                  />
                </div>
              </div> :
                <div className="grid grid-cols-2 gap-4 mt-6">
                  <div>
                    <label className="text-white">Country</label>
                    <select
                      name="country"
                      withIcon
                      lessSpace
                      id="country"
                      placeholder="Country"
                      className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                      onChange={(event) => {
                        let x = event.target.value;
                        setCountry(x);
                        setCountryStatesArray(CountryStates.countries.filter((countries) => countries.country === x)[0].states);
                      }}
                    >
                      <option selected value={country} disabled>{country}</option>
                      {CountryStates.countries.map((countries) => {
                        return <option value={countries.country}>{countries.country}</option>
                      })}
                    </select>
                  </div>
                  <div>
                    <label className="text-white">State</label>
                    {/* <Input id="state" name="state" type="text" defaultValue={profileDataVar.agency["state"] || "State"} /> */}
                    <select
                      name={`state`}
                      id="state"
                      placeholder="State"
                      className="h-10 px-2 w-full text-black rounded mt-3 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                      onChange={(event) => {
                        let x = event.target.value;
                        setCountryState(x);
                      }}
                      defaultValue={countryState}
                    >
                      <option selected value={countryState} disabled>{countryState}</option>
                      {countryStatesArray && countryStatesArray.map((states) => {
                        return <option value={states}>{states}</option>
                      })}
                    </select>
                  </div>

                </div>

              }

              <div className="grid grid-cols-2 gap-4 mt-6 mt-6">
                {!data.is_international && <div>
                  <label className="text-white">City</label>
                  {/* <Input id="city" name="city" type="text" defaultValue={profileDataVar.agency["city"] || "City"} /> */}
                  <select
                    name={`city`}
                    id="city"
                    className="h-10 px-2 mt-3 w-full text-black rounded focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75"
                    value={cityState}
                    onChange={(event) => {
                      setCityState(event.target.value)
                    }}
                    defaultValue={cityState}
                  >
                    <option selected value={cityState} disabled>{cityState}</option>
                    {
                      stateCityArray.map((cities) => {
                        return <option value={cities}>{cities}</option>
                      })
                    }
                  </select>
                </div>}
                <div>
                  <label className="text-white">PINCODE</label>
                  <Input
                    id="pincode"
                    name="pincode"
                    placeholder="PINCODE"
                    type="number"
                    defaultValue={profileDataVar.agency["pincode"] || "Pincode"}

                  />
                </div>
              </div>
              <div className="mt-6">
                <label className="text-white">Agent type</label>
                <select
                  name="portal_agent_type"
                  id="portal_agent_type"
                  style={{
                    width: "100%",
                    padding: "0.625rem",
                    borderRadius: "0.625rem",
                    boxShadow:
                      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                    marginTop: "10px",
                  }}
                  defaultValue={profileDataVar.agency["portal_agent_type"] || "Portal Agent Type"}

                >
                  <option defaultValue="mice">
                    Mice
                  </option>
                  <option defaultValue="b2b">B2B</option>
                  <option defaultValue="leisure">Leisure</option>
                  <option defaultValue="consolidator">Consolidator</option>
                  <option defaultValue="weddings">Weddings</option>
                </select>
              </div>
              {(profileDataVar.agency["bank_accounts"].length > 0 && !data.is_international) ? (<div><div className="mt-6">
                <label className="text-white">Bank Name</label>
                <Input
                  id="bank_name"
                  name="bank_name"
                  type="text"
                  defaultValue={profileDataVar.agency["bank_accounts"][0] ? profileDataVar.agency["bank_accounts"][0].bank_name : null}
                  placeholder="Bank Name"
                  required="false"
                />
              </div>
                <div className="mt-6">
                  <label className="text-white">Account Number</label>
                  <Input
                    id="account_number"
                    name="account_number"
                    type="number"
                    defaultValue={profileDataVar.agency["bank_accounts"][0] ? profileDataVar.agency["bank_accounts"][0].account_number : null}
                    placeholder="Account Number"
                    required="false"
                  />
                </div>
                <div className="mt-6">
                  <label className="text-white">Beneficiary Name</label>
                  <Input
                    id="beneficiary_name"
                    name="beneficiary_name"
                    type="text"
                    defaultValue={profileDataVar.agency["bank_accounts"][0] ? profileDataVar.agency["bank_accounts"][0].beneficiary_name : null}
                    placeholder="Beneficiary Name"
                    required="false"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-6">
                  <div>
                    <label className="text-white">IFSC</label>
                    <Input
                      id="ifsc_code"
                      name="ifsc_code"
                      type="text"
                      defaultValue={profileDataVar.agency["bank_accounts"][0] ? profileDataVar.agency["bank_accounts"][0].ifsc_code : null}
                      placeholder="IFSC"
                      required="false"
                    />
                  </div>
                  <div>
                    <label className="text-white">Branch Name</label>
                    <Input
                      id="branch_name"
                      name="branch_name"
                      placeholder="Branch Name"
                      type="text"
                      defaultValue={profileDataVar.agency["bank_accounts"][0] ? profileDataVar.agency["bank_accounts"][0].branch_name : null}
                      required="false"
                    />
                  </div>
                </div></div>) : (<div></div>)}
              <div className="px-2 sm:px-6 mt-6">
                <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-gray-200 rounded text-white px-8 py-3 text-sm mt-6">
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  } else {
    return (<></>)
  }
};

export default AgentProfile;
