import React, { useRef, useState } from "react";
import { getToken } from "../../utils/common";
import Input from "../Form/LoginInput";

const checkPassword = (str) => {
  // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
  // at least six characters
  let re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
  return re.test(str);
}


const ChangePassword = (payload) => {
  if(payload["new_password"] !== payload["confirm_new_password"] && !checkPassword(payload["new_password"])) {
    alert("Passwords Don't Match && Please Enter a Valid Password - Min. 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character")
  } else if (payload["new_password"] !== payload["confirm_new_password"]) {
    alert("Password Don't Match");
  } else if (!checkPassword(payload["new_password"])) {
    alert("Please Enter a Valid Password - Min. 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character ");
  } else {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/change_password`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status === 200) {
          let data = await response.json();
          alert(data.message);
          window.location.reload();
        } else {
          let data = await response.json();
          alert(data.message);
          window.location.reload();
        }
      })
      .catch((err) => err);
  }
};

export default function ChangePasswordCard() {
  let form = useRef(null);
  const [typeOfPassword, changePasswordType] = useState("password");
  const handleChangeSubmit = (event) => {
    event.preventDefault();
    const form_data = new FormData(form.current);
    let payload = {};
    form_data.forEach(function (value, key) {
      payload[key] = value;
    });
    ChangePassword(payload);
  };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Change Password</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-4">
          <form
            ref={form}
            onSubmit={handleChangeSubmit}
            className="w-full text-j-magenta p-20"
          >
            <div>
              <Input
                id="current_password"
                name="current_password"
                placeholder="Current Password"
                type="password"
              />
              <div style={{height: "36px"}}>
                <Input
                  id="new_password"
                  name="new_password"
                  placeholder="New Password"
                  type={typeOfPassword}
              />
              {
                (typeOfPassword === "password") ? <i style={{position: "relative", top: "-24px", right: "-96%",}} class="fa fa-eye" onClick={()=>{
                  changePasswordType("text");
                }}></i> : <i style={{position: "relative", top: "-24px", right: "-96%",}} class="fa fa-eye-slash" onClick={()=>{
                  changePasswordType("password");
                }}></i>
              }
              </div>
              <Input
                id="confirm_new_password"
                name="confirm_new_password"
                placeholder="Confirm New Password"
                type="password"
              />
            </div>
            <div className="px-2 sm:px-6">
              <button className="focus:outline-none w-full bg-magenta transition duration-150 ease-in-out hover:bg-gray-200 rounded text-white px-8 py-3 text-sm mt-6">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
