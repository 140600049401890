import React, { Component } from "react";

class DisabledCopy extends Component {
    state = {
    }

    render() {
        return(
            <>
                <div className="flex flex-col mt-1">
                    <input
                        required
                        id= {this.props.id} 
                        name= {this.props.id} 
                        className="h-10 px-2 w-full text-black rounded mt-2 focus:outline-none shadow placeholder-gray-500 placeholder-opacity-75" 
                        placeholder={this.props.placeholder}  
                        type={this.props.type} 
                        value={this.props.value}
                        onpaste="return false;"
                        ondrop="return false;"
                        autocomplete="off"
                        onChange={this.props.onChangeValue}/>
                </div>
            </>
        )
    }
}

export default DisabledCopy;