import React, { useEffect, useState, useContext } from "react"
import cx from "classnames";
import Button from "../Button/Button";
// import { getToken } from "../../utils/common";
import { getToken } from "../../utils/common";
import UserContext from "../../store/UserContext";
import Checkbox from "../Form/CheckBox"
import styles from "./safetyProtection.module.css";
import SafetyProtectionImg from "../../assets/images/safety_protection.png";
import CloseImg from "../../assets/images/closeImg.png";

export default function SafetyProtection({nextStep}) {
    const [user, setUser] = useContext(UserContext);
    const booking = user.booking || {}
    const [loading, setLoading] = useState(true)
    const [planResponse, setPlanResponse] = useState();
    const [checkBoxSelected, setCheckBoxSelected] = useState(false)

    useEffect(()=> {
        getSafetyProtectionAPI()
    },[])

    
    const getSafetyProtectionAPI = () => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${user.booking.id}/get_protection_plan`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            }, 
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Something went wrong");
            }
        }).then((response) => {
            setPlanResponse(response)
            setLoading(false)
            console.log('response', response)
        })
    }

    const handleContinue = () => {
        var requestData = {
            plan_id: planResponse.plan_id,
            plan_enabled: checkBoxSelected ? "true" : "false"
        }
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/bookings/${user.booking.id}/apply_protection_plan`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            }, 
            body: JSON.stringify(requestData)
        }).then((res) => {
            console.log(res)
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Something went wrong");
            }
        }).then((response) => {
            console.log(response);
            sessionStorage.setItem('booking', JSON.stringify(response.booking))
            sessionStorage.setItem('safetyProtection', JSON.stringify({
                'isApplied': checkBoxSelected,
                'amount': planResponse.plan_amout
            }))
            sessionStorage.setItem('partialPayment', response.partial_payable_amount);
            sessionStorage.setItem(
                "tcs_taxes",
                JSON.stringify({
                    tcs: response.tcs && response.tcs !== null ? response.tcs : 0,
                    tcs_with_pan: response.tcs_with_pan && response.tcs_with_pan !== null ? response.tcs_with_pan : 0
                })
            );
            setUser(Object.assign({}, user, {booking: booking}))
            nextStep()
        })
    }


    return(
        <div className="pl-6 pr-6">
            <HeaderSection />
            {!loading && planResponse.plan_present && <SafetyProtectionSection 
                planResponse={planResponse} 
                checkBoxSelected = {checkBoxSelected}
                setCheckBoxSelected={setCheckBoxSelected}
            /> }
            <div className="w-full flex-end mt-6 mb-6 flex">
                <button 
                    className="w-full h-10 pl-16 pr-16 text-white rounded transition duration-300 bg-j-orange ease-in-out"
                    onClick={() => handleContinue()}
                >Continue
                </button>
            </div>
        </div>
    )
}

const HeaderSection = () => {
    return(
        <div>
            <h3 className={styles.heading}>Travel with ease onboard the Empress!</h3>
            <div className={styles.headerImage} style={{backgroundImage:`url(${SafetyProtectionImg})` }}>
                <div className="p-10 flex items-end h-full">
                    <div>
                            {/* <p className={styles.heading1}>Enjoy</p>
                            <p className={styles.heading2}>Ultimate Flexibility </p> */}
                    </div>
                </div>
            </div>
            <h3 className={styles.content}>
            Don’t let the fear of last-minute cancellation cost stop you from booking the sea cation of your dreams! With Cordelia Cruises, you can cancel your booking up to 48 hours before sailing. 
            </h3>
        </div>
    )
}

const SafetyProtectionSection = ({planResponse, checkBoxSelected, setCheckBoxSelected}) => {
    return(
        <div>
              <div className={styles.mainContPrice}>
                  <div className="grid grid-cols-2">
                      <div className={cx(styles.closeContainer ,"flex ml-20 pr-10")}>
                            <div className="pr-10">
                                <img src={CloseImg} alt="Wave" />
                            </div>
                            <h3 className={styles.title}>{planResponse.title}</h3>
                      </div>
                      <div className="pl-6">
                            <h3 className={styles.heading}>Only at extra Rs. {Math.round(planResponse.plan_amout)}/<span className={styles.cabinStyle}>Cabin</span></h3>
                            <p className={styles.subHead}>Avail the freedom of flexibility</p>
                            <Checkbox
                                name="authorize"
                                value={checkBoxSelected}
                                onChange={() => {
                                    setCheckBoxSelected(!checkBoxSelected)
                                }}
                                classes={[
                                "mb-2",
                                "pt-4"
                                ]}
                            >
                                <span className="text-lg text-j-magenta">
                                    Yes I want to Secure my trip
                                </span>
                          </Checkbox>
                      </div>
                  </div>
              </div>

        </div>
    )
}