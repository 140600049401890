import Button from "../../Button/Button";
import cx from "classnames";


export const BookingFormView = ({
  children,
  title,
  buttonText = "Proceed to select room category",
  buttonClassName = "bg-j-red-light",
  disabled,
  id,
  lessSpace,
  loading,
  specialFare,
  setSpecialFare,
  confirm,
  ...rest
}) => { 
  
  var booking = JSON.parse(localStorage.getItem('store'));
  let fare = booking?.booking?.itinerary?.special_pricing_available
  
  return(
  <div className={lessSpace ? "my-2" : "my-2"} id={id} >
    <div className="flex justify-between pb-7">
      <h2 className="text-xl font-medium">{title}</h2>
      {title == 'Find your perfect room' ? 
    fare ?   <div className="flex items-center">
        {/* <div
          className="cursor-pointer mr-5"
          onClick={(e) => setSpecialFare(false)}
        >
          <label>
            <input
              type="radio"
              name="fare"
              className="cursor-pointer"
              checked={!specialFare}
            />
            <span className="text-sm pl-2 cursor-pointer">Regular Fare </span>
          </label>
        </div>
        <div
          className="cursor-pointer mr-5"
          onClick={(e) => setSpecialFare(true)}
        >
          <label>
            <input
              type="radio"
              name="fare"
              className="cursor-pointer"
              checked={specialFare}
            />
            <span className="text-sm pl-2 cursor-pointer">Special Fare </span>
          </label>
        </div>
        <div 
        className="py-1.5 px-4 bg-j-orange-brand text-j-white rounded-md cursor-pointer"
        onClick={confirm}
        >
          Confirm
        </div> */}
      </div> : null 
      : null}
    </div>
    {children}
    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4">
      <div />
      <Button
        disabled={disabled}
        loading={loading}
        className={cx(
          "w-full mt-9 text-white mb-7 pt-1",
          disabled ? "bg-j-gray-lighter" : buttonClassName
        )}
        {...rest}
      // style={{textAlign:'center', color: 'white' , paddingTop: '5x' , paddingBottom: '5px'}}
      >
        {buttonText}
      </Button>
    </div>

  </div>
);
}

export default BookingFormView;
