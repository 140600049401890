import React, { Component } from "react";
import CardStats from "../../components/Cards/CardStats";
import CardSettings from "../../components/Cards/CardSettings";
import CardProfile from "../../components/Cards/CardProfile";
import ContactDetails from "../../components/Cards/ContactDetails";
import ReactHtmlParser from "react-html-parser";

import Telegram from "../../assets/images/telegram-back-i.png";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userProf: JSON.parse(localStorage.getItem("UserProfile")),
    }
  }

  render() {
      if(this.state.userProf.access_types.includes('portal')) {
          return(
              <>
               <div className="relative bg-magenta pt-16 pb-16 ">
                        <div className="px-4 md:px-10 mx-auto w-full">
                            <div className="text-center text-white">
                            <div className="flex flex-wrap">
                                {/* <div className="w-1/3 lg:w-1/3 xl:w-1/3 px-4">
                                    <CardStats
                                        statSubtitle="Whatsapp"
                                        statTitle="97654321"
                                        statArrow="up"
                                        // statPercent="3.48"
                                        // statPercentColor="text-green-500"
                                        statDescripiron="Contact Now"
                                        statIconName="far fa-chart-bar"
                                        statIconColor="bg-red-500"
                                    />
                                </div> */}
                                <div className="w-1/2 lg:w-1/2 xl:w-1/2 px-4">
                                    <CardStats
                                        statSubtitle="Email"
                                        statTitle="travelpartners@cordeliacruises.com"
                                        statArrow="up"
                                        // statPercent="3.48"
                                        // statPercentColor="text-green-500"
                                        statDescripiron="Contact Now"
                                        statIconName="far fa-chart-bar"
                                        statIconColor="bg-red-500"
                                    />
                                </div>
                                <div className="w-1/2 lg:w-1/2 xl:w-1/2 px-4">
                                    <CardStats
                                        statSubtitle="Phone Number"
                                        statTitle="02268811110"
                                        statArrow="up"
                                        // statPercent="3.48"
                                        // statPercentColor="text-green-500"
                                        statDescripiron="Contact Now"
                                        statIconName="far fa-chart-bar"
                                        statIconColor="bg-red-500"
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap">
                        <BottomView />
                    </div>
              </>
          )
      } else {
        return (
            <div>
              You're Not Authorized to View this Page.
            </div>
          )
      }
  }
}

export default Contact;

const BottomView = () => {
    const [openTab, setOpenTab] = React.useState(1);
    let mapFrame = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.3088843440637!2d72.81649601490004!3d19.00610598712806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf71544f260b%3A0xe8d55c642124e199!2sWaterways%20Leisure%20Tourism%20Private%20Limited!5e0!3m2!1sen!2sin!4v1614028029026!5m2!1sen!2sin" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe>`;
    return(
        <>
            {/* <div className="w-full lg:w-6/12 px-4 mt-4">
                <CardProfile />
            </div>
            <div className="w-full lg:w-6/12 px-4 mt-4">
                <CardSettings />
            </div> */}
            <div className="w-full lg:w-6/12 px-4 mt-4">
            <ul
                className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                role="tablist"
            >
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                    className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                        ? "text-white bg-j-red-light"
                        : "text-j-magenta bg-white")
                    }
                    onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                >
                    Payment Details
                </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                    className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                        ? "text-white bg-j-red-light"
                        : "text-j-magenta bg-white")
                    }
                    onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link2"
                    role="tablist"
                >
                    Write to Us
                </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                    className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                        ? "text-white bg-j-red-light"
                        : "text-j-magenta bg-white")
                    }
                    onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                    }}
                    data-toggle="tab"
                    href="#link3"
                    role="tablist"
                >
                    Contact Details
                </a>
                </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                    <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                        <CardProfile />
                    </div>
                    <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                        <CardSettings />
                    </div>
                    <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                        <ContactDetails />
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 mt-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
                  {ReactHtmlParser(mapFrame)}
                  <div className="m-4">
                    <p className="text-sm underline">M/s. Waterways Leisure Tourism Pvt. Ltd.</p>
                    <p className="text-sm">1st Floor, Continental Building,</p>
                    <p className="text-sm">135, Dr. Annie Besant Road,</p>
                    <p className="text-sm">Next to Doordarshan Building,</p>
                    <p className="text-sm">Worli, Mumbai 400018</p>
                  </div>
                  <div className="bg-magenta">
                    <h5 className="leading-4 mb-4 divide-y divide-yellow-500 text-center mt-4 text-white">Connect with us and stay in the loop</h5>
                        <div className="flex grid grid-cols-6 text-center gap-4 text-white justify-center mb-4">
                            <a
                                className="cursor-pointer text-3xl"
                                href="https://www.linkedin.com/company/cordelia-cruises/mycompany/?viewAsMember=true" target="_blank"
                                
                            >
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="https://www.facebook.com/cordeliacruises/" target="_blank" className="cursor-pointer text-3xl">
                                <i className="fab fa-facebook-square"></i>
                            </a>
                            <a
                                href="https://www.instagram.com/cordeliacruises/?hl=enn"
                                target="_blank"
                                className="cursor-pointer text-3xl"
                            >
                                <i className="fab fa-instagram-square"></i>
                            </a>
                            <a href="https://twitter.com/CordeliaCruises" target="_blank" className="cursor-pointer text-3xl">
                                <i className="fab fa-twitter-square"></i>
                            </a>
                            <a href="https://blog.cordeliacruises.com/" target="_blank" className="cursor-pointer text-3xl">
                                <i className="fab fa-blogger"></i>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCIGZzyqWsbCH1-VNFsXrY9g"
                                target="_blank"
                                className="cursor-pointer text-3xl"
                                >
                                <i className="fab fa-youtube-square"></i>
                                </a>
                                <a
                                href="https://t.me/cordeliacruises"
                                target="_blank"
                                className="cursor-pointer text-3xl"
                                >
                                <i className="fab fa-telegram rounded" style={{backgroundImage: `url(${Telegram})`,backgroundSize:'contain',transform:`scale(0.9)`}}></i>
                                </a>    
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}