import React, { Component, useState } from "react";
import Cards from "../../components/Booking/Cards/cards";
import Layout from "../../components/Layout/Layout";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import { getToken } from "../../utils/common";
// import RangeCalendar from "../../components/RangeCalendar/RangeCalendar"
// import Select from "../../components/Select/Select"
// import Overlay from "../../components/Overlay/Overlay"
// import View from "../../components/View/View"
// import Button from "../../components/Button/Button"

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProf: JSON.parse(localStorage.getItem("UserProfile")),
      samplesData: null,
      loading: true,
      resetFlag: true,
      backupData: null,
      backupWholeData: null,
      filteredItineraries: [],
      flagDestination: false,
      flagDeparture: false,
      flagMonth: false,
      destVal: null,
      origVal: null,
      origMonth: null,
      dest: null,
      orig: null,
      filteredItineraries: [],
    };
  }
  handleReset() {
    let backUp = this.state.backupData;
    this.setState({
      samplesData: backUp,
      flagDestination: false,
      flagDeparture: false,
      flagMonth: false,
      destVal: null,
      origVal: null,
      origMonth: null,
    });
    const resetTheFlag = () => {
      this.setState({ resetFlag: false });
    }
    setTimeout(resetTheFlag, 250);
  }
  handleSearch() {
    let backUp = this.state.backupData;
    let tempArrBack = [];
    tempArrBack = backUp;
    this.setState({ samplesData: backUp });
    if (this.state.flagDestination) {
      let tempArr = [];
      tempArrBack.forEach((ele) => {
        let obj = {};
        if (
          ele.destination.toLowerCase() === this.state.destVal.toLowerCase()
        ) {
          obj = ele;
          tempArr.push(obj);
        }
      });
      tempArrBack = [];
      tempArrBack = tempArr;
    }
    if (this.state.flagDeparture) {
      let tempArr = [];
      tempArrBack.forEach((ele) => {
        let obj = {};
        if (ele.Departure.toLowerCase() === this.state.origVal.toLowerCase()) {
          obj = ele;
          tempArr.push(obj);
        }
      });
      tempArrBack = [];
      tempArrBack = tempArr;
    }
    if (this.state.flagMonth) {
      let tempArr = [];
      tempArrBack.forEach((ele) => {
        let obj = {};
        let x = (new Date(ele.departure_date)).getMonth() + 1;
        if (x === (Number(this.state.origMonth))) {
          obj = ele;
          tempArr.push(obj);
        }
      });
      tempArrBack = [];
      tempArrBack = tempArr;
    }
    this.setState({ samplesData: tempArrBack });
    this.setState({ resetFlag: false });
  }
  setFilterDestination(dest) {
    this.setState({ resetFlag: true });
    this.setState({ destVal: dest, flagDestination: true });
  }
  setFilteredItineraries() {
    this.setState({ filteredItineraries: [] });
  }
  setFilterOrigin(orig) {
    this.setState({ resetFlag: true });
    this.setState({ origVal: orig, flagDeparture: true });
  }
  setFilterMonth(orig) {
    this.setState({ resetFlag: true });
    this.setState({ origMonth: orig, flagMonth: true });
  }
  uniques = (value, index, self) => self.indexOf(value) === index;
  componentDidMount() {
    sessionStorage.setItem('step', 1);
    sessionStorage.setItem('hold-step', 1);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/agent_portal/routes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let dataArr = response.data.routes;
        this.setState({ backupWholeData: dataArr });
        let tempArr = [];
        let destinations = [];
        let origins = [];
        let durations = [];
        destinations = Object.values(dataArr)
          .map(
            (route) =>
              route.ports &&
              route.ports.length &&
              route.ports.slice(1, route.ports.length).map((p) => p.port.name)
          )
          .flat(1)
          .filter(this.uniques);
        destinations.sort();
        origins = Object.values(dataArr)
          .map(
            (route) =>
              route.ports && route.ports.length && route.ports[0].port.name
          )
          .filter(this.uniques);
        origins.sort();
        durations = [2, 3, 4, 5, 6, 7];
        durations.sort();
        this.setState({ dest: destinations });
        this.setState({ orig: origins });
        dataArr.forEach((ele) => {
          for (let i = 0; i < ele.itineraries.length; i++) {
            let obj = {};
            obj.itiId = ele.id;
            obj.id = ele.itineraries[i].id;
            obj.cruice_name = ele.itineraries[i].name;
            obj.Departure = ele.itineraries[i].ports[0].port.name;
            obj.destination =
              ele.itineraries[i].ports[
                ele.itineraries[i].ports.length - 1
              ].port.name;
            obj.departure_date = `${new Date(ele.itineraries[i].startTime)}`;
            obj.no_days = ele.itineraries[i].nightCount;
            obj.rem_obj = ele.itineraries[i];
            tempArr.push(obj);
            obj = {};
          }
        });
        sessionStorage.setItem("itinerary", JSON.stringify(dataArr));
        this.setState({ samplesData: tempArr });
        this.setState({ backupData: tempArr });
        this.setState({ loading: false });
        this.setState({ resetFlag: false });
      })
      .catch((err) => err);
  }

  render() {
    if(this.state.userProf.access_types.includes('portal')) {
      return (
        <Layout>
          <div className="flex flex-col h-screen">
            <main className="flex-grow overflow-y-auto">
              {this.state.loading && (
                <>
                  <h1 className="text-4xl">
                    <LoadingIcon className="py-20 text-j-magenta" />
                  </h1>
                </>
              )}
              {!this.state.loading && (
                <>
                  <div className="ml-10 mr-10 bg-magenta mt-5 rounded-lg">
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 text-center pt-5 pb-5 ml-4">
                      <div className="text-center">
                        <select
                          value={this.state.destVal || "Filter By Destination"}
                          onChange={(e) => {
                            this.setFilterDestination(e.target.value);
                            this.setFilteredItineraries([]);
                          }}
                          className="pr-2 pl-2 justify-between rounded-l-full rounded-r-full h-12 border border-j-gray select-none cursor-pointer w-full focus:outline-none"
                        >
                          <option value={null} selected disabled>
                            Filter By Destination
                          </option>
                          {this.state.dest.map((ele) => (
                            <option key={ele} value={ele}>
                              {ele}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <select
                          value={this.state.origVal || "Filter By Origin"}
                          onChange={(e) => {
                            this.setFilterOrigin(e.target.value);
                            this.setFilteredItineraries([]);
                          }}
                          className="pr-2 pl-2 rounded-l-full rounded-r-full h-12 border border-j-gray select-none cursor-pointer w-full focus:outline-none"
                        >
                          <option value={null} selected disabled>
                            Filter By Origin
                          </option>
                          {this.state.orig.map((ele) => (
                            <option key={ele} value={ele}>
                              {ele}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <select
                          value={this.state.origMonth || "Filter By Month"}
                          onChange={(e) => {
                            this.setFilterMonth(e.target.value);
                            this.setFilteredItineraries([]);
                          }}
                          className="pr-2 pl-2 rounded-l-full rounded-r-full h-12 border border-j-gray select-none cursor-pointer w-full focus:outline-none"
                        >
                          <option value={null} selected disabled>
                            Filter By Month
                          </option>
                          <option key={1} value={1}>January</option>
                          <option key={2} value={2}>February</option>
                          <option key={3} value={3}>March</option>
                          <option key={4} value={4}>April</option>
                          <option key={5} value={5}>May</option>
                          <option key={6} value={6}>June</option>
                          <option key={7} value={7}>July</option>
                          <option key={8} value={8}>August</option>
                          <option key={9} value={9}>September</option>
                          <option key={10} value={10}>October</option>
                          <option key={11} value={11}>November</option>
                          <option key={12} value={12}>December</option>
                        </select>
                      </div>
                      <div>
                        <button
                          className="bg-j-orange shadow-md text-sm text-white font-bold py-3 md:px-8 px-4 hover:bg-blue-400 rounded uppercase w-full"
                          onClick={() => {
                            this.handleSearch();
                          }}
                        >
                          Apply Filter(s)
                        </button>
                      </div>
                      <div className="mr-4">
                        <button
                          className="bg-white shadow-md text-sm text-j-orange font-bold py-3 md:px-8 px-4 hover:bg-blue-400 rounded uppercase w-full"
                          onClick={() => {
                            this.setState({ resetFlag: true });
                            this.handleReset();
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.samplesData.length === 0 ? (
                    <p className="text-center text-4xl text-gray-400 pt-10 pb-10">No Result Found</p>
                  ) : (
                    (this.state.resetFlag) ? (
                      <div style={{ textAlign: "center", fontSize: "4rem" }}>
                        <p className="loader-text mt-4">Click on Apply</p>
                        <LoadingIcon className="py-20 text-j-magenta" />
                      </div>
                    ) : (<Cards cardData={this.state.samplesData} monthHere={this.state.origMonth} />)
                  )}
                </>
              )}
            </main>
          </div>
        </Layout>
      );
    } else {
      return (
        <div>
          You're Not Authorized to View this Page.
        </div>
      )
    }
  }
}

export default Routes;
