import cx from "classnames";
import styles from "./Tile.module.css";

export const Tile = ({
  children,
  className,
  theme,
  shadow,
  hugeShadow,
  padded,
  tiny,
  ...rest
}) => (
  <div
    className={cx(
        "rounded-big flex flex-col",
        theme ? "text-white bg-magenta" : "text-black bg-white",
        shadow && "shadow-xl",
        hugeShadow && "shadow-xl",
        padded ? (tiny ? "p-6" : "px-6 py-10") : "",
        className
    )}
    {...rest}
    >
        {children}
  </div>
);

export const InnerTile = ({
  children,
  className,
  theme,
  tiny,
  noPadding,
  noPadx,
  as = "div",
  ...rest
}) => {
  const Component = as;
  return (
    <Component
        className={cx(
            noPadding ? "px-0 py-0" :
            noPadx ? "px-0 ":
            tiny ? "px-5" : "px-4",
            noPadding ? "px-0 py-0" :
            tiny ? "py-5" : "py-8",
            theme && styles[theme],
            className
        )}
      {...rest}
    >
      {children}
    </Component>
  );
};
export const TopTile = ({ children, theme, className, ...rest }) => (
    <InnerTile className={cx("rounded-t-big", className)} theme={theme} {...rest}>
        {children}
    </InnerTile>
);
// export const BottomTile = ({ children, className, theme, ...rest }) => (
//   <InnerTile className={cx("rounded-b-big", className)} theme={theme} {...rest}>
//     {children}
//   </InnerTile>
// );

Tile.Inner = InnerTile;
Tile.Top = TopTile;
// Tile.Bottom = BottomTile;

export default Tile;
