import React, { useEffect, useState, useContext } from "react";
import Layout from "../../../components/Layout/Layout";
// import Header from "../../../components/Header/Header";
// import FooterView from "../../../components/FooterAlt/Footer";
import cx from "classnames";
import View from "../../../components/View/View";
// import { useRouter } from "next/router";
import UserContext from "../../../store/UserContext";
import { getToken } from "../../../utils/common";

const Success = () => {
  const [user, setUser] = useContext(UserContext);
  const [bookingId, setBookingId] = useState();
  const [booking, setBooking] = useState();
  const [action, setAction] = useState();

  const fetchBookingDetails = (id) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/agent_portal/bookings/" + id + "/details",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setBooking(response.booking);
      });
  };


  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.id) {
      setBookingId(params.id);
      setAction(params.action);
      fetchBookingDetails(params.id);
    } else window.location = "/my-bookings";
  }, [setBookingId]);

  
  return (
    <Layout title="My Bookings">
      {/* <Header
        className="bg-j-magenta text-j-white"
        bgImages={[
          "/images/my-booking-web.webp",
          "/images/my-booking-mobile.webp",
        ]}
        isManangeBooking
      ></Header> */}
      <main className="bg-auto">
        <div className={cx("bg-j-magenta text-j-white p-6")} id="filters">
          <div className="flex justify-between cursor-pointer">
            <span className="self-center">My Bookings</span>
          </div>
        </div>
        <View>
          <div className="md:mx-10 py-8 h-full">
            <div className="text-center my-5 mb-10">
              <h3 className="text-4xl font-bold my-9">Successful!</h3>
              <h4 className="text-3xl text-j-available font-bold">
                {action === "add-guest"
                  ? "New guest has been added"
                  : action === "remove-cabin"
                  ? "Your cabin has been removed"
                  : action === "change-cabin"
                  ? "Your cabin has been changed"
                  : ""}
              </h4>
              <p className="text-xl mb-5 text-j-gray">
                Booking ID:{" "}
                <span className="text-j-magenta">
                  {booking ? booking.number : ""}
                </span>
              </p>
              {/* <p className="text-lg text-j-gray mb-10">
                Updated E-Ticket has been sent to your registered mobile nubmer
                and Email ID
              </p> */}
              <div className="grid grid-cols-2 gap-4 md:w-4/12 mx-auto">
                <button
                  className="bg-j-white text-j-orange border border-j-orange p-2 px-8 rounded mb-2 uppercase"
                  onClick={() => (window.location = "/admin/dashboard")}
                >
                  MY BOOKINGS
                </button>
                <button
                  className="bg-j-orange text-white p-2 px-8 rounded mb-2 uppercase"
                  onClick={() => window.open(booking.evoucher_url, "_blank")}
                >
                  DOWNLOAD TICKET
                </button>
              </div>
            </div>
          </div>
        </View>
      </main>
      {/* <footer>
        <FooterView />
      </footer> */}
    </Layout>
  );
};

export default Success;
