import React, { Component, useContext, useState } from "react";
import Layout from "../../components/Layout/Layout";
import GuestSelectionView from "../../components/Booking/GuestSelectionView";
import UserContext from "../../store/UserContext";
import HoldBookingRoomSelectionView from "../../components/Booking/HoldBookingRoomSelectionView";
import PaymentSummaryView from "../../components/Booking/PaymentSummaryView";
import HoldGuestDetailsView from "../../components/Booking/HoldGuestDetailsView";
import HoldBookingConformBookingView from "../../components/Booking/HoldBookingConformBookingView";
import SelectionCard from "../../components/Booking/Cards/SelectionCard";
import GuestsCard from "../../components/Booking/Cards/GuestsCard";
import ProductCard from "../../components/Booking/Cards/ProductCard";
import RoomsCard from "../../components/Booking/Cards/RoomsCard";

class HoldBooking extends Component {

    state = {
        stepHold: sessionStorage.getItem('hold-step') == undefined ? 1 : parseInt(window.sessionStorage.getItem('hold-step')),
        backButtonStatus: true,
    };

    nextStep = () => {
        const { stepHold } = this.state;
        this.setState({
            stepHold: stepHold + 1,
        });
    };
    
    prevStep = () => {
        const { stepHold } = this.state;
        this.setState({ 
            stepHold: stepHold - 1,
        });
    };
      
    handleModify = (newStep) => {
        const { stepHold } = this.state;
        this.setState({
            stepHold: newStep
        })
    }

    backButtonFunction = () => {
        this.setState({backButtonStatus: false})
    }

    switchState = () => {
        const { stepHold } = this.state;
        sessionStorage.setItem('hold-step', stepHold);
        var maxCount = sessionStorage.getItem('maxHoldCount')
        switch (stepHold) {
            case 1:
                return (
                    <>
                        <HeaderView 
                            prevStep={this.prevStep}
                            backStatus={false}
                            stepHold={stepHold}
                            handleModify={this.handleModify}
                        />
                        <GuestSelectionView
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            maxRoom={maxCount}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <HeaderView 
                           prevStep={this.prevStep}
                           backStatus={true}
                           stepHold={stepHold}
                           handleModify={this.handleModify}
                        />
                        <HoldBookingRoomSelectionView
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChange={this.handleChange}
                          // values={values}
                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <HeaderView 
                          prevStep={this.prevStep}
                          backStatus={true}
                          stepHold={stepHold}
                          handleModify={this.handleModify}
                        />
                        <PaymentSummaryView
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChange={this.handleChange}
                          // values={values}
                        />
                    </>
                );
            case 4:
                return (
                    <>
                        <HeaderView 
                            prevStep={this.prevStep}
                            backStatus={true}
                            stepHold={stepHold}
                            handleModify={this.handleModify}
                        />
                        <HoldGuestDetailsView
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChange={this.handleChange}
                          // values={values}
                        />
                    </>
                );
            case 5:
                return (
                    <>
                        <HeaderView 
                            prevStep={this.prevStep}
                            backStatus={this.state.backButtonStatus}
                            stepHold={stepHold}
                            handleModify={this.handleModify}
                        />
                        <HoldBookingConformBookingView
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChange={this.handleChange}
                          backButtonFunction = {this.backButtonFunction}
                          // values={values}
                        />
                    </>
                );
        }
    }

    render() {
        return (
            <Layout>
                <div className="flex flex-col h-screen">
                    <main class="flex-grow overflow-y-auto md:pr-14 lg:pr-14 pl-4 pr-4 md:pl-14 lg:14">
                        {this.switchState()}
                    </main>
                </div>
            </Layout>
        );
      }
    }
    
export default HoldBooking;

const HeaderView = ({prevStep, backStatus, stepHold, handleModify}) => {
    const [user, setUser] = useContext(UserContext);
    const booking = user.booking || {};
    const [modifyBooking, setModifyBooking] = useState(false)
    const backbuttonpressed = () => {
      prevStep()
    }
  
    const toggleModify = () => {
        console.log(modifyBooking)
      setModifyBooking(!modifyBooking);
    }

    return(
        <>
            <div className="grid grid-cols-3 border-b p-6">
                <div>
                    {backStatus && <button className="font-semibold text-j-magenta" onClick={backbuttonpressed}>
                                <i className="fas fa-arrow-circle-left" style={{fontSize: "28px"}}></i>
                    </button>
                    }
                </div>
                <div className="text-center text-j-orange font-bold">{booking.itinerary.ship.name}</div>
                <div 
                className="uppercase cursor text-right text-j-magenta"
                >
                <button className="bg-transparent  font-semibold py-2 px-4 border border-j-magenta rounded" onClick={() => toggleModify()}>
                    Modify Booking
                </button>
                </div>
            </div>
            <ModifyToggle stepHold={stepHold} modifyBooking={modifyBooking} handleModify={handleModify}/>
        </>
      )
}  

const ModifyToggle = ({stepHold, modifyBooking, handleModify}) => {
    console.log(stepHold)
    console.log(modifyBooking)
    console.log(handleModify)
    const [user, setUser] = useContext(UserContext);
    const booking = user.booking || {};
    const gridClass = stepHold>2 ? "grid grid-cols-1 md:grid-cols-1 gap-4" : "grid grid-cols-1 md:grid-cols-1 gap-4"
    return(
      <div className={gridClass}>
        <div>
          {stepHold > 0 && modifyBooking && (
            <ProductCard booking={booking} handleModify={handleModify} step={stepHold}/>
          )}
          {stepHold > 1 && modifyBooking && (
            <RoomsCard booking={booking} handleModify={handleModify} step={stepHold}/>)
          }
          {stepHold > 2 && modifyBooking && (
            <SelectionCard booking={booking} handleModify={handleModify} step={stepHold}/>)
          }
          {stepHold > 4 && modifyBooking && (
            <GuestsCard booking={booking} handleModify={handleModify} step={stepHold}/>)
          }
        </div>
      </div>
    )
  }
  