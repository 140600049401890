import React, { Component, useRef, useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import Table from "../../components/Table/table";
import { useHistory } from "react-router-dom";
import { getToken } from "../../utils/common";


export default function BookingList() {
  let history = useHistory();
  var dataNew;
  const [paginationstatus, setPaginationStatus] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [currentPages, setCurrentPages] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    bookingListApi(count)
  }, [paginationstatus]);

  const bookingListApi = (count, search, sDate, eDate, cDate, moreData, created_via) => {
    var params = '&';
    if (search != null && search != undefined && search.length > 1) {
      params += 'search=' + search + '&'
    }
    if (sDate != "" && sDate != undefined) {
      params += 'from_date=' + sDate + '&'
    }
    if (eDate != "" && eDate != undefined) {
      params += 'to_date=' + eDate + '&'
    }
    if (cDate != "" && cDate != undefined) {
      params += 'cruise_date=' + cDate + '&'
    } if (created_via !="" && created_via != undefined) {
      params += 'created_via=' + created_via + '&'
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + '/agent_portal/bookings?page=' + count + params, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          `Bearer ${getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        dataNew = response.bookings
        if (moreData) {
          setData(data => data.concat(response.bookings))
        } else {
          setData(response.bookings)
        }
        setTotalRecords(response.pagination.total_records)
        setCurrentPages(response.pagination.current_page)
        setTotalPages(response.pagination.total_pages)
        setLoading(false);
      })
  }

  // const searchApi = (search) => {
  //   fetch(process.env.REACT_APP_API_ENDPOINT+'/agent_portal/bookings?search='+search, {
  //     method: 'GET',
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization:
  //       `Bearer ${getToken()}`,
  //     },
  //   })
  //   .then((response) => response.json())
  //   .then((response) => {
  //     setData(data => response.bookings)
  //   })
  // }
  const columns =
    [
      {
        Header: 'Booking ID',
        accessor: 'number', // accessor is the "key" in the data
      },
      {
        Header: 'Date',
        accessor: 'booked_on',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Details',
        accessor: "id",
        Cell: ({ cell }) => (
          <button className="px-5 py-2 border-blue-500 border text-blue-500 rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none px-10 py-2 whitespace-nowrap" value={cell.accessor} onClick={() => viewDetails(cell.row.values.id)}>
            Details
          </button>
        )
      },
    ];

  const viewDetails = (id) => {
    history.push({
      pathname: '/admin/booking-details',
      state: { id: id }
    })
  }


  return (
    <>
      <div className="flex flex-col h-screen">
        <main class="flex-grow overflow-y-auto">
          <div className="mr-20 ml-20 mt-5">
            <Table
              columns={columns}
              data={data}
              bookingListApi={bookingListApi}
              count={count}
              currentPages={currentPages}
              totalPages={totalPages}
              totalRecords={totalRecords}
              loading={loading}
            />
          </div>
        </main>
      </div>
    </>
  )
}