import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import Joi from "joi"
import { joiResolver } from "@hookform/resolvers/joi"
import { cvv, date } from "../../../utils/validations"
import InputField from "../../Form/InputField"
import SelectField from "../../Form/SelectField"
import PaymentForm from "./PaymentForm"
import CheckoutForm from "./CheckoutForm"

let currentDate = new Date()
let currentYear = Number(currentDate.getFullYear())
let currentMonth = Number(Number(currentDate.getMonth()) + 1)

const schema = Joi.object({
  cardNumber: Joi.string().creditCard().required(),
  month: Joi.number().when("year", {
    is: currentYear,
    then: Joi.number().integer().min(currentMonth).max(12),
    otherwise: Joi.number().integer().min(1).max(12),
  }),
  year: Joi.number().integer().min(currentYear),
  cvv,
})

const CardPaymentForm = ({
  booking,
  billingData,
  partialStatus,
  submitRef,
  setFailed,
  paymentPath,
  amount,
  walletStatus,
  bookingID,
  paymentOptionID,
  normalBooking,
  bookingType
}) => {
  const { register, errors, handleSubmit, setError, getValues } = useForm({
    resolver: joiResolver(schema),
  })
  const [type, setType] = useState("text")
  const [submitted, setSubmitted] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false)

  const onSubmit = () => {
    setClicked(true)
    if (submitRef && submitRef.current) submitRef.current.click()
  }

  useEffect(() => {
    // paymentPath 'if condition' is commented since paymentPath prop is undefined. 
    // also both execute same thing.
    if (clicked || clicked && billingData) {
      setSubmitted(true)
      setClicked(false)
      setShowCheckout(true)
    }
    // if (paymentPath == "wallet") {
    //   if (clicked) {
    //     setSubmitted(true)
    //     setClicked(false)
    //     setShowCheckout(true)
    //   }
    // } else if (paymentPath == "booking") {
    //   if (clicked && billingData) {
    //     setSubmitted(true)
    //     setClicked(false)
    //     setShowCheckout(true)
    //   }
    // }
  }, [billingData, clicked])

  return (
    <>
      <PaymentForm onSubmit={handleSubmit(onSubmit)} submitted={submitted}>
        <InputField
          placeholder="Card number"
          name="cardNumber"
          type="number"
          maxLength="16"
          lessSpace
          ref={register({ required: true })}
          error={
            errors && errors.cardNumber && "Please enter a valid card number"
          }
        />
        <div className="flex">
          <InputField
            className="mr-4"
            placeholder="Year"
            type="number"
            name="year"
            pattern="[0-9]{4}"
            maxLength="4"
            lessSpace
            ref={register({ required: true })}
            error={errors && errors.year && "Please enter a valid year"}
          />
          <SelectField
            className="w-full mr-4"
            name="month"
            placeholder="Month"
            ref={register({ required: true })}
            error={errors && errors.month && "Please enter a valid month"}
          >
            <option selected value="" disabled>
              Month
            </option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </SelectField>
          <InputField
            className=""
            placeholder="CVV"
            type="password"
            name="cvv"
            maxLength="3"
            lessSpace
            noClear
            ref={register({ required: true })}
            error={errors && errors.cvv && "Please check the CVV"}
          />
        </div>
        <p className="text-danger pl-2">*Additional 1.70% charges on credit card</p>
        <p className="text-danger pl-2">**Please carry the same card used while booking during your travel</p>
      </PaymentForm>
      {showCheckout ? (
        <CheckoutForm
          amount={amount}
          paymentPath={paymentPath}
          walletStatus={walletStatus}
          bookingID={bookingID}
          booking={booking}
          partialStatus={partialStatus}
          billingData={billingData}
          paymentOptionID={paymentOptionID}
          normalBooking= { normalBooking }
          bookingType={bookingType}
          {...getValues()}
          cancel={(error) => {
            setShowCheckout(false)
            setSubmitted(false)
            if (error) setFailed(error)
          }}
        />
      ) : null}
    </>
  )
}

export default CardPaymentForm
